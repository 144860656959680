import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@bts-web/design-system/component/icon';
import { AssetImage, HyperLinkButtonBase } from '../../common';
import { StartSavingButton } from '../StartSavingButton/StartSavingButton';
import { SavingPlansTranslationsKeys } from '../utils/getSavingsPlansTranslations';
import { AssetFields } from '../../asset-details/components/AssetDetailsDataContext/AssetDetailsDataContext.use-client';

const containerStyles = css({
  border: '1px solid rgba(234, 234, 234, 1)',
  borderEndEndRadius: 'smallBottomRight',
  borderEndStartRadius: 'smallTopRight',
  borderStartStartRadius: 'smallTopLeft',
  borderStartEndRadius: 'smallBottomLeft',
  padding: 'small',
  display: 'flex',
  flexDirection: 'column',
  gap: 'medium',
});

const titleSectionStyles = css({
  display: 'flex',
  justifyContent: 'space-between',
});

const titleStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'extra_small_3',

  '& > span:first-child': {
    color: 'neutrals.text_primary',
    fontSize: 'body.medium_medium',
    fontWeight: 'body.medium_medium',
    lineHeight: 'body.medium_medium',
  },

  '& > span:last-child': {
    color: 'neutrals.text_secondary',
    fontSize: 'caption.small_light',
    fontWeight: 'caption.small_light',
    lineHeight: 'caption.small_light',
  },
});

const startSavingBtnStyles = css({
  alignItems: 'center',
  justifyContent: 'start',

  '& > span': {
    color: 'neutrals.text_primary',
    fontSize: 'body.medium_medium',
    fontWeight: 'body.medium_medium',
    lineHeight: 'body.medium_medium',
  },
});

const arrowIconStyles = css({
  color: 'positive.fill_primary',
});

interface SavingsPlansFrameProps {
  assetInfo?: Pick<NonNullable<AssetFields>, 'id' | 'name' | 'logoUrl'>;
  savingsPlanCount: number;
  translations: SavingPlansTranslationsKeys;
  title?: string;
  subTitle?: string;
}

const SavingsPlansFrame = ({
  savingsPlanCount,
  assetInfo,
  translations,
  subTitle,
  title,
}: SavingsPlansFrameProps) => {
  // temporary hide subtitle for asset details page when there are savings plans
  const displaySubtitle = assetInfo ? !savingsPlanCount : true;

  return (
    <div className={containerStyles} data-testid="savings-plans-frame">
      <div className={titleSectionStyles}>
        <div className={titleStyles}>
          <span>{title}</span>
          <span>{displaySubtitle && subTitle}</span>
        </div>

        {assetInfo?.logoUrl ? (
          <AssetImage
            height={24}
            width={24}
            placeholder="empty"
            src={assetInfo.logoUrl}
            alt={assetInfo.name as string}
          />
        ) : (
          <Icon iconPrefix="mdi" icon="clock-plus-outline" size="24" />
        )}
      </div>

      {savingsPlanCount ? (
        <HyperLinkButtonBase
          className={startSavingBtnStyles}
          noPadding
          visual="ghost"
          to={
            assetInfo?.id
              ? `/savings-plans?assetId=${assetInfo.id}`
              : '/savings-plans'
          }
        >
          <span>{translations.savingsPlanManageButton}</span>
          <Icon className={arrowIconStyles} icon="arrow-right" size="16" />
        </HyperLinkButtonBase>
      ) : (
        <StartSavingButton
          className={startSavingBtnStyles}
          continueUrl={
            assetInfo?.id
              ? `/trade/standing-buy/${assetInfo.id}`
              : '/assets/discover?next=savings'
          }
          translations={translations}
        />
      )}
    </div>
  );
};

export { SavingsPlansFrame };
