/**
 * @generated SignedSource<<32610d4ac99ba34997f1962b020bd0af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CryptoTransfersTransactionRequiredActions = "BENEFICIARY_DETAILS";
export type CryptoTransfersTransactionStatus = "CANCELED" | "FINISHED" | "ON_HOLD" | "PENDING" | "PROCESSING" | "REJECTED";
export type InitiatedBy = "AUTOMATED_ORDER" | "BITPANDA_CORPORATE_ACTION" | "BITPANDA_MANUAL_TRADE" | "BITPANDA_SAVINGS" | "OTHER" | "USER";
export type TaxDeclarationStatus = "DECLARED" | "NOT_ELIGIBLE" | "UNDECLARED" | "UNKNOWN";
export type TransactionCategory = "CORPORATE_ACTION" | "CRYPTO_ACTION" | "CRYPTO_TRANSFER" | "NON_USER_INITIATED" | "TRADE";
export type TransactionDirection = "INCOMING" | "OUTGOING";
export type TransactionStatus = "CREATED" | "EXPIRED" | "FAILED" | "FINISHED" | "IN_PROGRESS";
export type TransactionType = "BUY" | "SELL";
export type TransactionsListInput = {
  after?: string | null;
  assetId?: string | null;
  before?: string | null;
  category?: TransactionCategory | null;
  first?: number | null;
  last?: number | null;
  type?: TransactionType | null;
};
export type TransactionsViewWithCryptoTransfersQuery$variables = {
  input?: TransactionsListInput | null;
};
export type TransactionsViewWithCryptoTransfersQuery$data = {
  readonly cryptoTransfersTransactions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: string;
        readonly asset: {
          readonly __typename: string;
          readonly id: string;
          readonly logoUrl: string | null;
          readonly name: string | null;
          readonly symbol: string | null;
        };
        readonly assetAmount: PrecisionFloat;
        readonly assetFee: PrecisionFloat;
        readonly createdAt: any | null;
        readonly externalAddress: {
          readonly address: string;
          readonly createdAt: any;
          readonly destinationTag: string | null;
          readonly destinationTagType: string | null;
          readonly id: string;
          readonly network: {
            readonly id: string;
            readonly name: string | null;
          };
        } | null;
        readonly fiat: {
          readonly id: string;
          readonly name: string | null;
          readonly symbol: string | null;
        };
        readonly fiatAmount: PrecisionFloat;
        readonly fiatFee: PrecisionFloat;
        readonly hash: string | null;
        readonly id: string;
        readonly internalAddress: {
          readonly address: string;
          readonly createdAt: any;
          readonly destinationTag: string | null;
          readonly destinationTagType: string | null;
          readonly id: string;
          readonly network: {
            readonly id: string;
            readonly name: string | null;
          };
        } | null;
        readonly network: {
          readonly id: string;
          readonly name: string | null;
        };
        readonly requiredActions: ReadonlyArray<CryptoTransfersTransactionRequiredActions | null> | null;
        readonly status: CryptoTransfersTransactionStatus;
        readonly taxDeclarationStatus: TaxDeclarationStatus | null;
      } | null;
    } | null> | null;
  } | null;
  readonly transactions: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly node: {
        readonly __typename: string;
        readonly asset: {
          readonly __typename: string;
          readonly id: string;
          readonly logoUrl: string | null;
          readonly name: string | null;
          readonly symbol: string | null;
        } | null;
        readonly assetAmount: PrecisionFloat | null;
        readonly assetPrice?: PrecisionFloat | null;
        readonly category: TransactionCategory;
        readonly direction: TransactionDirection | null;
        readonly fiatAmount: PrecisionFloat | null;
        readonly id: string;
        readonly initiatedBy: InitiatedBy | null;
        readonly offer?: {
          readonly id: string;
        } | null;
        readonly status: TransactionStatus | null;
        readonly time: any | null;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
    };
    readonly totalCount: number | null;
  } | null;
};
export type TransactionsViewWithCryptoTransfersQuery = {
  response: TransactionsViewWithCryptoTransfersQuery$data;
  variables: TransactionsViewWithCryptoTransfersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Network",
  "kind": "LinkedField",
  "name": "network",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "address",
    "storageKey": null
  },
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "destinationTag",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "destinationTagType",
    "storageKey": null
  },
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logoUrl",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assetAmount",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fiatAmount",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v12 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "assetPrice",
    "storageKey": null
  }
],
v13 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TransactionOffer",
    "kind": "LinkedField",
    "name": "offer",
    "plural": false,
    "selections": [
      (v2/*: any*/)
    ],
    "storageKey": null
  }
],
v14 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "input",
        "value": {
          "size": 100,
          "status": [
            "PROCESSING",
            "PENDING"
          ]
        }
      }
    ],
    "concreteType": "CryptoTransfersTransactionsConnection",
    "kind": "LinkedField",
    "name": "cryptoTransfersTransactions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CryptoTransfersTransactionsEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CryptoAddress",
                "kind": "LinkedField",
                "name": "internalAddress",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CryptoAddress",
                "kind": "LinkedField",
                "name": "externalAddress",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "asset",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v7/*: any*/),
                  (v3/*: any*/),
                  (v8/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "assetFee",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Fiat",
                "kind": "LinkedField",
                "name": "fiat",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fiatFee",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hash",
                "storageKey": null
              },
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "requiredActions",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "taxDeclarationStatus",
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "cryptoTransfersTransactions(input:{\"size\":100,\"status\":[\"PROCESSING\",\"PENDING\"]})"
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TransactionsConnection",
    "kind": "LinkedField",
    "name": "transactions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasNextPage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasPreviousPage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endCursor",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TransactionEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "time",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "category",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "initiatedBy",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "direction",
                "storageKey": null
              },
              (v11/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "asset",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v3/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v12/*: any*/),
                "type": "BuyTransaction",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v12/*: any*/),
                "type": "SellTransaction",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v13/*: any*/),
                "type": "DepositTransaction",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v13/*: any*/),
                "type": "WithdrawalTransaction",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TransactionsViewWithCryptoTransfersQuery",
    "selections": (v14/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TransactionsViewWithCryptoTransfersQuery",
    "selections": (v14/*: any*/)
  },
  "params": {
    "cacheID": "e67bc409f7bb6e0f61c097f03c07359f",
    "id": null,
    "metadata": {},
    "name": "TransactionsViewWithCryptoTransfersQuery",
    "operationKind": "query",
    "text": "query TransactionsViewWithCryptoTransfersQuery(\n  $input: TransactionsListInput\n) {\n  cryptoTransfersTransactions(input: {status: [PROCESSING, PENDING], size: 100}) {\n    edges {\n      node {\n        __typename\n        id\n        internalAddress {\n          id\n          address\n          network {\n            id\n            name\n          }\n          destinationTag\n          destinationTagType\n          createdAt\n        }\n        externalAddress {\n          id\n          address\n          network {\n            id\n            name\n          }\n          destinationTag\n          destinationTagType\n          createdAt\n        }\n        asset {\n          id\n          logoUrl\n          name\n          symbol\n          __typename\n        }\n        assetAmount\n        assetFee\n        fiat {\n          id\n          name\n          symbol\n        }\n        fiatAmount\n        fiatFee\n        network {\n          id\n          name\n        }\n        hash\n        status\n        requiredActions\n        taxDeclarationStatus\n        createdAt\n      }\n    }\n  }\n  transactions(input: $input) {\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      endCursor\n    }\n    totalCount\n    edges {\n      cursor\n      node {\n        __typename\n        id\n        time\n        category\n        initiatedBy\n        direction\n        status\n        fiatAmount\n        asset {\n          id\n          logoUrl\n          symbol\n          name\n          __typename\n        }\n        assetAmount\n        ... on BuyTransaction {\n          assetPrice\n        }\n        ... on SellTransaction {\n          assetPrice\n        }\n        ... on DepositTransaction {\n          offer {\n            id\n          }\n        }\n        ... on WithdrawalTransaction {\n          offer {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9022830bbdd73d09a746823b9c36456f";

export default node;
