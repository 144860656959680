'use client';

import { ReactNode } from 'react';
import { css, stack } from '@bts-web/utils-style-engine';
import { useRouter } from 'next/navigation';
import { Icon } from '@bts-web/design-system/component/icon';
import {
  AssetImage,
  DialogBase,
  HyperLinkButtonBase,
  HyperLinkButtonBaseVariants,
  AlertBox,
} from './../../../common';

const containerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  mx: 'auto',
  flex: {
    base: 'auto',
    ['lg' as string]: '1',
  },
});

const titleAndDescriptionContainerStyles = css({
  margin: 'auto 0',
  gap: 'extra_large',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  pt: {
    base: '0',
    lg: 'small',
  },
  pb: {
    base: '0',
    lg: 'medium',
  },
});

const titleStyles = css({
  fontSize: 'headline.large_semi_bold',
  fontWeight: 'headline.large_semi_bold',
  lineHeight: 'headline.large_semi_bold',
  letterSpacing: 'headline.large_semi_bold',
  textAlign: 'center',
  mx: 'auto',
});

export interface FailureModalProps {
  title: string;
  description: string | ReactNode;
  poweredByLabel?: string;
  tryAgainLink?: {
    label: string;
    url: string;
    visual?: HyperLinkButtonBaseVariants;
  } | null;
  cancelLink?: {
    label: string;
    url: string;
    visual?: HyperLinkButtonBaseVariants;
  } | null;
  logo?: {
    url: string;
    name: string;
  };
  onClose: () => void;
}

export const FailureModal = ({
  title,
  description,
  tryAgainLink,
  cancelLink,
  poweredByLabel,
  onClose,
  logo,
}: FailureModalProps) => {
  const router = useRouter();

  const handleClose = () => {
    router.refresh();

    onClose();
  };

  return (
    <DialogBase
      id="base-modal"
      open
      onOpenChange={handleClose}
      size="medium"
      onMobileFullScreen
      visual="secondary"
      disableBackdropClose
      closeIcon={null}
    >
      <div className={containerStyles}>
        <div className={titleAndDescriptionContainerStyles}>
          {logo && (
            <div
              className={css({
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '100%',
                p: {
                  base: 'extra_small_2',
                  lg: 'small',
                },
                backgroundColor: 'neutrals.fill_quinary',
                boxShadow: `0px 0px 18px 0px rgba(0, 0, 0, 0.25)`,
              })}
            >
              <AssetImage
                src={logo.url}
                width={48}
                height={48}
                alt={logo.name}
                priority
                testId="trade-asset-logo"
                aria-label="asset logo"
              />
            </div>
          )}
          <h1 className={titleStyles}>{title}</h1>

          <AlertBox variant="error-full">
            <div
              className={css({
                display: 'flex',
                flexDirection: 'column',
                gap: 'medium',
                alignItems: 'center',
                py: 'medium',
                px: 'extra_small_2',
              })}
            >
              <Icon
                icon="dismiss-circle"
                size="24"
                theme="regular"
                data-element="icon-visual"
                data-testid="icon-error"
              />

              <p>{description}</p>
            </div>
          </AlertBox>
        </div>

        <div
          className={stack({
            marginTop: 'auto',
            marginInlineStart: 'auto',
            marginInlineEnd: 'auto',
            maxWidth: '390px',
            width: '100%',
            gap: 'small',
            alignItems: 'center',
          })}
        >
          {poweredByLabel && (
            <p
              className={css({
                color: 'neutrals.text_secondary',
                fontSize: 'caption.small',
                fontWeight: 'caption.small',
                lineHeight: 'caption.small',
                letterSpacing: 'caption.small',
                textAlign: 'center',
              })}
            >
              {poweredByLabel}
            </p>
          )}

          {tryAgainLink && (
            <HyperLinkButtonBase
              prefetch
              visual={tryAgainLink.visual || 'accent'}
              data-testid="continue-link"
              to={tryAgainLink.url}
              onClick={handleClose}
            >
              {tryAgainLink.label}
            </HyperLinkButtonBase>
          )}

          {cancelLink && (
            <HyperLinkButtonBase
              prefetch
              visual={cancelLink.visual || 'accent'}
              data-testid="cancel-link"
              to={cancelLink.url}
              onClick={handleClose}
            >
              {cancelLink.label}
            </HyperLinkButtonBase>
          )}
        </div>
      </div>
    </DialogBase>
  );
};
