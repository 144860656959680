'use client';

import { type TransactionsViewQuery } from '@bts-web/data-layer/server';
import {
  InfiniteScroll,
  InfiniteScrollItem,
} from '@bts-web/design-system/component/infinite-scroll';
import { CellSkeleton } from '../../common/components/loading/CellSkeleton';
import {
  TransactionItem,
  TransactionItemProps,
} from '../TransactionItem/TransactionItem';

export interface InfiniteTransactionListProps {
  itemsPerPage: number;
  loadMoreTransactions: (itemsPerPage: number) => void;
  transactions: TransactionsViewQuery['response']['transactions'];
}

const InfiniteTransactionList = ({
  itemsPerPage,
  transactions,
  loadMoreTransactions,
}: InfiniteTransactionListProps) => {
  if (!transactions?.edges?.length) {
    return null;
  }

  return (
    <InfiniteScroll
      itemsPerPage={itemsPerPage}
      fetchMore={loadMoreTransactions}
      hasMore={transactions?.pageInfo.hasNextPage}
      skeleton={<CellSkeleton />}
      items={
        transactions?.edges.map(
          (transaction) => transaction?.node,
        ) as InfiniteScrollItem[]
      }
      renderItemComponent={(transaction) => (
        <TransactionItem
          transaction={transaction as TransactionItemProps['transaction']}
          showCategoryTypeChip
        />
      )}
    />
  );
};

export { InfiniteTransactionList };
