import { NextPage } from 'next';
import {
  getLocale,
  i18n,
  initServerTranslation,
  Language,
  updateServerLanguage,
} from '@bts-web/utils-lokalise';
import { TFunction } from 'i18next';
import { redirect } from 'next/navigation';
import { getAppConfig } from '@bts-web/core-app-config';
import { isDevelopmentEnv } from '@bts-web/utils-functions';
import { getAssetById } from '../../common';
import { AppMainLayoutWrapper, AssetDetailTradeWrapper } from '../../layouts';
import { getTradeTranslations, AssetPageInitiateTradeBox } from '../../trade';
import { AssetDetailsControllerWithSuspense } from '../AssetDetailsControllerWithSuspense';
import { AssetType } from '../types';
import { AssetTypeFilters } from '../utils/AssetTypeFilters';
import { getAssetDetailsTranslations } from '../utils/getAssetDetailsTranslations';

export interface AssetDetailsPageProps {
  params: Promise<{
    assetId: string;
  }>;
}

const AssetDetailByIdIndex: NextPage<AssetDetailsPageProps> = async (props) => {
  const { params } = props;

  await updateServerLanguage();

  const { t } = initServerTranslation();

  const tradeTranslations = getTradeTranslations(t as TFunction);

  const { assetId } = await params;

  const locale = getLocale(i18n.resolvedLanguage as Language);

  const assetDetailTranslations = getAssetDetailsTranslations(t as TFunction);

  const assetByIdResponse = await getAssetById(assetId);

  const { assetById } = assetByIdResponse.data ?? {};

  const { currency } = getAppConfig();

  if (!assetById) {
    if (!isDevelopmentEnv()) {
      redirect('/error/generic');
    }

    return null;
  }

  const { __typename, name } = assetById;

  return (
    <AppMainLayoutWrapper
      settings={{
        hideDesktopFooter: true,
        noMainElementPaddingBottom: true,
        hideTopBarTradeButton: true,
        hideMobileHeader: true,
      }}
      title={{
        titleType: 'breadcrumbs',
        value: [
          {
            label: assetDetailTranslations[__typename as AssetType],
            href: `/assets/discover?assetType=${encodeURIComponent(
              AssetTypeFilters[__typename as AssetType],
            )}`,
          },
          {
            label: name ?? '',
          },
        ],
      }}
    >
      <AssetDetailTradeWrapper
        AssetDetailsContent={
          <AssetDetailsControllerWithSuspense assetId={assetId} />
        }
        hideMobile="tradeElement"
        TradeStateContent={
          <AssetPageInitiateTradeBox
            assetInitialData={assetById}
            currency={currency}
            currentLocale={locale}
            tradeTranslations={tradeTranslations}
          />
        }
      />
    </AppMainLayoutWrapper>
  );
};

export { AssetDetailByIdIndex };
