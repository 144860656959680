'use client';

import {
  SavingsPlansTransactionsQuery,
  TransactionsViewQuery,
  TransactionsViewWithCryptoTransfersQuery,
} from '@bts-web/data-layer/server';
import Link from 'next/link';
import { useMemo } from 'react';
import { css } from '@bts-web/utils-style-engine';
import {
  formatAssetAmount,
  formatFiatAmount,
  getAssetAmountText,
  getTransactionTime,
} from './transactionItemUtils';
import { TransactionCell } from '../TransactionCell/TransactionCell.use-client';
import { useTransactionTitle } from '../utils/getTransactionTitle';
import {
  isCryptoTransferTransaction,
  isSavingsPlanTransaction,
  transactionTypeByTypeName,
  TransactionTypes,
  transactionTypesWithDetail,
} from '../types/transactions';
import { Chip } from '../../common/components/Chip/Chip';
import { useIsBelowScreenWidth } from '../../layouts';
import { AssetImage, ButtonBase } from '../../common';
import { useComposableDrawer } from '@bts-web/utils-context';
import { getTransactionIconName } from '../utils/transactions';
import { Icon } from '@bts-web/design-system/component/icon';
import { getAppConfig } from '@bts-web/core-app-config';
import { openTransactionDetailsDesktopDrawer } from './openTransactionDetailsDesktopDrawer';
import {
  getLocale,
  i18n,
  Language,
  useClientTranslation,
} from '@bts-web/utils-lokalise';

export type SavingsPlanTransactionItemType = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        SavingsPlansTransactionsQuery['response']['savingsPlanTransactions']
      >['edges']
    >[number]
  >['node']
>;

export type TransactionEntryType = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<TransactionsViewQuery['response']['transactions']>['edges']
    >[number]
  >['node']
>;

export type TransactionCryptoTransferEntryType = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        TransactionsViewWithCryptoTransfersQuery['response']['cryptoTransfersTransactions']
      >['edges']
    >[number]
  >['node']
>;

export type AllTypesTransaction =
  | SavingsPlanTransactionItemType
  | TransactionEntryType
  | TransactionCryptoTransferEntryType;

const isClassicTransaction = (
  transaction: AllTypesTransaction,
): transaction is TransactionEntryType => {
  return (transaction && 'direction' in transaction) ?? false;
};

const isSavingsPlansTransactionFromSavingsPlansPage = (
  transaction: AllTypesTransaction,
): transaction is SavingsPlanTransactionItemType => {
  return transaction && 'state' in transaction ? true : false;
};

export interface TransactionItemProps {
  transaction: AllTypesTransaction;
  showStatusChip?: boolean;
  showCategoryTypeChip?: boolean;
  useAssetLogoInsteadOfIcon?: boolean;
  useSimpleTitle?: boolean;
}

export const TransactionItem = ({
  transaction,
  showCategoryTypeChip,
  useAssetLogoInsteadOfIcon,
  useSimpleTitle,
}: TransactionItemProps) => {
  const { t } = useClientTranslation();

  const failedText = t('failed');

  const locale = getLocale(i18n.resolvedLanguage as Language);

  const transactionType: TransactionTypes =
    transactionTypeByTypeName[
      transaction?.__typename as keyof typeof transactionTypeByTypeName
    ];

  const href: string | null = useMemo(() => {
    const isSavingsPlanTransactionBool =
      isSavingsPlanTransaction(transactionType);

    if (!transactionTypesWithDetail.includes(transactionType)) {
      return null;
    }

    const isFailedSavingsPlanTransaction =
      isSavingsPlanTransactionBool &&
      (transaction as SavingsPlanTransactionItemType)?.state === 'FAILED';

    if (isFailedSavingsPlanTransaction) {
      return null;
    }

    if (isCryptoTransferTransaction(transactionType)) {
      const hasOfferid = (transaction as TransactionEntryType).offer?.id;

      return `/transactions/${hasOfferid ?? transaction.id}?transactionType=${transactionType}`;
    }

    if (
      isClassicTransaction(transaction) ||
      isSavingsPlansTransactionFromSavingsPlansPage(transaction)
    ) {
      return `/transactions/${transaction?.id}?transactionType=${transactionType}`;
    }

    return null;
  }, [transaction, transactionType]);

  const {
    assetAmount,
    isOutgoing,
    formattedDate,
    fiatAmountText,
    startIconContent,
    isFailedTransaction,
  } = useMemo(() => {
    const startIconContent = useAssetLogoInsteadOfIcon ? (
      <AssetImage
        height={24}
        width={24}
        src={transaction?.asset?.logoUrl as string}
        alt={transaction?.asset?.name as string}
        placeholder="empty"
      />
    ) : (
      <Icon icon={getTransactionIconName({ transactionType })} />
    );

    const isBuyOrSavingsPlan =
      transactionType === transactionTypeByTypeName.BuyTransaction ||
      transactionType === transactionTypeByTypeName.SavingsPlanTransaction;

    const isOutgoing = isClassicTransaction(transaction)
      ? transaction?.direction === 'OUTGOING'
      : false;

    const isGiveaway =
      transactionType === transactionTypeByTypeName.GiveawayTransaction;

    const isFailedTransaction =
      (transaction &&
        isSavingsPlansTransactionFromSavingsPlansPage(transaction) &&
        (transaction as SavingsPlanTransactionItemType)?.state === 'FAILED') ??
      false;

    const isCryptoTransferTransaction =
      (transaction as TransactionEntryType).category === 'CRYPTO_TRANSFER';

    const assetAmount = formatAssetAmount(transaction?.assetAmount, locale);

    const { currency } = getAppConfig();

    const fiatAmountText = formatFiatAmount(
      transaction?.fiatAmount,
      isBuyOrSavingsPlan,
      isGiveaway,
      isOutgoing,
      isCryptoTransferTransaction,
      {
        locale,
        currency,
      },
    );

    const formattedDate = getTransactionTime({
      locale,
      time: isClassicTransaction(transaction)
        ? (transaction?.time as string)
        : (transaction?.createdAt as string),
    });

    return {
      isFailedTransaction,
      startIconContent,
      formattedDate,
      fiatAmountText,
      assetAmount,
      isOutgoing,
      isBuyOrSavingsPlan,
      transactionType,
    };
  }, [locale, transaction, transactionType, useAssetLogoInsteadOfIcon]);

  const { openDrawer } = useComposableDrawer();

  const isMobile = useIsBelowScreenWidth(1024);

  const {
    grams: amountLabel,
    title,
    tagValue,
  } = useTransactionTitle({
    transactionType,
    name: transaction?.asset?.name,
  });

  const assetAmountText =
    transaction?.asset && transaction.asset.symbol
      ? getAssetAmountText(
          assetAmount,
          amountLabel,
          transaction.asset.__typename,
          transaction.asset.symbol,
        )
      : '';

  const clickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    e.stopPropagation();

    openTransactionDetailsDesktopDrawer(
      openDrawer,
      transaction as TransactionEntryType,
    );
  };

  const transactionCell = (
    <TransactionCell
      onClick={undefined}
      aria-label={`transaction ${transaction?.id}`}
      startIconSlot={startIconContent}
      endIconSlot={href && <Icon icon="arrow-right-gray" />}
      disabled={!href}
      key={transaction?.id as string}
      title={useSimpleTitle ? (transaction.asset?.name ?? '') : title}
      transactionType={transactionType}
      date={formattedDate}
      fiatAmountText={fiatAmountText}
      assetAmountText={assetAmount ? assetAmountText : undefined}
      direction={isOutgoing ? 'OUTGOING' : 'INCOMING'}
      tagContent={
        tagValue && showCategoryTypeChip ? (
          <Chip
            visual="primary"
            className={css({ marginInlineEnd: 'auto' })}
            label={tagValue} // This is the label of the text for the pill
          />
        ) : null
      }
      transactionStatus={isFailedTransaction ? failedText : undefined}
      visual={isFailedTransaction ? 'error' : undefined}
    />
  );

  const MobileWrapperElement = !href ? 'li' : Link;

  return isMobile && href ? (
    <MobileWrapperElement data-testid="transaction-cell-link" href={href}>
      {transactionCell}
    </MobileWrapperElement>
  ) : (
    <ButtonBase
      fullWidth
      noPadding
      visual="ghost"
      type="button"
      data-testid="transaction-cell-button"
      onClick={clickHandler}
      disabled={!href}
    >
      {transactionCell}
    </ButtonBase>
  );
};
