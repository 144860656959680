import { ErrorBoundaryWithSuspense } from '../../layouts/ErrorBoundary/ErrorBoundaryWithSuspense';
import { TransactionsPageSkeleton } from '../TransactionsPageSkeleton/TransactionsPageSkeleton.server';
import {
  TransactionsControllerProps,
  TransactionsController,
} from './TransactionsController.server';

export const TransactionsControllerWithSuspense = async ({
  searchParams,
}: TransactionsControllerProps) => {
  return (
    <ErrorBoundaryWithSuspense
      fallbackOrSkeleton={<TransactionsPageSkeleton />}
    >
      <TransactionsController searchParams={searchParams} />
    </ErrorBoundaryWithSuspense>
  );
};
