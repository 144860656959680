'use client';

import { type PropsWithChildren, useContext, useState } from 'react';
import { DEFAULT_DISABLED_SELL } from '@bts-web/core-features/trade';
import { Dispatch, SetStateAction, createContext } from 'react';

interface TradeContextValue {
  isSellButtonVisible: boolean;
  setSellButtonDisablerKeys: Dispatch<SetStateAction<string[]>>;
}

const TradeContext = createContext<TradeContextValue>({} as TradeContextValue);

const TradeProvider = ({ children }: PropsWithChildren) => {
  const [sellButtonDisablerKeys, setSellButtonDisablerKeys] = useState<
    string[]
  >([DEFAULT_DISABLED_SELL]);

  return (
    <TradeContext.Provider
      value={{
        isSellButtonVisible: sellButtonDisablerKeys.length === 0,
        setSellButtonDisablerKeys,
      }}
    >
      {children}
    </TradeContext.Provider>
  );
};

function useTrade() {
  const contextValue = useContext(TradeContext);

  if (contextValue === null) {
    throw new Error('useTrade must be used within a TradeContextProvider');
  }

  return contextValue;
}

export { TradeProvider, useTrade, TradeContext };
