'use client';

import { FC } from 'react';
import { Z_INDEX_LIBRARY, css } from '@bts-web/utils-style-engine';
import { UserAccessWithRoleCheck } from '../../../../common';
import { HyperLinkButtonBase } from '../../../../common/components/';
import { useAppNotification } from '../../../../notifications/NotificationContextProvider';

interface AssetDetailsFloatingCTAProps {
  isOwningAsset?: boolean;
  isAssetActive: boolean;
  isBuyActive: boolean;
  isSellActive: boolean;
  assetId: string;
  translations: {
    buy: string;
    sell: string;
    errorAssetNotAvailableTitle: string;
    errorAssetNotAvailableSubtitle: string;
  };
}

const inlineButtonClass = css({
  flex: 1,
  minWidth: '100px',
});

const AssetDetailsFloatingCTASpacer = () => (
  <div
    aria-label="floating menu spacer"
    className={css({ height: '96px', minHeight: '96px', maxHeight: '96px' })}
  />
);

const AssetDetailsFloatingCTA: FC<AssetDetailsFloatingCTAProps> = ({
  isOwningAsset,
  isAssetActive,
  isBuyActive,
  isSellActive,
  assetId,
  translations,
}) => {
  const { setAppNotification, closeAllNotifications } = useAppNotification();

  const showAssetDisabledNotification = () => {
    closeAllNotifications();

    setAppNotification({
      title: translations.errorAssetNotAvailableTitle,
      subtitle: translations.errorAssetNotAvailableSubtitle,
      visual: 'info',
      customDuration: 4000,
      withClose: true,
    });
  };

  const isBuyDisabled = !isBuyActive || !isAssetActive;

  const isSellDisabled = !isSellActive || !isAssetActive;

  return (
    <div
      role="menu"
      aria-label="trading options menu"
      style={{
        zIndex: Z_INDEX_LIBRARY.MOBILE_NAVIGATION,
      }}
      className={css({
        display: 'flex',
        flexWrap: 'wrap',
        p: 'small',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
      })}
    >
      <div
        data-testid="asset-details-mobile-trade-options"
        className={css({
          display: 'flex',
          flexWrap: 'wrap',
          p: 'small',
          position: 'fixed',
          gap: 'extra_small',
          bottom: 0,
          insetInlineStart: 0,
          insetInlineEnd: 0,
          backgroundColor: 'screen_background.primary',
          boxShadow: `0px -6px 12px 0px rgba(158,158,158,0.20)`,
        })}
      >
        <UserAccessWithRoleCheck>
          <HyperLinkButtonBase
            disabled={isBuyDisabled}
            {...(isBuyDisabled && {
              onClick: showAssetDisabledNotification,
            })}
            size="medium"
            visual="primary"
            to={`/trade/buy/${assetId}`}
            className={inlineButtonClass}
          >
            {translations.buy}
          </HyperLinkButtonBase>
        </UserAccessWithRoleCheck>

        {isOwningAsset && (
          <UserAccessWithRoleCheck>
            <HyperLinkButtonBase
              disabled={isSellDisabled}
              {...(isSellDisabled && {
                onClick: showAssetDisabledNotification,
              })}
              size="medium"
              visual="secondary"
              to={`/trade/sell/${assetId}`}
              className={inlineButtonClass}
              inverse
            >
              {translations.sell}
            </HyperLinkButtonBase>
          </UserAccessWithRoleCheck>
        )}
      </div>
    </div>
  );
};

export { AssetDetailsFloatingCTA, AssetDetailsFloatingCTASpacer };
