'use client';

import { useTrade } from '@bts-web/utils-context';
import { useEffect } from 'react';

export const DEFAULT_DISABLED_SELL = 'DEFAULT_DISABLED_SELL';

export const useTradeSellDisabler = ({
  shouldDisable,
  disablerKey,
}: {
  shouldDisable: boolean;
  disablerKey: string;
}) => {
  const { setSellButtonDisablerKeys } = useTrade();

  useEffect(() => {
    if (shouldDisable) {
      setSellButtonDisablerKeys((initialState) => [
        ...initialState.filter((key) => key !== DEFAULT_DISABLED_SELL),
        disablerKey,
      ]);
    } else {
      setSellButtonDisablerKeys((initialState) => {
        const initialkeys = initialState.filter(
          (key) => key !== DEFAULT_DISABLED_SELL,
        );

        return initialkeys.filter((key) => key !== disablerKey);
      });
    }

    return () => {
      setSellButtonDisablerKeys((initialState) => {
        const initialkeys = initialState.filter(
          (key) => key !== DEFAULT_DISABLED_SELL,
        );

        return initialkeys.filter((key) => key !== disablerKey);
      });
    };
  }, [shouldDisable]);
};
