'use client';

import { ModalComponent } from '@bts-web/utils-context';
import { DialogBase } from '../../../DialogBase/DialogBase';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { TwoFaLayoutWrapper } from '../shared/TwoFaLayoutWrapper';
import { TwoFaSectionTitle } from '../shared/TwoFaSectionTitle';
import { TwoFaSectionDescription } from '../shared/TwoFaSectionDescription';
import { TwoFaSectionButtonsWrapper } from '../shared/TwoFaSectionButtonsWrapper';
import { ButtonBase } from '../../../ButtonBase/ButtonBase';
import { signOutForTwoFaReset } from '../../../../../auth';
import { useState } from 'react';
import { css } from '@bts-web/utils-style-engine';

const TriggerResetTwoFaModal: ModalComponent = ({ onClose }) => {
  const [loading, setLoading] = useState(false);

  const { t } = useClientTranslation();

  const onLogoutChangeInfo = async () => {
    setLoading(true);

    await signOutForTwoFaReset();

    setLoading(false);
  };

  return (
    <DialogBase
      id="trigger-reset-2fa-modal"
      open
      onOpenChange={onClose}
      size="medium"
      disableBackdropClose
      visual="secondary"
      title={t('2fa_reset_title')}
      animate="top"
      showHeaderBottomDivider
    >
      <TwoFaLayoutWrapper>
        <TwoFaSectionTitle title={`${t('2fa_reset_are_you_sure')}`} />
        <TwoFaSectionDescription
          textContent={`${t('2fa_reset_description_body')}`}
        />
      </TwoFaLayoutWrapper>
      <TwoFaSectionButtonsWrapper>
        <ButtonBase
          isLoading={loading}
          onClick={onLogoutChangeInfo}
          visual="primary"
          size="large"
          fullWidth
          disabled={loading}
        >
          {t('2fa_reset_accept_button')}
        </ButtonBase>
        <ButtonBase
          disabled={loading}
          isLoading={loading}
          visual={'ghost'}
          onClick={onClose}
          className={css({
            textDecoration: 'underline',
          })}
        >
          {t('2fa_reset_skip')}
        </ButtonBase>
      </TwoFaSectionButtonsWrapper>
    </DialogBase>
  );
};

export { TriggerResetTwoFaModal };
