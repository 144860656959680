import { Icon } from '@bts-web/design-system/component/icon';
import { CompassIcon } from '../../../common/svgIcons/CompassIcon/CompassIcon';
import { css } from '@bts-web/utils-style-engine';
import { MobileNavItemLink } from './MobileNavItemLink.use-client/MobileNavItemLink.use-client';
import { MobileNavTradeButton } from './MobileNavTradeButton/MobileNavTradeButton';

export interface MobileNavWithTradeProps {
  labels: {
    portfolio: string;
    discover: string;
    trade: string;
  };
  savingsPlansCount: number;
}

const MobileNavWithTrade = ({
  labels,
  savingsPlansCount,
}: MobileNavWithTradeProps) => {
  return (
    <div
      className={css({
        alignItems: 'end',
        backgroundColor: 'neutrals.card_fill_primary',
        borderTopWidth: '1px',
        borderColor: 'neutrals.divider',
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        paddingInlineStart: 'extra_small',
        paddingInlineEnd: 'extra_small',
        position: 'fixed',
        zIndex: 3,
        borderRadius: '16px',
        borderTop: 'none',
        boxShadow:
          '0px 10px 20px 0px rgba(0, 0, 0, 0.05), 0px 4px 15px 0px rgba(0, 0, 0, 0.10)',
        gap: 'small',
        insetInlineStart: '50%',
        marginBottom: 'small',
        transform: 'translate(-50%, 0)',
        width: 'auto',
      })}
      role="navigation"
    >
      <MobileNavItemLink
        href="/assets/portfolio"
        icon={<Icon size="24" icon="portfolio" />}
        label={labels.portfolio}
      />

      <MobileNavTradeButton
        label={labels.trade}
        savingsPlansCount={savingsPlansCount}
      />

      <MobileNavItemLink
        href="/assets/discover"
        icon={<CompassIcon size={24} color="neutrals.text_primary" />}
        label={labels.discover}
      />
    </div>
  );
};

export { MobileNavWithTrade };
