'use client';

import { useCallback, useTransition } from 'react';
import { i18n } from '@bts-web/utils-lokalise';
import { useRouter } from 'next/navigation';
import type { UserLanguage } from '@bts-web/data-layer/server';
import { setCookieValue } from '@bts-web/utils-storage';
import {
  useClientUserInfo,
  useClientUserInfoUpdater,
} from '../../common/userinfo/InitialUserInfoFetcherAndParser/utils/useClientUserInfo';
import { useAppNotification } from '../../notifications/NotificationContextProvider';
import { postUpdateBusinessUser } from '../../common/gqlActions/postUpdateBusinessUser.action';
import { postUpdateUser } from '../../common/gqlActions/postUpdateUser.action';

export const useChangeLanguage = ({
  translations,
}: {
  translations: {
    errorTitle: string;
    errorSubtitle: string;
  };
}) => {
  const router = useRouter();

  const { refreshUserInfo } = useClientUserInfoUpdater();

  const { userType } = useClientUserInfo();

  const { setAppNotification } = useAppNotification();

  const [isUserUpdateLoading, startTransition] = useTransition();

  const defaultSelectedLanguage = i18n.resolvedLanguage as UserLanguage;

  const updateLanguageErrorHandler = useCallback(
    (error: Error): void => {
      i18n.changeLanguage(defaultSelectedLanguage, () => {
        setCookieValue('app_language', defaultSelectedLanguage as string);

        router.refresh();
      });

      setAppNotification({
        title: translations.errorTitle,
        subtitle: translations.errorSubtitle,
        withClose: true,
        visual: 'error',
      });

      console.log('[ERROR][change language]', error);

      return;
    },

    [setAppNotification],
  );

  const onChangeLanguage: (params: {
    languageValue: string;
    onError?: () => void;
  }) => void = useCallback(
    async ({ languageValue, onError }) => {
      const updateUserAction =
        userType === 'User' ? postUpdateUser : postUpdateBusinessUser;

      const variablesInput = {
        language: languageValue.toUpperCase() as UserLanguage,
      };

      startTransition(async () => {
        try {
          await updateUserAction(variablesInput);

          i18n.changeLanguage(languageValue, () => {
            setCookieValue('app_language', languageValue as string);
          });

          await refreshUserInfo();
        } catch (error) {
          if (onError) {
            onError();
          }

          updateLanguageErrorHandler(error as Error);
        }
      });
    },

    [updateLanguageErrorHandler, userType],
  );

  return {
    isUserUpdateLoading,
    onChangeLanguage,
  };
};
