import { NextPage } from 'next';
import { updateServerLanguage } from '@bts-web/utils-lokalise';
import { css } from '@bts-web/utils-style-engine';
import { t } from 'i18next';
import { BitpandaLogoDark } from '../assets';
import { Status, HyperLinkButtonBase } from '../components';
import { InfoLayout, InfoLayoutProps } from '../layout/InfoLayout/InfoLayout';
import { getServerUserInfo } from '../userinfo/InitialUserInfoFetcherAndParser/utils/getServerUserInfo';
import { permanentRedirect } from 'next/navigation';
import Image from 'next/image';
import { getAppConfig } from '@bts-web/core-app-config';

const descriptionStyles = css({
  color: 'neutrals.text_primary',
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  letterSpacing: 'body.medium',
  lineHeight: 'body.medium',
});

const welcomeStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'large',
  justifyContent: 'space-between',
  height: '100%',
});

const WelcomeIndexPage: NextPage = async () => {
  const { appId } = getAppConfig();

  await updateServerLanguage();

  const infoLayoutProps: InfoLayoutProps = {
    primaryText: t('welcome_screen_title_line_1'),
    secondaryText: t('welcome_screen_title_line_2'),
    description:
      appId === 3
        ? t('welcome_screen_subtitle_rakbank')
        : t('welcome_screen_subtitle'),
  };

  const userData = await getServerUserInfo();

  const { providerActivated, onboarded, userType } = userData;

  if (userType === 'User') {
    if (providerActivated === false && onboarded === true) {
      permanentRedirect(`error/generic`);
    }

    if (providerActivated === true && onboarded === true) {
      permanentRedirect(`assets/portfolio`);
    }
  } else {
    if (onboarded === true) {
      permanentRedirect(`assets/portfolio`);
    } else {
      // this redirects corporate users to the terms section, skipping the welcome content
      permanentRedirect('terms-and-conditions');
    }
  }

  // the returned default content is agreement to terms and conditions
  return (
    <InfoLayout {...infoLayoutProps} showLanguageSwitcher>
      <div className={welcomeStyles} aria-label="welcome section">
        <div
          className={css({
            display: 'flex',
            gap: 'extra_small_2',
            paddingTop: 'small',
          })}
          aria-label="welcome status entries"
        >
          <Status
            visual="filledNeutralDark"
            text={t('welcome_screen_label_1')}
          />
          <Status
            visual="filledNeutralDark"
            text={t('welcome_screen_label_2')}
          />
          <Status
            visual="filledNeutralDark"
            text={t('welcome_screen_label_3')}
          />
        </div>
      </div>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'large',
          mt: 'auto',
          pb: 'medium',
        })}
        aria-label="welcome onboarding button container"
      >
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            gap: 'extra_small_2',
          })}
        >
          <p className={descriptionStyles}>{t('powered_by')}</p>
          <Image priority src={BitpandaLogoDark} alt="Bitpanda" />
        </div>

        <HyperLinkButtonBase to={'/terms-and-conditions'} visual="primary">
          {t('continue')}
        </HyperLinkButtonBase>
      </div>
    </InfoLayout>
  );
};

export { WelcomeIndexPage };
