import { css, hstack } from '@bts-web/utils-style-engine';
import { decodeBase64String } from '@bts-web/utils-functions';
import { BuySellMoreButton } from '../BuySellMoreButton/BuySellMoreButton';
import { CopyToClipboard } from '../../common/components';

const footerStyles = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 'medium',
});

type TransactionDetailsFooterProps = {
  isSell: boolean;
  assetId: string;
  transactionId?: string;
  translations: {
    buyMore: string;
    sellMore: string;
    pid: string;
    orderIdCopySuccessMessage: string;
    orderIdCopyFailedMessage: string;
    copy: string;
  };
  showActionButton?: boolean;
};

const TransactionDetailsFooter = ({
  isSell,
  assetId,
  transactionId,
  translations,
  showActionButton = true,
}: TransactionDetailsFooterProps) => {
  const decodedTransactionId =
    transactionId && decodeBase64String(transactionId).split(':')[1];

  const pid = transactionId && (
    <div
      className={hstack({
        overflow: 'hidden',
        fontSize: 'label.medium_medium',
        justifyContent: 'space-between',
        flex: 'auto',
        width: '100%',
      })}
    >
      <div
        role="presentation"
        className={css({
          flex: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          minWidth: 0,
          color: 'neutrals.text_secondary',
          fontSize: 'caption.small',
          fontWeight: 'caption.small',
          lineHeight: 'caption.small',
          letterSpacing: 'caption.small',
        })}
      >
        {translations.pid} {decodedTransactionId}
      </div>
      <CopyToClipboard
        value={decodedTransactionId as string}
        translations={{
          successMessage: translations.orderIdCopySuccessMessage,
          failedMessage: translations.orderIdCopyFailedMessage,
        }}
      />
    </div>
  );

  const actionButton = (
    <BuySellMoreButton assetId={assetId} isSell={isSell}>
      {isSell ? translations.sellMore : translations.buyMore}
    </BuySellMoreButton>
  );

  return (
    <div
      className={css({
        width: '100%',
        marginTop: 'auto',
        paddingX: 'medium',
      })}
      data-testid="transaction-details-footer"
    >
      {pid}

      <div className={footerStyles}>
        <div className={css({ maxWidth: '342px', width: '100%' })}>
          {showActionButton ? actionButton : null}
        </div>
      </div>
    </div>
  );
};

export { TransactionDetailsFooter };
