'use client';

import { ReactNode } from 'react';
import { css, Z_INDEX_LIBRARY } from '@bts-web/utils-style-engine';
import { Root, Portal, Overlay, Content, Title } from '@radix-ui/react-dialog';
import { useComposableDrawer } from '@bts-web/utils-context';

interface DrawerProps {
  contentNode: ReactNode;
  disableOverlayClose?: boolean;
  position?: 'right' | 'bottom';
  onClose?: () => void;
  title?: string | null;
}

/**
 * Drawer is a functional wrapper component used for displaying custom content
 *  in a right-side modal together with a background overlay on top of the main UI
 *
 * @component
 * @param {object} props - The properties that define the component's behavior and display.
 * @param {ReactNode} props.buttonTriggerNode -  The buttonTriggerNode will triger the show/hide action of the drawer
 * @param {ReactNode} props.contentNode - The contentNode the drawer content
 * @param {boolean | undefined} props.disableOverlayClose - Blocks the closing when clicking just on the overlay, for usage with drawers which internally have their own close button
 *
 */

export const Drawer = ({
  contentNode,
  disableOverlayClose = false,
  position = 'right',
  title,
}: DrawerProps) => {
  const { closeDrawer, drawerStatus } = useComposableDrawer();

  const handleCloseDrawer = () => closeDrawer();

  const handleOverlayClick = () => {
    if (disableOverlayClose) {
      return;
    }

    handleCloseDrawer();
  };

  return (
    <Root
      open
      onOpenChange={disableOverlayClose ? undefined : handleCloseDrawer}
    >
      <Portal>
        <Overlay
          data-testid={`${position}-drawer-overlay`}
          style={{ zIndex: Z_INDEX_LIBRARY.DRAWER }}
          data-state={drawerStatus}
          className={css({
            position: 'fixed',
            inset: '0',
            '&[data-state="open"]': {
              animation: 'overlayShow 500ms cubic-bezier(0.16, 1, 0.3, 1)',
            },
            '&[data-state="close"]': {
              animation: 'overlayHide 500ms cubic-bezier(0.16, 1, 0.3, 1)',
            },

            backgroundColor: 'rgba(166, 166, 166, 0.50)',
            backdropFilter: 'blur(6px)',
          })}
          data-element={`${position}-drawer-overlay`}
          onClick={handleOverlayClick}
        />

        <Content
          aria-describedby={undefined} // supresses radix-ui browser console warning for missing Dialog.Description
          data-testid={`${position}-drawer-content`}
          data-element={`${position}-drawer-content`}
          data-state={drawerStatus}
          style={{ zIndex: Z_INDEX_LIBRARY.DRAWER }}
          className={css({
            position: 'fixed',
            bg: 'screen_background.primary',
            ...(position === 'right'
              ? {
                  top: 0,
                  right: 0,
                  width: {
                    base: '100%',
                    lg: '420px',
                  },
                  height: '100vh',
                  overflow: 'auto',
                  '&[data-state="open"]': {
                    animation:
                      'slideLeftAndFade 500ms cubic-bezier(0.16, 1, 0.3, 1)',
                  },
                  '&[data-state="close"]': {
                    animation:
                      'slideRightAndFade 500ms cubic-bezier(0.16, 1, 0.3, 1)',
                  },
                }
              : {}),
            ...(position === 'bottom'
              ? {
                  borderStartEndRadius: '8px',
                  borderStartStartRadius: '8px',
                  top: 'auto',
                  insetInlineStart: 0,
                  bottom: 0,
                  insetInlineEnd: 0,
                  height: 'auto',
                  maxHeight: '100%',
                  width: '100%',
                  '&[data-state="open"]': {
                    animation:
                      'slideUpAndFade 500ms cubic-bezier(0.16, 1, 0.3, 1)',
                  },
                  '&[data-state="close"]': {
                    animation:
                      'slideDownAndFade 500ms cubic-bezier(0.16, 1, 0.3, 1)',
                  },
                }
              : {}),
          })}
        >
          <Title className={css({ display: 'none' })}>{title}</Title>
          {contentNode}
        </Content>
      </Portal>
    </Root>
  );
};
