import Link from 'next/link';
import { css } from '@bts-web/utils-style-engine';
import { WrapperForMobileStickySectionHeader } from '../../layouts/LayoutWithLeftDesktopNav/Reusable/WrapperForMobileStickySectionHeader/WrapperForMobileStickySectionHeader';
import { NavigationBackButton } from '../../navigation/index';
import { HiddenDesktop } from '../../layouts/LayoutWithLeftDesktopNav/Reusable/HiddenDesktop/HiddenDesktop';
import { TabBreadcrumbs } from '../../common';

interface TransactionsPageStickyBreadcrumbsProps {
  translations: {
    navBarPortfolio: string;
    transactions: string;
  };
}

export const TransactionsPageStickyBreadcrumbs = ({
  translations,
}: TransactionsPageStickyBreadcrumbsProps) => {
  return (
    <WrapperForMobileStickySectionHeader>
      <HiddenDesktop>
        <div
          className={css({
            backgroundColor: 'screen_background.primary',
          })}
        >
          <TabBreadcrumbs LeftComponent={<NavigationBackButton />}>
            <Link href={'/assets/portfolio'}>
              {translations.navBarPortfolio}
            </Link>

            <span>{translations.transactions}</span>
          </TabBreadcrumbs>
        </div>
      </HiddenDesktop>
    </WrapperForMobileStickySectionHeader>
  );
};
