import { getAppConfig } from '@bts-web/core-app-config';
import { TFunction } from 'i18next';
import { TermsTranslations } from '../types';

export const getTermsAndConditionsTranslations = (t: ReturnType<TFunction>) => {
  const { appName } = getAppConfig();

  const translations: TermsTranslations = {
    changedTermsHeadline: t('changed_terms_headline'),
    newUserTermsUpperDisclaimer: t('new_user_terms_upper_disclaimer'),
    changedTermsUpperDisclaimer: t('changed_terms_upper_disclaimer'),
    riskTermDisclosure: t('onboarding_new_terms_risk_disclosure'),
    term_risk_link: t('onboarding_terms_risk_link'),
    termsAcceptButton: t('onboarding_terms_accept_button'),
    termsAndConditions: t('terms_and_conditions'),
    onboardingTermsConsent: t('onboarding_terms_consent'),
    onboardingTermsGroupPrivacy: t('onboarding_terms_group_privacy'),
    termsWarningMessage: t('terms_warning_message'),
    iAgree: t('I_agree'),
  };

  if (appName === 'LBBW') {
    return {
      ...translations,
      lbbw_terms: t('product_terms'),
      BTS_rlb_terms: t('corporate_general_terms'),
    };
  }

  return translations;
};
