import { TFunction } from 'i18next';
import { defaultLocale } from '@bts-web/utils-lokalise';
import { intlFiatValueFormatting } from '@bts-web/utils-formatting';
import { getAppConfig } from '@bts-web/core-app-config';
import { CryptoTransferDetailsFooter } from '../CryptoTransferDetailsFooter/CryptoTransferDetailsFooter';
import type { TransactionDetailsTransactionKeysResult } from '../../transaction-details/utils';
import {
  getTransactionTranslationKeywordByType,
  transactionTypeByTypeName,
} from '../../transactions/types/transactions';
import {
  TransactionDetailsTitle,
  TransactionDetailsWrapper,
} from '../../transaction-details';
import { CryptoTransfersTransactionsItemType } from '../../transaction-details/types';

export interface CryptoTransferDetailsProps {
  title?: string | null;
  translations: TransactionDetailsTransactionKeysResult;
  locale: typeof defaultLocale;
  cryptoTransferTransaction: CryptoTransfersTransactionsItemType;
  translationUtil: TFunction;
}

export const CryptoTransferDetails = ({
  translations,
  cryptoTransferTransaction,
  locale,
  translationUtil,
}: CryptoTransferDetailsProps) => {
  const { currency } = getAppConfig();

  const { asset, __typename, fiat, fiatAmount } =
    cryptoTransferTransaction || {};

  const isWithdrawalTransferType =
    __typename === 'WithdrawalCryptoTransfersTransaction';

  const additionalTranslations = {
    assetSymbol: translationUtil('tx_details_asset_wallet', {
      assetsymbol: asset?.symbol,
    }),
    fiatSymbol: translationUtil('tx_details_fiat_wallet', {
      fiatsymbol: fiat?.symbol,
    }),
    transactionType: translationUtil(
      getTransactionTranslationKeywordByType(
        transactionTypeByTypeName[
          __typename as unknown as keyof typeof transactionTypeByTypeName
        ],
      ),
      {
        0: '',
        interpolation: {
          escapeValue: false,
        },
      },
    ).replace(/:/g, ''),
  };

  const transactionFiatAmount = intlFiatValueFormatting(
    fiatAmount?.value ?? 0,
    {
      locale,
      currency,
      fractionDigits: fiatAmount?.precision ?? 2,
    },
  );

  const subtitle = isWithdrawalTransferType
    ? `${translations.sent} ${transactionFiatAmount}`
    : `${translations.received} ${transactionFiatAmount}`;

  return (
    <>
      <TransactionDetailsTitle
        title={translations.details}
        subtitle={subtitle}
      />

      <TransactionDetailsWrapper
        transaction={cryptoTransferTransaction}
        translations={{ ...translations, ...additionalTranslations }}
        locale={locale}
        currency={currency}
      />

      <CryptoTransferDetailsFooter
        transactionId={cryptoTransferTransaction?.id}
        translations={translations}
        assetId={asset?.id as string}
        status={cryptoTransferTransaction?.status}
      />
    </>
  );
};
