'use client';

import { ReactElement, cloneElement, FC } from 'react';
import { useComposableModal } from '@bts-web/utils-context';
import { ButtonComponentProps } from '../../types';
import { UserAccessModal } from '../../components/composableModals';
import { useClientUserInfo } from '../../userinfo/InitialUserInfoFetcherAndParser/utils/useClientUserInfo';
import { AccessModalInfoType } from './getAccessModalInfo';

export enum RoleCheckActionType {
  TRADE = 'TRADE',
}

const getActionToModalTypeMap = (
  actionType: RoleCheckActionType | undefined,
) => {
  const ActionToModalTypeMap = {
    [RoleCheckActionType.TRADE]: AccessModalInfoType.WarningTrade,
  };

  if (!actionType) {
    return AccessModalInfoType.InfoApprovalPending;
  }

  return ActionToModalTypeMap[actionType];
};

interface UserAccessWithRoleCheckProps {
  children: ReactElement<ButtonComponentProps>;
  actionType?: RoleCheckActionType;
}

export const UserAccessWithRoleCheck: FC<UserAccessWithRoleCheckProps> = ({
  children,
  actionType,
}) => {
  const userData = useClientUserInfo();

  const { showModal } = useComposableModal();

  const { isReadOnlyUser, approved, tradingActive, userType } = userData;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault();

    event?.stopPropagation();

    if (!approved && userType === 'BusinessUser') {
      showModal(UserAccessModal, {
        type: getActionToModalTypeMap(actionType),
      });

      return;
    }

    if (!tradingActive) {
      showModal(UserAccessModal, {
        type: AccessModalInfoType.WarningTrade,
      });

      return;
    }

    if (isReadOnlyUser) {
      showModal(UserAccessModal, {
        type: AccessModalInfoType.ReadOnly,
      });

      return;
    }
  };

  if (
    isReadOnlyUser ||
    (!approved && userType === 'BusinessUser') ||
    !tradingActive
  ) {
    return cloneElement(children, {
      onClick: handleClick,
      href: '',
    });
  }

  return children;
};
