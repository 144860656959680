'use client';

import { ReactNode, useEffect, useRef, useState } from 'react';

export type InfiniteScrollItem = {
  id: string;
  [key: string]: any;
};

export type RenderItemComponentParams = InfiniteScrollItem;

export interface InfiniteScrollProps {
  external: {
    hasMore?: boolean;
    itemsPerPage?: number;
    fetchMore: (itemsPerPage: number) => void;
    renderItemComponent: (item: RenderItemComponentParams) => ReactNode;
    skeleton: ReactNode;
    items: InfiniteScrollItem[];
  };
}

export const INCREMENT_LOAD_ASSETS_COUNT = 20;

export const InfiniteScroll = ({
  items,
  hasMore,
  skeleton,
  fetchMore,
  itemsPerPage = INCREMENT_LOAD_ASSETS_COUNT,
  renderItemComponent,
}: InfiniteScrollProps['external']) => {
  const observerTarget = useRef(null);

  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (items.length === 0) {
      setIsFetching(true);

      fetchMore(itemsPerPage);
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0]?.isIntersecting && hasMore) {
          setIsFetching(true);

          fetchMore(itemsPerPage);
        }
      },
      { threshold: 1 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    setIsFetching(false);

    return () => observer.disconnect();
  }, [items]);

  const lastScrollItemProps = {
    ref: observerTarget,
    'data-testid': 'observe-item',
  };

  return (
    <>
      {items.map((item, idx) => {
        const isBeforeLast = idx === Math.max(itemsPerPage, items.length) - 2;

        return (
          <div
            aria-label="infinite scroll item"
            data-testid="item"
            key={`scroll-item-${item?.id}`}
            {...(isBeforeLast && hasMore && lastScrollItemProps)}
          >
            {renderItemComponent(item)}
          </div>
        );
      })}
      {isFetching ? skeleton : null}
    </>
  );
};
