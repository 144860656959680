'use client';

import { css, hstack } from '@bts-web/utils-style-engine';
// import { useRouter } from 'next/navigation';
import { decodeBase64String } from '@bts-web/utils-functions';
import { CryptoTransfersTransactionStatus } from '@bts-web/data-layer/server';
import { ButtonBase, CopyToClipboard } from '../../common/components';
import { BuySellMoreButton } from '../../transaction-details/index';
import { CryptoTransactionInfoStatus } from '../utils/getCryptoTransferCryptoTransferDetailsLabelsByStatus';

type CryptoTransferDetailsFooterProps = {
  transactionId?: string;
  assetId: string;
  status?: CryptoTransfersTransactionStatus;
  translations: {
    pid: string;
    orderIdCopySuccessMessage: string;
    orderIdCopyFailedMessage: string;
    copy: string;
    buyMore: string;
    verifyWallet: string;
  };
};

const footerStyles = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 'medium',
});

const CryptoTransferDetailsFooter = ({
  transactionId,
  translations,
  status,
  assetId,
}: CryptoTransferDetailsFooterProps) => {
  // const router = useRouter();

  const decodedTransactionId =
    transactionId && decodeBase64String(transactionId).split(':')[1];

  const pid = transactionId && (
    <div
      className={hstack({
        overflow: 'hidden',
        fontSize: 'label.medium_medium',
        justifyContent: 'space-between',
        flex: 'auto',
        width: '100%',
      })}
    >
      <div
        role="presentation"
        className={css({
          flex: 1,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          minWidth: 0,
          color: 'neutrals.text_secondary',
          fontSize: 'caption.small',
          fontWeight: 'caption.small',
          lineHeight: 'caption.small',
          letterSpacing: 'caption.small',
        })}
      >
        {translations.pid} {decodedTransactionId}
      </div>

      <CopyToClipboard
        value={decodedTransactionId as string}
        translations={{
          successMessage: translations.orderIdCopySuccessMessage,
          failedMessage: translations.orderIdCopyFailedMessage,
        }}
      />
    </div>
  );

  const handleVerifyWallet = () => {
    //  TODO add redirect to self-certification flow"
    // router.push('')
  };

  let actionButton = (
    <BuySellMoreButton isSell={false} assetId={assetId}>
      {translations.buyMore}
    </BuySellMoreButton>
  );

  if (status === CryptoTransactionInfoStatus.ON_HOLD) {
    actionButton = (
      <ButtonBase onClick={handleVerifyWallet} size="large" visual="primary">
        {translations.verifyWallet}
      </ButtonBase>
    );
  }

  return (
    <div
      className={css({ width: '100%', marginTop: 'auto', paddingX: 'medium' })}
      aria-label="crypto-transfer-details-footer"
    >
      {pid}
      <footer className={footerStyles}>
        <div className={css({ maxWidth: '342px', width: '100%' })}>
          {actionButton}
        </div>
      </footer>
    </div>
  );
};

export { CryptoTransferDetailsFooter };
