import { getActiveTermsAndConditions } from '../common/gqlActions';
import { TermsAndConditionsModal } from './TermsAndConditionsModal';

export const TermsAndConditionsModalController = async () => {
  const termsConditionsResponse = await getActiveTermsAndConditions();

  return (
    <TermsAndConditionsModal
      termsAndConditions={termsConditionsResponse.data.termsAndConditions}
    />
  );
};
