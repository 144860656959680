import { FC } from 'react';
import { ErrorBoundaryWithSuspense } from '../layouts/ErrorBoundary/ErrorBoundaryWithSuspense';
import { AssetDetailsController } from './AssetDetailsController.server';
import { AssetDetailsHeaderSkeleton } from './AssetDetailsLoadingSkeleton.server';
import { HiddenDesktop } from '../layouts';
import { AssetDetailsFloatingCTASpacer } from './components/sections/AssetDetailsFloatingCTA/AssetDetailsFloatingCTA';
import { TransactionsWidgetControllerWithSuspense } from '../common/TransactionsWidget/TransactionsWidgetControllerWithSuspense';
import { getAppConfig } from '@bts-web/core-app-config';

const MAX_NR_OF_TRANSACTIONS = 3;

const AssetDetailsControllerWithSuspense: FC<{
  assetId: string;
  latestTransactionsFetchCount?: number;
  hideTradeCTAFloatingButton?: boolean;
}> = ({
  assetId,
  latestTransactionsFetchCount = MAX_NR_OF_TRANSACTIONS,
  hideTradeCTAFloatingButton,
}) => {
  const { feature_showAssetDetailsRelatedTransactions } = getAppConfig();

  return (
    <>
      <ErrorBoundaryWithSuspense
        fallbackOrSkeleton={<AssetDetailsHeaderSkeleton />}
      >
        <AssetDetailsController
          assetId={assetId}
          hideTradeCTAFloatingButton={hideTradeCTAFloatingButton}
        />
      </ErrorBoundaryWithSuspense>

      <TransactionsWidgetControllerWithSuspense
        assetId={assetId}
        fetchNumberOfItems={latestTransactionsFetchCount}
        variant="AssetDetails"
        showOnlyRedirectLink={!feature_showAssetDetailsRelatedTransactions}
      />

      {!hideTradeCTAFloatingButton && (
        <HiddenDesktop>
          <AssetDetailsFloatingCTASpacer />
        </HiddenDesktop>
      )}
    </>
  );
};

export { AssetDetailsControllerWithSuspense };
