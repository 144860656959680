'use client';

import { FC } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@bts-web/design-system/component/icon';
import { useComposableDrawer } from '@bts-web/utils-context';

export const CloseDrawerButton: FC = () => {
  const { closeDrawer } = useComposableDrawer();

  return (
    <button
      data-testid="close-transaction-drawer-button"
      className={css({
        py: 'small',
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        ml: 'auto',
      })}
      disabled={false}
      onClick={closeDrawer}
    >
      <Icon icon="dismiss" size="24" color="primary" />
    </button>
  );
};
