const initialTransactionsTranslations: TransactionsTranslationsKeysResult = {
  grams: 'grams',
  navBarPortfolio: 'nav_bar_portfolio',
  transactions: 'transactions',
  emptyStateNoTransactions: 'empty_state_no_transactions',
  activity: 'activity',
  transactionDescription: 'transaction_description',
  amount: 'amount',
  failed: 'failed',
  completed: 'completed',
  pending: 'tx_details_pending',
};

export type TransactionsTranslationsKeysResult = {
  grams: string;
  navBarPortfolio: string;
  transactions: string;
  emptyStateNoTransactions: string;
  activity: string;
  amount: string;
  transactionDescription: string;
  failed: string;
  completed: string;
  pending: string;
};

const getTransactionsTranslations = (
  t: (translationKey: keyof TransactionsTranslationsKeysResult) => string,
): TransactionsTranslationsKeysResult => {
  return Object.entries(initialTransactionsTranslations).reduce(
    (acc, [key, value]) => {
      acc[key as keyof TransactionsTranslationsKeysResult] = t(
        value as keyof TransactionsTranslationsKeysResult,
      );

      return acc;
    },
    {} as TransactionsTranslationsKeysResult,
  );
};

export { getTransactionsTranslations };
