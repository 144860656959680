'use client';

import { useContext, createContext, useState, PropsWithChildren } from 'react';
import { SavingsPlansTransactionsQuery$data } from '@bts-web/data-layer/server';
import {
  getSavingsPlansTransactions,
  useHandleGqlErrorsWithSnackbar,
} from '@bts-web/core-features/common';
import { SAVINGS_PLANS_TRANSACTIONS_LIST_PAGE_SIZE } from '@bts-web/core-features/config';

export interface SavingsPlansTransactionsContextProps {
  transactions: SavingsPlansTransactionsQuery$data['savingsPlanTransactions'];
  loadMoreTransactions: () => void;
}

const SavingsPlansTransactionsContext =
  createContext<SavingsPlansTransactionsContextProps | null>(null);

export function useSavingsPlansTransactionsContext() {
  const contextValue = useContext(SavingsPlansTransactionsContext);

  if (contextValue === null) {
    throw new Error(
      'useSavingsPlansTransactionsContext must be used within a SavingsPlansTransactionsContextProvider',
    );
  }

  return contextValue;
}

export const SavingsPlansTransactionsContextProvider = ({
  children,
  initialTransactionsData,
}: PropsWithChildren<{
  initialTransactionsData: SavingsPlansTransactionsContextProps['transactions'];
}>) => {
  const [transactions, setTransactions] = useState<
    SavingsPlansTransactionsContextProps['transactions']
  >(initialTransactionsData || null);

  const { processErrors } = useHandleGqlErrorsWithSnackbar();

  const loadMoreTransactions = async () => {
    const currentPageCursor = transactions?.pageInfo?.endCursor;

    try {
      const response = await getSavingsPlansTransactions({
        states: ['FINISHED', 'FAILED'],
        after: currentPageCursor,
        first: SAVINGS_PLANS_TRANSACTIONS_LIST_PAGE_SIZE,
      });

      if (response.errors) {
        processErrors(response.errors);

        return;
      }

      const loadedTransactions = response?.data?.savingsPlanTransactions;

      if (loadedTransactions) {
        setTransactions({
          ...loadedTransactions,
          edges: [
            ...(transactions?.edges || []),
            ...(loadedTransactions?.edges || []),
          ],
          pageInfo: loadedTransactions?.pageInfo,
        } as SavingsPlansTransactionsContextProps['transactions']);
      }
    } catch (error) {
      if (typeof error === 'string') {
        processErrors([error] as string[]);
      } else {
        processErrors(error as unknown as string[]);
      }
    }
  };

  return (
    <SavingsPlansTransactionsContext.Provider
      value={{
        loadMoreTransactions,
        transactions,
      }}
    >
      {children}
    </SavingsPlansTransactionsContext.Provider>
  );
};
