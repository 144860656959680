import { TimeAmounts } from '@bts-web/design-system/component/chart';

const PriceChangeTimeAmountKeys: Partial<
  Record<
    TimeAmounts,
    | 'priceChange24Hours'
    | 'priceChange1Week'
    | 'priceChange1Month'
    | 'priceChange1Year'
    | 'priceChange5Years'
  >
> = {
  [TimeAmounts.OneDay]: 'priceChange24Hours',
  [TimeAmounts.SevenDays]: 'priceChange1Week',
  [TimeAmounts.OneMonth]: 'priceChange1Month',
  [TimeAmounts.OneYear]: 'priceChange1Year',
  [TimeAmounts.MaxYears]: 'priceChange5Years',
};

export { PriceChangeTimeAmountKeys };
