'use client';

import { FC, useEffect, useState } from 'react';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { TwoFaSectionTitle } from '../../shared/TwoFaSectionTitle';
import { TwoFaSectionDescription } from '../../shared/TwoFaSectionDescription';
import { getUserQRCodeImageValue } from '../../../../../../auth';
import { QRCode } from '../../../../QRCode/QRCode';
import { SkeletonElement } from '../../../../loading/SkeletonElement/SkeletonElement';
import { TwoFaSectionButtonsWrapper } from '../../shared/TwoFaSectionButtonsWrapper';
import { css } from '@bts-web/utils-style-engine';
import { CopyToClipboardButton } from '../../../../CopyToClipboardButton/CopyToClipboardButton';
import { TTwoFaCommonProps } from '../types';
import { ButtonBase } from '../../../../ButtonBase/ButtonBase';
import { useAppNotification } from '../../../../../../notifications/NotificationContextProvider';
import { useHandleGqlErrorsWithSnackbar } from '../../../../../utils/errors';

const ScanQrPage: FC<Pick<TTwoFaCommonProps, 'onContinue'>> = ({
  onContinue,
}) => {
  const [qrCode, setQrCode] = useState<string | null>(null);

  const { processErrors } = useHandleGqlErrorsWithSnackbar();

  const [secretCode, setSecretCode] = useState<string | undefined>(undefined);

  const { setAppNotification } = useAppNotification();

  const { t } = useClientTranslation();

  useEffect(() => {
    getUserQRCodeImageValue()
      .then((res) => {
        if ('message' in res) {
          processErrors([res.message]);
        } else {
          setQrCode(res.url);

          setSecretCode(res.secret);
        }
      })
      .catch((_) => {
        setAppNotification({
          title: t('general_error_headline'),
          visual: 'error',
        });
      });
  }, []);

  return (
    <>
      <TwoFaSectionTitle title={`${t('2fa_qr_subtitle')}`} />
      <TwoFaSectionDescription textContent={t('2fa_qr_description')} />
      {!qrCode ? (
        <div
          className={css({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          <SkeletonElement height="224px" width="224px" />
        </div>
      ) : (
        <QRCode
          value={qrCode}
          className={css({
            p: 0,
          })}
        />
      )}
      <TwoFaSectionDescription textContent={t('2fa_qr_manual_description')} />
      {!qrCode || !secretCode ? (
        <div
          className={css({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          })}
        >
          <SkeletonElement height="28px" width="320px" />
        </div>
      ) : (
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 'extra_small',
            flexWrap: 'wrap',
          })}
        >
          <code
            className={css({
              border: '1px solid',
              borderColor: 'neutrals.fill_tertiary',
              p: 'extra_small_3',
              borderRadius: '6px',
              fontSize: 'caption.medium_medium',
              flex: '1',
              minWidth: '200px',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            })}
          >
            {secretCode}
          </code>
          <CopyToClipboardButton
            value={secretCode}
            ariaLabel="copy qr code url value"
          />
        </div>
      )}
      <TwoFaSectionButtonsWrapper>
        <ButtonBase
          visual="primary"
          size="large"
          fullWidth
          disabled={!qrCode || qrCode === null}
          onClick={onContinue}
          isLoading={!qrCode || qrCode === null}
        >
          {t('continue')}
        </ButtonBase>
      </TwoFaSectionButtonsWrapper>
    </>
  );
};

export { ScanQrPage };
