import { FC } from 'react';
import { ReadMore } from '../../../../common';
import { ContentSectionWrapper } from '../../../../layouts/ContentSectionWrapper/ContentSectionWrapper';
import { AssetDetailsTranslations } from '../../../types';

const AssetDetailsOverviewSection: FC<{
  translations: AssetDetailsTranslations;
  assetDescription: string;
}> = ({ translations, assetDescription }) => {
  return (
    <ContentSectionWrapper sectionTitle={translations.assetDetailOverview}>
      <ReadMore
        labels={{
          readLess: translations.readLess,
          readMore: translations.readMore,
        }}
        text={assetDescription}
      />
    </ContentSectionWrapper>
  );
};

export { AssetDetailsOverviewSection };
