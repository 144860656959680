'use client';

import { useRouter } from 'next/navigation';
import { FC, useCallback, useMemo, useState } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { isDevelopmentEnv } from '@bts-web/utils-functions';
import { Icon } from '@bts-web/design-system/component/icon';
import { Locale } from '@bts-web/utils-lokalise';
import { AmountFor, TransactionStatus } from '@bts-web/data-layer/server';
import { DatadogErrorLevels } from '@bts-web/utils-next-infrastructure';
import {
  PrecisionFloat,
  getPrecisionFloatValue,
  intlFiatValueFormatting,
} from '@bts-web/utils-formatting';
import { isBpfsAsset } from '../../utils/isBpfsAsset';
import {
  AssetType,
  submitButtonWrapperAndButtonProperStyles,
} from '../../utils';
import { OfferLoaderElement } from '../../Reusable/OfferLoaderElement/OfferLoaderElement';
import { PoweredByText } from '../../Reusable/PoweredByText/PoweredByText';
import { TradeTaxSummary } from './components/TradeTaxSummary/TradeTaxSummary';
import { AcceptBuyTradeDisclaimerWrapper } from './components/AcceptBuyTradeDisclaimerWrapper/AcceptBuyTradeDisclaimerWrapper';
import { finishTradeRedirectBuilder } from '../../FinishTrade/actions/finishTradeRedirectBuilder.action';
import { TradeAssetInfoDisplay } from '../../../common/components/TradeAssetInfoDisplay/TradeAssetInfoDisplay';
import { TradeScreenTranslations } from '../../utils/getTradeTranslations';
import {
  AcceptTradeControllerVariants,
  TradeOfferDataNonNullable,
} from '../types';
import {
  AmountInputWrapper,
  ButtonBase,
  Divider,
  useHandleGqlErrorsWithSnackbar,
  datadogErrorHelper,
} from '../../../common';
import { acceptTradeOffer, fetchOfferData } from '../../../common/gqlActions';
import { BelowInputInfoTable } from '../../../common/components/BelowInputInfoTable/BelowInputInfoTable';
import { getConvertedSummary } from '../../TradeParent/utils/getConvertedSummary';
import { removeInputValueFromLocalStorage } from '../../TradeParent/utils/localStorageInputValueUtils';
import { useAppNotification } from '../../../notifications/NotificationContextProvider';
import { getMaximumTradeAmount } from '../../TradeParent/utils';
import { FeesList } from './components/FeesList/FeesList';
import { useComposableModal } from '@bts-web/utils-context';
import { FinishTradeStatusModal } from '../../FinishTrade/FinishTradeStatusModal/FinishTradeStatusModal';
import { useIsBelowScreenWidth } from '../../../layouts';
import { getAppConfig } from '@bts-web/core-app-config';

export type AcceptTradeSubscriptionWrapperProps = {
  initialOfferData: TradeOfferDataNonNullable;
  offerId: string;
  translations: TradeScreenTranslations;
  variant: AcceptTradeControllerVariants;
  currentLocale: Locale;
};

// THIS FUNCTION is required to be executed outside of a try-catch block, because a redirect would trigger a fail in the catch block
const executeRedirect = (
  variant: AcceptTradeControllerVariants,
  offerId: string,
  transactionStatus: TransactionStatus | null,
  hasTaxOfflineWarning?: boolean,
) => {
  finishTradeRedirectBuilder(
    variant,
    offerId,
    transactionStatus,
    hasTaxOfflineWarning,
  );
};

export const AcceptTradeSubscriptionWrapper: FC<
  AcceptTradeSubscriptionWrapperProps
> = ({ initialOfferData, offerId, translations, variant, currentLocale }) => {
  const isMobile = useIsBelowScreenWidth(1024);

  const { setAppNotification } = useAppNotification();

  const { showModal } = useComposableModal();

  const { processErrors } = useHandleGqlErrorsWithSnackbar();

  const router = useRouter();

  const [tradeOfferData, setTradeOfferData] =
    useState<TradeOfferDataNonNullable>(initialOfferData);

  const [nextExpiresAt, setNextExpiresAt] = useState(
    initialOfferData.expiresAt,
  );

  const [isTradeOfferViewQueryRefetching, setIsTradeOfferViewQueryRefetching] =
    useState(false);

  const [isOfferAccepted, setOfferAccepted] = useState(false);

  const { currency } = getAppConfig();

  const offerAvailabilityInMs = useMemo(() => {
    const MINIMUM_REFRESH_TIME = 5000;

    if (!nextExpiresAt) return MINIMUM_REFRESH_TIME;

    const nextExpiresAtInMs = new Date(nextExpiresAt).getTime();

    const currentTimeInMs = Date.now();

    const newValue = nextExpiresAtInMs - currentTimeInMs;

    return Math.max(newValue, MINIMUM_REFRESH_TIME);
  }, [nextExpiresAt]);

  const refetchTradeOfferViewQuery = useCallback(async () => {
    if (isTradeOfferViewQueryRefetching || isOfferAccepted) return;

    setIsTradeOfferViewQueryRefetching(true);

    try {
      const response = await fetchOfferData(offerId);

      const tradeOffer = response.data.tradeOffer;

      if (!tradeOffer) {
        console.warn('%cNo trade offer data found', 'color:red');

        if (!isDevelopmentEnv()) {
          router.push('/error/server');
        }
      } else {
        setTradeOfferData(tradeOffer);

        setNextExpiresAt(tradeOffer.expiresAt);

        setAppNotification({
          title: translations.offerExpiredTitle,
          subtitle: translations.offerExpiredSubtitle,
          withClose: true,
          visual: 'info',
          customDuration: 2000,
        });

        document.dispatchEvent(new Event('onTradeOfferRefetched'));
      }
    } catch (e) {
      console.error('Error fetching trade offer:', e);

      if (!isDevelopmentEnv()) {
        router.push('/error/server');
      }
    } finally {
      setIsTradeOfferViewQueryRefetching(false);
    }
  }, [
    offerId,
    isOfferAccepted,
    isDevelopmentEnv,
    router,
    setAppNotification,
    translations,
  ]);

  const handleSpinnerComplete = useCallback(() => {
    refetchTradeOfferViewQuery();
  }, [refetchTradeOfferViewQuery]);

  const { asset, totalAmount, taxAmount, fiatAmount, hasTaxOfflineWarning } =
    tradeOfferData;

  const { logoUrl, name, symbol: initialSymbol, __typename } = asset;

  const symbol =
    __typename === 'MetalAsset' ? translations.grams : initialSymbol;

  const defaultDisplayType: AmountFor = 'FIAT';

  const totalSum = getPrecisionFloatValue(totalAmount);

  const isBpf = isBpfsAsset(__typename as AssetType);

  const poweredByText = {
    value: isBpf ? translations.poweredByBpfs : translations.poweredByBitpanda,
    fontSize: isBpf ? 'caption.medium_medium' : 'caption.small',
  };

  const shouldShowTaxSummary =
    taxAmount && Number(taxAmount?.value) > 0 && fiatAmount?.value;

  const triggerAcceptTradeOffer = async () => {
    setIsTradeOfferViewQueryRefetching(true);

    setOfferAccepted(true);

    try {
      const offerResponse = await acceptTradeOffer(offerId);

      const statusResponse = offerResponse.data.acceptTradeOffer?.status;

      if (offerResponse.errors) {
        processErrors(offerResponse.errors);

        setIsTradeOfferViewQueryRefetching(false);

        setOfferAccepted(false);

        return;
      } else if (!statusResponse) {
        if (!statusResponse) {
          throw new Error('No status response from accept trade offer');
        }
      }

      removeInputValueFromLocalStorage();

      if (isMobile) {
        // mobile trading
        executeRedirect(
          variant,
          offerId,
          statusResponse as TransactionStatus,
          !!hasTaxOfflineWarning,
        );
      } else {
        // desktop trading
        showModal(FinishTradeStatusModal, {
          currentLocale: currentLocale,
          actionVariant: variant,
          transactionData: offerResponse.data.acceptTradeOffer,
          translations: translations,
          hasTaxOfflineWarning: !!hasTaxOfflineWarning,
        });
      }
    } catch (error) {
      console.error('Error accepting trade offer:', error);

      setIsTradeOfferViewQueryRefetching(false);

      setOfferAccepted(false);

      if (typeof error === 'string') {
        processErrors([error] as string[]);
      } else {
        processErrors(error as unknown as string[]);
      }

      datadogErrorHelper({
        errorMessage: JSON.stringify(error),
        errorSeverity: DatadogErrorLevels.CRITICAL,
      });
    }
    // Do not remove the loading state as after the operation is completed, the duration until the redirect is not known, and the user could submit multiple times
    // finally {
    //   setIsTradeOfferViewQueryRefetching(false);

    //   setOfferAccepted(false);
    // }
  };

  const maximumTradeAmount = getMaximumTradeAmount({
    fiatBalance: asset.portfolio?.fiatBalance,
    assetBalance: asset.portfolio?.assetBalance,
    type: variant,
  });

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        px: 'medium',
        height: '100%',
      })}
    >
      <TradeAssetInfoDisplay
        assetData={{ logoUrl, name }}
        price={tradeOfferData.assetConversionRate}
        symbol={symbol}
        loaderElement={
          <OfferLoaderElement
            key={nextExpiresAt}
            duration={offerAvailabilityInMs}
            size={20}
            onComplete={handleSpinnerComplete}
          />
        }
      />

      <AmountInputWrapper
        inputContextData={{
          symbol: asset.symbol ?? '',
          currency,
          displayType: defaultDisplayType,
          value: totalSum,
          placeholder: undefined,
        }}
      />

      <div
        className={css({
          pt: 'extra_small',
          pb: 'medium',
        })}
      >
        <BelowInputInfoTable
          items={[
            {
              title: translations['units'],
              value: getConvertedSummary({
                assetPrice: tradeOfferData.assetConversionRate,
                tradeType: variant,
                assetType: tradeOfferData.asset.__typename as AssetType,
                currency: currency,
                displayType: defaultDisplayType,
                locale: currentLocale,
                symbol: symbol,
                translations,
                value: totalSum,
                useRawSource: {
                  rawSourceDisplayType: 'ASSET',
                  rawSourceValue: tradeOfferData.assetAmount as PrecisionFloat,
                },
                maximumTradeAmount,
              }),
            },
          ]}
        />
      </div>

      <Divider />

      <FeesList
        variant={variant}
        translations={translations}
        values={{
          paymentMethod: translations.sepaDirectDebit,
          amount: tradeOfferData.assetAmount
            ? `${Number(tradeOfferData.assetAmount.value).toFixed(
                tradeOfferData.assetAmount.precision,
              )} ${tradeOfferData.asset.symbol}`
            : '',
          offerPrice: tradeOfferData.assetNetConversionRate
            ? intlFiatValueFormatting(
                tradeOfferData.assetNetConversionRate.value, // assetNetConversionRate excludes fees
                {
                  locale: currentLocale,
                  notation: 'standard',
                  currency,
                  fractionDigits:
                    tradeOfferData?.assetNetConversionRate.precision,
                },
              )
            : '',
          tradeFee: tradeOfferData.spread
            ? intlFiatValueFormatting(tradeOfferData.spread.value, {
                locale: currentLocale,
                notation: 'standard',
                currency,
                fractionDigits: tradeOfferData?.spread.precision,
              })
            : '0',
          volume: tradeOfferData.volume
            ? intlFiatValueFormatting(tradeOfferData.volume.value, {
                locale: currentLocale,
                notation: 'standard',
                currency,
                fractionDigits: tradeOfferData.volume.precision,
              })
            : '',
        }}
      />

      {shouldShowTaxSummary && (
        <TradeTaxSummary
          currency={currency}
          currentLocale={currentLocale}
          fiatAmount={fiatAmount}
          isTaxServiceOffline={!!hasTaxOfflineWarning}
          taxAmount={taxAmount}
          translations={translations}
        />
      )}

      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          pb: 'medium',
          gap: 'extra_small',
          marginTop: 'auto',
        })}
      >
        <AcceptBuyTradeDisclaimerWrapper
          translations={translations}
          variant={variant}
          legalUrl={asset.legalUrl ?? ''}
          assetType={asset.__typename as AssetType}
        >
          {({ areTermsAgreedTo, isDisclaimerLoading }) => {
            const isLoading =
              isDisclaimerLoading || isTradeOfferViewQueryRefetching;

            const isDisabled = !areTermsAgreedTo || isLoading;

            return (
              <>
                <PoweredByText {...poweredByText} />

                <div className={submitButtonWrapperAndButtonProperStyles}>
                  <ButtonBase
                    visual="primary"
                    size="large"
                    disabled={isDisabled}
                    onClick={triggerAcceptTradeOffer}
                    fullWidth
                  >
                    {variant === 'buy'
                      ? translations.buyNow
                      : translations.sellNow}

                    {isLoading && (
                      <Icon
                        data-element="loading-icon"
                        theme="filled"
                        size="20"
                        icon="spinner-ios"
                        aria-label="loading icon"
                      />
                    )}
                  </ButtonBase>
                </div>
              </>
            );
          }}
        </AcceptBuyTradeDisclaimerWrapper>
      </div>
    </div>
  );
};
