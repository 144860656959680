import { TFunction } from 'i18next';
import { AssetFields } from '../../asset-details/components/AssetDetailsDataContext/AssetDetailsDataContext.use-client';
import { getSavingsPlansCount } from '../../common/gqlActions/getSavingsPlansCount.action';
import { getSavingsPlansTranslations } from '../utils/getSavingsPlansTranslations';
import { SavingsPlansFrame } from './SavingsPlansFrame';
import {
  initServerTranslation,
  updateServerLanguage,
} from '@bts-web/utils-lokalise';
import { ContentSectionWrapper } from '../../layouts/ContentSectionWrapper/ContentSectionWrapper';

export interface SavingsPlansFrameControllerProps {
  assetId?: string;
  assetInfo?: Pick<NonNullable<AssetFields>, 'id' | 'name' | 'logoUrl'>;
}

const { t } = initServerTranslation();

const SavingsPlansFrameController = async (
  props: SavingsPlansFrameControllerProps,
) => {
  await updateServerLanguage();

  const { assetId, assetInfo } = props;

  const savingsPlansData = await getSavingsPlansCount({
    status: 'ACTIVE',
    first: 100,
    ...(assetId ? { assetId } : {}),
  });

  const savingsPlanCount =
    savingsPlansData?.data?.savingsPlans?.totalCount ?? 0;

  const translations = getSavingsPlansTranslations(t as TFunction);

  const savingsPlansSubtitle =
    savingsPlanCount > 1
      ? 'savings_plans_frame_active_plans_plural'
      : 'savings_plans_frame_active_plans';

  const savingsPlansTitle =
    savingsPlanCount > 1 ? translations.savingsPlans : translations.savingsPlan;

  let title = savingsPlansTitle;

  if (assetInfo?.name) {
    title =
      savingsPlanCount > 0
        ? `${savingsPlanCount} ${assetInfo.name} ${savingsPlansTitle.toLocaleLowerCase()}`
        : translations.savingsPlan;
  }

  const subtitle = savingsPlanCount
    ? t(savingsPlansSubtitle, {
        active_plans_count: savingsPlanCount,
      })
    : translations.noPlan;

  return (
    <ContentSectionWrapper>
      <SavingsPlansFrame
        savingsPlanCount={savingsPlanCount ?? 0}
        assetInfo={assetInfo}
        translations={translations}
        title={title}
        subTitle={subtitle}
      />
    </ContentSectionWrapper>
  );
};

export { SavingsPlansFrameController };
