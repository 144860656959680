'use client';

import { ReactNode } from 'react';
import { ButtonBase, UserAccessWithRoleCheck } from '../../common';
import { useComposableDrawer } from '@bts-web/utils-context';
import { useRouter } from 'next/navigation';

export interface BuySellMoreButtonProps {
  children: ReactNode;
  assetId: string;
  isSell: boolean;
}

export const BuySellMoreButton = ({
  children,
  assetId,
  isSell,
}: BuySellMoreButtonProps) => {
  const { closeDrawer } = useComposableDrawer();

  const router = useRouter();

  const handleBuyMore = () => {
    router.push(isSell ? `/trade/sell/${assetId}` : `/trade/buy/${assetId}`);

    closeDrawer();
  };

  return (
    <UserAccessWithRoleCheck>
      <ButtonBase onClick={handleBuyMore} size="large" visual="primary">
        {children}
      </ButtonBase>
    </UserAccessWithRoleCheck>
  );
};
