import { TFunction } from 'i18next';
import { defaultLocale } from '@bts-web/utils-lokalise';
import { TransactionViewQuery } from '@bts-web/data-layer/server';
import {
  getRoundedPrecision,
  intlFiatValueFormatting,
} from '@bts-web/utils-formatting';
import { getAppConfig } from '@bts-web/core-app-config';
import {
  getTransactionTranslationKeywordByType,
  transactionTypeByTypeName,
} from '../../transactions/types/transactions';
import { TransactionDetailsFooter } from '../TransactionDetailsFooter/TransactionDetailsFooter';
import { TransactionDetailsWrapper } from '../TransactionDetailsWrapper/TransactionDetailsWrapper';
import { TransactionDetailsTitle } from '../TransactionDetailsTitle/TransactionDetailsTitle';
import type { TransactionDetailsTransactionKeysResult } from '../utils';

export interface TransactionDetailsProps {
  title?: string | null;
  translations: TransactionDetailsTransactionKeysResult;
  locale: typeof defaultLocale;
  currency?: string;
  transaction: TransactionViewQuery['response']['transaction'];
  translationUtil: TFunction;
  showActionButton?: boolean;
}

export const TransactionDetails = ({
  showActionButton = true,
  translations,
  transaction,
  locale,
  translationUtil,
}: TransactionDetailsProps) => {
  const { asset, __typename, fiat, fiatAmount } = transaction || {};

  const { minimumSellAmount } = asset || {};

  const isSellType = __typename === 'SellTransaction';

  const additionalTranslations = {
    assetSymbol: translationUtil('tx_details_asset_wallet', {
      assetsymbol: asset?.symbol,
    }),
    fiatSymbol: translationUtil('tx_details_fiat_wallet', {
      fiatsymbol: fiat?.symbol,
    }),
    transactionType: translationUtil(
      getTransactionTranslationKeywordByType(
        transactionTypeByTypeName[
          __typename as unknown as keyof typeof transactionTypeByTypeName
        ],
      ),
      {
        0: '',
        interpolation: {
          escapeValue: false,
        },
      },
    ).replace(/:/g, ''),
  };

  const { currency } = getAppConfig();

  const transactionFiatAmount = intlFiatValueFormatting(
    fiatAmount?.value ?? 0,
    {
      locale,
      currency,
      fractionDigits: fiatAmount?.precision ?? 2,
    },
  );

  const fiatBalanceAmount =
    asset?.portfolio &&
    Number(getRoundedPrecision(asset?.portfolio?.fiatBalance));

  const minimumSellAmountNumber = Number(
    getRoundedPrecision(minimumSellAmount),
  );

  const isSellAllowed =
    isSellType &&
    fiatBalanceAmount &&
    fiatBalanceAmount > minimumSellAmountNumber;

  const subtitle = isSellType
    ? `${translations.typeSold} ${transactionFiatAmount}`
    : `${translations.typeBought} ${transactionFiatAmount}`;

  return (
    <>
      <TransactionDetailsTitle
        title={translations.details}
        subtitle={subtitle}
      />

      <TransactionDetailsWrapper
        transaction={transaction}
        translations={{ ...translations, ...additionalTranslations }}
        locale={locale}
        currency={currency}
      />

      <TransactionDetailsFooter
        showActionButton={showActionButton}
        assetId={transaction?.asset?.id as string}
        isSell={Boolean(isSellAllowed)}
        transactionId={transaction?.id}
        translations={translations}
      />
    </>
  );
};
