'use client';

import { css } from '@bts-web/utils-style-engine';
import { parseHtmlString } from '@bts-web/utils-functions';
import { useClientTranslation } from '@bts-web/utils-lokalise';

const noResultsContainerStyles = css({
  padding: 'medium',
  gap: 'extra_small',
  display: 'flex',
  flexDirection: 'column',
});

const noResultsTitleStyles = css({
  textAlign: 'center',
  color: 'neutrals.text_primary',
  fontSize: 'headline.small',
  fontWeight: 'headline.small',
  letterSpacing: 'headline.small',
  lineHeight: 'headline.small',
});

const noResultsSubtitleStyles = css({
  textAlign: 'center',
  color: 'neutrals.text_secondary',
});

const NoResults = ({ searchTerm }: { searchTerm?: string }) => {
  const { t } = useClientTranslation();

  const title = t('no_search_result_title', {
    search: searchTerm,
  });

  const htmlParsedTitle = parseHtmlString(title);

  return (
    <div data-testid="no-results" className={noResultsContainerStyles}>
      <p className={noResultsTitleStyles}>{htmlParsedTitle}</p>

      <p className={noResultsSubtitleStyles}>
        {t('no_search_result_subtitle')}
      </p>
    </div>
  );
};

export { NoResults };
