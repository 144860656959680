import { css, hstack } from '@bts-web/utils-style-engine';

interface AssetDetailsInfoRowProps {
  label: string;
  value: string | null | undefined;
  children?: React.ReactNode;
}

const containerStyles = hstack({
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  lineHeight: 'body.medium',
  justifyContent: 'space-between',
});

const labelStyles = css({
  color: 'neutrals.text_secondary',
});

const valueContainerStyles = hstack({
  gap: 'extra_small_2',
});

const valueStyles = css({
  color: 'neutrals.text_primary',
});

const AssetDetailsInfoRow = ({
  label,
  value,
  children,
}: AssetDetailsInfoRowProps) => {
  if (value == null) return null;

  return (
    <div className={containerStyles} aria-label="asset details info row entry">
      <span className={labelStyles} aria-label="row name">
        {label}
      </span>

      <div className={valueContainerStyles} aria-label="row value">
        <span className={valueStyles}>{value}</span>
        {children}
      </div>
    </div>
  );
};

export { AssetDetailsInfoRow };
