import { ComponentProps, FC, ReactElement } from 'react';
import { AcceptsDataTestid } from '@bts-web/utils-test/types';
import { Locale } from '@bts-web/utils-lokalise';
import { css } from '@bts-web/utils-style-engine';
import { CopyToClipboardButton, Status } from '../../common';
import { RenderTransactionDateHour } from './RenderTransactionDateHour.use-client';
import { CryptoTransferTransactionStatus } from '../../crypto-transfer-details';
import { AllTypeTransactionStatus, STATUS_COLORS } from '../types';
import { CryptoTransactionInfoStatus } from '../../crypto-transfer-details/utils/getCryptoTransferCryptoTransferDetailsLabelsByStatus';

type BaseTransactionDetailsFieldProps = AcceptsDataTestid & {
  label: string;
  value: string;
  endSlot?: ReactElement;
  locale: Locale;
};

export enum TransactionDetailsFieldVariants {
  TRANSACTION = 'transaction',
  SAVINGS_PLAN = 'savings-plan',
  WALLET_ADDRESS = 'wallet-address',
  TRANSACTION_DATE_HOUR = 'transaction-date-hour',
  TRANSACTION_STATUS = 'transaction-status',
  CRYPTO_TRANSACTION_STATUS = 'crypto-transaction-status',
}

type TransactionDetailsFieldProps = BaseTransactionDetailsFieldProps &
  (
    | {
        variant?:
          | TransactionDetailsFieldVariants.TRANSACTION
          | TransactionDetailsFieldVariants.SAVINGS_PLAN
          | TransactionDetailsFieldVariants.WALLET_ADDRESS
          | TransactionDetailsFieldVariants.TRANSACTION_DATE_HOUR;
        status?: never;
      }
    | {
        variant:
          | TransactionDetailsFieldVariants.TRANSACTION_STATUS
          | TransactionDetailsFieldVariants.CRYPTO_TRANSACTION_STATUS;
        status: AllTypeTransactionStatus | undefined;
      }
  );

const TransactionValue: FC<{
  value: string;
  locale: Locale;
  variant?: TransactionDetailsFieldVariants;
  status?: AllTypeTransactionStatus;
}> = ({ value, variant, status, locale }) => {
  const statusVisualVariant =
    status &&
    (STATUS_COLORS[status] as ComponentProps<typeof Status>['visual']);

  const variantComponents = {
    [TransactionDetailsFieldVariants.TRANSACTION_DATE_HOUR]: () => (
      <RenderTransactionDateHour time={value} locale={locale} />
    ),
    [TransactionDetailsFieldVariants.CRYPTO_TRANSACTION_STATUS]: () => (
      <CryptoTransferTransactionStatus
        text={value}
        status={status as CryptoTransactionInfoStatus}
      />
    ),
    [TransactionDetailsFieldVariants.TRANSACTION_STATUS]: () => (
      <Status text={value} size="medium" visual={statusVisualVariant} />
    ),
    [TransactionDetailsFieldVariants.WALLET_ADDRESS]: () => (
      <div
        className={css({
          flex: '1',
          minWidth: '0px',
        })}
      >
        <CopyToClipboardButton
          value={value}
          visual="ghost"
          noBorder
          fullWidth
          ariaLabel={`${TransactionDetailsFieldVariants.WALLET_ADDRESS} copy`}
        >
          <span
            className={css({
              flex: 1,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              wordBreak: 'break-all',
              whiteSpace: 'normal',
              textAlign: 'end',
              lineClamp: 2,
            })}
          >
            {value}
          </span>
        </CopyToClipboardButton>
      </div>
    ),
    default: () => value,
  };

  const render =
    variantComponents[variant as keyof typeof variantComponents] ||
    variantComponents.default;

  return <>{render()}</>;
};

const valueContainerStyles = css({
  textAlign: 'end',
  minWidth: 80,
  textOverflow: 'ellipsis',
  flex: 1,
  fontSize: 'caption.medium_medium',
  fontWeight: 'caption.medium_medium',
  lineHeight: 'caption.medium_medium',
  letterSpacing: 'caption.medium_medium',
});

export const TransactionDetailsField: FC<TransactionDetailsFieldProps> = ({
  label,
  value,
  endSlot,
  locale,
  variant = TransactionDetailsFieldVariants.TRANSACTION,
  'data-testid': dataTestId,
  status,
}) => {
  return (
    <li
      className={css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 'small',
        flexWrap: 'wrap',
        maxWidth: '100%',
        ...([
          TransactionDetailsFieldVariants.TRANSACTION_DATE_HOUR,
          TransactionDetailsFieldVariants.TRANSACTION,
          TransactionDetailsFieldVariants.TRANSACTION_STATUS,
          TransactionDetailsFieldVariants.CRYPTO_TRANSACTION_STATUS,
          TransactionDetailsFieldVariants.WALLET_ADDRESS,
        ].includes(variant)
          ? { borderBottomWidth: '1px', borderColor: 'neutrals.divider' }
          : {}),

        py: 'small',
      })}
      data-testid={dataTestId}
      aria-label={`${label}-${value}`}
    >
      <div
        className={css({
          color: 'neutrals.text_secondary',
          fontSize: 'body.medium',
          fontWeight: 'body.medium',
          letterSpacing: 'body.medium',
          lineHeight: 'body.medium',
        })}
        role="presentation"
      >
        {label}
      </div>
      <div className={valueContainerStyles}>
        <TransactionValue
          locale={locale}
          value={value}
          variant={variant}
          status={status}
        />
      </div>
      {endSlot}
    </li>
  );
};
