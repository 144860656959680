'use client';

import { useRouter } from 'next/navigation';
import { Icon } from '@bts-web/design-system/component/icon';
import { ButtonBase } from '../../common/components/ButtonBase/ButtonBase';

type NavigationBackButtonProps = {
  handleVirtualNavigation?: () => void;
  isDisabled?: boolean;
  noPadding?: boolean;
  icon?: string;
};

const NavigationBackButton = ({
  handleVirtualNavigation,
  isDisabled,
  noPadding,
  icon,
}: NavigationBackButtonProps) => {
  const router = useRouter();

  const navigate = () => {
    if (handleVirtualNavigation) {
      handleVirtualNavigation();
    } else {
      router.back();

      setTimeout(() => {
        router.refresh();
      }, 500);
      // ? above setTimeout-based hack tricks the browser into a delayed router.refresh() after calling router.back() to ensure target page server actions refetch
      // ? helps on Accept Offer pages to make sure that the latest offer is fetched and BE error is avoided on pressing confirm, after going back from Legal Documents page
    }
  };

  return (
    <ButtonBase
      onClick={navigate}
      disabled={isDisabled}
      noPadding={noPadding}
      visual="ghost"
      size="small"
      aria-label="navigation back button"
      data-testid="navigation-back-button"
    >
      <Icon size="24" icon={icon ? icon : 'arrow-left'} />
    </ButtonBase>
  );
};

export { NavigationBackButton };
