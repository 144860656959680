import {
  MaximumFractionDigits,
  type PrecisionFloat,
} from '@bts-web/utils-formatting';
import { css } from '@bts-web/utils-style-engine';
import { AssetDetailsStatsSection } from './subcomponents/AssetDetailsStatsSection';
import { AssetType } from '../../../../trade/utils/reusableTypes';
import { ContentSectionWrapper } from '../../../../layouts/ContentSectionWrapper/ContentSectionWrapper';
import { Locale } from '@bts-web/utils-lokalise';

type AssetDetailsStatsTranslations = {
  dailyLow: string;
  dailyHigh: string;
  yearLow: string;
  yearHigh: string;
  assetFundSize: string;
  marketCap: string;
  volatility: string;
};

type AssetDetailsStatsData = {
  priceDailyLow: PrecisionFloat;
  priceDailyHigh: PrecisionFloat;
  price52WeeksLow: PrecisionFloat;
  price52WeeksHigh: PrecisionFloat;
  fundSize?: number | null | undefined;
  marketCap: number | null;
  monthlyVolatility: number | null | undefined;
};

interface AssetDetailsStatsProps {
  applicationLocale: Locale;
  translations: AssetDetailsStatsTranslations;
  assetType: AssetType;
  statsData: AssetDetailsStatsData;
  className?: string;
}

export const AssetDetailsStats = ({
  applicationLocale,
  translations,
  statsData,
  assetType,
  className,
}: AssetDetailsStatsProps) => {
  let assetDetailsPriceTestIdx = 1;

  const hasAnyStat = Object.values(statsData).some((value) => Boolean(value));

  if (!hasAnyStat) {
    return null;
  }

  return (
    <ContentSectionWrapper
      aria-label="asset statistics section"
      className={className ? className : ''}
      data-testid="asset statistics"
    >
      <ul
        className={css({
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 'small',
        })}
      >
        <AssetDetailsStatsSection
          locale={applicationLocale}
          topItemLabel={translations.dailyLow}
          topItemValue={statsData.priceDailyLow}
          bottomItemLabel={translations.yearLow}
          bottomItemValue={statsData.price52WeeksLow}
          data-testid={assetDetailsPriceTestIdx++}
        />
        <AssetDetailsStatsSection
          locale={applicationLocale}
          topItemLabel={translations.dailyHigh}
          topItemValue={statsData.priceDailyHigh}
          bottomItemLabel={translations.yearHigh}
          bottomItemValue={statsData.price52WeeksHigh}
          data-testid={assetDetailsPriceTestIdx++}
        />
        {assetType === AssetType.ETF || assetType === AssetType.ETC ? (
          <AssetDetailsStatsSection
            locale={applicationLocale}
            topItemLabel={translations.assetFundSize}
            topItemValue={
              statsData?.fundSize
                ? {
                    value: statsData.fundSize.toFixed(
                      MaximumFractionDigits.FIAT,
                    ),
                    precision: MaximumFractionDigits.FIAT,
                  }
                : null
            }
            data-testid={assetDetailsPriceTestIdx++}
          />
        ) : (
          <AssetDetailsStatsSection
            locale={applicationLocale}
            topItemLabel={translations.marketCap}
            topItemValue={
              statsData?.marketCap
                ? {
                    value: statsData?.marketCap?.toFixed(
                      MaximumFractionDigits.FIAT,
                    ),
                    precision: MaximumFractionDigits.FIAT,
                  }
                : null
            }
            data-testid={assetDetailsPriceTestIdx++}
          />
        )}
        <AssetDetailsStatsSection
          locale={applicationLocale}
          priceVariation={{
            label: translations.volatility,
            value: statsData?.monthlyVolatility as number,
          }}
          data-testid={assetDetailsPriceTestIdx++}
        />
      </ul>
    </ContentSectionWrapper>
  );
};
