'use client';

import { useEffect, useMemo, useState } from 'react';
import type { TransactionsViewQuery } from '@bts-web/data-layer/server';
import { useRouter } from 'next/navigation';
import { css } from '@bts-web/utils-style-engine';
import { TransactionsTranslationsKeysResult } from '../utils/getTransactionsTranslations';
import { getTransactions } from '../../common/gqlActions/getTransactions.action';
import { InfiniteTransactionList } from '../InfiniteTransactionList/InfiniteTransactionList';

const descriptionStyles = css({
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  letterSpacing: 'body.medium',
  lineHeight: 'body.medium',
  color: 'neutrals.text_primary',
  paddingX: 'medium',
});

export type TransactionsListTranslations = {
  activity: string;
  transactionDescription: string;
  amount: string;
};

export interface TransactionsListProps {
  itemsPerPage: number;
  assetId: string;
  translations: TransactionsTranslationsKeysResult;
  initialTransactions: TransactionsViewQuery['response']['transactions'];
}

export const InfiniteScrollTransactionListWrapper = ({
  itemsPerPage,
  assetId,
  translations,
  initialTransactions,
}: TransactionsListProps) => {
  const router = useRouter();

  const [transactions, setTransactions] =
    useState<TransactionsViewQuery['response']['transactions']>(
      initialTransactions,
    );

  useEffect(() => {
    getTransactions({
      input: {
        first: itemsPerPage,
        assetId,
      },
    })
      .then((transactionQueryData) => {
        const { data } = transactionQueryData;

        setTransactions(data.transactions);
      })
      .catch(() => {
        router.push('/error/server');
      });
  }, []);

  const currentEndCursor = useMemo(() => {
    return transactions?.pageInfo.endCursor;
  }, [transactions?.pageInfo.endCursor]);

  const loadMoreTransactions = () => {
    const queryParams = {
      input: {
        first: itemsPerPage,
        after: currentEndCursor,
        ...(assetId && { assetId }),
      },
    };

    getTransactions(queryParams)
      .then((transactionQueryData) => {
        const { transactions: responseTransactions } =
          transactionQueryData?.data ?? {};

        setTransactions({
          ...responseTransactions,
          edges: [
            ...(transactions?.edges || []),
            ...(responseTransactions?.edges || []),
          ],
        } as TransactionsViewQuery['response']['transactions']);
      })
      .catch(() => {
        router.push('/error/server');
      });
  };

  if (!transactions || transactions?.edges?.length === 0) {
    return (
      <span data-testid="empty-transactions" className={descriptionStyles}>
        {translations.emptyStateNoTransactions}
      </span>
    );
  }

  return (
    <InfiniteTransactionList
      transactions={transactions}
      loadMoreTransactions={loadMoreTransactions}
      itemsPerPage={itemsPerPage}
    />
  );
};
