import {
  SavingPlansFrameTranslationsKeys,
  SavingsPlansListTranslationsKeys,
  SavingsPlansTransactionsTranslationsKeys,
  StartSavingTranslationsKeys,
} from '../types';

export type SavingPlansTranslationsKeys = SavingPlansFrameTranslationsKeys &
  StartSavingTranslationsKeys &
  SavingsPlansListTranslationsKeys &
  SavingsPlansTransactionsTranslationsKeys;

export const initialSavingsPlansTranslations: SavingPlansTranslationsKeys = {
  savingsPlan: 'savings_plan',
  savingsPlans: 'savings_plans',
  savingsPlanStartSavingButton: 'savings_plans_frame_start_saving_button',
  savingsPlanManageButton: 'savings_plans_frame_manage_button',
  noPlan: 'savings_plans_frame_no_plan',
  startSavingTitle: 'start_saving_sheet_title',
  startSavingSectionTitleOne: 'start_saving_sheet_section_title_1',
  startSavingSectionTitleTwo: 'start_saving_sheet_section_title_2',
  startSavingSectionTitleThree: 'start_saving_sheet_section_title_3',
  startSavingSectionDescriptionOne: 'start_saving_sheet_section_description_1',
  startSavingSectionDescriptionTwo: 'start_saving_sheet_section_description_2',
  startSavingSectionDescriptionThree:
    'start_saving_sheet_section_description_3',
  continue: 'continue',
  savingsPlanSuccessTitle: 'savings_plan_success_title',
  savingsPlanSuccessSubtitle: 'savings_plan_success_subtitle',
  savingsPlanSuccessBodyLine1: 'savings_plan_success_body_line_1',
  savingsPlanSuccessBodyLine2: 'savings_plan_success_body_line_2',
  savingsPlanSuccessBodyLine3: 'savings_plan_success_body_line_3',
  savingsPlanSuccessBody2Line1: 'savings_plan_success_body2_line_1',
  savingsPlanSuccessBody2Line3: 'savings_plan_success_body2_line_3',
  savingsPlanSuccessBody2Line2: 'savings_plan_success_body2_line_2',
  okGoToPortfolio: 'ok_go_to_portfolio',
  savingsPlanSuccessCheckItOut: 'savings_plan_success_check_it_out',
  savingsPlanFailedTitle: 'transaction_fail_title',
  savingsPlanFailedSubtitle: 'savings_plan_failed_subtitle',
  tryAgain: 'try_again',
  cancelTransaction: 'cancel_transaction',
  poweredByBitpanda: 'powered_by_bitpanda',
  savingsPlansListTitleEmpty: 'savings_plans_list_title_empty',
  savingsPlansListSubtitle: 'savings_plans_list_subtitle',
  savingsPlansListSubtitleEmpty: 'savings_plans_list_subtitle_empty',
  savingsPlansListMonthly: 'monthly',
  savingsPlansListMonthlyNextOn: 'savings_plans_list_monthly_next_on',
  savingsPlansListActive: 'active',
  savingsPlansListCancelled: 'savings_plans_list_cancelled',
  savingsPlansListEmpty: 'reports_and_statements_list_empty',
  savingsPlansListCreateButton: 'savings_plans_list_create_button',
  viewAllTransactions: 'view_all_transactions',
  latestTransactions: 'dashboard_portfolio_latest_transactions',
  emptyStateNoTransactions: 'empty_state_no_transactions',
  transactions: 'transactions',
  transactionsListEmpty: 'reports_and_statements_list_empty',
  savingsPlansFrameActivePlans: 'savings_plans_frame_active_plans',
  savingsPlansFrameActivePlansPlural: 'savings_plans_frame_active_plans_plural',
  savingsPlansListTitleCancelled: 'savings_plans_list_title_cancelled',
  savingsPlansListTitleActivePlural: 'savings_plans_list_title_active_plural',
  savingsPlansListTitleActive: 'savings_plans_list_title_active',
  savingsPlansListTitleCancelledPlural:
    'savings_plans_list_title_cancelled_plural',
  errorMessageGenericSavingsPlan: 'error_message_generic_savings_plan',
  goToPortfolio: 'go_to_portfolio',
  goBack: 'go_back',
  failed: 'failed',
};

const getSavingsPlansTranslations = (
  t: (translationKey: keyof SavingPlansTranslationsKeys) => string,
): SavingPlansTranslationsKeys => {
  return Object.entries(initialSavingsPlansTranslations).reduce(
    (acc, [key, value]) => {
      acc[key as keyof SavingPlansTranslationsKeys] = t(
        value as keyof SavingPlansTranslationsKeys,
      );

      return acc;
    },
    {} as SavingPlansTranslationsKeys,
  );
};

export { getSavingsPlansTranslations };
