import { css } from '@bts-web/utils-style-engine';
import Image from 'next/image';
import { BelowInputInfoTable, Collapsible } from '../../../../../common';
import { getAppConfig } from '@bts-web/core-app-config';
import { AcceptTradeControllerVariants } from '../../../types';
import guaranteeCheckmark from './guarantee-checkmark.svg';

export type FeesListTranslations = {
  priceGuarantee: string;
  feesIncluded: string;
  paymentMethod: string;
  buyAmount: string;
  buyVolume: string;
  sellAmount: string;
  sellVolume: string;
  offerPrice: string;
  tradeFee: string;
  offerFeesDescription: string;
};

type FeesListValues = {
  paymentMethod?: string;
  amount: string;
  volume: string;
  offerPrice: string;
  tradeFee: string;
};

export const FeesList = ({
  translations,
  variant,
  values,
}: {
  translations: FeesListTranslations;
  variant: AcceptTradeControllerVariants;
  values: FeesListValues;
}) => {
  const { feature_isPaymentMethodVisibleOnAccept } = getAppConfig();

  return (
    <Collapsible
      header={{
        RightContentIndicatorSlot: translations.feesIncluded,
        LeftContentIndicatorSlot: (
          <span className={css({ display: 'flex', gap: 'extra_small_3' })}>
            {translations.priceGuarantee}
            <Image
              src={guaranteeCheckmark}
              alt="checkmark icon"
              data-testid="guarantee-icon"
              width={14}
              height={14}
            />
          </span>
        ),
      }}
    >
      <div
        data-testid="transaction-fees"
        className={css({
          display: 'flex',
          flexDirection: 'column',
          pb: 'medium',
          gap: 'medium',
          borderBottom: '1px solid',
          borderColor: 'neutrals.divider',
        })}
      >
        <BelowInputInfoTable
          items={[
            ...(feature_isPaymentMethodVisibleOnAccept
              ? [
                  {
                    title: translations.paymentMethod,
                    value: values.paymentMethod,
                  },
                ]
              : []),
            {
              title:
                variant === 'buy'
                  ? translations.buyVolume
                  : translations.sellVolume,
              value: values.volume,
            },
            {
              title:
                variant === 'buy'
                  ? translations.buyAmount
                  : translations.sellAmount,
              value: values.amount,
            },
            {
              title: translations.offerPrice,
              value: values.offerPrice,
            },
            {
              title: translations.tradeFee,
              value: values.tradeFee,
            },
          ]}
        />

        <p
          className={css({
            color: 'neutrals.text_secondary',
            fontSize: 'caption.medium_medium',
            fontWeight: 'caption.medium_medium',
            letterSpacing: 'caption.medium_medium',
            lineHeight: 'caption_medium',
          })}
        >
          {translations.offerFeesDescription}
        </p>
      </div>
    </Collapsible>
  );
};
