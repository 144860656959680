'use client';

import { useState } from 'react';
import { useRouter } from 'next/navigation';
import { TermsAndConditionsViewQuery } from '@bts-web/data-layer/server';
import { css } from '@bts-web/utils-style-engine';
import { ButtonBase } from '../../common/components/ButtonBase/ButtonBase';
import { AppUserFullInfo } from '../../common/userinfo/types';
import { postUpdateUser } from '../../common/gqlActions/postUpdateUser.action';
import { postUpdateBusinessUser } from '../../common/gqlActions/postUpdateBusinessUser.action';

interface IAgreeExtraTermsButtonProps extends React.PropsWithChildren {
  termsAndConditions: TermsAndConditionsViewQuery['response']['termsAndConditions'];
  userType: AppUserFullInfo['userType'];
  onClose?: () => void;
  disabled?: boolean;
}

const IAgreeExtraTermsButton = ({
  termsAndConditions,
  userType,
  children,
  onClose,
  disabled = false,
}: IAgreeExtraTermsButtonProps) => {
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);

  const termsIds =
    (termsAndConditions?.edges
      ?.map((edge) => edge?.node?.id)
      .filter(Boolean) as string[]) || [];

  const updateUserAction =
    userType === 'User' ? postUpdateUser : postUpdateBusinessUser;

  const onClickIAgree = () => {
    setIsLoading(true);

    updateUserAction({
      termsAndConditions: termsIds,
    })
      .then((response) => {
        if (onClose) {
          onClose();
        }

        if (response.errors) {
          router.push('/error/generic');

          return;
        }

        router.push('/assets/portfolio');
      })
      .catch((_error) => {
        router.push('/error/generic');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div
      className={css({
        mt: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      })}
    >
      <ButtonBase
        isLoading={isLoading}
        disabled={disabled || isLoading}
        onClick={onClickIAgree}
        visual="primary"
        size={'large'}
      >
        {children}
      </ButtonBase>
    </div>
  );
};

export { IAgreeExtraTermsButton };
