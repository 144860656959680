import { css } from '@bts-web/utils-style-engine';
import { type TransactionsViewQuery } from '@bts-web/data-layer/server';
import { Icon } from '@bts-web/design-system/component/icon';
import { getTransactionsWidgetTranslations } from './utils/getTransactionsWidgetTranslations';
import { itemsListHeaderStyles } from '../styles';
import { getTransactions } from '../gqlActions';
import { ContentSectionWrapper } from '../../layouts/ContentSectionWrapper/ContentSectionWrapper';
import { Link } from '../components';
import { TFunction } from 'i18next';
import {
  initServerTranslation,
  updateServerLanguage,
} from '@bts-web/utils-lokalise';
import { TransactionList } from '../../transactions/TransactionList/TransactionList';

const { t } = initServerTranslation();

const transactionsLinkStyles = css({
  gap: 'extra_small_2',
  fontSize: 'small',
  display: 'flex',
  alignItems: 'center',
});

const descriptionStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'small',
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  letterSpacing: 'body.medium',
  lineHeight: 'body.medium',
  color: 'neutrals.text_primary',
});

type TransactionsWidgetVariant = 'Portfolio' | 'AssetDetails';

export interface TransactionsWidgetControllerProps {
  variant: TransactionsWidgetVariant;
  showOnlyRedirectLink?: boolean;
  fetchNumberOfItems: number;
  assetId?: string;
}

export const TransactionsWidgetController = async ({
  variant,
  showOnlyRedirectLink = false,
  fetchNumberOfItems = 20,
  assetId,
}: TransactionsWidgetControllerProps) => {
  await updateServerLanguage();

  const widgetTranslations = getTransactionsWidgetTranslations(t as TFunction);

  const latestTransactions = await getTransactions({
    input: {
      ...(assetId && { assetId }),
      first: fetchNumberOfItems,
    },
  });

  const transactions =
    (
      latestTransactions?.data?.transactions as NonNullable<
        TransactionsViewQuery['response']['transactions']
      >
    )?.edges ?? [];

  // do not display related transactions if the user do not own any asset in the asset-detail page
  if (assetId && !transactions?.length) {
    return null;
  }

  const transactionsLink = (
    <Link
      size="small"
      href={assetId ? `/transactions?assetId=${assetId}` : '/transactions'}
    >
      <span className={transactionsLinkStyles}>
        {assetId
          ? widgetTranslations.viewRelatedTransactions
          : widgetTranslations.viewAllTransactions}
        <Icon size="16" icon="arrow-right" />
      </span>
    </Link>
  );

  if (showOnlyRedirectLink) {
    return <ContentSectionWrapper>{transactionsLink}</ContentSectionWrapper>;
  }

  const noTransactionsToShow = transactions.length === 0;

  return (
    <ContentSectionWrapper
      sectionTitle={
        variant === 'AssetDetails'
          ? widgetTranslations.viewRelatedTransactions
          : widgetTranslations.latestTransactions
      }
      {...(noTransactionsToShow ? { id: 'latest-transactions' } : {})}
      // ? For the case when there are transactions we attach the id attribute that serves as an URL anchor to
      // ? an element positioned above - see PortfolioAssets.tsx, line 188;
      // ? The id attribute in here is only useful as an anchor in the case of an empty portfolio, where no assets = no transactions;
      // ? It is done this way because the mobile floating header will hide the title when scrolling directly to the
      // ? Latest Transactions heading using an anchor, in the case of a portfolio with assets and transactions.
      data-testid="transactions widget"
    >
      {noTransactionsToShow ? (
        <div className={descriptionStyles}>
          <p data-testid="empty-transactions">
            {widgetTranslations.emptyStateNoTransactions}
          </p>

          <p data-testid="latest-transactions-description">
            {widgetTranslations.latestTransactionsDescription}
          </p>
        </div>
      ) : (
        <div>
          <div className={itemsListHeaderStyles}>
            <span>{widgetTranslations.transactionDescription}</span>
            <span>{widgetTranslations.amount}</span>
          </div>
          <TransactionList transactions={transactions} />

          <div
            className={css({
              mt: 'medium',
            })}
          >
            {transactionsLink}
          </div>
        </div>
      )}
    </ContentSectionWrapper>
  );
};
