'use client';

import { useEffect, useState, useTransition } from 'react';
import { datadogErrorHelper } from '../../common/utils/datadogErrorHelper';
import { DatadogErrorLevels } from '@bts-web/utils-next-infrastructure';
import { getCryptoTransfersTransactions } from '../../common/gqlActions';
import { CryptoTransfersTransactionsItemType } from '../../transaction-details/types';
import { useHandleGqlErrorsWithSnackbar } from '@bts-web/core-features/common';

export const useCryptoTransferTransactionDetails = ({
  cryptoTransferId,
}: {
  cryptoTransferId?: string;
}) => {
  const [
    isCryptoTransferTransactionFetching,
    startCryptoTransferTransactionFetching,
  ] = useTransition();

  const [cryptoTransferTransaction, setCryptoTransfer] =
    useState<CryptoTransfersTransactionsItemType>(null);

  const { processErrors } = useHandleGqlErrorsWithSnackbar();

  useEffect(() => {
    startCryptoTransferTransactionFetching(async () => {
      try {
        const response = await getCryptoTransfersTransactions({
          id: cryptoTransferId,
        });

        if (response?.errors) {
          processErrors(response.errors);
        }

        const cryptoTransferTransactionNode =
          response?.data?.cryptoTransfersTransactions?.edges?.[0]?.node || null;

        setCryptoTransfer(cryptoTransferTransactionNode);
      } catch (error) {
        datadogErrorHelper({
          errorMessage: JSON.stringify(error),
          errorSeverity: DatadogErrorLevels.HIGH,
        });
      }
    });
  }, [cryptoTransferId]);

  return { isCryptoTransferTransactionFetching, cryptoTransferTransaction };
};
