import { TimeAmounts } from '@bts-web/design-system/component/chart';
import { AssetDetailsAreaChartTranslations } from '../types';

export type RangeSelectorConfigItem = {
  timeAmountId: TimeAmounts;
  text: string;
};

export const getChartRangeSelectorsConfig = (
  translations: AssetDetailsAreaChartTranslations,
) => {
  const { last24h, lastWeek, lastMonth, lastYear, maxYears } = translations;

  const rangeSelectorsConfig: RangeSelectorConfigItem[] = [
    {
      timeAmountId: TimeAmounts.OneDay,
      text: last24h,
    },
    {
      timeAmountId: TimeAmounts.SevenDays,
      text: lastWeek,
    },
    {
      timeAmountId: TimeAmounts.OneMonth,
      text: lastMonth,
    },
    {
      timeAmountId: TimeAmounts.OneYear,
      text: lastYear,
    },
    {
      timeAmountId: TimeAmounts.MaxYears,
      text: maxYears,
    },
  ];

  return rangeSelectorsConfig;
};
