'use client';

import { ReactNode } from 'react';
import * as RadixAccordion from '@radix-ui/react-accordion';
import { Item, type AccordionItem } from './AccordionItem.use-client';
import { AccordionContent } from './AccordionContent';

export interface AccordionProps {
  internal: {
    className: string;
    itemClassName: string;
  };
  external: {
    defaultValue?: string;
    items: AccordionItem[];
    IconComponent?: ReactNode;
    lastItemId?: string;
  };
}

export function Accordion({
  defaultValue,
  className,
  itemClassName,
  IconComponent,
  items,
  lastItemId,
  ...restProps
}: AccordionProps['internal'] & AccordionProps['external']) {
  const isSingleItem = items.length === 1;

  return (
    <RadixAccordion.Root
      data-testid="accordion-root"
      className={className}
      type="single"
      defaultValue={defaultValue}
      collapsible
      {...restProps}
    >
      {isSingleItem ? (
        <>
          {items[0].contents.map(
            ({ ContentIndicatorSlot, ...restContent }, index) => (
              <AccordionContent
                {...restContent}
                key={`content_${restContent.title}_${restContent.subtitle}`}
                {...(lastItemId && index === items[0].contents.length - 1
                  ? { id: lastItemId }
                  : {})}
              >
                {ContentIndicatorSlot}
              </AccordionContent>
            ),
          )}
        </>
      ) : (
        <>
          {items.map((item, index) => (
            <Item
              key={`accordion_item_${item.value}`}
              className={itemClassName}
              IconComponent={IconComponent}
              item={item}
              {...(lastItemId && index === items.length - 1
                ? { id: lastItemId }
                : {})}
            />
          ))}
        </>
      )}
    </RadixAccordion.Root>
  );
}
