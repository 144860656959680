'use client';

import { FC } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { QRCode as QRCodeLogo } from 'react-qrcode-logo';

export const QR_CODE_SIZE = 204;

interface QRCodeProps {
  value: string;
  size?: number;
  logoImageUrl?: string;
  className?: string;
}

const qrCodeContainerStyle = css({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
});

export const QRCode: FC<QRCodeProps> = ({
  value,
  logoImageUrl,
  className,
  size = QR_CODE_SIZE,
}) => {
  let logoImageProps = {};

  if (logoImageUrl) {
    logoImageProps = {
      logoImage: logoImageUrl,
      logoWidth: 38,
      logoHeight: 38,
      removeQrCodeBehindLogo: true,
    };
  }

  return (
    <div className={`${className ? className : ''} ${qrCodeContainerStyle}`}>
      <QRCodeLogo
        size={size}
        value={value}
        {...logoImageProps}
        style={{
          borderRadius: 6,
        }}
      />
    </div>
  );
};
