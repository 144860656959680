import { TransactionEntryType } from '../TransactionItem/TransactionItem';

export const transactionTypeByTypeName = {
  BuyTransaction: 'BUY',
  SellTransaction: 'SELL',
  GiveawayTransaction: 'GIVEAWAY',
  StakingRewardTransaction: 'STAKING_REWARD',
  MergerCryptoTransaction: 'MERGER_CRYPTO',
  DeductionTransaction: 'DEDUCTION',
  SwapTransaction: 'SWAP',
  OtcTransaction: 'OTC',
  SavingsPlanTransaction: 'SAVINGS_PLAN',
  InternalSavingsPlanTransaction: 'INTERNAL_SAVINGS_PLAN',
  DepositTransaction: 'DEPOSIT',
  DelistingTransaction: 'DELISTING',
  DividendTransaction: 'DIVIDEND',
  BaseTimelineTransaction: 'BASE_TIMELINE',
  MergerCashTransaction: 'MERGER_CASH',
  MergerStockTransaction: 'MERGER_STOCK',
  MetalStorageFeeTransaction: 'METAL_STORAGE_FEE',
  StockSplitTransaction: 'STOCK_SPLIT',
  ReverseStockSplitTransaction: 'REVERSE_STOCK_SPLIT',
  SpinOffTransaction: 'SPINOFF',
  TradeTransaction: 'TRADE',
  TaxRefundTransaction: 'TAX_REFUND',
  WithdrawalTransaction: 'WITHDRAWAL',
  Transaction: 'TRANSACTION',
  TenderTransaction: 'TENDER',
  RightsIssueTransaction: 'RIGHTS_ISSUE',
  WithdrawalCryptoTransfersTransaction: 'WITHDRAWAL_CRYPTO_TRANSFERS',
  DepositCryptoTransfersTransaction: 'DEPOSIT_CRYPTO_TRANSFERS',
} as const;

type Keys = keyof typeof transactionTypeByTypeName;

type Values = (typeof transactionTypeByTypeName)[Keys];

const transactionTypeNamesByType = Object.fromEntries(
  Object.entries(transactionTypeByTypeName).map(([key, value]) => [value, key]),
) as Record<Values, Keys>;

export const getTransactionTypeNameByType = (type: string) => {
  return transactionTypeNamesByType[type as Values];
};

export type TransactionTypes =
  (typeof transactionTypeByTypeName)[keyof typeof transactionTypeByTypeName];

export const transactionTagTranslationsByLocalType = {
  // the translation key for this has kind of a wrong name
  [transactionTypeByTypeName.OtcTransaction]: 'tx_tag_otc_buy',
  [transactionTypeByTypeName.SpinOffTransaction]: 'tx_tag_spinoff',
  // [transactionTypeByTypeName.TenderTransaction]: 'tx_tag_tender',
  [transactionTypeByTypeName.StockSplitTransaction]: 'tx_tag_stocksplit',
  // [transactionTypeByTypeName.RightsIssueTransaction]: 'tx_tag_rightissue',
  [transactionTypeByTypeName.ReverseStockSplitTransaction]:
    'tx_tag_reversestocksplit',
  [transactionTypeByTypeName.MergerStockTransaction]: 'tx_tag_mergerstock',
  [transactionTypeByTypeName.MergerCashTransaction]: 'tx_tag_mergercash',
  [transactionTypeByTypeName.MergerCryptoTransaction]: 'tx_tag_mergercrypto',
  [transactionTypeByTypeName.DividendTransaction]: 'tx_tag_dividend',
  [transactionTypeByTypeName.DelistingTransaction]: 'tx_tag_delisting',
  [transactionTypeByTypeName.SavingsPlanTransaction]: 'savings_plan',
  [transactionTypeByTypeName.InternalSavingsPlanTransaction]: 'savings_plan',
  [transactionTypeByTypeName.WithdrawalCryptoTransfersTransaction]:
    'tx_details_type_withdrawal',
  [transactionTypeByTypeName.DepositCryptoTransfersTransaction]: 'deposit',
};

enum FrontendTitleTransactionCategories {
  BUY = 'tx_buy_item',
  SELL = 'tx_sell_item',
  GIVEAWAY = 'tx_giveaway_item',
  CORPORATE_ACTION = 'tx_corporate_action_item',
  STORAGE_FEE = 'tx_storage_fee_item',
  STAKING_REWARD = 'tx_staking_reward_item',
  TAX_REFUND = 'tx_tax_refund_item',
  WITHDRAWAL = 'tx_withdrawal_item',
  DEPOSIT = 'tx_deposit_item',
  OTC = 'tx_otc_item',
  SAVINGS_PLAN = 'tx_savings_plan_item',
}

export const transactionTypesWithDetail: string[] = [
  transactionTypeByTypeName.BuyTransaction,
  transactionTypeByTypeName.SellTransaction,
  transactionTypeByTypeName.SavingsPlanTransaction,
  transactionTypeByTypeName.WithdrawalTransaction,
  transactionTypeByTypeName.DepositTransaction,
  transactionTypeByTypeName.InternalSavingsPlanTransaction,
  transactionTypeByTypeName.WithdrawalCryptoTransfersTransaction,
  transactionTypeByTypeName.DepositCryptoTransfersTransaction,
  transactionTypeByTypeName.DepositTransaction,
  transactionTypeByTypeName.WithdrawalTransaction,
];

export const getTransactionTranslationKeywordByType = (
  type: TransactionTypes,
) => {
  switch (true) {
    case type === 'BUY':
      return FrontendTitleTransactionCategories.BUY;

    case type === 'SELL':
      return FrontendTitleTransactionCategories.SELL;

    case type === 'GIVEAWAY':
      return FrontendTitleTransactionCategories.GIVEAWAY;

    case type === 'SAVINGS_PLAN':
      return FrontendTitleTransactionCategories.SAVINGS_PLAN;

    case type === 'STAKING_REWARD':
      return FrontendTitleTransactionCategories.STAKING_REWARD;

    case type === 'DIVIDEND':
      return FrontendTitleTransactionCategories.CORPORATE_ACTION;

    case type === 'TAX_REFUND':
      return FrontendTitleTransactionCategories.TAX_REFUND;

    case type === 'DEDUCTION':
      // MISSING TRANSLATION IN SOURCE FOR THIS: https://docs.google.com/spreadsheets/d/12TVXMsmIp-oQy50hP69QOs6uU2h2_KP9RaM9TFqer0k/edit?gid=1367121438#gid=1367121438
      return FrontendTitleTransactionCategories.CORPORATE_ACTION;

    case type === 'METAL_STORAGE_FEE':
      return FrontendTitleTransactionCategories.STORAGE_FEE;

    case type === 'OTC':
      return FrontendTitleTransactionCategories.OTC;

    case type === 'WITHDRAWAL':
      return FrontendTitleTransactionCategories.WITHDRAWAL;

    case type === 'DEPOSIT':
      return FrontendTitleTransactionCategories.DEPOSIT;

    case type === 'SWAP':
      return FrontendTitleTransactionCategories.CORPORATE_ACTION;

    case type === 'DELISTING':
      return FrontendTitleTransactionCategories.CORPORATE_ACTION;

    case type === 'MERGER_CRYPTO':
      return FrontendTitleTransactionCategories.CORPORATE_ACTION;

    case type === 'MERGER_CASH':
      return FrontendTitleTransactionCategories.CORPORATE_ACTION;

    case type === 'MERGER_STOCK':
      return FrontendTitleTransactionCategories.CORPORATE_ACTION;

    case type === 'REVERSE_STOCK_SPLIT':
      return FrontendTitleTransactionCategories.CORPORATE_ACTION;

    case type === 'SPINOFF':
      return FrontendTitleTransactionCategories.CORPORATE_ACTION;

    case type === 'STOCK_SPLIT':
      return FrontendTitleTransactionCategories.CORPORATE_ACTION;

    case type === 'WITHDRAWAL_CRYPTO_TRANSFERS':
      return FrontendTitleTransactionCategories.WITHDRAWAL;

    case type === 'DEPOSIT_CRYPTO_TRANSFERS':
      return FrontendTitleTransactionCategories.DEPOSIT;

    default:
      return FrontendTitleTransactionCategories.CORPORATE_ACTION;
  }
};

export const isCryptoTransferTransaction = (transactionType: string) => {
  return [
    transactionTypeByTypeName.DepositTransaction,
    transactionTypeByTypeName.WithdrawalTransaction,
    transactionTypeByTypeName.DepositCryptoTransfersTransaction,
    transactionTypeByTypeName.WithdrawalCryptoTransfersTransaction,
  ].some((item) => item === transactionType);
};

export const isClassicTransaction = (transactionType: string) => {
  return [
    transactionTypeByTypeName.BuyTransaction,
    transactionTypeByTypeName.SellTransaction,
    transactionTypeByTypeName.SavingsPlanTransaction,
    transactionTypeByTypeName.InternalSavingsPlanTransaction,
  ].some((item) => item === transactionType);
};

export const isSavingsPlanTransaction = (transactionType: string) => {
  return [
    transactionTypeByTypeName.SavingsPlanTransaction,
    transactionTypeByTypeName.InternalSavingsPlanTransaction,
  ].some((item) => item === transactionType);
};

export const getPartialTransactionEntryType = (
  typename: string,
  transactionId: string,
  assetName: string,
): TransactionEntryType => {
  return {
    __typename: typename,
    id: transactionId,
    asset: {
      __typename: '',
      id: '',
      name: assetName,
      logoUrl: null,
      symbol: null,
    },
    assetAmount: null,
    initiatedBy: null,
    category: 'CRYPTO_TRANSFER',
    direction: null,
    fiatAmount: null,
    status: null,
    time: null,
  };
};
