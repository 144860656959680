'use client';

import NextLink from 'next/link';
import { css } from '@bts-web/utils-style-engine';
import { useRedirectBasedOnRefQueryParam } from '../../../utils/useRedirectBasedOnRefQueryParam';
import { NavigationBackButton } from '../../../../navigation/index';
import { TabBreadcrumbs } from '../../../../common';

const navigationStyles = css({
  backgroundColor: 'screen_background.primary',
  paddingTop: 'extra_small',
  paddingBottom: 'extra_small',
  paddingInlineStart: 'small',
  paddingInlineEnd: 'small',

  lg: {
    padding: 0,
  },
});

interface AssetDetailsNavigationProps {
  assetName: string;
  assetTypeFilterName: string;
  assetTypeTranslation: string;
}

export const AssetDetailsNavigation = ({
  assetName,
  assetTypeFilterName,
  assetTypeTranslation,
}: AssetDetailsNavigationProps) => {
  const { redirectBasedOnRefQueryParam } = useRedirectBasedOnRefQueryParam();

  return (
    <div className={navigationStyles} aria-label="asset details navigation">
      <TabBreadcrumbs
        LeftComponent={
          <NavigationBackButton
            noPadding
            handleVirtualNavigation={redirectBasedOnRefQueryParam}
          />
        }
      >
        <NextLink
          href={`/assets/discover?assetType=${encodeURIComponent(
            assetTypeFilterName,
          )}`}
        >
          {assetTypeTranslation}
        </NextLink>

        <div>{assetName}</div>
      </TabBreadcrumbs>
    </div>
  );
};
