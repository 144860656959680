import { getLocale, i18n, Language } from '@bts-web/utils-lokalise';
import {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  useId,
  FocusEvent,
  InputHTMLAttributes,
  FormEventHandler,
} from 'react';

type MainInputProps = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: (value: string) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  value: string;
  placeholder?: HTMLInputElement['placeholder'];
  id?: string;
  className?: string;
  onInvalid?: FormEventHandler<HTMLInputElement>;
  pattern?: string;
  maxLength?: number;
  disabled?: boolean;
};

export const MainInput = forwardRef<HTMLInputElement, MainInputProps>(
  (
    {
      onBlur,
      onChange,
      onFocus,
      value,
      placeholder,
      id,
      className,
      disabled,
      pattern,
      maxLength,
      onInvalid,
    },
    ref,
  ) => {
    const locale = getLocale(i18n.resolvedLanguage as Language);

    const localId = `input-${useId()}`;

    const inputProps: InputHTMLAttributes<HTMLInputElement> = {
      autoComplete: 'off',
      placeholder: placeholder,
      type: 'text',
      value,
      lang: locale,
      pattern,
      maxLength,
      onChange,
      onBlur: (e: FocusEvent<HTMLInputElement>) => onBlur?.(e.target.value),
      onFocus,
      onInvalid,
    };

    return (
      <input
        ref={ref}
        aria-label="input field"
        data-element="input"
        data-testid="main__input"
        {...inputProps}
        id={id || localId}
        className={className}
        disabled={disabled}
        // data-valid={value.length > 0 && isValid}
        // data-invalid={value.length > 0 && !isValid}
      />
    );
  },
);
