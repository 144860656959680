import { forwardRef } from 'react';
import {
  Icon as IconifyIcon,
  IconifyIconHTMLElement,
  addCollection,
} from '@iconify-icon/react';
import { collection } from '@bts-web/custom-icon/collection';

export type IconSize = '10' | '12' | '16' | '20' | '24' | '28' | '32' | '48';

export type IconThemes = 'regular' | 'filled';

export type IconProps = Omit<React.HTMLProps<HTMLElement>, 'size' | 'ref'> & {
  icon: string;
  iconPrefix?: string;
  size?: IconSize;
  theme?: IconThemes;
};

const Icon = forwardRef<IconifyIconHTMLElement | null, IconProps>(
  (props, ref) => {
    const {
      icon,
      size = '20',
      width,
      height,
      theme = 'filled',
      iconPrefix = 'fluent',
      ...extraProps
    } = props;

    const iconName =
      iconPrefix === 'fluent'
        ? `${iconPrefix}:${icon}-${size}-${theme}`
        : `${iconPrefix}:${icon}`;

    return (
      <IconifyIcon
        ref={ref}
        icon={iconName}
        width={width || size}
        height={height || size}
        data-testid={`icon_${icon}`}
        aria-label={`${icon} icon`}
        {...extraProps}
      />
    );
  },
);

addCollection(collection);

export { Icon };
