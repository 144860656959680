'use client';

import { Icon } from '@bts-web/design-system/component/icon';
import { css } from '@bts-web/utils-style-engine';
import { useComposableModal } from '@bts-web/utils-context';
import { ButtonBase } from '../../common';
import { StartSavingTranslationsKeys } from '../types';
import { StartSavingModal } from '../StartSavingModal/StartSavingModal';

interface StartSavingButtonProps {
  className?: string;
  continueUrl: string;
  translations: StartSavingTranslationsKeys;
}

const StartSavingButton = ({
  className,
  continueUrl,
  translations,
}: StartSavingButtonProps) => {
  const { showModal } = useComposableModal();

  const onClickStartSaving = () => {
    showModal(StartSavingModal, {
      continueUrl,
    });
  };

  return (
    <ButtonBase
      className={className}
      noPadding
      visual="ghost"
      onClick={onClickStartSaving}
    >
      <span>{translations.savingsPlanStartSavingButton}</span>
      <Icon
        className={css({ color: 'positive.fill_primary' })}
        icon="arrow-right"
        size="16"
      />
    </ButtonBase>
  );
};

export { StartSavingButton };
