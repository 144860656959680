'use client';

import { FC } from 'react';
import { Drawer } from '../../Drawer/Drawer';
import { DrawerTriggerButton } from '../../Drawer/DrawerTriggerButton';
import { DrawerContent } from '../../../common/components/DrawerContent/DrawerContent';
import { UserTopBarMenu } from '../DesktopTopHeaderBar/subcomponents/UserTopBarMenu/UserTopBarMenu';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { Icon } from '@bts-web/design-system/component/icon';

const MobileProfileMenu: FC = () => {
  const { t } = useClientTranslation();

  const translations = {
    frequentlyAskedQuestions: t('frequently_asked_questions'),
    customerSupport: t('customer_support'),
    termsAndConditions: t('terms_and_conditions'),
    generalLogout: t('general_logout'),
    profile: t('general_profile'),
    twoFaWelcomeTitle: t('2fa_welcome_title'),
    twoFaResetTitle: t('2fa_reset_title'),
    reportsAndStatements: t('reports_and_statements'),
    legalDocumentation: t('legal_documentation'),
  };

  const ProfileDrawer = () => (
    <Drawer
      title={translations.profile}
      position="bottom"
      contentNode={
        <DrawerContent title={translations.profile} ariaLabel="profile menu">
          <UserTopBarMenu translatedValues={translations} />
        </DrawerContent>
      }
    />
  );

  return (
    <DrawerTriggerButton DrawerComponent={ProfileDrawer}>
      <button type="button">
        <Icon icon="person" size="20" theme="regular" />
      </button>
    </DrawerTriggerButton>
  );
};

export { MobileProfileMenu };
