/**
 * @generated SignedSource<<b2f1a996e92fe54df78be7cb4b81d187>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CryptoTransfersVASPCategory = "OTHER" | "POPULAR";
export type CryptoTransfersVASPQuery$variables = {};
export type CryptoTransfersVASPQuery$data = {
  readonly cryptoTransfersVASP: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly category: CryptoTransfersVASPCategory;
        readonly id: string;
        readonly name: string;
      } | null;
    } | null> | null;
  } | null;
};
export type CryptoTransfersVASPQuery = {
  response: CryptoTransfersVASPQuery$data;
  variables: CryptoTransfersVASPQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CryptoTransfersVASPConnection",
    "kind": "LinkedField",
    "name": "cryptoTransfersVASP",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CryptoTransfersVASPEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Vasp",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "category",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CryptoTransfersVASPQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CryptoTransfersVASPQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "36c868a11d8f72cdcb5f06e8361854ac",
    "id": null,
    "metadata": {},
    "name": "CryptoTransfersVASPQuery",
    "operationKind": "query",
    "text": "query CryptoTransfersVASPQuery {\n  cryptoTransfersVASP {\n    edges {\n      node {\n        id\n        name\n        category\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1a38babf285b2dd58c1be883ac460b82";

export default node;
