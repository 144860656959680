import { FC, useState } from 'react';
import { TwoFaSectionButtonsWrapper } from '../TwoFaSectionButtonsWrapper';
import { DigitCodeInput } from '../../../../DigitCodeInput';
import { ButtonBase } from '../../../../ButtonBase/ButtonBase';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { css } from '@bts-web/utils-style-engine';
import { TTwoFaCommonProps } from '../../SetupTwoFaModal/types';
import {
  postTwoFaCodeSetup,
  TwoFaCodeSetupActionVariant,
} from '../../../../../../auth';
import { datadogErrorHelper } from '../../../../../utils/datadogErrorHelper';
import { DatadogErrorLevels } from '@bts-web/utils-next-infrastructure';
import { useUserInfoContext } from '../../../../../userinfo/UserInfoContext/UserInfoProvider';
import { useHandleGqlErrorsWithSnackbar } from '../../../../../utils/errors';

export type TwoFaCodeSubmitBoxProps = Pick<
  TTwoFaCommonProps,
  'onContinue' | 'bindRecoveryCodes'
> & {
  actionVariant: TwoFaCodeSetupActionVariant;
};

type TwoFaInputStatus = 'invalid' | 'valid';

/**
 * TwoFaCodeSubmitBox component handles the submission of a 2FA inputCode during the initial setup process.
 * It validates the inputCode, sends it to the server, and processes the response.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onContinue - Callback function to be called when the process is successful.
 * @param {Function} props.bindRecoveryCodes - Function to bind the recovery codes received from the server.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <TwoFaCodeSubmitBox
 *   onContinue={handleContinue}
 *   bindRecoveryCodes={handleBindRecoveryCodes}
 * />
 */
const TwoFaCodeSubmitBox: FC<TwoFaCodeSubmitBoxProps> = ({
  onContinue,
  actionVariant,
  bindRecoveryCodes,
}) => {
  const { refreshUserInfo } = useUserInfoContext();

  const { t } = useClientTranslation();

  const { processErrors } = useHandleGqlErrorsWithSnackbar();

  const [inputStatus, setInputStatus] = useState<TwoFaInputStatus>('valid');

  const [inputCode, setInputCode] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);

  const isCodeValid =
    inputCode.length === 6 &&
    inputCode.split('').every((digit) => /^\d$/.test(digit));

  const onCodeSubmit = async () => {
    setLoading(true);

    try {
      const response = await postTwoFaCodeSetup(inputCode, actionVariant);

      const { backup_codes } = response ?? {};

      if (backup_codes && backup_codes.length > 0) {
        const combinedValue = response.backup_codes.join(',\n').trimEnd();

        bindRecoveryCodes(combinedValue);

        onContinue();

        setLoading(false);
      } else if (response.errors || response.message === 'invalid code') {
        setInputStatus('invalid');

        if (response?.errors) {
          processErrors(response.errors);
        }

        setLoading(false);
      } else {
        datadogErrorHelper({
          errorMessage: JSON.stringify(response),
          errorSeverity: DatadogErrorLevels.HIGH,
        });

        processErrors([JSON.stringify(response)]);

        setLoading(false);
      }
    } catch (error) {
      processErrors();

      datadogErrorHelper({
        errorMessage: JSON.stringify(error),
        errorSeverity: DatadogErrorLevels.HIGH,
      });

      setLoading(false);
    } finally {
      refreshUserInfo();

      setLoading(false);
    }
  };

  const onChangeInput = (isFilled: boolean, code: string) => {
    if (code !== inputCode) {
      setInputStatus('valid');
    }

    setInputCode(code);
  };

  return (
    <>
      <DigitCodeInput
        onChange={onChangeInput}
        hasError={inputStatus === 'invalid'}
        className={css({
          width: '100%',
          maxWidth: '340px',
          margin: '0 auto',
        })}
      />
      {inputStatus === 'invalid' && (
        <p
          className={css({
            color: 'negative.text_primary',
            textAlign: 'center',
            py: 'extra_small_3',
            fontSize: 'caption.small',
          })}
        >
          {t('2fa_reset_invalid_code')}
        </p>
      )}
      <TwoFaSectionButtonsWrapper>
        <ButtonBase
          visual={'accent'}
          disabled={loading || !isCodeValid}
          isLoading={loading}
          onClick={onCodeSubmit}
        >
          {actionVariant === 'setup' ? t('continue') : t('2fa_reset_button')}
        </ButtonBase>
      </TwoFaSectionButtonsWrapper>
    </>
  );
};

export { TwoFaCodeSubmitBox };
