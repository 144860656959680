'use client';

import { useContext, createContext, useState, PropsWithChildren } from 'react';
import {
  SavingsPlanStatus,
  SavingsPlansViewQuery$data,
} from '@bts-web/data-layer/server';
import {
  getSavingsPlans,
  useHandleGqlErrorsWithSnackbar,
} from '@bts-web/core-features/common';
import { SAVINGS_PLANS_LIST_PAGE_SIZE } from '@bts-web/core-features/config';

export interface SavingsPlansContextProps {
  savingsPlans: SavingsPlansViewQuery$data['savingsPlans'];
  isLoading: boolean;
  status: SavingsPlanStatus;
  setCurrentStatus: (status: SavingsPlanStatus) => void;
  loadMoreSavingsPlans: () => void;
}

const SavingsPlansContext = createContext<SavingsPlansContextProps | null>(
  null,
);

export function useSavingsPlansContext() {
  const contextValue = useContext(SavingsPlansContext);

  if (contextValue === null) {
    throw new Error(
      'useSavingsPlansContext must be used within a SavingsPlansContextProvider',
    );
  }

  return contextValue;
}

export const SavingsPlansContextProvider = ({
  children,
  initialSavingsPlansData,
  assetId,
}: PropsWithChildren<{
  initialSavingsPlansData: SavingsPlansContextProps['savingsPlans'];
  assetId?: string;
}>) => {
  const [savingsPlans, setSavingsPlans] = useState<
    SavingsPlansContextProps['savingsPlans']
  >(initialSavingsPlansData || null);

  const [status, setStatus] = useState<SavingsPlanStatus>('ACTIVE');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { processErrors } = useHandleGqlErrorsWithSnackbar();

  const setCurrentStatus = async (newStatus: SavingsPlanStatus) => {
    setStatus(newStatus);

    setIsLoading(true);

    try {
      const savingsPlansResponse = await getSavingsPlans({
        first: SAVINGS_PLANS_LIST_PAGE_SIZE,
        status: newStatus,
        assetId,
      });

      if (savingsPlansResponse.errors) {
        setStatus(status);

        processErrors(savingsPlansResponse.errors);

        return;
      }

      setSavingsPlans(savingsPlansResponse.data.savingsPlans);
    } catch (error) {
      setStatus(status);

      if (typeof error === 'string') {
        processErrors([error] as string[]);
      } else {
        processErrors(error as unknown as string[]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const loadMoreSavingsPlans = async () => {
    const currentPageCursor = savingsPlans?.pageInfo?.endCursor;

    try {
      const response = await getSavingsPlans({
        after: currentPageCursor,
        first: SAVINGS_PLANS_LIST_PAGE_SIZE,
        status,
        assetId,
      });

      if (response.errors) {
        processErrors(response.errors);

        return;
      }

      const loadedSavingsPlans = response?.data?.savingsPlans;

      if (loadedSavingsPlans) {
        setSavingsPlans({
          ...savingsPlans,
          edges: [
            ...(savingsPlans?.edges || []),
            ...(loadedSavingsPlans?.edges || []),
          ],
          pageInfo: loadedSavingsPlans?.pageInfo,
        } as SavingsPlansContextProps['savingsPlans']);
      }
    } catch (error) {
      if (typeof error === 'string') {
        processErrors([error] as string[]);
      } else {
        processErrors(error as unknown as string[]);
      }
    }
  };

  return (
    <SavingsPlansContext.Provider
      value={{
        isLoading,
        status,
        savingsPlans,
        setCurrentStatus,
        loadMoreSavingsPlans,
      }}
    >
      {children}
    </SavingsPlansContext.Provider>
  );
};
