import type { TransactionsViewQuery } from '@bts-web/data-layer/server';
import { flex } from '@bts-web/utils-style-engine';
import {
  TransactionEntryType,
  TransactionItem,
} from '../TransactionItem/TransactionItem';

export interface TransactionListProps {
  transactions: NonNullable<
    NonNullable<TransactionsViewQuery['response']['transactions']>['edges']
  >;
}

export const TransactionList = ({ transactions }: TransactionListProps) => {
  return (
    <ul
      className={flex({ flexDirection: 'column' })}
      data-testid="transaction-list"
    >
      {transactions.map((transaction) => {
        return (
          <TransactionItem
            key={transaction?.node?.id}
            transaction={transaction!.node as TransactionEntryType}
            showCategoryTypeChip
          />
        );
      })}
    </ul>
  );
};
