import { BitPandaLogoIcon } from '../assets/BitPandaLogoIcon';
import { RakbankLogoIcon } from '../assets/RakbankLogoIcon';
import { AppConfig } from './types';

export const APPLICATION_CONFIGS: AppConfig[] = [
  {
    appId: 1,
    appLogo: BitPandaLogoIcon,
    appName: 'Raiffeisen',
    currency: 'EUR',
    feature_hasCryptoTransfersEnabled: false,
    feature_hasSavingsPlansEnabled: true,
    feature_isPaymentMethodVisibleOnAccept: false,
    feature_showCryptoTransfersDrawerSearchBar: false,
    feature_showNotifications: true,
    landingPageLangIconColorScheme: 'dark', //TODO: change this to light after onboarding background token is fixed
    riskDisclosureLinks: {
      de: 'https://cdn.bitpanda.com/terms-and-conditions/risk-disclosure-bts-de-latest.pdf',
      en: 'https://cdn.bitpanda.com/terms-and-conditions/risk-disclosure-bts-en-latest.pdf',
    },
  },
  {
    additionalBuyConfirmationText: 'lbbw_buy_success_info',
    additionalSellConfirmationText: 'lbbw_sell_success_info',
    appId: 2,
    appLogo: BitPandaLogoIcon,
    appName: 'LBBW',
    currency: 'EUR',
    feature_hasCryptoTransfersEnabled: true, // set on true only on local development/testing for now
    feature_isPaymentMethodVisibleOnAccept: true,
    feature_showAssetDetailsRelatedTransactions: true,
    feature_showCryptoTransfersDrawerSearchBar: true,
    feature_showNotifications: true,
    feature_usesSmallDiscoverHeaderWithoutSearch: true,
    landingPageLangIconColorScheme: 'dark', //TODO: change this to light after onboarding background token is fixed
    localPublicFolderPathTo_FAQDocument: '/LBBW-CryptoApp-FAQ-document.pdf',
    riskDisclosureLinks: {
      de: 'https://cdn.bitpanda.com/terms-and-conditions/risk-disclosure-lbbw-bam-gmbh-de-latest.pdf',
      en: 'https://cdn.bitpanda.com/terms-and-conditions/risk-disclosure-lbbw-bam-gmbh-de-latest.pdf',
    },
    URLforCustomerSupport:
      'https://lbbwsupport.bitpanda.com/hc/de/requests/new',
  },
  {
    appId: 3,
    appLogo: RakbankLogoIcon,
    appName: 'Rakbank',
    currency: 'AED',
    feature_hasCryptoTransfersEnabled: false,
    feature_hasSavingsPlansEnabled: false,
    feature_isPaymentMethodVisibleOnAccept: true,
    feature_showNotifications: true,
    feature_usesSmallDiscoverHeaderWithSearch: true,
    landingPageLangIconColorScheme: 'dark',
    riskDisclosureLinks: {
      de: 'https://cdn.bitpanda.com/terms-and-conditions/risk-disclosure-bts-de-latest.pdf',
      en: 'https://cdn.bitpanda.com/terms-and-conditions/risk-disclosure-bts-en-latest.pdf',
    },
  },
];
