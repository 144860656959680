'use client';

import { ModalComponent } from '@bts-web/utils-context';
import { ReactNode } from 'react';
import { Icon } from '@bts-web/design-system/component/icon';
import { css, stack } from '@bts-web/utils-style-engine';
import { ButtonBase, DialogBase, HyperLinkButtonBase } from '../../../common';

const containerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  mx: 'auto',
  flex: {
    base: 'auto',
    ['lg' as string]: '1',
  },
});

const statusAndDescriptionContainerStyles = css({
  margin: 'auto 0',
  gap: 'extra_large',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  pt: {
    base: '0',
    lg: 'small',
  },
  pb: {
    base: '0',
    lg: 'medium',
  },
});

export type WarningModalProps = {
  title: string;
  subtitle?: string;
  description: string | ReactNode;
  highlightedDescription?: string | ReactNode;
  continueLink?: {
    text: string;
    url: string;
  };
  cancelBtnText: string;
  enableClose?: boolean;
};

export const WarningModal: ModalComponent<WarningModalProps> = ({
  title,
  subtitle,
  description,
  highlightedDescription,
  onClose,
  continueLink,
  cancelBtnText,
  enableClose,
}) => {
  return (
    <DialogBase
      id="transaction-warning-modal"
      open
      onOpenChange={onClose}
      size="medium"
      onMobileFullScreen
      visual="secondary"
      {...(!enableClose && { disableBackdropClose: true, closeIcon: null })}
    >
      <div className={containerStyles}>
        <div className={statusAndDescriptionContainerStyles}>
          <div
            className={css({
              width: '52px',
              height: '52px',
              color: 'warning.text_primary',
              backgroundColor: 'warning.fill_secondary',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
            })}
          >
            <Icon icon="warning" width="36" height="36" theme="regular" />
          </div>

          <div className={stack({ gap: 'small' })}>
            <h1
              className={css({
                fontSize: 'headline.large_semi_bold',
                fontWeight: 'headline.large_semi_bold',
                lineHeight: 'headline.large_semi_bold',
                letterSpacing: 'headline.large_semi_bold',
                textAlign: 'center',
                mx: 'auto',
              })}
              data-testid="finish-trade-main-message"
            >
              {title}
            </h1>

            {subtitle && (
              <div
                className={css({
                  textAlign: 'center',
                  color: 'neutrals.text_primary',
                  fontSize: 'headline.small',
                  fontWeight: 'headline.small',
                  letterSpacing: 'headline.small',
                  lineHeight: 'headline.small',
                })}
              >
                {subtitle}
              </div>
            )}
          </div>

          <div
            className={css({
              color: 'neutrals.text_secondary',
              fontSize: 'body.medium',
              fontWeight: 'body.medium',
              letterSpacing: 'body.medium',
              lineHeight: 'body.medium',
            })}
          >
            {description}
          </div>

          {highlightedDescription && (
            <div
              className={css({
                color: 'neutrals.text_secondary',
                fontSize: 'body.medium_medium',
                fontWeight: 'body.medium_medium',
                letterSpacing: 'body.medium_medium',
                lineHeight: 'body.medium_medium',
              })}
            >
              {highlightedDescription}
            </div>
          )}
        </div>

        <div
          className={stack({
            marginTop: 'auto',
            marginInlineStart: 'auto',
            marginInlineEnd: 'auto',
            maxWidth: '390px',
            width: '100%',
            gap: 'small',
            alignItems: 'center',
          })}
        >
          {continueLink?.url && (
            <HyperLinkButtonBase
              onClick={onClose}
              size="large"
              to={continueLink.url}
              visual="primary"
              prefetch
              data-testid="continue button"
            >
              {continueLink.text}
            </HyperLinkButtonBase>
          )}

          <ButtonBase
            fullWidth
            size="large"
            onClick={onClose}
            visual="secondary"
            date-testid="do-it-later-btn"
          >
            {cancelBtnText}
          </ButtonBase>
        </div>
      </div>
    </DialogBase>
  );
};
