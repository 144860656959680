import { PropsWithChildren } from 'react';
import { css, flex, stack } from '@bts-web/utils-style-engine';
import { Divider, SkeletonElement, NavSkeleton } from '../../common';
import { getAppConfig } from '@bts-web/core-app-config';

const SpecialInputWithInfoSkeleton = () => (
  <div
    className={css({
      display: 'flex',
      flexDirection: 'column',
      gap: 'extra_small',
      p: 'medium',
    })}
  >
    <div
      className={flex({
        justifyContent: 'space-between',
        alignItems: 'center',
      })}
    >
      <div className={flex({ gap: 'extra_small_2' })}>
        <SkeletonElement width="24px" height="24px" borderRadius="50%" />
        <SkeletonElement width="90px" height="24px" />
      </div>

      <SkeletonElement width="110px" height="18px" />
    </div>

    <SkeletonElement width="190px" height="42px" />
    <div
      className={flex({
        pt: 'extra_small_2',
        display: 'flex',
        flexDirection: 'column',
        gap: 'small',
      })}
    >
      <div className={flex({ justifyContent: 'space-between' })}>
        <SkeletonElement width="110px" height="18px" />
        <SkeletonElement width="110px" height="18px" />
      </div>

      <div className={flex({ justifyContent: 'space-between' })}>
        <SkeletonElement width="110px" height="18px" />
        <SkeletonElement width="110px" height="18px" />
      </div>
    </div>
  </div>
);

export const QuickSelectButtonsSkeleton = () => {
  const { currency } = getAppConfig();

  const isEuroCurrency = currency === 'EUR';

  return (
    <div
      className={flex({
        gap: 'small',
        px: 'medium',
        py: 'extra_small_2',
        overflow: 'hidden',
      })}
    >
      <SkeletonElement width="100%" height="45px" borderRadius="8px" />
      <SkeletonElement width="100%" height="45px" borderRadius="8px" />
      <SkeletonElement width="100%" height="45px" borderRadius="8px" />
      {isEuroCurrency && (
        <SkeletonElement width="100%" height="45px" borderRadius="8px" />
      )}
    </div>
  );
};

const ConfirmButtonSkeleton = () => (
  <SkeletonElement width="100%" height="58px" borderRadius="8px" />
);

const PoweredByTextSkeleton = () => (
  <SkeletonElement width="200px" height="18px" />
);

export interface TradeBoxSkeletonProps extends PropsWithChildren {
  withNav: boolean;
}

export const TradeBoxSkeleton = ({
  children,
  withNav, // no nav on Asset Details trade box
}: TradeBoxSkeletonProps) => (
  <div
    data-testid="trade-loading-skeleton"
    className={css({
      display: 'flex',
      flexDirection: 'column',
      pb: 'medium',
      width: '100%',
      maxWidth: {
        lg: '420px',
      },
    })}
  >
    {withNav && <NavSkeleton />}

    <Divider />

    <SpecialInputWithInfoSkeleton />

    <Divider />

    <QuickSelectButtonsSkeleton />

    {children}

    <div className={stack({ px: 'medium', mt: 'auto', gap: 'medium' })}>
      <PoweredByTextSkeleton />

      <ConfirmButtonSkeleton />
    </div>
  </div>
);
