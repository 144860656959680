import { css } from '@bts-web/utils-style-engine';
import { SkeletonElement } from '../../common';

export const PortfolioSavingsPlansFrameSkeleton = () => (
  <div
    className={css({
      display: 'flex',
      flexDirection: 'column',
      p: 'small',
      gap: 'medium',
    })}
  >
    <SkeletonElement height="34px" width="120px" />

    <SkeletonElement height="18px" width="200px" />
  </div>
);
