import { css, stack } from '@bts-web/utils-style-engine';
import { TermsAndConditionsViewQuery } from '@bts-web/data-layer/server';
import { TermsTranslations } from '../types';
import { HTMLRenderer, DocumentationExternalLink } from '../../common';

const termsHeaderStyles = css({
  color: 'neutrals.text_primary',
  fontSize: 'headline.large_semi_bold',
  fontWeight: 'headline.large_semi_bold',
  lineHeight: 'headline.large_semi_bold',
  letterSpacing: 'headline.large_semi_bold',
  textAlign: 'start',
});

export const termsContentTextStyles = css({
  fontSize: 'body.medium',
  fontWeight: 'body.medium',
  lineHeight: 'body.medium',
  letterSpacing: 'body.medium',
  color: 'neutrals.text_primary',
  textAlign: 'start',
});

export interface TermsProps {
  onboarded: boolean;
  translations: TermsTranslations;
  termsAndConditions: TermsAndConditionsViewQuery['response']['termsAndConditions'];
}

const TermsWrapper = ({
  children,
  titleLabel,
}: React.PropsWithChildren & { titleLabel: string }) => (
  <div
    className={stack({ gap: 'medium', overflowY: 'auto' })}
    data-testid="terms-and-privacy-wrapper"
  >
    <h2 className={termsHeaderStyles}>{titleLabel}</h2>
    {children}
  </div>
);

const TermsAndPrivacy = ({
  translations,
  termsAndConditions,
  onboarded,
}: TermsProps) => {
  if (!termsAndConditions) {
    return <TermsWrapper titleLabel={translations.changedTermsHeadline} />;
  }

  const notAcceptedTerms = termsAndConditions?.edges?.filter(
    (item) => !item?.node?.accepted,
  );

  const notAcceptedTermsLinks = notAcceptedTerms?.length
    ? notAcceptedTerms?.map((item) => ({
        href: item?.node?.source as string,
        id: item?.node?.id as string,
        label: (item?.node?.title || item?.node?.source) as string,
      }))
    : [];

  return (
    <TermsWrapper
      titleLabel={
        onboarded
          ? translations.changedTermsHeadline
          : translations.termsAndConditions
      }
    >
      <p className={termsContentTextStyles}>
        <HTMLRenderer>
          {!onboarded
            ? translations.newUserTermsUpperDisclaimer
            : translations.changedTermsUpperDisclaimer}
        </HTMLRenderer>
      </p>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          borderColor: 'neutrals.stroke_secondary',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderRadius: '4px',
          textAlign: 'start',
          '& > a': {
            width: '100%',
            px: 'extra_small',
          },
          '& > a:last-child': {
            borderBottomWidth: 0,
          },
        })}
      >
        {notAcceptedTermsLinks.map((item) => (
          <DocumentationExternalLink
            size="small"
            visual="primary"
            key={item.id}
            href={item.href}
            label={item.label}
            className={css({
              py: 'medium',
              textAlign: 'start',
              borderColor: 'neutrals.stroke_secondary',
              borderBottomWidth: '1px',
              borderStyle: 'solid',
              justifyContent: 'space-between',
            })}
            icon="open"
          />
        ))}
      </div>
    </TermsWrapper>
  );
};

export { TermsAndPrivacy };
