import { getAssetByIdAndHystoricalInterval } from '../common/gqlActions';
import { getAssetDetailsTranslations } from './utils/getAssetDetailsTranslations';
import { TFunction } from 'i18next';
import {
  getLocale,
  i18n,
  initServerTranslation,
  Language,
} from '@bts-web/utils-lokalise';
import { getStockAssetTranslations } from './utils/getStockAssetTranslations';
import { css } from '@bts-web/utils-style-engine';
import { HiddenDesktop, WrapperForMobileStickySectionHeader } from '../layouts';
import { AssetDetailsFloatingCTA } from './components/sections/AssetDetailsFloatingCTA/AssetDetailsFloatingCTA';
import { AssetTypeFilters } from './utils/AssetTypeFilters';
import { AssetType } from '../trade';
import { ContentSectionWrapper } from '../layouts/ContentSectionWrapper/ContentSectionWrapper';
import { AssetDetailsETF } from './components/sections/AssetDetailsETF/AssetDetailsETF';
import { AssetDetailsStocks } from './components/sections/AssetDetailsStocks/AssetDetailsStocks';
import { AssetDetailsOverviewSection } from './components/sections/Overview/AssetDetailsOverviewSection';
import { AssetDetailsHeader } from './components/sections/AssetDetailsHeader/AssetDetailsHeader';
import { AssetDetailsAreaChart } from './components/sections/AssetDetailsAreaChart/AssetDetailsAreaChart';
import { AssetDetailsDataContextProvider } from './components/AssetDetailsDataContext/AssetDetailsDataContext.use-client';
import { AssetDetailsNavigation } from './components/sections/AssetDetailsNavigation/AssetDetailsNavigation';
import { AssetDetailsStats } from './components/sections/AssetDetailsStats/AssetDetailsStats';
import { getAppConfig } from '@bts-web/core-app-config';
import { SavingsPlansFrameControllerWithSuspense } from '../savings-plans/SavingsPlansFrame/SavingsPlansFrameControllerWithSuspense';
import { AssetDetailsPrice } from './components/sections/AssetDetailsHeader/subcomponents/AssetDetailsPrice/AssetDetailsPrice';
import { extractAssetDetailsInfoFromResponse } from './utils/extractAssetDetailsInfoFromResponse';
import { AssetDetailsAverageBuyPrice } from './components/sections/AssetDetailsAverageBuyPrice/AssetDetailsAverageBuyPrice';

const ChartWrapper = ({ children }: { children: React.ReactNode }) => (
  <div
    className={css({
      position: 'relative',
      // negative margin to compensate for the content spacing paddings of parent ContentSectionWrapper
      mx: { base: '-small', md: '-medium' },
    })}
  >
    {children}
    <div // white background for chart's desktop timeframe - Highcharts lacks bg setting for it
      className={css({
        background: 'neutrals.card_fill_primary',
        position: 'absolute',
        width: '100%',
        height: '46px',
        bottom: 0,
        hideBelow: 'lg',
      })}
    />
  </div>
);

interface AssetDetailsControllerProps {
  assetId: string;
  hideTradeCTAFloatingButton?: boolean;
}

const { t } = initServerTranslation();

const AssetDetailsController = async ({
  assetId,
  hideTradeCTAFloatingButton,
}: AssetDetailsControllerProps) => {
  const { feature_hasSavingsPlansEnabled } = getAppConfig();

  const translations = getAssetDetailsTranslations(t as TFunction);

  const locale = getLocale(i18n.resolvedLanguage as Language);

  const initialAssetDetailsData = await getAssetByIdAndHystoricalInterval({
    assetHistoryPeriod: 'DAY',
    assetId,
  });

  const { responseData: assetInitialData, ...processedData } =
    extractAssetDetailsInfoFromResponse({
      assetId,
      responseData: initialAssetDetailsData.data?.assetById,
    });

  // Translations
  const stockAssetTranslations = getStockAssetTranslations({
    locale,
    assetData: assetInitialData,
    t: t as TFunction,
  });

  const assetDetailsTranslations = {
    ...translations,
    ...stockAssetTranslations,
  };

  const youOwnProps: React.ComponentProps<typeof AssetDetailsPrice> = {
    label: translations.youOwn,
    locale,
    value: processedData.fiatBalance,
    priceVariation: processedData.totalReturn,
  };

  const totalReturnProps: React.ComponentProps<typeof AssetDetailsPrice> = {
    label: translations.totalReturn,
    locale,
    value: processedData.totalReturn,
    timestampText: undefined,
    hidePercentage: true,
  };

  const dailyReturnProps: React.ComponentProps<typeof AssetDetailsPrice> = {
    label: translations.dailyReturn,
    locale,
    value: processedData.dailyReturn,
    priceVariation: processedData.dailyReturn,
    timestampText: undefined,
  };

  return (
    <>
      <WrapperForMobileStickySectionHeader>
        {/* the hidden component must be within the sticky wrapper in order to not css-break the sticky property */}
        <HiddenDesktop>
          <AssetDetailsNavigation
            assetName={processedData.assetName}
            assetTypeFilterName={AssetTypeFilters[processedData.assetType]}
            assetTypeTranslation={translations[processedData.assetType]}
          />
        </HiddenDesktop>
      </WrapperForMobileStickySectionHeader>

      <AssetDetailsDataContextProvider initialData={assetInitialData}>
        <ContentSectionWrapper
          className={css({
            // internal content spacing
            display: 'flex',
            flexDirection: 'column',
            gap: 'small',
            backgroundColor: 'screen_background.primary',
          })}
        >
          <AssetDetailsHeader
            translations={assetDetailsTranslations}
            locale={locale}
          />
          {assetInitialData.priceChange24Hours && (
            <ChartWrapper>
              <AssetDetailsAreaChart
                translations={assetDetailsTranslations}
                locale={locale}
              />
            </ChartWrapper>
          )}
        </ContentSectionWrapper>

        <ContentSectionWrapper
          className={css({
            display: 'grid',
            gridTemplateColumns: {
              base: 'repeat(2, 1fr)',
              md: 'repeat(4, 1fr)',
            },
            gap: 'small',
            alignItems: 'flex-start',
          })}
        >
          <AssetDetailsPrice {...youOwnProps} data-testid="youOwn" />
          <AssetDetailsPrice {...dailyReturnProps} data-testid="dailyReturn" />
          <AssetDetailsPrice {...totalReturnProps} data-testid="totalReturn" />
          <AssetDetailsAverageBuyPrice
            title={translations.averageBuyPrice}
            value={processedData.averageBuyPrice}
            locale={locale}
          />
        </ContentSectionWrapper>
      </AssetDetailsDataContextProvider>

      <AssetDetailsStats
        applicationLocale={locale}
        assetType={assetInitialData.__typename as AssetType}
        statsData={{
          priceDailyLow: assetInitialData.priceDailyLow,
          priceDailyHigh: assetInitialData.priceDailyHigh,
          price52WeeksLow: assetInitialData.price52WeeksLow,
          price52WeeksHigh: assetInitialData.price52WeeksHigh,
          fundSize: assetInitialData.fundSize,
          marketCap: assetInitialData.marketCap,
          monthlyVolatility: assetInitialData.monthlyVolatility,
        }}
        translations={translations}
      />

      {feature_hasSavingsPlansEnabled && (
        <SavingsPlansFrameControllerWithSuspense
          assetId={assetId}
          assetInfo={assetInitialData}
        />
      )}

      {assetInitialData.description && (
        <AssetDetailsOverviewSection
          translations={translations}
          assetDescription={assetInitialData.description}
        />
      )}
      {processedData.assetType === AssetType.STOCK ? (
        <AssetDetailsStocks
          assetData={assetInitialData}
          translations={assetDetailsTranslations}
          locale={locale}
        />
      ) : processedData.assetType === AssetType.ETF ||
        processedData.assetType === AssetType.ETC ? (
        <AssetDetailsETF
          assetData={assetInitialData}
          applicationLocale={locale}
          translations={translations}
        />
      ) : null}

      {!hideTradeCTAFloatingButton && (
        <HiddenDesktop>
          <AssetDetailsFloatingCTA
            assetId={assetInitialData.id}
            isOwningAsset={processedData.isAssetOwned}
            translations={translations}
            isAssetActive={assetInitialData.isActive ?? false}
            isBuyActive={assetInitialData.isBuyActive ?? false}
            isSellActive={assetInitialData.isSellActive ?? false}
          />
        </HiddenDesktop>
      )}
    </>
  );
};

export { AssetDetailsController };
