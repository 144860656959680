const initialTransactionDetailsTranslations = {
  typeBought: 'tx_details_type_bought',
  typeSold: 'tx_details_type_sold',
  typeDeposit: 'deposit',
  typeWithdraw: 'tx_details_type_withdrawal',
  assetClassification: 'asset_classification',
  topBar: 'tx_details_top_bar',
  details: 'details',
  from: 'tx_details_from',
  to: 'tx_details_to',
  type: 'tx_details_type',
  date: 'tx_details_date',
  status: 'status',
  traded: 'tx_details_you_traded',
  price: 'price',
  pid: 'pid',
  hash: 'tx_details_hash',
  statusProcessing: 'tx_details_processing',
  statusPending: 'tx_details_pending',
  statusCancelled: 'tx_details_cancelled',
  statusActive: 'tx_details_active',
  statusRejected: 'tx_details_rejected',
  statusCompleted: 'completed',
  statusFinished: 'finished',
  statusCreated: 'created',
  statusExpired: 'expired',
  statusFailed: 'failed',
  statusInProgress: 'in_progress',
  rejected: 'tx_details_rejected',
  sellMore: 'sell_more_button',
  buyMore: 'buy_more_button',
  spread: 'spread',
  copy: 'copy',
  orderIdCopySuccessMessage: 'tx_details_order_id_copy_success',
  orderIdCopyFailedMessage: 'tx_details_order_id_copy_failed',
  taxWithheld: 'tax_withheld',
  tradeFee: 'trade_fee',
  intiated: 'intiated',
  pending: 'tx_details_pending',
  verificationInProgress: 'tx_details_verification_in_progress',
  pendingStatusTitle: 'tx_details_pending_status_title',
  pendingStatusDescription: 'tx_details_pending_status_description',
  onHoldStatusTitle: 'tx_details_on_hold_status_title',
  onHoldDescription: 'tx_details_on_hold_description',
  processingStatusTitle: 'tx_details_processing_status_title',
  processingStatusDescription: 'tx_details_processing_status_description',
  cancelledStatusTitle: 'tx_details_cancelled_status_title',
  cancelledStatusDescription: 'tx_details_cancelled_status_description',
  rejectedStatusTitle: 'tx_details_rejected_status_title',
  rejectedStatusDescription: 'tx_details_rejected_status_description',
  other: 'crypto_asset_type_none_other',
  none: 'crypto_asset_type_none',
  arts: 'crypto_asset_type_arts',
  emts: 'crypto_asset_type_emts',
  network: 'network',
  walletAddress: 'wallet_address',
  sent: 'crypto_withdrawal_sent',
  received: 'crypto_deposit_received',
  total: 'total',
  wallet: 'wallet',
  fee: 'tx_details_fee',
  verifyWallet: 'crypto_deposit_verify_wallet',
};

export type TransactionDetailsTransactionKeysResult = {
  topBar: string;
  copy: string;
  orderIdCopySuccessMessage: string;
  orderIdCopyFailedMessage: string;
  typeSold: string;
  typeBought: string;
  typeDeposit: string;
  typeWithdraw: string;
  details: string;
  from: string;
  to: string;
  type: string;
  date: string;
  status: string;
  traded: string;
  price: string;
  pid: string;
  statusPending: string;
  statusCompleted: string;
  statusFinished: string;
  statusCancelled: string;
  statusProcessing: string;
  statusActive: string;
  statusCreated: string;
  statusExpired: string;
  statusFailed: string;
  statusInProgress: string;
  statusOnHold: string;
  statusRejected: string;
  sellMore: string;
  buyMore: string;
  spread: string;
  assetClassification: string;
  taxWithheld: string;
  tradeFee: string;
  intiated: string;
  pending: string;
  verificationInProgress: string;
  pendingStatusTitle: string;
  pendingStatusDescription: string;
  onHoldStatusTitle: string;
  onHoldDescription: string;
  processingStatusTitle: string;
  processingStatusDescription: string;
  cancelledStatusTitle: string;
  cancelledStatusDescription: string;
  rejectedStatusTitle: string;
  rejectedStatusDescription: string;
  other: string;
  none: string;
  arts: string;
  emts: string;
  network: string;
  wallet: string;
  hash: string;
  fee: string;
  walletAddress: string;
  total: string;
  sent: string;
  received: string;
  verifyWallet: string;
};

const getTransactionDetailsTranslations = (
  t: (translationKey: keyof TransactionDetailsTransactionKeysResult) => string,
): TransactionDetailsTransactionKeysResult => {
  return Object.entries(initialTransactionDetailsTranslations).reduce(
    (acc, [key, value]) => {
      acc[key as keyof TransactionDetailsTransactionKeysResult] = t(
        value as keyof TransactionDetailsTransactionKeysResult,
      );

      return acc;
    },
    {} as TransactionDetailsTransactionKeysResult,
  );
};

export { getTransactionDetailsTranslations };
