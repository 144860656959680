const initialCryptoTransferDetailsTranslations = {
  verificationInProgress: 'tx_details_verification_in_progress',
  pendingStatusTitle: 'tx_details_pending_status_title',
  pendingStatusDescription: 'tx_details_pending_status_description',
  onHoldStatusTitle: 'tx_details_on_hold_status_title',
  onHoldDescription: 'tx_details_on_hold_description',
  processingStatusTitle: 'tx_details_processing_status_title',
  processingStatusDescription: 'tx_details_processing_status_description',
  cancelledStatusTitle: 'tx_details_cancelled_status_title',
  cancelledStatusDescription: 'tx_details_cancelled_status_description',
  rejectedStatusTitle: 'tx_details_rejected_status_title',
  rejectedStatusDescription: 'tx_details_rejected_status_description',
  initiated: 'tx_details_initiated',
};

export type CryptoTransferDetailsTransactionKeysResult = {
  verificationInProgress: string;
  pendingStatusTitle: string;
  pendingStatusDescription: string;
  onHoldStatusTitle: string;
  onHoldDescription: string;
  processingStatusTitle: string;
  processingStatusDescription: string;
  cancelledStatusTitle: string;
  cancelledStatusDescription: string;
  rejectedStatusTitle: string;
  rejectedStatusDescription: string;
  initiated: string;
};

const getCryptoTransferDetailsTranslations = (
  t: (
    translationKey: keyof CryptoTransferDetailsTransactionKeysResult,
  ) => string,
): CryptoTransferDetailsTransactionKeysResult => {
  return Object.entries(initialCryptoTransferDetailsTranslations).reduce(
    (acc, [key, value]) => {
      acc[key as keyof CryptoTransferDetailsTransactionKeysResult] = t(
        value as keyof CryptoTransferDetailsTransactionKeysResult,
      );

      return acc;
    },
    {} as CryptoTransferDetailsTransactionKeysResult,
  );
};

export { getCryptoTransferDetailsTranslations };
