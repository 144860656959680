import { css } from '@bts-web/utils-style-engine';
import {
  TransactionsWidgetController,
  TransactionsWidgetControllerProps,
} from './TransactionsWidgetController.server';
import { ErrorBoundaryWithSuspense } from '../../layouts/ErrorBoundary/ErrorBoundaryWithSuspense';
import { SkeletonElement, CellSkeleton } from '../components';

const TransactionsWidgetControllerWithSuspense = async ({
  variant,
  showOnlyRedirectLink = false,
  fetchNumberOfItems = 20,
  assetId,
}: TransactionsWidgetControllerProps) => {
  return (
    <ErrorBoundaryWithSuspense
      fallbackOrSkeleton={
        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            px: 'small',
            py: 'medium',
            gap: 'medium',
          })}
        >
          <SkeletonElement height="24px" width="200px" />

          <div>
            <CellSkeleton />
            <CellSkeleton />
            <CellSkeleton />
          </div>
        </div>
      }
    >
      <TransactionsWidgetController
        fetchNumberOfItems={fetchNumberOfItems}
        variant={variant}
        assetId={assetId}
        showOnlyRedirectLink={showOnlyRedirectLink}
      />
    </ErrorBoundaryWithSuspense>
  );
};

export { TransactionsWidgetControllerWithSuspense };
