import { Icon } from '@bts-web/design-system/component/icon';
import React, { forwardRef, ButtonHTMLAttributes } from 'react';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  internal: {
    className: string;
  };
  external: {
    disabled?: boolean;
    isLoading?: boolean;
    tabIndex?: number;
    children: React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  };
}

export const Button = forwardRef<
  HTMLButtonElement,
  ButtonProps['internal'] & ButtonProps['external']
>((props, buttonRef) => {
  const { children, tabIndex = 0, isLoading, ...rest } = props;

  return (
    <button
      data-testid="button"
      ref={buttonRef}
      tabIndex={tabIndex}
      type="button"
      {...rest}
    >
      {children}
      {isLoading && (
        <Icon
          aria-label="loading icon"
          data-element="loading-icon"
          theme="filled"
          size="20"
          icon="spinner-ios"
        />
      )}
    </button>
  );
});
