'use client';

import { ComponentProps } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { Status } from '../../common/components';
import { Icon } from '@bts-web/design-system/component/icon';
import { CryptoTransferTransactionStatusModal } from './CryptoTransferTransactionStatusModal';
import { useComposableModal } from '@bts-web/utils-context';
import { STATUS_COLORS } from '../../transaction-details/types';
import { CryptoTransfersTransactionStatus } from '@bts-web/data-layer/server';
import { CryptoTransactionInfoStatus } from '../utils/getCryptoTransferCryptoTransferDetailsLabelsByStatus';

export type CryptoTransferTransactionStatusProps = {
  text: string;
  status: CryptoTransactionInfoStatus;
};

export const CryptoTransferTransactionStatus = ({
  text,
  status,
}: CryptoTransferTransactionStatusProps) => {
  const visual = STATUS_COLORS[status] as ComponentProps<
    typeof Status
  >['visual'];

  const showCryptoTransfersTransactionsInfo = Object.values(
    CryptoTransactionInfoStatus,
  ).includes(status);

  const { showModal } = useComposableModal();

  const handleStatusClick = () => {
    showModal(CryptoTransferTransactionStatusModal, {
      status: status as Partial<CryptoTransfersTransactionStatus>,
    });
  };

  const onClickAction = showCryptoTransfersTransactionsInfo
    ? { onClick: handleStatusClick }
    : {};

  return (
    <div
      data-testid="status_info_btn"
      className={css({
        display: 'flex',
        gap: 'extra_small_2',
        justifyContent: 'end',
        alignItems: 'center',
        textAlign: 'start',
        cursor: showCryptoTransfersTransactionsInfo ? 'pointer' : 'default',
        ...(visual === 'outlineCompleted'
          ? {
              '& [data-element="info-icon"]': {
                color: 'positive.stroke_primary',
              },
            }
          : {}),

        ...(visual === 'outlineOnHold'
          ? {
              '& [data-element="info-icon"]': {
                color: 'warning.stroke_primary',
              },
            }
          : {}),

        ...(visual === 'outlineCancelled'
          ? {
              '& [data-element="info-icon"]': {
                color: 'negative.stroke_primary',
              },
            }
          : {}),

        ...(visual === 'outlineInfoStatus'
          ? {
              '& [data-element="info-icon"]': {
                color: 'information.stroke_primary',
              },
            }
          : {}),

        ...(visual === 'outlineNeutralLight'
          ? {
              '& [data-element="info-icon"]': {
                color: 'neutrals.stroke_primary',
              },
            }
          : {}),

        ...(visual === 'outlineNeutralDark'
          ? {
              '& [data-element="info-icon"]': {
                color: 'neutrals.fill_tertiary',
              },
            }
          : {}),
      })}
      {...onClickAction}
    >
      <Status visual={visual} text={text} />
      {showCryptoTransfersTransactionsInfo && (
        <Icon icon="info" size="16" data-element="info-icon" />
      )}
    </div>
  );
};
