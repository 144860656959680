import { css } from '@bts-web/utils-style-engine';
import { SkeletonElement, CellSkeleton } from '../../common';
import { HiddenDesktop } from '../../layouts/LayoutWithLeftDesktopNav/Reusable/HiddenDesktop/HiddenDesktop';

export const SavingsPlansNavigationBarSkeleton = () => (
  <div
    data-testid="savings-plans-navigation-bar-skeleton"
    className={css({ display: 'flex', gap: 'medium', paddingX: 'medium' })}
  >
    <SkeletonElement width="24px" height="24px" borderRadius="30px" />
    <div
      className={css({
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginInlineEnd: 'extra_large',
      })}
    >
      <SkeletonElement width="120px" height="22px" borderRadius="30px" />
    </div>
  </div>
);

export const SavingsPlansHeaderSkeleton = () => (
  <div
    data-testid="savings-plans-header-skeleton"
    className={css({
      display: 'flex',
      flexDirection: 'column',
      gap: 'small',
      width: '100%',
    })}
  >
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'extra_small_4',
      })}
    >
      <SkeletonElement width="60%" height="30px" borderRadius="30px" />
      <HiddenDesktop>
        <SkeletonElement width="50%" height="28px" borderRadius="30px" />
      </HiddenDesktop>
    </div>
  </div>
);

export const SavingsPlansPillBarSkeleton = () => (
  <div
    className={css({
      display: 'flex',
      gap: 'extra_small_2',
    })}
    data-testid="savings-plans-pill-bar-skeleton"
  >
    <SkeletonElement width="66px" height="38px" borderRadius="50px" />
    <SkeletonElement width="76px" height="38px" borderRadius="50px" />
  </div>
);

export const SavingsPlansListItemSkeleton = () => (
  <div
    data-testid="savings-plans-list-item-skeleton"
    className={css({
      display: 'flex',
      gap: 'medium',
      alignItems: 'center',
      padding: 'medium',
      borderBottomWidth: '1px',
      borderColor: 'neutrals.divider',
    })}
  >
    <SkeletonElement width="24px" height="24px" borderRadius="50%" />

    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'extra_small_3',
        flex: 1,
      })}
    >
      <SkeletonElement width="80px" height="20px" />
      <SkeletonElement width="120px" height="16px" />
    </div>

    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'extra_small_3',
      })}
    >
      <SkeletonElement width="54px" height="14px" borderRadius="34px" />
      <SkeletonElement width="56px" height="18px" />
    </div>
  </div>
);

export const SavingsPlansItemsSkeleton = () => (
  <div
    className={css({
      display: 'flex',
      flexDirection: 'column',
    })}
    data-testid="savings-plans-items-skeleton"
  >
    <SavingsPlansListItemSkeleton />
    <SavingsPlansListItemSkeleton />
  </div>
);

export const SavingsPlansLoadingSkeleton = () => (
  <div
    data-testid="saving-plans-loading-skeleton"
    className={css({
      display: 'flex',
      flexDirection: 'column',
      gap: 'small',
      paddingTop: 'small',
    })}
  >
    <HiddenDesktop>
      <SavingsPlansNavigationBarSkeleton />
    </HiddenDesktop>
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'large',
        paddingX: 'medium',
      })}
    >
      <SavingsPlansHeaderSkeleton />
      <SavingsPlansPillBarSkeleton />
    </div>
    <div>
      <SavingsPlansListItemSkeleton />
      <SavingsPlansListItemSkeleton />
    </div>
    <div>
      <CellSkeleton />
      <CellSkeleton />
      <CellSkeleton />
    </div>
  </div>
);
