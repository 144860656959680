import { Locale } from '@bts-web/utils-lokalise';
import { MaximumFractionDigits } from './precision';

type NumberFormatOptions = Omit<
  Intl.NumberFormatOptions,
  'minimumFractionDigits' | 'maximumFractionDigits'
>;

type FormatOptionsBase = NumberFormatOptions & {
  locale?: string;
  roundingPriority?: 'auto' | 'morePrecision' | 'lessPrecision';
};

type FormatOptionsDigitsOverride = FormatOptionsBase & {
  fractionDigits: number | undefined;
  minimumFractionDigits?: never;
  maximumFractionDigits?: never;
};

type FormatOptionsMinMaxDigits = FormatOptionsBase & {
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  fractionDigits?: never;
};

type IntlValueFormattingParams = {
  value: string | number;
  options: FormatOptionsDigitsOverride | FormatOptionsMinMaxDigits;
};

export const maxAllowedOrderOfMagnitude =
  getOrderOfMagnitude(Number.MAX_SAFE_INTEGER) - 1;

function getOrderOfMagnitude(n: number) {
  return Number(n.toExponential().replace(/.+\+(\d+)/, '$1'));
}

export const safelyCastNumber = (n: string) => {
  let castedNumber = Number(n);

  // comma separator string ex: 122,23 converting to 122.23;
  if (isNaN(castedNumber)) {
    castedNumber = Number(n.replace(/,/g, '.'));
  }

  if (castedNumber === Infinity) {
    castedNumber = Number.MAX_SAFE_INTEGER;
  }

  if (castedNumber > Number.MAX_SAFE_INTEGER) {
    const orderOfMagnitude = getOrderOfMagnitude(castedNumber);

    if (orderOfMagnitude > maxAllowedOrderOfMagnitude) {
      return (
        castedNumber / 10 ** (orderOfMagnitude - maxAllowedOrderOfMagnitude)
      );
    }

    return castedNumber;
  }

  return castedNumber;
};

/**
 * Converts string to safe number
 * ex: 100.230.111,10 => 100230111.10
 */
export const parseLocalisedNumber = (value: string, locale: Locale): number => {
  if (locale === 'de-DE') {
    const valueForLocale = value.replace(/\./g, '').replace(',', '.');

    return safelyCastNumber(valueForLocale);
  }

  console.warn('locale is not supported', locale);

  return safelyCastNumber(value);
};

// by default, use standard below 10k to keep thousands separator
// i.e. 1000 should be converted to 1.000,00
export const getDefaultFormattingNotation = (value: number) =>
  value < 10000 ? 'standard' : 'compact';

/**
 * Internationalization number formatting
 * Covert number(string number) value to the current language
 * @param {string | number} value - value to be formated
 * @param {FormatOptionsParams} options - Customize the format options
 * @returns string
 *
 * ex:
 *    1200.89 with  {locale='de-DE'} formated to '1200,89'
 *    '1200.89' with  {locale='de-DE', currency='EUR'} formated to '1200,89 €'
 *
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options
 */
export const intlAssetValueFormatting = (
  value: IntlValueFormattingParams['value'],
  {
    locale,
    currency,
    fractionDigits,
    minimumFractionDigits = currency ? fractionDigits : 0, // for removing the trailing zeros from asset amounts
    maximumFractionDigits = fractionDigits,
    style,
    notation = getDefaultFormattingNotation(Number(value)),
  }: IntlValueFormattingParams['options'],
): string => {
  const intlOptions: Intl.NumberFormatOptions = {
    minimumFractionDigits,
    maximumFractionDigits,
    style,
  };

  if (currency) {
    intlOptions.style = 'currency';

    intlOptions.currency = currency;
  } else {
    // for non-fiat currencies without the floating part, display only the integer part
    if (parseFloat(value as string) % 1 === 0 && notation !== 'compact') {
      intlOptions.minimumFractionDigits = 0;

      intlOptions.maximumFractionDigits = 0;
    }
  }

  const castValue = typeof value === 'string' ? safelyCastNumber(value) : value;

  if (
    intlOptions.minimumFractionDigits &&
    intlOptions.maximumFractionDigits &&
    intlOptions.maximumFractionDigits < intlOptions.minimumFractionDigits
  ) {
    console.warn(
      `maximumFractionDigits format is out of range: maximumFractionDigits=${intlOptions.maximumFractionDigits}, minimumFractionDigits=${intlOptions.minimumFractionDigits}`,
    );

    intlOptions.maximumFractionDigits = undefined;
  }

  return Intl.NumberFormat(locale, {
    currency,
    notation,
    ...intlOptions,
  }).format(castValue);
};

export const intlFiatValueFormatting = (
  value: IntlValueFormattingParams['value'],
  options: IntlValueFormattingParams['options'],
) => {
  // for assets having very low value (below the fiat precision), consider the provided precision for that asset
  const fractionDigits =
    Math.abs(Number(value)) > 0 &&
    Number(value) < 1 / Math.pow(10, MaximumFractionDigits.FIAT)
      ? options.fractionDigits
      : MaximumFractionDigits.FIAT;

  return intlAssetValueFormatting(value, {
    ...options,
    fractionDigits,
  } as FormatOptionsDigitsOverride);
};

export const assetUnitsValueToPercentage = (
  value: string | number = 0,
  options?: FormatOptionsMinMaxDigits & { isAbsolute?: boolean },
): string => {
  const {
    minimumFractionDigits = 0,
    maximumFractionDigits = MaximumFractionDigits.FIAT,
    isAbsolute,
    ...otherOptions
  } = options || {};

  const displayedValue = isAbsolute ? Math.abs(Number(value)) : Number(value);

  return intlAssetValueFormatting(displayedValue / 100, {
    style: 'percent',
    roundingPriority: 'lessPrecision',
    minimumFractionDigits,
    maximumFractionDigits,
    ...otherOptions,
  }).replace(/\s/, '');
};
