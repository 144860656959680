'use client';

import { DialogBase } from '../../../DialogBase/DialogBase';
import { ModalComponent } from '@bts-web/utils-context';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import {
  TwoFaModalState,
  useTwoFaModalState,
} from './utils/useTwoFaModalState';
import { TwoFaLayoutWrapper } from '../shared/TwoFaLayoutWrapper';
import { InitialPage } from './subPages/InitialPage';
import { ProgressIndicatorWithSteps } from '../../../ProgressIndicatorWithSteps/ProgressIndicatorWithSteps';
import { ScanQrPage } from './subPages/ScanQrPage';
import { InputCodePage } from '../shared/InputCodePage/InputCodePage';
import { RecoveryCodesPage } from './subPages/RecoveryCodesPage/RecoveryCodesPage';
import { FinishedSummaryPage } from './subPages/FinishedSummaryPage/FinishedSummaryPage';
import { useCallback, useState } from 'react';
import { dismissTwoFaSetup } from '../../../../../auth';
import { useUserInfoContext } from '../../../../userinfo/UserInfoContext/UserInfoProvider';

const SetupTwoFaModal: ModalComponent = ({ onClose }) => {
  const { t } = useClientTranslation();

  const { refreshUserInfo } = useUserInfoContext();

  const [dismissLoading, setDismissLoading] = useState(false);

  const {
    currentStep,
    onContinue,
    currentStepNumber,
    recoveryCodes,
    setRecoveryCodes,
    setCurrentStep,
  } = useTwoFaModalState();

  const isCloseDisabledInTheCorner =
    currentStep === TwoFaModalState.INITIAL_PAGE ||
    currentStep === TwoFaModalState.RECOVERY_CODES_PAGE ||
    currentStep === TwoFaModalState.SUMMARY_PAGE;

  const onDismiss = async () => {
    setDismissLoading(true);

    await dismissTwoFaSetup();

    await refreshUserInfo();

    setDismissLoading(false);

    onClose();
  };

  const backToPreviousStep = useCallback(() => {
    switch (currentStep) {
      case TwoFaModalState.INPUT_CODE_PAGE:
        return () => setCurrentStep(TwoFaModalState.SCAN_QR_PAGE);

      case TwoFaModalState.SUMMARY_PAGE:
        return () => setCurrentStep(TwoFaModalState.RECOVERY_CODES_PAGE);

      default:
        return undefined;
    }
  }, [currentStep, setCurrentStep]);

  return (
    <DialogBase
      backAction={backToPreviousStep()}
      id="2fa-modal"
      open
      onOpenChange={onDismiss}
      size="medium"
      disableBackdropClose
      visual="secondary"
      title={t('2fa_welcome_title')}
      animate="top"
      showHeaderBottomDivider
      belowHeaderElement={
        <ProgressIndicatorWithSteps
          currentStep={currentStepNumber}
          totalSteps={5}
        />
      }
      closeIcon={isCloseDisabledInTheCorner ? null : undefined} // null hides, undefined sets it to the default icon
    >
      <TwoFaLayoutWrapper>
        {currentStep === TwoFaModalState.INITIAL_PAGE && (
          <InitialPage
            onDismiss={onDismiss}
            onContinue={onContinue}
            dismissLoading={dismissLoading}
          />
        )}
        {currentStep === TwoFaModalState.SCAN_QR_PAGE && (
          <ScanQrPage onContinue={onContinue} />
        )}
        {currentStep === TwoFaModalState.INPUT_CODE_PAGE && (
          <InputCodePage
            onContinue={onContinue}
            bindRecoveryCodes={(newCode: string) => setRecoveryCodes(newCode)}
            actionVariant="setup"
          />
        )}
        {currentStep === TwoFaModalState.RECOVERY_CODES_PAGE && (
          <RecoveryCodesPage
            onContinue={onContinue}
            recoveryCodes={recoveryCodes}
          />
        )}
        {currentStep === TwoFaModalState.SUMMARY_PAGE && (
          <FinishedSummaryPage />
        )}
      </TwoFaLayoutWrapper>
    </DialogBase>
  );
};

export { SetupTwoFaModal };
