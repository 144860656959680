'use client';

import { css } from '@bts-web/utils-style-engine';
import {
  InfiniteScroll,
  InfiniteScrollItem,
} from '@bts-web/design-system/component/infinite-scroll';
import { useSavingsPlansTransactionsContext } from '@bts-web/utils-context';
import {
  SavingsPlansTransactionsTranslationsKeys,
  SavingsPlanTransactionItemType,
} from '../types';
import { SAVINGS_PLANS_TRANSACTIONS_LIST_PAGE_SIZE } from '../../config/query';
import { CellSkeleton } from '../../common/components/loading/CellSkeleton';
import { TransactionItem } from '../../transactions';

interface SavingsPlansListProps {
  translations: SavingsPlansTransactionsTranslationsKeys;
}

const containerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
});

const SavingsPlansTransactionsList = ({
  translations,
}: SavingsPlansListProps) => {
  const { transactions, loadMoreTransactions } =
    useSavingsPlansTransactionsContext();

  const transactionNodes = transactions?.edges?.map(
    (transaction) => transaction?.node,
  );

  if (!transactionNodes?.length) {
    return (
      <div
        className={css({
          padding: 'medium',
        })}
      >
        <span
          className={css({
            fontSize: 'label.medium_medium',
            lineHeight: 'label.medium_medium',
            letterSpacing: 'label.medium_medium',
            fontWeight: 'label.medium_medium',
            color: 'brand.text_disabled',
          })}
        >
          {translations.transactionsListEmpty}
        </span>
      </div>
    );
  }

  return (
    <div
      className={containerStyles}
      data-testid="savings-plans-transactions-list"
    >
      <InfiniteScroll
        itemsPerPage={SAVINGS_PLANS_TRANSACTIONS_LIST_PAGE_SIZE}
        fetchMore={loadMoreTransactions}
        hasMore={Boolean(transactions?.pageInfo?.hasNextPage)}
        skeleton={
          <>
            <CellSkeleton />
            <CellSkeleton />
          </>
        }
        items={transactionNodes as InfiniteScrollItem[]}
        renderItemComponent={(transaction) => (
          <TransactionItem
            transaction={transaction as SavingsPlanTransactionItemType}
            useAssetLogoInsteadOfIcon
            useSimpleTitle
          />
        )}
      />
    </div>
  );
};

export { SavingsPlansTransactionsList };
