import { stack, hstack, css } from '@bts-web/utils-style-engine';
import { SkeletonElement } from '../../common/components';
import { HiddenDesktop } from '../../layouts/LayoutWithLeftDesktopNav/Reusable/HiddenDesktop/HiddenDesktop';

export const TransactionDetailsSkeleton = () => (
  <div
    className={stack({
      gap: 'large',
      paddingX: 'medium',
      width: '100%',
    })}
    data-testid="details-skeleton-container"
  >
    <div className={stack({ gap: 'small' })}>
      <HiddenDesktop>
        <div
          className={css({
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            paddingTop: 'small',
          })}
        >
          <SkeletonElement width="24px" height="24px" borderRadius="30px" />
          <div
            className={css({
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
            })}
          >
            <SkeletonElement width="120px" height="22px" borderRadius="30px" />
          </div>
        </div>
      </HiddenDesktop>

      <div
        className={stack({
          alignItems: 'start',
        })}
      >
        <SkeletonElement height="24px" width="100px" borderRadius="30px" />
        <SkeletonElement height="24px" width="140px" borderRadius="30px" />
      </div>
      <div className={stack()}>
        <TransactionDetailsFieldSkeleton keyWidth="50px" valueWidth="80px" />
        <TransactionDetailsFieldSkeleton keyWidth="70px" valueWidth="120px" />
        <TransactionDetailsFieldSkeleton keyWidth="50px" valueWidth="80px" />
        <TransactionDetailsFieldSkeleton keyWidth="70px" valueWidth="120px" />
      </div>
    </div>
  </div>
);

const TransactionDetailsFieldSkeleton = ({
  keyWidth,
  valueWidth,
}: {
  keyWidth: string;
  valueWidth: string;
}) => (
  <div
    className={hstack({
      justifyContent: 'space-between',
      paddingY: 'extra_small',
    })}
  >
    <SkeletonElement height="16px" width={keyWidth} borderRadius="30px" />
    <SkeletonElement height="16px" width={valueWidth} borderRadius="30px" />
  </div>
);
