'use client';

import { AssetImage, TimeAmounts } from '../../../../common';
import { css } from '@bts-web/utils-style-engine';
import { AssetDetailsPrice } from './subcomponents/AssetDetailsPrice/AssetDetailsPrice';
import { AssetDetailsTranslations } from '../../../types';
import { StockAssetDetailsTranslations } from '../AssetDetailsStocks/AssetDetailsStocks';
import { useAssetDetailsDataContext } from '../../AssetDetailsDataContext/AssetDetailsDataContext.use-client';
import { useMemo } from 'react';
import { Locale } from '@bts-web/utils-lokalise';

export interface AssetDetailsHeaderProps {
  translations: AssetDetailsTranslations & StockAssetDetailsTranslations;
  locale: Locale;
}

export const AssetDetailsHeader = ({
  translations,
  locale,
}: AssetDetailsHeaderProps) => {
  const { assetData, priceChange, timeRange } = useAssetDetailsDataContext();

  const logoProps: { name: string; logoUrl: string; symbol: string } = {
    logoUrl: assetData?.logoUrl || '',
    name: assetData?.name || '',
    symbol: assetData?.symbol || '',
  };

  const marketValueProps: React.ComponentProps<typeof AssetDetailsPrice> = {
    label: translations.marketValue,
    locale: locale,
    value: assetData?.price,
    priceVariation: priceChange,
  };

  const timestampTranslationMappings: {
    [K in TimeAmounts]: string;
  } = {
    oneDay: translations.in24Hours,
    maxYears: translations.inMaxYears,
    oneMonth: translations.inOneMonth,
    oneYear: translations.inOneYear,
    sevenDays: translations.inOneWeek,
    sixMonths: translations.inOneMonth,
  };
  // const timeStampText = timestampOptions[timeSpan];

  const assetDetailsPriceTimestampText = useMemo(() => {
    if (!timeRange) return '';

    return (
      timestampTranslationMappings[
        timeRange as keyof typeof timestampTranslationMappings
      ] || ''
    );
  }, [timeRange]);

  return (
    <section
      aria-label="asset details header"
      className={css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      })}
    >
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'extra_small_2',
        })}
      >
        <h1
          className={css({
            fontSize: 'body.medium',
            fontWeight: 'body.medium',
            lineHeight: 1.15,
          })}
        >
          {logoProps.name} ({logoProps.symbol})
        </h1>
        {assetData?.price && (
          <AssetDetailsPrice
            {...marketValueProps}
            hideLabel
            data-testid="marketValue"
            bolderTitle
            timestampText={assetDetailsPriceTimestampText}
          />
        )}
      </div>

      <AssetImage
        testId="logo-header"
        height={48}
        width={48}
        alt={logoProps.name}
        src={logoProps.logoUrl}
      />
    </section>
  );
};
