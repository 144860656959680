import { stack } from '@bts-web/utils-style-engine';
import { intlAssetValueFormatting } from '@bts-web/utils-formatting';
import { DocumentationExternalLink } from '../../../../common';
import { AssetDetailsETFTranslations } from '../../../types';
import { AssetDetailsInfoRow } from '../../AssetDetailsInfoRow/AssetDetailsInfoRow';
import { ContentSectionWrapper } from '../../../../layouts/ContentSectionWrapper/ContentSectionWrapper';
import { AssetFields } from '../../AssetDetailsDataContext/AssetDetailsDataContext.use-client';

const containerStyles = stack({
  gap: 'small',
});

interface AssetDetialsEtfProps {
  assetData: AssetFields;
  applicationLocale: string;
  translations: AssetDetailsETFTranslations;
}

export const AssetDetailsETF = ({
  assetData,
  applicationLocale,
  translations,
}: AssetDetialsEtfProps) => {
  return (
    <ContentSectionWrapper
      aria-label="ETF details section"
      sectionTitle={translations.underlyingAsset}
      className={containerStyles}
    >
      <AssetDetailsInfoRow
        label={translations.assetName}
        value={assetData?.name}
      />

      <AssetDetailsInfoRow
        label={translations.assetIsin}
        value={assetData?.isin ? assetData?.isin?.toUpperCase() : null}
      />

      <AssetDetailsInfoRow
        label={translations.assetProvider}
        value={assetData?.provider}
      />

      <AssetDetailsInfoRow
        label={translations.assetTotalExpenseRatio}
        value={
          assetData?.totalExpenseRatio != null
            ? `${intlAssetValueFormatting(assetData?.totalExpenseRatio * 100, {
                locale: applicationLocale,
              })}% ${translations.perAnnum}`
            : null
        }
      />

      <AssetDetailsInfoRow
        label={translations.assetDistributionPolicy}
        value={assetData?.distributionPolicy}
      />

      <AssetDetailsInfoRow
        label={translations.assetReplicationMethod}
        value={assetData?.replicationMethod}
      />

      {assetData && (
        <DocumentationExternalLink
          href={assetData?.legalUrl as string}
          label={translations.legalUrl}
          size="small"
          visual="special"
          icon="document"
        />
      )}
    </ContentSectionWrapper>
  );
};
