'use client';

import { useClientTranslation } from '@bts-web/utils-lokalise';
import {
  useComposableDrawer,
  useComposableModal,
  useTrade,
} from '@bts-web/utils-context';
import { useCallback, useMemo } from 'react';
import { Icon } from '@bts-web/design-system/component/icon';
import { useRouter } from 'next/navigation';
import { TradeOptionButton } from './TradeOptionButton';
import { DrawerContent, Tag } from '../../../common';
import { getAppConfig } from '@bts-web/core-app-config';
import { StartSavingModal } from '../../../savings-plans';

const TradeOptionsDrawerContent = ({
  savingsPlansCount,
}: {
  savingsPlansCount: number;
}) => {
  const { feature_hasSavingsPlansEnabled, feature_hasCryptoTransfersEnabled } =
    getAppConfig();

  const { showModal } = useComposableModal();

  const { t } = useClientTranslation();

  const { isSellButtonVisible } = useTrade();

  const router = useRouter();

  const { closeDrawer } = useComposableDrawer();

  const translations = useMemo(
    () => ({
      dashboardDrawerTopBar: t('dashboard_drawer_top_bar'),
      dashboardDrawerSell: t('sell'),
      dashboardDrawerConvertYourAssetsToCash: t(
        'dashboard_drawer_convert_your_assets_to_cash',
      ),
      dashboardDrawerStartSavingWithARecurringBuy: t(
        'dashboard_drawer_start_saving_with_a_recurring_buy',
      ),
      dashboardDrawerSetASavingsPlan: t('dashboard_drawer_set_a_savings_plan'),
      dashboardDrawerBuyImmediately: t('dashboard_drawer_buy_immediately'),
      dashboardDrawerMarketOrder: t('dashboard_drawer_market_order'),
      dashboardDrawerNew: t('dashboard_drawer_new'),
      dashboardDrawerDeposit: t('deposit'),
      dashboardDrawerWithdraw: t('tx_details_type_withdrawal'),
    }),
    [t],
  );

  const onClickTradeSellButton = useCallback(() => {
    router.push('/assets/my-assets');

    closeDrawer();
  }, [router, closeDrawer]);

  const onClickTradeBuyButton = useCallback(() => {
    router.push('/assets/discover?next=buy');

    closeDrawer();
  }, [router, closeDrawer]);

  const onClickSavingsButton = useCallback(() => {
    if (savingsPlansCount > 0) {
      router.push('/assets/discover?next=savings');
    } else {
      showModal(StartSavingModal, {
        continueUrl: '/assets/discover?next=savings',
      });
    }

    closeDrawer();
  }, [savingsPlansCount, router, showModal, closeDrawer]);

  const onClickDepositButton = useCallback(() => {
    router.push('/crypto-transfers/deposit');

    closeDrawer();
  }, [router, closeDrawer]);

  const onClickWithdrawalButton = useCallback(() => {
    router.push('/crypto-transfers/withdrawal');

    closeDrawer();
  }, [router, closeDrawer]);

  const tradeOptions = useMemo(() => {
    return [
      {
        testId: 'trade-options-drawer-buy-btn',
        title: translations.dashboardDrawerBuyImmediately,
        subTitle: translations.dashboardDrawerMarketOrder,
        icon: <Icon icon="marker-order" size="32" />,
        onClick: onClickTradeBuyButton,
      },

      ...(feature_hasSavingsPlansEnabled
        ? [
            {
              testId: 'trade-options-drawer-savings-btn',
              title: translations.dashboardDrawerSetASavingsPlan,
              subTitle:
                translations.dashboardDrawerStartSavingWithARecurringBuy,
              icon: <Icon icon="savings" size="32" />,
              tag: (
                <Tag visual="filledInfoStatus" size="medium">
                  {translations.dashboardDrawerNew}
                </Tag>
              ),
              onClick: onClickSavingsButton,
            },
          ]
        : []),

      ...(isSellButtonVisible
        ? [
            {
              testId: 'trade-options-drawer-sell-btn',
              title: translations.dashboardDrawerSell,
              subTitle: translations.dashboardDrawerConvertYourAssetsToCash,
              icon: <Icon icon="sell" size="32" />,
              onClick: onClickTradeSellButton,
            },
          ]
        : []),

      ...(feature_hasCryptoTransfersEnabled
        ? [
            {
              testId: 'trade-options-drawer-deposit-btn',
              title: translations.dashboardDrawerDeposit,
              icon: <Icon icon="deposit" size="32" />,
              onClick: onClickDepositButton,
            },
            {
              testId: 'trade-options-drawer-withdrawal-btn',
              title: translations.dashboardDrawerWithdraw,
              icon: <Icon icon="withdraw" size="32" />,
              onClick: onClickWithdrawalButton,
            },
          ]
        : []),
    ];
  }, [
    feature_hasSavingsPlansEnabled,
    feature_hasCryptoTransfersEnabled,
    isSellButtonVisible,
    translations,
    onClickTradeBuyButton,
    onClickSavingsButton,
    onClickTradeSellButton,
    onClickWithdrawalButton,
    onClickDepositButton,
  ]);

  return (
    <DrawerContent
      title={translations.dashboardDrawerTopBar}
      ariaLabel="trade options drawer content"
    >
      {tradeOptions.map(({ testId, title, subTitle, icon, tag, onClick }) => (
        <TradeOptionButton
          key={testId}
          data-testid={testId}
          title={title}
          subTitle={subTitle}
          icon={icon}
          tag={tag}
          onClick={onClick}
        />
      ))}
    </DrawerContent>
  );
};

export { TradeOptionsDrawerContent };
