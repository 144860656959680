import { FC } from 'react';
import {
  Collapsible as DesignSystemCollapsible,
  type CollapsibleProps as DesignSystemCollapsibleProps,
} from '@bts-web/design-system/component/collapsible';
import { css } from '@bts-web/utils-style-engine';

export type CollapsibleProps = DesignSystemCollapsibleProps['external'];

export const Collapsible: FC<CollapsibleProps> = (props) => {
  return (
    <DesignSystemCollapsible
      {...props}
      className={css({
        backgroundColor: 'screen_background.primary',
        '& [data-element="right-content-slot-wrap"]': {
          display: 'flex',
          alignItems: 'center',
          gap: 'extra_small_3',
        },
        '& [data-element="trigger"]': {
          py: 'medium',
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: 'neutrals.text_primary',
          fontSize: 'title.medium',
          fontWeight: 'title.medium',
          letterSpacing: 'title.medium',
          lineHeight: 'title.medium',
        },
        '& [data-element="icon"]': {
          display: 'flex',
          transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
        },
        '&[data-state="open"] [data-element="icon"]': {
          transform: 'rotate(180deg)',
        },
        '&[data-state="open"] > [data-element="content"]': {
          overflow: 'hidden',
          backgroundColor: 'screen_background.primary',
          animation: `slideCollapseDown 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
        },
        '&[data-state="closed"] > [data-element="content"]': {
          animation: `slideCollapseUp 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
        },
      })}
    />
  );
};
