import { AccentTradeControllerProps } from '../types';
import { getTradeTranslations } from '../../utils/getTradeTranslations';
import { NavigationBackButton, NavigationButton } from '../../../navigation';
import { NavigationBar } from '../../../common';
import { AcceptTradeSubscriptionWrapper } from '../AcceptTradeSubscriptionWrapper.use-client';
import {
  getLocale,
  i18n,
  Language,
  updateServerLanguage,
} from '@bts-web/utils-lokalise';

const AcceptTradeController = async ({
  offerData,
  variant,
  translationUtil,
}: AccentTradeControllerProps) => {
  await updateServerLanguage();

  const translations = getTradeTranslations(translationUtil);

  const currentLocale = getLocale(i18n.resolvedLanguage as Language);

  const ACCEPT_TRADE_TITLES = {
    buy: translations.buy,
    sell: translations.sell,
  };

  return (
    <>
      <NavigationBar
        title={ACCEPT_TRADE_TITLES[variant]}
        NavigationLeftSlot={<NavigationBackButton />}
        NavigationRightSlot={
          <NavigationButton icon="dismiss" to="/assets/portfolio" />
        }
      />

      <AcceptTradeSubscriptionWrapper
        currentLocale={currentLocale}
        initialOfferData={offerData}
        offerId={offerData.id}
        translations={translations}
        variant={variant}
      />
    </>
  );
};

export { AcceptTradeController };
