'use client';

import { FC } from 'react';
import { Icon } from '@bts-web/design-system/component/icon';
import { css } from '@bts-web/utils-style-engine';
import { useComposableDrawer } from '@bts-web/utils-context';
import { UserAccessWithRoleCheck } from '../../../../common';
import { TradeOptionsDrawer } from '../../../../trade';

export const MobileNavTradeButton: FC<{
  label: string;
  savingsPlansCount: number;
}> = ({ label, savingsPlansCount }) => {
  const { openDrawer } = useComposableDrawer();

  const handleClick = () =>
    openDrawer(TradeOptionsDrawer, {
      savingsPlansCount,
    });

  return (
    <UserAccessWithRoleCheck>
      <button
        type="button"
        data-testid="trade-button"
        className={css({
          color: 'neutrals.text_primary',
          alignItems: 'center',
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          fontSize: 'caption.small',
          justifyContent: 'center',
          borderRadius: 0,
          pt: 'extra_small_3',
        })}
        aria-label="trade button"
        onClick={handleClick}
      >
        <div
          className={css({
            backgroundColor: 'brand.fill_primary',
            padding: 'extra_small_2',
            borderRadius: '100px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            color: 'neutrals.card_fill_primary',
          })}
        >
          <Icon size="24" icon="trade" />
        </div>

        <span
          className={css({
            lineHeight: 1,
            marginTop: 'extra_small_3',
            pb: 'extra_small_3',
          })}
        >
          {label}
        </span>
      </button>
    </UserAccessWithRoleCheck>
  );
};
