import { FC } from 'react';
import { css } from '@bts-web/utils-style-engine';
import {
  intlAssetValueFormatting,
  PrecisionFloat,
} from '@bts-web/utils-formatting';
import { getAppConfig } from '@bts-web/core-app-config';

export interface AssetDetailsAverageBuyPriceProps {
  title: string;
  value: PrecisionFloat;
  locale: string;
}

const AssetDetailsAverageBuyPrice: FC<AssetDetailsAverageBuyPriceProps> = ({
  title,
  value,
  locale,
}) => {
  const { currency } = getAppConfig();

  const numberedValue = value?.value;

  if (numberedValue === null || numberedValue === undefined) {
    return null;
  }

  return (
    <div data-testid="average-buy-price">
      <div
        className={css({
          fontSize: 'body.medium',
          fontWeight: 'body.medium',
          color: 'neutrals.fill_secondary',
        })}
      >
        {title}
      </div>
      <div
        className={css({
          fontSize: 'body.medium',
          fontWeight: 'body.medium',
          color: 'neutrals.text_primary',
          mt: 'extra_small_3',
        })}
      >
        {intlAssetValueFormatting(numberedValue, {
          locale,
          currency,
        })}
      </div>
    </div>
  );
};

export { AssetDetailsAverageBuyPrice };
