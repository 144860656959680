import {
  TransactionStatus,
  AcceptTradeOfferMutation$data,
} from '@bts-web/data-layer/server';
import {
  AssetType,
  NonNullableTransactionFinalizedSummaryData,
  TradeControllerVariants,
  TradeScreenTranslations,
  isBpfsAsset,
} from '../../utils';
import { SuccessModal } from '../../../common';
import { FailContent } from '../FinishTradeController/subcomponents/FailContent';
import { PendingContent } from '../FinishTradeController/subcomponents/PendingContent';
import { SuccessBuyContent } from '../FinishTradeController/subcomponents/SuccessBuyContent';
import { SuccessSellContent } from '../FinishTradeController/subcomponents/SuccessSellContent';

export const FinishTradeStatusModal = ({
  currentLocale,
  actionVariant,
  transactionData,
  onClose,
  translations,
  hasTaxOfflineWarning,
}: {
  currentLocale: string;
  actionVariant: TradeControllerVariants;
  transactionData: NonNullable<AcceptTradeOfferMutation$data>['acceptTradeOffer'];
  onClose: () => void;
  translations: TradeScreenTranslations;
  hasTaxOfflineWarning: boolean;
}) => {
  const { status } = transactionData ?? { status: 'FAILED' };

  const castedStatus = status as Exclude<
    TransactionStatus,
    'CREATED' | 'EXPIRED'
  >;

  const isBpf = isBpfsAsset(transactionData?.asset?.__typename as AssetType);

  const poweredByText = isBpf
    ? translations.poweredByBpfs
    : translations.poweredByBitpanda;

  const isBuyAction = actionVariant === 'buy';

  const mainContentValues: Record<
    Exclude<TransactionStatus, 'CREATED' | 'EXPIRED'>,
    {
      mainMessage: string;
      buttonMessage: string;
    }
  > = {
    FINISHED: {
      mainMessage: isBuyAction
        ? translations.successBuyPageTitle
        : translations.successSellPageTitle,
      buttonMessage: translations.successPageButtonContent,
    },
    FAILED: {
      mainMessage: translations.failPageTitle,
      buttonMessage: translations.failPageButtonContent,
    },
    IN_PROGRESS: {
      mainMessage: isBuyAction
        ? translations.pendingBuyPageTitle
        : translations.pendingSellPageTitle,
      buttonMessage: translations.pendingPageBUttonContent,
    },
  };

  const isFailure = castedStatus === 'FAILED';

  const isPending = castedStatus === 'IN_PROGRESS';

  const isBuySuccess = castedStatus === 'FINISHED' && isBuyAction;

  const isSellSuccess = actionVariant === 'sell' && castedStatus === 'FINISHED';

  const contentTransactionFields = {
    asset: transactionData?.asset,
    ...transactionData?.offer,
  };

  const getStatusContent = () => {
    if (isPending) {
      return (
        <PendingContent
          actionVariant={actionVariant}
          currentLocale={currentLocale}
          transactionData={
            contentTransactionFields as NonNullableTransactionFinalizedSummaryData
          }
        />
      );
    }

    if (isSellSuccess) {
      return (
        <SuccessSellContent
          transactionData={
            contentTransactionFields as NonNullableTransactionFinalizedSummaryData
          }
          currentLocale={currentLocale}
          hasTaxOfflineWarning={hasTaxOfflineWarning}
          translatedValues={{
            successSellFirstLine: translations.successSellSubtitleFirstLine,
            warningTransaction: translations.sellScreenTaxNotFinalWarning,
          }}
        />
      );
    }

    if (isBuySuccess) {
      return (
        <SuccessBuyContent
          currentLocale={currentLocale}
          transactionData={
            contentTransactionFields as NonNullableTransactionFinalizedSummaryData
          }
          translatedValues={{
            firstLine: translations.successBuySubtitleFirstLine,
            screenSubtitle: translations.successScreenSubtitleFirstLine,
            screenSubtitleTwo: translations.successScreenSubtitleSecondLine,
          }}
        />
      );
    }

    if (isFailure) {
      return <FailContent failCardText={translations.failCardText} />;
    }
  };

  return (
    <SuccessModal
      logo={{
        url: transactionData?.asset.logoUrl as string,
        name: transactionData?.asset.name as string,
      }}
      title={mainContentValues[castedStatus].mainMessage}
      description={getStatusContent()}
      onClose={onClose}
      poweredByLabel={poweredByText}
      goToPortfolioBtnVariant="primary"
    />
  );
};
