import { FC } from 'react';
import { TransactionsViewWithCryptoTransfersQuery$data } from '@bts-web/data-layer/server';
import { TransactionCryptoTransferEntryType, TransactionItem } from '..';
import { flex } from '@bts-web/utils-style-engine';

const CryptoTransfersPendingProcessingList: FC<{
  transactions: TransactionsViewWithCryptoTransfersQuery$data['cryptoTransfersTransactions'];
}> = ({ transactions }) => {
  const transactionsProc =
    transactions?.edges?.map((transaction) => {
      if (!transaction) {
        return null;
      }

      return transaction.node;
    }) ?? [];

  return (
    <ul
      className={flex({ flexDirection: 'column' })}
      data-testid="crypto-transfer-pending-processing-transaction-list"
    >
      {transactionsProc.map((transaction) => {
        return (
          <TransactionItem
            key={transaction?.id}
            transaction={transaction as TransactionCryptoTransferEntryType}
            showCategoryTypeChip
            useSimpleTitle
            useAssetLogoInsteadOfIcon
          />
        );
      })}
    </ul>
  );
};

export { CryptoTransfersPendingProcessingList };
