'use client';

import { useAppNotification } from '@bts-web/core-features/notifications';
import { translateGqlErrorCodesOrReturnThemDirectly } from './translateGqlErrorCodesOrReturnThemDirectly';

export const useHandleGqlErrorsWithSnackbar = () => {
  const { setAppNotification } = useAppNotification();

  const processErrors = async (gqlResponseErrors?: string[]) => {
    const { genericTitle, translatedCodes } =
      await translateGqlErrorCodesOrReturnThemDirectly(gqlResponseErrors);

    setAppNotification({
      visual: 'error',
      title: genericTitle,
      subtitle: translatedCodes.join(' '),
    });
  };

  return { processErrors };
};
