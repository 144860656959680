'use client';

import {
  getLocale,
  i18n,
  Language,
  useClientTranslation,
} from '@bts-web/utils-lokalise';
import { TFunction } from 'i18next';
import { getTransactionDetailsTranslations } from '../utils/getTransactionDetailsTranslations';
import { useTransactionDetails } from '../hooks/useTransactionDetails.use-client';
import { Drawer } from '../../layouts';
import { TransactionDetailsLayout } from '../TransactionDetailsLayout/TransactionDetailsLayout';
import { TransactionDetailsSkeleton } from '../TransactionDetailsSkeleton/TransactionDetailsSkeleton';
import { TransactionDetails } from '../TransactionDetails/TransactionDetails';

export const transactionDetailsDrawerId = 'transaction_details_drawer';

export const TransactionDetailsDrawer = ({
  tradeId,
  isFetchedByExternalTransactionId,
  showActionButton = true,
  title,
}: {
  tradeId?: string;
  isFetchedByExternalTransactionId: boolean;
  showActionButton?: boolean;
  title?: string | null;
}) => {
  const { transaction, isTransactionFetching } = useTransactionDetails({
    tradeId,
    isFetchedByExternalTransactionId,
  });

  const locale = getLocale(i18n.resolvedLanguage as Language);

  const { t } = useClientTranslation();

  const translations = getTransactionDetailsTranslations(t as TFunction);

  return (
    <Drawer
      title={title}
      disableOverlayClose
      contentNode={
        <TransactionDetailsLayout
          navigationBarTitle={translations.topBar}
          withClose
        >
          {isTransactionFetching ? (
            <TransactionDetailsSkeleton />
          ) : (
            <TransactionDetails
              showActionButton={showActionButton}
              transaction={transaction}
              locale={locale}
              translations={translations}
              translationUtil={t as TFunction}
              title={title}
            />
          )}
        </TransactionDetailsLayout>
      }
    />
  );
};
