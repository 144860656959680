import { stack, css } from '@bts-web/utils-style-engine';
import { PageSkeletonWrapper, SkeletonElement, CellSkeleton } from '../common';
import { PortfolioSavingsPlansFrameSkeleton } from '../savings-plans/SavingsPlansFrame/SavingsPlansFrameSkeleton';

export const PortfolioLoadingSkeleton = () => (
  <PageSkeletonWrapper>
    <PortfolioContentLoadingSkeleton />

    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'medium',
      })}
    >
      <SkeletonElement height="24px" width="200px" />

      <div>
        <CellSkeleton />
        <CellSkeleton />
        <CellSkeleton />
      </div>
    </div>
  </PageSkeletonWrapper>
);

const PortfolioOverviewSkeleton = () => (
  <div
    className={css({
      display: 'flex',
      flexDirection: 'column',
      gap: 'medium',
    })}
  >
    <SkeletonElement height="24px" width="200px" />

    <div>
      <CellSkeleton />
      <CellSkeleton />
      <CellSkeleton />
    </div>
  </div>
);

export const PortfolioContentLoadingSkeleton = () => (
  <>
    <PortfolioHeaderSkeleton />

    <div
      className={css({
        borderBottom: '4px solid',
        borderBottomColor: 'neutrals.widget_primary',
      })}
    >
      <div className={css({ hideFrom: 'lg' })}>
        {/* mobile */}
        <SkeletonElement height="325px" width="100%" />
      </div>

      <div className={css({ hideBelow: 'lg' })}>
        {/* desktop */}
        <SkeletonElement height="478px" width="100%" />
      </div>
    </div>

    <div
      className={css({
        px: 'small',
        py: 'medium',
        display: 'flex',
        flexDirection: 'column',
        gap: 'medium',
      })}
    >
      <PortfolioSavingsPlansFrameSkeleton />

      <PortfolioOverviewSkeleton />
    </div>
  </>
);

export const PortfolioHeaderSkeleton = () => (
  <div
    className={stack({
      gap: {
        base: 'extra_small_2',
        lg: 'medium',
      },
      paddingX: 'medium',
      paddingY: 'small',
      width: '100%',
    })}
    data-testid="portfolio-header-skeleton-container"
  >
    <SkeletonElement
      width="62px"
      height="16px"
      testId="portfolio-header-skeleton-title"
    />

    <div
      className={css({
        display: 'flex',
        gap: 'extra_small_2',
        alignItems: 'center',
        justifyContent: 'space-between',
        '@media (min-width: 1024px)': {
          justifyContent: 'start',
          gap: 'medium',
        },
      })}
    >
      <div className={css({ hideFrom: 'lg' })}>
        <SkeletonElement
          width="231px"
          height="32px"
          testId="portfolio-header-skeleton-left"
        />
      </div>

      <div className={css({ hideBelow: 'lg' })}>
        <SkeletonElement
          width="231px"
          height="46px"
          testId="portfolio-header-skeleton-left"
        />
      </div>

      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'extra_small_2',
          alignItems: 'end',
          hideFrom: 'lg', // mobile
        })}
        data-testid="portfolio-header-skeleton-right"
      >
        <SkeletonElement width="70px" height="16px" />

        <SkeletonElement width="52px" height="18px" borderRadius="30px" />
      </div>

      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'extra_small_2',
          alignItems: 'end',
          hideBelow: 'lg', // desktop
        })}
        data-testid="portfolio-header-skeleton-right"
      >
        <SkeletonElement width="70px" height="16px" borderRadius="30px" />

        <SkeletonElement width="68px" height="27px" borderRadius="32px" />
      </div>
    </div>
  </div>
);
