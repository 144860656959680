import { FC } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { SVGIconType } from './types';

const RakbankLogoIcon: FC<SVGIconType> = ({ color, size = 32 }) => {
  const colorClasses = css({
    color: color || 'rgba(0, 0, 0, 1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

  // Calculate height based on original aspect ratio (1466:1539)
  const aspectRatio = 1539 / 1466;

  const height = Math.round(size * aspectRatio);

  return (
    <div
      data-testid="bp-logo-icon"
      className={colorClasses}
      style={{ width: size, height: 'auto' }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1466 1539"
        width={size}
        height={height}
      >
        <title>Rakbank Logo</title>
        <style>
          {`.s0 { fill: #ffd249 }
            .s1 { fill: #ff2e17 }`}
        </style>
        <g>
          <path
            className="s0"
            d="m0.1 836.9q2.7-5.4 8-8c16.1-10.8 37.5-10.8 50.8 5.3 195.2 206.2 438.5 329.3 703.1 329.3 211.2 0 411.7-77.6 582.8-216.8-58.8 152.6-139 281.1-232.6 382.8-77.5 21.4-160.4 32.1-245.9 32.1-360.9 0-679.1-208.8-866.2-524.7z"
          />
          <path
            className="s1"
            d="m254.1 52.5c0 0 876.8 53.5 505.2 977.1 0 0 1368.8-1301.1-505.2-977.1z"
          />
          <path
            className="s1"
            d="m1465.1 269.3v-13.4q0-28.1-16.1-48.2-5.3-5.3-8-8-2.7 0-2.7-2.7-5.3-2.7-8-5.3-2.6 0-5.3-2.7-2.7 0-5.4-2.7-5.3 0-13.3-2.7-5.4 0-10.7 0 0 0-2.7 0-2.7 0-5.3 0c-24.1 5.4-45.5 24.1-53.5 53.6-104.3 398.9-342.2 688-561.4 808.5 229.9-32.1 430.4-182 566.7-404.3-61.5 227.6-179.1 423.1-328.8 556.9-82.9 29.5-168.4 45.5-259.3 45.5-288.7 0-545.3-163.3-716.4-423q-13.4 0-26.8 8.1-5.3 2.6-8 8c165.8 281.1 435.8 476.5 748.5 516.7q-108.2 40.2-224.5 40.2c-93.6 0-184.5-21.5-270-56.3q16 13.4 29.4 29.5 2.7 2.7 8 5.3 5.4 5.4 13.4 13.4 2.6 2.7 8 5.4 8 8 16 13.4 0 0 0 2.6 32.1 24.1 64.2 45.6 2.7 0 5.3 2.6 8.1 5.4 16.1 8.1 5.3 2.6 10.7 5.3 5.3 5.4 13.3 8 5.4 2.7 10.7 5.4 8.1 5.4 13.4 8 8 2.7 13.4 5.4 5.3 2.7 13.3 5.3 5.4 2.7 13.4 5.4 5.3 2.7 13.4 5.4 5.3 2.6 13.3 2.6 5.4 2.7 13.4 5.4 5.3 2.7 13.4 2.7 5.3 2.6 13.3 2.6 5.4 2.7 13.4 5.4 8 0 13.4 0 8 2.7 16 2.7 5.3 2.6 10.7 2.6 10.7 0 18.7 2.7 5.4 0 10.7 0 13.4 0 26.7 0c160.4 0 310.1-77.6 433.1-208.8q0-2.7 0-2.7 13.4-13.4 26.7-29.4 2.7-2.7 5.4-5.4 10.7-13.4 24-29.4 2.7-2.7 5.4-5.4 10.7-16.1 21.4-32.1 2.7-2.7 5.3-8 13.4-16.1 24.1-32.2 2.7-5.3 5.3-8 10.7-16.1 18.7-34.8 2.7-5.4 5.4-8 10.7-18.8 21.4-34.8 2.6-5.4 5.3-10.8 8-18.7 16.1-34.8 2.6-8 5.3-13.3 10.7-18.8 18.7-37.5 0-2.7 2.7-5.4c77.5-182 128.3-396.2 139-629.1q0-2.7 0-2.7 0-10.7 2.7-21.4 0-8.1 0-16.1 0-29.4 0-58.9z"
          />
        </g>
      </svg>
    </div>
  );
};

export { RakbankLogoIcon };
