'use client';

import { DialogBase } from '../../../DialogBase/DialogBase';
import { ModalComponent } from '@bts-web/utils-context';
import { TwoFaLayoutWrapper } from '../shared/TwoFaLayoutWrapper';
import { TwoFaSectionDescription } from '../shared/TwoFaSectionDescription';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { TwoFaSectionTitle } from '../shared/TwoFaSectionTitle';
import { TwoFaSectionButtonsWrapper } from '../shared/TwoFaSectionButtonsWrapper';
import { ButtonBase } from '../../../ButtonBase/ButtonBase';
import { useState } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { signOutForTwoFaSetup } from '../../../../../auth';

export type TriggerSetupTwoFaModalProps = {
  onModalClose?: () => void;
};

const TriggerSetupTwoFaModal: ModalComponent<TriggerSetupTwoFaModalProps> = ({
  onClose,
  onModalClose,
}) => {
  const [loading, setLoading] = useState(false);

  const { t } = useClientTranslation();

  const triggerSetupTwoFaLogout = async () => {
    setLoading(true);

    try {
      await signOutForTwoFaSetup();

      return;
    } catch (_error) {
      setLoading(false);
    }
  };

  const onClickClose = () => {
    if (onModalClose) {
      onModalClose();
    }

    onClose();
  };

  return (
    <DialogBase
      id="trigger-reset-2fa-modal"
      open
      onOpenChange={onClickClose}
      size="medium"
      disableBackdropClose
      visual="secondary"
      title={t('2fa_welcome_title')}
      animate="top"
      showHeaderBottomDivider
    >
      <TwoFaLayoutWrapper>
        <TwoFaSectionTitle title={`${t('2fa_welcome_subtitle')}`} />
        <TwoFaSectionDescription
          textContent={`${t('2fa_reauthenticate_body')}`}
        />
      </TwoFaLayoutWrapper>
      <TwoFaSectionButtonsWrapper>
        <ButtonBase
          isLoading={loading}
          onClick={triggerSetupTwoFaLogout}
          visual="primary"
          size="large"
          fullWidth
          disabled={loading}
        >
          {t('2fa_enable_accept_button')}
        </ButtonBase>
        <ButtonBase
          disabled={loading}
          isLoading={loading}
          visual={'ghost'}
          onClick={onClickClose}
          className={css({
            textDecoration: 'underline',
          })}
        >
          {t('2fa_welcome_cta_skip')}
        </ButtonBase>
      </TwoFaSectionButtonsWrapper>
    </DialogBase>
  );
};

export { TriggerSetupTwoFaModal };
