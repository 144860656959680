import {
  SavingsPlansFrameController,
  SavingsPlansFrameControllerProps,
} from './SavingsPlansFrameController';
import { ErrorBoundaryWithSuspense } from '../../layouts/ErrorBoundary/ErrorBoundaryWithSuspense';
import { css } from '@bts-web/utils-style-engine';
import { PortfolioSavingsPlansFrameSkeleton } from './SavingsPlansFrameSkeleton';

const SavingsPlansFrameControllerWithSuspense = (
  props: SavingsPlansFrameControllerProps,
) => {
  return (
    <ErrorBoundaryWithSuspense
      fallbackOrSkeleton={
        <div
          className={css({
            px: 'small',
            py: 'medium',
          })}
        >
          <PortfolioSavingsPlansFrameSkeleton />
        </div>
      }
    >
      <SavingsPlansFrameController {...props} />
    </ErrorBoundaryWithSuspense>
  );
};

export { SavingsPlansFrameControllerWithSuspense };
