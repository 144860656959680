import { ErrorBoundaryWithSuspense } from '../../layouts/ErrorBoundary/ErrorBoundaryWithSuspense';
import {
  TransactionDetailsController,
  TransactionDetailsControllerProps,
} from '../TransactionDetailsController';
import { TransactionDetailsSkeleton } from '../TransactionDetailsSkeleton/TransactionDetailsSkeleton';

const TransactionDetailsControllerWithSuspense = async ({
  locale,
  transactionId,
  transactionType,
}: TransactionDetailsControllerProps) => {
  return (
    <ErrorBoundaryWithSuspense
      fallbackOrSkeleton={<TransactionDetailsSkeleton />}
    >
      <TransactionDetailsController
        locale={locale}
        transactionId={transactionId}
        transactionType={transactionType}
      />
    </ErrorBoundaryWithSuspense>
  );
};

export { TransactionDetailsControllerWithSuspense };
