'use client';

import { css } from '@bts-web/utils-style-engine';
import { HyperLinkButtonBase } from '../../common/components/HyperLinkButtonBase/HyperLinkButtonBase';
import { AppUserFullInfo } from '../../common/userinfo/types';
import { ButtonBase } from '../../common/components';

interface IAgreeButtonProps extends React.PropsWithChildren {
  userType: AppUserFullInfo['userType'];
  firstAmlQuestionId?: string;
  disabled?: boolean;
}

const IAgreeButton = ({
  children,
  userType,
  firstAmlQuestionId,
  disabled = false,
}: IAgreeButtonProps) => {
  let nextUrl = '/corporate-onboarding';

  let disableCondition = false;

  if (userType === 'User') {
    nextUrl = `/onboarding/aml-questions/${firstAmlQuestionId}`;

    disableCondition = !firstAmlQuestionId;
  }

  const ButtonComponent = !disableCondition ? HyperLinkButtonBase : ButtonBase;

  return (
    <div
      className={css({
        mt: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      })}
    >
      <ButtonComponent
        to={nextUrl}
        disabled={disabled || disableCondition}
        visual="primary"
      >
        {children}
      </ButtonComponent>
    </div>
  );
};

export { IAgreeButton };
