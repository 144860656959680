import { Locale } from '@bts-web/utils-lokalise';
import {
  assetUnitsValueToPercentage,
  getRoundedPrecision,
  PrecisionFloat,
  intlFiatValueFormatting,
} from '@bts-web/utils-formatting';
import { AcceptsDataTestid } from '@bts-web/utils-test/types';
import { getAppConfig } from '@bts-web/core-app-config';
import { PriceVariation } from '../../../../../common';
import { AssetDetailsStatsSectionEntry } from './AssetDetailsStatsSectionEntry';

interface AssetDetailStatsSectionProps extends AcceptsDataTestid {
  topItemLabel?: string;
  topItemValue?: PrecisionFloat | null;
  bottomItemLabel?: string;
  bottomItemValue?: PrecisionFloat | null;
  children?: React.ReactNode;
  locale: Locale;
  priceVariation?: {
    label: string;
    value: number | null;
  };
}

export const AssetDetailsStatsSection = ({
  topItemLabel,
  locale,
  topItemValue,
  bottomItemLabel,
  bottomItemValue,
  priceVariation,
}: AssetDetailStatsSectionProps) => {
  const showTopItem = !!topItemValue;

  const showBottomItem = !!bottomItemValue;

  const precisiontopItemValue = getRoundedPrecision(topItemValue);

  const bottomItemPrecisionValue = getRoundedPrecision(bottomItemValue);

  const { currency } = getAppConfig();

  return (
    <>
      {showTopItem && (
        <AssetDetailsStatsSectionEntry>
          <span>{topItemLabel}</span>

          <span suppressHydrationWarning>
            {intlFiatValueFormatting(precisiontopItemValue, {
              locale,
              currency,
              fractionDigits: topItemValue.precision,
            })}
          </span>
        </AssetDetailsStatsSectionEntry>
      )}

      {priceVariation?.value != null && (
        <AssetDetailsStatsSectionEntry data-testid="asset-details-stats-price-variation">
          <span>{priceVariation.label}</span>

          <PriceVariation
            size="smallAccent"
            trend={
              priceVariation.value > 0
                ? 'positive'
                : priceVariation.value < 0
                  ? 'negative'
                  : 'neutral'
            }
            visual={
              priceVariation.value > 0
                ? 'iconStyleAccentPositive'
                : priceVariation.value < 0
                  ? 'iconStyleAccentNegative'
                  : 'iconStyleAccentNeutral'
            }
            value={assetUnitsValueToPercentage(priceVariation.value, {
              locale,
              isAbsolute: true,
            })}
            valuePosition="left"
          />
        </AssetDetailsStatsSectionEntry>
      )}

      {showBottomItem && (
        <AssetDetailsStatsSectionEntry>
          <span>{bottomItemLabel}</span>

          <span suppressHydrationWarning>
            {intlFiatValueFormatting(bottomItemPrecisionValue, {
              locale,
              currency,
              fractionDigits: bottomItemValue.precision,
            })}
          </span>
        </AssetDetailsStatsSectionEntry>
      )}
    </>
  );
};
