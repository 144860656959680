import { css } from '@bts-web/utils-style-engine';
import { IntroHeader, IntroHeaderProps } from '../IntroHeader/IntroHeader';
import { ResponsiveLayoutWithLogo } from '../../../layouts/ResponsiveLayoutWithLogo/ResponsiveLayoutWithLogo';
import { HiddenDesktop } from '../../../layouts/LayoutWithLeftDesktopNav/Reusable/HiddenDesktop/HiddenDesktop';
import { ResponsiveLayoutWithLogoLanguageSwitcher } from '../../../layouts/ResponsiveLayoutWithLogo/subcomponents/ResponsiveLayoutWithLogoLanguageSwitcher';
import { FC } from 'react';
import { getAppConfig } from '@bts-web/core-app-config';

export interface InfoLayoutProps
  extends IntroHeaderProps,
    React.PropsWithChildren {}

const infoLayoutContainerStyles = css({
  backgroundColor: 'neutrals.fill_quinary',
  borderRadius: {
    base: 0,
    lg: 8,
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
  px: 'medium',
  paddingTop: 'extra_large',
});

const InfoLayoutMobileHeader: FC<{ showLanguageSwitcher?: boolean }> = ({
  showLanguageSwitcher,
}) => {
  const { appLogo } = getAppConfig();

  const LogoComponent = appLogo;

  if (showLanguageSwitcher) {
    return (
      <HiddenDesktop>
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          })}
        >
          <LogoComponent size={23} />
          <ResponsiveLayoutWithLogoLanguageSwitcher contrastVariant />
        </div>
      </HiddenDesktop>
    );
  }

  return (
    <HiddenDesktop>
      <LogoComponent size={23} />
    </HiddenDesktop>
  );
};

export const InfoLayout = ({
  children,
  showLanguageSwitcher,
  ...introHeaderProps
}: InfoLayoutProps) => (
  <ResponsiveLayoutWithLogo showLanguageSwitcher={showLanguageSwitcher}>
    <div className={infoLayoutContainerStyles}>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'medium',
        })}
      >
        <InfoLayoutMobileHeader showLanguageSwitcher={showLanguageSwitcher} />

        <IntroHeader {...introHeaderProps} />
      </div>
      {children}
    </div>
  </ResponsiveLayoutWithLogo>
);
