import { SkeletonElement } from './SkeletonElement/SkeletonElement';
import { hstack, stack } from '@bts-web/utils-style-engine';

export const CellSkeleton = () => (
  <div
    className={stack({
      gap: 'small',
      padding: 'medium',
      width: '100%',
    })}
    data-testid="transaction-item-skeleton-container"
  >
    <div className={hstack({ justifyContent: 'space-between' })}>
      <div className={hstack()}>
        <SkeletonElement width="24px" height="24px" borderRadius="30px" />

        <div className={stack()}>
          <SkeletonElement width="103px" height="20px" borderRadius="30px" />
          <SkeletonElement width="69px" height="18px" borderRadius="30px" />
        </div>
      </div>

      <div className={hstack()}>
        <div className={stack()}>
          <SkeletonElement width="103px" height="20px" borderRadius="30px" />
          <SkeletonElement width="103px" height="20px" borderRadius="30px" />
        </div>

        <SkeletonElement width="24px" height="24px" borderRadius="30px" />
      </div>
    </div>
  </div>
);
