import {
  intlAssetValueFormatting,
  getRoundedPrecision,
  PrecisionFloat,
  convertToLocaleDateString,
  intlFiatValueFormatting,
} from '@bts-web/utils-formatting';
import { Locale } from '@bts-web/utils-lokalise';

export const getTransactionTime = ({
  locale,
  time,
}: {
  time: string;
  locale: Locale;
}) => {
  return convertToLocaleDateString(time, locale);
};

export const formatAssetAmount = (
  assetAmount: PrecisionFloat,
  locale: Locale,
) => {
  if (!assetAmount) {
    return '';
  }

  return intlAssetValueFormatting(getRoundedPrecision(assetAmount), {
    locale,
    fractionDigits: assetAmount.precision,
  });
};

export const formatFiatAmount = (
  fiatAmount: PrecisionFloat,
  isBuyOrSavingsPlan: boolean,
  isGiveaway: boolean,
  isOutgoing: boolean,
  isCryptoTransferTransaction: boolean,
  opts: {
    locale: Locale;
    currency: string;
  },
) => {
  const sign = isBuyOrSavingsPlan || (isGiveaway && isOutgoing) ? '-' : '+';

  return `${isCryptoTransferTransaction ? '' : sign}${intlFiatValueFormatting(
    getRoundedPrecision(fiatAmount),
    {
      currency: opts.currency,
      locale: opts.locale,
    },
  )}`;
};

export const getAssetAmountText = (
  assetAmount: string,
  amountLabel: string,
  assetType: string,
  assetSymbol: string | undefined,
) => {
  return assetType === 'MetalAsset'
    ? `${assetAmount} ${amountLabel}`
    : `${assetAmount} ${assetSymbol}`;
};
