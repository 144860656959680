'use client';

import {
  useComposableDrawer,
  useComposableModal,
} from '@bts-web/utils-context';
import { css } from '@bts-web/utils-style-engine';
import { FC } from 'react';
import { ButtonBase } from '../ButtonBase/ButtonBase';
import { SetupTwoFaModal } from './twoFaModals/SetupTwoFaModal/SetupTwoFaModal';
import { UserAccessModal } from './UserAccessModal/UserAccessModal';
import { TriggerResetTwoFaModal } from './twoFaModals/TriggerResetTwoFaModal/TriggerResetTwoFaModal';
import { ResetTwoFaModal } from './twoFaModals/ResetTwoFaModal/ResetTwoFaModal';
import { TriggerSetupTwoFaModal } from './twoFaModals/TriggerSetupTwoFaModal/TriggerSetupTwoFaModal';
import { Drawer } from '../../../layouts/Drawer/Drawer';
import { AccessModalInfoType } from '../../utils/userAccess';

const ComposableModalPlayGround: FC = () => {
  const { showModal } = useComposableModal();

  const { openDrawer } = useComposableDrawer();

  return (
    <div
      className={css({
        display: 'flex',
        flexWrap: 'wrap',
        padding: '10px',
        gap: '10px',
      })}
    >
      <ButtonBase onClick={() => openDrawer(Drawer)}>Show Drawer</ButtonBase>

      <ButtonBase onClick={() => showModal(TriggerSetupTwoFaModal)}>
        Setup trigger logout Two FA Modal
      </ButtonBase>
      <ButtonBase onClick={() => showModal(ResetTwoFaModal)}>
        Reset Two FA Modal
      </ButtonBase>
      <ButtonBase onClick={() => showModal(TriggerResetTwoFaModal)}>
        Trigger Reset Two FA Modal
      </ButtonBase>
      <ButtonBase onClick={() => showModal(SetupTwoFaModal)}>
        Setup Two FA Modal
      </ButtonBase>
      <ButtonBase
        onClick={() =>
          showModal(UserAccessModal, {
            type: AccessModalInfoType.InfoApprovalPending,
          })
        }
      >
        User access : Info Approval Pending
      </ButtonBase>
      <ButtonBase
        onClick={() =>
          showModal(UserAccessModal, {
            type: AccessModalInfoType.ReadOnly,
          })
        }
      >
        User access : read only
      </ButtonBase>
      <ButtonBase
        onClick={() =>
          showModal(UserAccessModal, {
            type: AccessModalInfoType.WarningTrade,
          })
        }
      >
        User access : warning trade
      </ButtonBase>
      <ButtonBase
        onClick={() =>
          showModal(UserAccessModal, {
            type: AccessModalInfoType.WarningTrade,
          })
        }
      >
        User access : warning transfer
      </ButtonBase>
    </div>
  );
};

export { ComposableModalPlayGround };
