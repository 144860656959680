'use client';

import { convertToLocaleDateString } from '@bts-web/utils-formatting';
import { Locale, useClientTranslation } from '@bts-web/utils-lokalise';
import { FC } from 'react';

export const RenderTransactionDateHour: FC<{
  time: string;
  locale: Locale;
}> = ({ time, locale }) => {
  const { t } = useClientTranslation();

  return t('tx_details_date_hour', {
    date: convertToLocaleDateString(time, locale),
    time: new Date(time).toLocaleTimeString(locale),
  });
};
