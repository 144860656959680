import { t, TFunction } from 'i18next';
import { Suspense } from 'react';
import { getTransactionsTranslations } from '../utils/getTransactionsTranslations';
import { getTransactions } from '../../common/gqlActions/getTransactions.action';
import { updateServerLanguage } from '@bts-web/utils-lokalise';
import { InfiniteScrollTransactionListWrapper } from '../InfiniteScrollTransactionListWrapper/InfiniteScrollTransactionListWrapper.use-client';
import { ContentSectionWrapper } from '../../layouts';
import { TransactionsPageHeader } from '../TransactionsPageHeader/TransactionsPageHeader.server';
import { TransactionsPageStickyBreadcrumbs } from '../TransactionsPageStickyBreadcrumbs/TransactionsPageStickyBreadcrumbs.server';
import { TRANSACTION_LIST_PAGE_SIZE } from '../../config';
import { CellSkeleton } from '../../common/components/loading/CellSkeleton';
import { TransactionsViewWithCryptoTransfersQuery$data } from '@bts-web/data-layer/server';
import { CryptoTransfersPendingProcessingList } from '../CryptoTransfersPendingProcessingList/CryptoTransfersPendingProcessingList';
import { css } from '@bts-web/utils-style-engine';
import { Status } from '../../common';
import { getAppConfig } from '@bts-web/core-app-config';

export type TransactionsControllerProps = {
  searchParams: Promise<Record<string, string>>;
};

export const TransactionsController = async ({
  searchParams,
}: TransactionsControllerProps) => {
  const { feature_hasCryptoTransfersEnabled } = getAppConfig();

  await updateServerLanguage();

  const { assetId } = await searchParams;

  const translations = getTransactionsTranslations(t as TFunction);

  const initialTransactions = await getTransactions(
    {
      input: {
        first: TRANSACTION_LIST_PAGE_SIZE,
        assetId,
      },
    },
    feature_hasCryptoTransfersEnabled,
  );

  const responseData = initialTransactions.data;

  const pendingCryptoTransfers: NonNullable<
    NonNullable<
      NonNullable<
        TransactionsViewWithCryptoTransfersQuery$data['cryptoTransfersTransactions']
      >['edges']
    >
  > =
    'cryptoTransfersTransactions' in responseData
      ? (responseData.cryptoTransfersTransactions?.edges ?? [])
      : [];

  const showDetailedSectionedView =
    pendingCryptoTransfers.length > 0 && feature_hasCryptoTransfersEnabled;

  return (
    <div
      className={css({
        background: 'screen_background.primary',
        minHeight: 'calc(100vh - 50px)',
      })}
    >
      <TransactionsPageStickyBreadcrumbs
        translations={{
          navBarPortfolio: translations.navBarPortfolio,
          transactions: translations.transactions,
        }}
      />

      <ContentSectionWrapper
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'small',
        })}
      >
        <TransactionsPageHeader
          translations={{
            transactions: translations.transactions,
            transactionDescription: translations.transactionDescription,
            amount: translations.amount,
          }}
        />

        {showDetailedSectionedView && (
          <>
            <Status
              visual="warning"
              text={translations.pending}
              className={css({
                alignSelf: 'start',
              })}
            />
            <CryptoTransfersPendingProcessingList
              transactions={{ edges: pendingCryptoTransfers }}
            />
          </>
        )}
        {showDetailedSectionedView && (
          <Status
            visual="positive"
            text={translations.completed}
            className={css({
              alignSelf: 'start',
            })}
          />
        )}

        <Suspense
          fallback={
            <>
              <CellSkeleton />
              <CellSkeleton />
            </>
          }
        >
          <InfiniteScrollTransactionListWrapper
            initialTransactions={initialTransactions.data.transactions}
            assetId={assetId}
            itemsPerPage={TRANSACTION_LIST_PAGE_SIZE}
            translations={translations}
          />
        </Suspense>
      </ContentSectionWrapper>
    </div>
  );
};
