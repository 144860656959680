import { css } from '@bts-web/utils-style-engine';
import { ReactNode } from 'react';

export interface TransactionDetailsTitleProps {
  title: ReactNode;
  subtitle: ReactNode;
}

export const TransactionDetailsTitle = ({
  title,
  subtitle,
}: TransactionDetailsTitleProps) => {
  return (
    <div
      className={css({
        display: 'flex',
        gap: 'extra_small_2',
        flexDirection: 'column',
        px: 'medium',
        width: '100%',
      })}
      data-testid="transaction-title"
    >
      <h1
        className={css({
          color: 'neutrals.text_primary',
          lineHeight: 'headline.small_medium',
          fontWeight: 'headline.small_medium',
          letterSpacing: 'headline.small_medium',
          fontSize: 'headline.small_medium',
        })}
      >
        {title}
      </h1>

      <div
        className={css({
          color: 'neutrals.text_secondary ',
          fontSize: 'headline.medium_medium',
          lineHeight: 'headline.medium_medium',
          letterSpacing: 'headline.medium_medium',
        })}
      >
        {subtitle}
      </div>
    </div>
  );
};
