import { css } from '@bts-web/utils-style-engine';
import { Icon, IconThemes } from '@bts-web/design-system/component/icon';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { TFunction } from 'i18next';
import { ButtonBase, DialogBase, HyperLinkButtonBase } from '../../common';
import { getSavingsPlansTranslations } from '../utils/getSavingsPlansTranslations';
import { SavingsPlansListTranslationsKeys } from '../types';

const container = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'small',
  height: '100%',
});

const modalContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'small',
  pb: 'small',
  height: '100%',
});

const modalSectionStyles = css({
  display: 'flex',
  gap: 'small',
});

const modalSectionTextStyles = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'baseline',
  gap: 'extra_small_2',

  '& > h3': {
    color: 'neutrals.text_primary',
    fontSize: 'title.medium_medium',
    fontWeight: 'title.medium_medium',
    lineHeight: 'title.medium_medium',
    letterSpacing: 'title.medium_medium',
  },

  '& > span': {
    color: 'neutrals.text_secondary',
    fontSize: 'body.medium',
    fontWeight: 'body.medium',
    lineHeight: 'body.medium',
    letterSpacing: 'body.medium',
    textAlign: 'left',
  },
});

const buttonsContainer = css({
  marginTop: 'auto',
  display: 'flex',
  flexDirection: {
    base: 'column',
    md: 'row',
  },
  gap: 'extra_small',
  flexWrap: 'wrap',
  width: '500px',
  maxWidth: '100%',
  mx: 'auto',
});

export interface StartSavingModalProps {
  onClose: () => void;
  continueUrl: string;
}

interface SectionData {
  icon: string;
  iconPrefix?: string;
  theme?: string;
  titleKey: string;
  descriptionKey: string;
}

const sections: SectionData[] = [
  {
    icon: 'gear-outline',
    iconPrefix: 'mdi',
    titleKey: 'startSavingSectionTitleOne',
    descriptionKey: 'startSavingSectionDescriptionOne',
  },
  {
    icon: 'lightbulb',
    theme: 'regular',
    titleKey: 'startSavingSectionTitleTwo',
    descriptionKey: 'startSavingSectionDescriptionTwo',
  },
  {
    icon: 'data-trending',
    theme: 'regular',
    titleKey: 'startSavingSectionTitleThree',
    descriptionKey: 'startSavingSectionDescriptionThree',
  },
];

const StartSavingModal = ({ onClose, continueUrl }: StartSavingModalProps) => {
  const { t } = useClientTranslation();

  const translations = getSavingsPlansTranslations(t as TFunction);

  return (
    <DialogBase
      title={translations.startSavingTitle}
      id="start-saving-plans"
      visual="secondary"
      size="small"
      open
      animate="top"
      onOpenChange={onClose}
    >
      <div className={container}>
        <div className={modalContainerStyles}>
          {sections.map((section, index) => (
            <section key={index} className={modalSectionStyles}>
              <Icon
                icon={section.icon}
                iconPrefix={section.iconPrefix}
                theme={section.theme as IconThemes}
                width={24}
                height={24}
              />
              <div className={modalSectionTextStyles}>
                <h3>
                  {
                    translations[
                      section.titleKey as unknown as keyof SavingsPlansListTranslationsKeys
                    ]
                  }
                </h3>
                <span>
                  {
                    translations[
                      section.descriptionKey as unknown as keyof SavingsPlansListTranslationsKeys
                    ]
                  }
                </span>
              </div>
            </section>
          ))}
        </div>
        <div className={buttonsContainer}>
          <HyperLinkButtonBase
            className={css({ flex: 1, maxWidth: '100%' })}
            visual="primary"
            to={continueUrl}
            onClick={onClose}
          >
            {translations.continue}
          </HyperLinkButtonBase>
          <ButtonBase
            className={css({
              flex: 1,
              maxWidth: '100%',
            })}
            visual="secondary"
            onClick={onClose}
            size="large"
          >
            {translations.goBack}
          </ButtonBase>
        </div>
      </div>
    </DialogBase>
  );
};

export { StartSavingModal };
