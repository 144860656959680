'use client';

import { FC, ReactNode } from 'react';
import { useRouter } from 'next/navigation';
import {
  ButtonBase,
  UserAccessWithRoleCheck,
  RoleCheckActionType,
} from '../../../../common';

export const BuySellButton: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const router = useRouter();

  return (
    <UserAccessWithRoleCheck actionType={RoleCheckActionType.TRADE}>
      <ButtonBase
        onClick={() => router.push('/assets/discover')}
        size="small"
        visual="accent"
      >
        {children}
      </ButtonBase>
    </UserAccessWithRoleCheck>
  );
};
