/**
 * @generated SignedSource<<f2a6cb13f81de55ee9627ed599632079>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserLanguage = "DE" | "EN" | "ES" | "FR" | "IT" | "PL" | "TR";
export type UpdateBusinessUserInput = {
  aml?: ReadonlyArray<UserAML> | null;
  cookiePreferences?: CookiePreferencesInput | null;
  email?: string | null;
  language?: UserLanguage | null;
  risk?: ReadonlyArray<BusinessUserRisks> | null;
  termsAndConditions?: ReadonlyArray<string> | null;
};
export type UserAML = {
  answerId: string;
  questionId: string;
};
export type BusinessUserRisks = {
  answerId: string;
  questionId: string;
};
export type CookiePreferencesInput = {
  marketing: boolean;
  performance: boolean;
};
export type UpdateBusinessUserMutation$variables = {
  input?: UpdateBusinessUserInput | null;
};
export type UpdateBusinessUserMutation$data = {
  readonly updateBusinessUser: {
    readonly active?: boolean;
    readonly approved?: boolean;
    readonly id: string;
    readonly language: UserLanguage;
    readonly onboarded: boolean;
    readonly tradingActive?: boolean;
    readonly withdrawalsActive?: boolean;
  } | null;
};
export type UpdateBusinessUserMutation = {
  response: UpdateBusinessUserMutation$data;
  variables: UpdateBusinessUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "onboarded",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "language",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tradingActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "approved",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "withdrawalsActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    }
  ],
  "type": "BusinessUser",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateBusinessUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateBusinessUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateBusinessUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateBusinessUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ff90351d32c0dea7082419d257bc5513",
    "id": null,
    "metadata": {},
    "name": "UpdateBusinessUserMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateBusinessUserMutation(\n  $input: UpdateBusinessUserInput\n) {\n  updateBusinessUser(input: $input) {\n    __typename\n    id\n    onboarded\n    language\n    ... on BusinessUser {\n      tradingActive\n      approved\n      withdrawalsActive\n      active\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "74bc804dfae1eb8e2a9c514dea18521e";

export default node;
