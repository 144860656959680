/**
 * @generated SignedSource<<7faad2b8470f65cd6dcd5ad3feab4527>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Appropriateness = "APPROPRIATE" | "NOT_APPROPRIATE" | "NOT_EXPERIENCED" | "UNKNOWN";
export type UserDetailsQuery$variables = {};
export type UserDetailsQuery$data = {
  readonly me: {
    readonly appropriateness?: Appropriateness | null;
    readonly appropriatenessRequiresTestCompletion?: boolean | null;
    readonly appropriatenessRequiresTestResubmission?: boolean | null;
    readonly hasTermsNotAccepted?: boolean;
    readonly tradingActive?: boolean;
  } | null;
};
export type UserDetailsQuery = {
  response: UserDetailsQuery$data;
  variables: UserDetailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appropriateness",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appropriatenessRequiresTestCompletion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appropriatenessRequiresTestResubmission",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tradingActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasTermsNotAccepted",
      "storageKey": null
    }
  ],
  "type": "IndividualUser",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0587dafab845ccdb3b0985655907c021",
    "id": null,
    "metadata": {},
    "name": "UserDetailsQuery",
    "operationKind": "query",
    "text": "query UserDetailsQuery {\n  me {\n    __typename\n    ... on IndividualUser {\n      appropriateness\n      appropriatenessRequiresTestCompletion\n      appropriatenessRequiresTestResubmission\n      tradingActive\n      hasTermsNotAccepted\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6b91ce7f17094f43041e9ecafbf2dcc1";

export default node;
