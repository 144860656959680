import {
  getPrecisionFloatValue,
  getRoundedPrecision,
  intlAssetValueFormatting,
  intlFiatValueFormatting,
} from '@bts-web/utils-formatting';
import { Icon } from '@bts-web/design-system/component/icon';
import { css } from '@bts-web/utils-style-engine';
import { FC } from 'react';
import { NonNullableTransactionFinalizedSummaryData } from '../../../utils';
import { getAppConfig } from '@bts-web/core-app-config';
import { initServerTranslation } from '@bts-web/utils-lokalise';

const warningTextStyle = css({
  color: 'warning.text_primary',
  fontSize: 'caption.small',
  lineHeight: 'caption.small',
  letterSpacing: 'caption.small',
  gap: 'extra_small_2',
  maxWidth: '200px',
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
});

const successSellSubtitlesStyles = css({
  display: 'flex',
  flexDirection: 'column',
  fontSize: 'body.medium',
  gap: 'extra_small',
  color: 'neutrals.text_secondary',
});

const successSellTitleHighlight = css({
  color: 'neutrals.text_primary',
});

const taxWithHoldingStatusStyles = css({
  paddingTop: 'extra_small_3',
  paddingInlineEnd: 'extra_small_2',
  paddingBottom: 'extra_small_3',
  paddingInlineStart: 'extra_small_2',
  fontSize: 'caption.medium_medium',
  fontWeight: 'caption.medium_medium',
  lineHeight: 'caption.medium_medium',
  letterSpacing: 'caption.medium_medium',
  bg: 'neutrals.fill_quinary',
  color: 'neutrals.text_primary',
  borderRadius: '32px',
});

export const SuccessSellContent: FC<{
  currentLocale: string;
  hasTaxOfflineWarning?: boolean;
  transactionData: NonNullableTransactionFinalizedSummaryData;
  translatedValues: {
    successSellFirstLine: string;
    warningTransaction: string;
  };
}> = ({
  currentLocale,
  transactionData,
  hasTaxOfflineWarning,
  translatedValues,
}) => {
  const { t } = initServerTranslation();

  const { additionalSellConfirmationText, currency } = getAppConfig();

  const lbbwAdditionalLine = additionalSellConfirmationText
    ? t(additionalSellConfirmationText as any)
    : null;

  const { successSellFirstLine, warningTransaction } = translatedValues;

  const assetAmount = intlAssetValueFormatting(
    getPrecisionFloatValue(transactionData?.assetAmount),
    {
      locale: currentLocale,
      fractionDigits: transactionData?.assetAmount?.precision,
    },
  );

  const totalAmount = intlFiatValueFormatting(
    getRoundedPrecision(transactionData?.totalAmount),
    {
      locale: currentLocale,
      currency,
    },
  );

  const taxAmount = intlFiatValueFormatting(
    getRoundedPrecision(transactionData?.taxAmount),
    {
      locale: currentLocale,
      currency,
    },
  );

  return (
    <div
      className={successSellSubtitlesStyles}
      data-testid="sell-success-content"
    >
      {transactionData?.asset ? (
        <p>
          {successSellFirstLine}
          <span className={successSellTitleHighlight}>
            {t('success_sale_subtitle_line_2', {
              amount: assetAmount,
              assetname: transactionData.asset.name,
              fiatamount: totalAmount,
              interpolation: {
                escapeValue: false,
              },
            })}
          </span>
          {lbbwAdditionalLine ? (
            <>
              <br />
              <br />
              <span>{lbbwAdditionalLine}</span>
            </>
          ) : null}
        </p>
      ) : null}

      {Number(transactionData?.taxAmount?.value) > 0 && (
        <div>
          <span data-testid="tax-amount" className={taxWithHoldingStatusStyles}>
            {t('amount_tax_withheld', {
              taxamount: `≈ ${taxAmount}`,
            })}
          </span>
        </div>
      )}

      {hasTaxOfflineWarning && (
        <div className={warningTextStyle}>
          <Icon theme="regular" icon="warning" width="12" height="13" />
          <span>{warningTransaction}</span>
        </div>
      )}
    </div>
  );
};
