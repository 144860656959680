import { FC, PropsWithChildren } from 'react';
import {
  UserInfoProvider,
  UserInfoMountCheckActions,
  AppUserFullInfo,
} from '@bts-web/core-features/common';
import { NotificationContextProvider } from '@bts-web/core-features/notifications';
import { updateServerLanguage } from '@bts-web/utils-lokalise';
import { ComposableDrawerContextProvider } from '../ComposableDrawerContext/ComposableDrawerContext';
import { ComposableModalContextProvider } from '../ComposableModalContext/ComposableModalContext';
import { TradeProvider } from '../TradeContext/TradeContext.use-client';
import { TermsAndConditionsModalController } from '@bts-web/core-features/terms-and-conditions';

const ProvidersParent: FC<
  PropsWithChildren<{ userInfoData: AppUserFullInfo }>
> = async ({ children, userInfoData }) => {
  await updateServerLanguage();

  const { onboarded, hasTermsNotAccepted, isReadOnlyUser } = userInfoData;

  const userHasUnacceptedTerms =
    onboarded && hasTermsNotAccepted && !isReadOnlyUser;

  return (
    // Order: everything should be able to trigger a notification
    <NotificationContextProvider>
      <UserInfoProvider data={userInfoData}>
        <ComposableModalContextProvider>
          {userHasUnacceptedTerms && <TermsAndConditionsModalController />}
          <UserInfoMountCheckActions />
          <TradeProvider>
            <ComposableDrawerContextProvider>
              {children}
            </ComposableDrawerContextProvider>
          </TradeProvider>
        </ComposableModalContextProvider>
      </UserInfoProvider>
    </NotificationContextProvider>
  );
};

export { ProvidersParent };
