import { FC, ReactNode } from 'react';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import { getAppConfig } from '@bts-web/core-app-config';
import { css } from '@bts-web/utils-style-engine';
import { Icon } from '@bts-web/design-system/component/icon';
import { BuySellButton } from './subcomponents/BuySellButton';
import { ReadOnlyTag } from '../../../common/components/ReadOnlyTag/ReadOnlyTag';
import { TopBarMenuButton } from './subcomponents/TopBarMenuButton/TopBarMenuButton';
import { type IMenuProps, Menu } from '../../../common/components/Menu/Menu';
import { IMainNavigationTranslations } from '../utils/getMainNavigationTranslations';
import { TransfersSkeleton } from '../../../crypto-transfers/components/TransfersParent/TransfersSkeleton';
import { UserTopBarMenu } from './subcomponents/UserTopBarMenu/UserTopBarMenu';
import { TopbarLanguageMenuWrapper } from './subcomponents/TopbarLanguageMenuWrapper/TopbarLanguageMenuWrapper';
import { i18n, Language } from '@bts-web/utils-lokalise';
import { CryptoTransfersDrawer } from '../../../crypto-transfers/components/CryptoTransfersDrawer/CryptoTransfersDrawer';
import { NotificationsBell } from '../../../common/components/NotificationsBell/NotificationsBell';

const TransfersLoaderDynamic = dynamic(
  () =>
    import(
      '../../../crypto-transfers/components/TransfersLoader/TransfersLoader'
    ).then((comp) => comp.TransfersLoader),
  {
    loading: () => <TransfersSkeleton />,
  },
);

export const appHeaderWrapperStyles = {
  display: 'flex',
  bgColor: 'neutrals.card_fill_primary',
  position: 'fixed',
  top: 0,
  insetInlineStart: 0,
  insetInlineEnd: 0,
  zIndex: 2,
  mb: 'extra_small_2',
  minHeight: `71px`,
  maxHeight: '71px',
};

const dropdownMenuProps: IMenuProps['dropdownMenuProps'] = {
  style: {
    width: '370px',
    maxWidth: `calc(100vw - 40px)`,
    backgroundColor: 'transparent',
    borderRadius: '12px',
  },
  align: 'end',
  sideOffset: 5,
  sticky: 'always',
  updatePositionStrategy: 'always',
};

export const DesktopTopHeaderBar: FC<{
  pageTitleElement: ReactNode;
  hideTopBarTradeButton: boolean;
  translatedValues: IMainNavigationTranslations;
}> = ({ pageTitleElement, hideTopBarTradeButton, translatedValues }) => {
  const {
    feature_hasCryptoTransfersEnabled,
    feature_showNotifications,
    appLogo,
  } = getAppConfig();

  const defaultSelectedLanguage = i18n.resolvedLanguage as Language;

  const LogoElement = appLogo;

  return (
    <header
      className={`compensateMarginRightWhenScrollingIsBlocked ${css(appHeaderWrapperStyles)}`}
      data-testid="desktop header bar"
    >
      <div
        className={css({
          p: '20px',
          paddingInlineEnd: '20px',
          borderInlineEndWidth: '1px',
          borderColor: 'neutrals.fill_quaternary',
          maxWidth: '72px',
        })}
      >
        <Link
          aria-label="bitpanda logo"
          href={`/`}
          className={css({
            width: '32px',
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          })}
        >
          <LogoElement size={22} />
        </Link>
      </div>

      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid',
          borderColor: 'neutrals.stroke_secondary',
          minHeight: '100%',
          flex: 1,
          py: 'extra_small_2',
          px: 'medium',
          gap: 'extra_small_2',
        })}
      >
        <div>{pageTitleElement}</div>

        <ReadOnlyTag />

        <div
          className={css({
            px: 'medium',
            borderInlineEndWidth: '1px',
            borderColor: 'neutrals.fill_quaternary',
            display: 'flex',
            gap: '8px',
            marginInlineStart: 'auto',
            marginInlineEnd: 'medium',
          })}
        >
          {feature_hasCryptoTransfersEnabled && (
            <CryptoTransfersDrawer
              contentNode={<TransfersLoaderDynamic />}
              buttonTransferTopNav={translatedValues.buttonTransferTopNav}
            />
          )}

          {!hideTopBarTradeButton && (
            <BuySellButton>{translatedValues.buySellButtonText}</BuySellButton>
          )}
        </div>

        <div
          className={css({
            display: 'flex',
            gap: 'extra_small_2',
            alignItems: 'center',
          })}
        >
          <TopbarLanguageMenuWrapper
            translations={translatedValues}
            defaultLanguage={defaultSelectedLanguage}
          />

          {feature_showNotifications && <NotificationsBell />}

          <Menu
            components={{
              buttonElement: (
                <TopBarMenuButton ariaLabel="open profile settings menu">
                  <div
                    className={css({
                      backgroundColor: 'neutrals.fill_quaternary',
                      color: 'neutrals.fill_secondary',
                      borderRadius: '100%',
                      p: 'extra_small_2',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    })}
                  >
                    <Icon icon="person" size="24" theme="regular" />
                  </div>
                </TopBarMenuButton>
              ),
              menuContent: (
                <UserTopBarMenu
                  translatedValues={translatedValues}
                  isWithinDropdown
                />
              ),
            }}
            dropdownMenuProps={dropdownMenuProps}
          />
        </div>
      </div>
    </header>
  );
};
