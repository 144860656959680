/**
 * @generated SignedSource<<761c724892ffd76097e654932e48f5ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CryptoAddressHostType = "KNOWN_VASP" | "SELF_HOSTED" | "UNKNOWN_VASP";
export type CryptoWithdrawalStatus = "CANCELED" | "FINISHED" | "PENDING" | "PROCESSING";
export type CreateCryptoWithdrawalInput = {
  assetAmount: string;
  externalAddressID: string;
};
export type CreateCryptoWithdrawalOfferMutation$variables = {
  input?: CreateCryptoWithdrawalInput | null;
};
export type CreateCryptoWithdrawalOfferMutation$data = {
  readonly createCryptoWithdrawal: {
    readonly address: {
      readonly address: string;
      readonly asset: {
        readonly id: string;
        readonly logoUrl: string | null;
        readonly name: string | null;
      };
      readonly createdAt: any;
      readonly destinationTag: string | null;
      readonly destinationTagType: string | null;
      readonly hostName: string | null;
      readonly hostType: CryptoAddressHostType | null;
      readonly id: string;
      readonly network: {
        readonly id: string;
        readonly logoUrl: string | null;
        readonly name: string | null;
      };
      readonly ownerLegalName: string | null;
      readonly userOwned: boolean;
    } | null;
    readonly assetAmount: PrecisionFloat | null;
    readonly assetFee: PrecisionFloat | null;
    readonly fiatAmount: PrecisionFloat | null;
    readonly fiatAmountFee: PrecisionFloat | null;
    readonly id: string;
    readonly status: CryptoWithdrawalStatus | null;
    readonly time: any;
    readonly transactionHash: string | null;
  } | null;
};
export type CreateCryptoWithdrawalOfferMutation = {
  response: CreateCryptoWithdrawalOfferMutation$data;
  variables: CreateCryptoWithdrawalOfferMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "destinationTag",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "destinationTagType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hostType",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hostName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logoUrl",
  "storageKey": null
},
v11 = [
  (v2/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Network",
  "kind": "LinkedField",
  "name": "network",
  "plural": false,
  "selections": (v11/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userOwned",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ownerLegalName",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assetAmount",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assetFee",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fiatAmount",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fiatAmountFee",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "transactionHash",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "time",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCryptoWithdrawalOfferMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CryptoWithdrawal",
        "kind": "LinkedField",
        "name": "createCryptoWithdrawal",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CryptoAddress",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "asset",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCryptoWithdrawalOfferMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CryptoWithdrawal",
        "kind": "LinkedField",
        "name": "createCryptoWithdrawal",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CryptoAddress",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "asset",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8c0bd82970a40cd0107c8547a4f34cd7",
    "id": null,
    "metadata": {},
    "name": "CreateCryptoWithdrawalOfferMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCryptoWithdrawalOfferMutation(\n  $input: CreateCryptoWithdrawalInput\n) {\n  createCryptoWithdrawal(input: $input) {\n    id\n    address {\n      id\n      address\n      destinationTag\n      destinationTagType\n      createdAt\n      hostType\n      hostName\n      network {\n        id\n        name\n        logoUrl\n      }\n      asset {\n        __typename\n        id\n        name\n        logoUrl\n      }\n      userOwned\n      ownerLegalName\n    }\n    assetAmount\n    assetFee\n    fiatAmount\n    fiatAmountFee\n    status\n    transactionHash\n    time\n  }\n}\n"
  }
};
})();

(node as any).hash = "8c18941c3205108f58f5455031d0ff26";

export default node;
