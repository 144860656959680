import { useClientTranslation } from '@bts-web/utils-lokalise';
import { FC } from 'react';
import { TwoFaSectionTitle } from '../../../shared/TwoFaSectionTitle';
import { TwoFaSectionDescription } from '../../../shared/TwoFaSectionDescription';
import { DeleteTwoFaOnClose } from '../../utils/types';
import { TwoFaSectionButtonsWrapper } from '../../../shared/TwoFaSectionButtonsWrapper';
import { ButtonBase } from '../../../../../ButtonBase/ButtonBase';

const ResetTwoFaConfirmationScreen: FC<{
  onClose: DeleteTwoFaOnClose;
}> = ({ onClose }) => {
  const { t } = useClientTranslation();

  return (
    <>
      <TwoFaSectionTitle title={`${t('2fa_reset_success_description')}`} />
      <TwoFaSectionDescription
        textContent={`${t('2fa_reset_success_description_body')}`}
      />
      <TwoFaSectionButtonsWrapper>
        <ButtonBase onClick={onClose} visual="primary" size="large" fullWidth>
          {t('done')}
        </ButtonBase>
      </TwoFaSectionButtonsWrapper>
    </>
  );
};

export { ResetTwoFaConfirmationScreen };
