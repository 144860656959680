import { css } from '@bts-web/utils-style-engine';
import { SkeletonElement } from '../../../common/components/loading/SkeletonElement/SkeletonElement';

const DesktopLeftNavSkeleton = () => {
  return (
    <nav
      className={css({
        display: 'flex',
        flexDirection: 'column',
        fontSize: '12px',
        alignItems: 'center',
        position: 'fixed',
        top: '72px',
        insetInlineStart: 0,
        bgColor: 'neutrals.card_fill_primary',
        height: 'calc(100vh - 72px)',
        width: '72px',
        py: '26px',
        px: 'extra_small_2',
        borderInlineEnd: '1px solid',
        borderColor: 'neutrals.stroke_secondary',
        gap: 'small',
      })}
      data-testid="desktop-left-nav-skeleton"
    >
      <SkeletonElement />
      <SkeletonElement />
    </nav>
  );
};

export { DesktopLeftNavSkeleton };
