import { memo } from 'react';
import { useTradeForm } from '../hooks/useTradeForm/useTradeForm';

export const HiddenInputs = memo(
  ({
    formState,
  }: {
    formState: ReturnType<typeof useTradeForm>['formState'];
  }) => (
    <>
      <input
        type="hidden"
        data-testid="trade-input-amount"
        name="amount"
        value={formState.inputValue}
      />
      <input
        type="hidden"
        data-testid="trade-input-amountFor"
        name="amountFor"
        value={formState.displayType}
      />
      <input
        type="hidden"
        data-testid="trade-savingsPlanRecurringDate"
        name="savingsPlanRecurringDate"
        value={formState.savingsPlanRecurringDate}
      />
    </>
  ),
);
