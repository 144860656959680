import { useState } from 'react';
import { TimeAmounts, RangeSelectorButtonsDomIdsValues } from '../utils';

type TabConfigItem = {
  timeAmountId: TimeAmounts;
  text: string;
  domId: RangeSelectorButtonsDomIdsValues;
};

export interface TabBarMiniProps {
  className: string;
  tabsConfig: TabConfigItem[];
  onClickCallback: (selectedTimeRange: TimeAmounts) => void;
  isDisabled?: boolean;
}

const TabBarMini = ({
  className,
  tabsConfig,
  onClickCallback,
  isDisabled = false,
}: TabBarMiniProps) => {
  const [selectedButtonId, setSelectedButtonId] =
    useState<RangeSelectorButtonsDomIdsValues>(tabsConfig[0].domId);

  const setSelectedAndTriggerCallback = (
    domId: RangeSelectorButtonsDomIdsValues,
    timeAmountId: TimeAmounts,
  ) => {
    if (selectedButtonId === domId) {
      return;
    }

    setSelectedButtonId(domId);

    onClickCallback(timeAmountId);
  };

  return (
    <div className={className} data-testid="tabBar-mini">
      {tabsConfig.map(({ timeAmountId, text, domId }: TabConfigItem) => (
        <button
          type="button"
          disabled={isDisabled}
          key={timeAmountId}
          data-selected={!isDisabled && selectedButtonId === domId}
          id={domId}
          onClick={() => setSelectedAndTriggerCallback(domId, timeAmountId)}
          data-testid="tabBar-mini-button"
        >
          {text}
        </button>
      ))}
    </div>
  );
};

export { TabBarMini };
