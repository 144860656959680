import {
  LegalClassification,
  TransactionStatus,
  CryptoTransfersTransactionStatus,
} from '@bts-web/data-layer/server';
import { ComponentProps } from 'react';
import {
  getRoundedPrecision,
  intlAssetValueFormatting,
  intlFiatValueFormatting,
  PrecisionFloat,
} from '@bts-web/utils-formatting';
import { TFunction } from 'i18next';
import { Locale } from '@bts-web/utils-lokalise';
import {
  TransactionDetailsField,
  TransactionDetailsFieldVariants,
} from '../TransactionDetailsField/TransactionDetailsField';
import { TransactionDetailsTransactionKeysResult } from './getTransactionDetailsTranslations';
import {
  AllTypeTransactionStatus,
  CryptoTransfersTransactionsItemType,
  TransactionViewQueryData,
} from '../types';

type DetailEntity = {
  name: string;
  label: string;
  value: string;
  status?: AllTypeTransactionStatus;
  variant?: ComponentProps<typeof TransactionDetailsField>['variant'];
};

const formatAssetPrice = (
  symbol: string,
  price: PrecisionFloat,
  locale: string,
  currency?: string,
) => {
  return `1 ${symbol} = ${intlAssetValueFormatting(getRoundedPrecision(price), {
    locale,
    currency,
    fractionDigits: price?.precision,
  })}`;
};

export function isCryptoTransfersTransactionsItemType(
  transaction: TransactionViewQueryData | CryptoTransfersTransactionsItemType,
): transaction is CryptoTransfersTransactionsItemType {
  return (
    transaction !== null &&
    (transaction.__typename === 'DepositCryptoTransfersTransaction' ||
      transaction?.__typename === 'WithdrawalCryptoTransfersTransaction')
  );
}

export function isTransactionViewQueryNode(
  transaction: TransactionViewQueryData | CryptoTransfersTransactionsItemType,
): transaction is TransactionViewQueryData {
  return (
    transaction?.__typename === 'BuyTransaction' ||
    transaction?.__typename === 'SellTransaction'
  );
}

export function isSavingsPlanItemType(
  transaction: TransactionViewQueryData | CryptoTransfersTransactionsItemType,
): transaction is CryptoTransfersTransactionsItemType {
  return transaction?.__typename === 'SavingsPlanTransaction';
}

export const getTransactionDetailsFieldsData = ({
  translations,
  transaction,
  locale,
  currency,
}: {
  translations: TransactionDetailsTransactionKeysResult & {
    assetSymbol: string;
    fiatSymbol: string;
    transactionType: string;
  };
  transaction: TransactionViewQueryData | CryptoTransfersTransactionsItemType;
  locale: Locale;
  currency?: string;
  translationUtil: TFunction;
}): DetailEntity[] => {
  const translationByStatus: Record<
    TransactionStatus | CryptoTransfersTransactionStatus,
    string
  > = {
    CREATED: translations.statusCreated,
    EXPIRED: translations.statusExpired,
    FAILED: translations.statusFailed,
    FINISHED: translations.statusFinished,
    IN_PROGRESS: translations.statusInProgress,
    CANCELED: translations.statusCancelled,
    PROCESSING: translations.statusProcessing,
    PENDING: translations.statusPending,
    ON_HOLD: translations.statusOnHold,
    REJECTED: translations.statusRejected,
  };

  const translationByLegalClassification: Record<LegalClassification, string> =
    {
      OTHER: translations.other,
      NONE: translations.none,
      ARTS: translations.arts,
      EMTS: translations.emts,
    };

  let transactionDetailsFields: Record<string, string | null> = {
    status: transaction?.status
      ? translationByStatus[transaction?.status]
      : null,
  };

  const isCryptoTransferTransaction =
    isCryptoTransfersTransactionsItemType(transaction);

  if (isCryptoTransferTransaction) {
    const isWithdrawalTransferType =
      transaction?.__typename === 'WithdrawalCryptoTransfersTransaction';

    transactionDetailsFields = {
      ...transactionDetailsFields,
      type: isWithdrawalTransferType
        ? translations.typeWithdraw
        : translations.typeDeposit,
      date:
        transaction?.status === 'FINISHED'
          ? transaction?.creditedAt
          : transaction?.createdAt || null,
      legalClassification:
        (transaction?.asset?.legalClassification &&
          translationByLegalClassification[
            transaction?.asset?.legalClassification
          ]) ||
        null,
      network: transaction?.network.name || null,
      to: `${transaction?.asset.name} ${translations.wallet}`,
      walletAddress: transaction?.externalAddress?.address || null,
      hash: transaction?.hash || null,
      fee: transaction?.assetFee
        ? `${intlAssetValueFormatting(
            Number(getRoundedPrecision(transaction?.assetFee)),
            {
              locale,
              fractionDigits: transaction?.assetFee?.precision ?? 8,
            },
          )} ${transaction.asset.symbol}`
        : null,
      total: transaction?.assetAmount
        ? intlAssetValueFormatting(
            Number(getRoundedPrecision(transaction?.assetAmount)),
            {
              locale,
              fractionDigits: transaction?.assetAmount?.precision ?? 8,
            },
          )
        : null,
    };
  }

  if (isTransactionViewQueryNode(transaction)) {
    transactionDetailsFields = {
      ...transactionDetailsFields,
      date: transaction?.time ?? null,
      type: translations.transactionType,
      price:
        transaction?.asset?.symbol && transaction?.assetPrice
          ? formatAssetPrice(
              transaction?.asset?.symbol,
              transaction?.assetPrice,
              locale,
              currency,
            )
          : null,

      spread: transaction?.spread
        ? intlFiatValueFormatting(getRoundedPrecision(transaction?.spread), {
            locale,
            currency,
            fractionDigits: transaction?.spread?.precision,
          })
        : null,

      legalClassification:
        (transaction?.asset?.legalClassification &&
          translationByLegalClassification[
            transaction?.asset?.legalClassification
          ]) ||
        null,

      taxWithheld: transaction?.taxAmount
        ? intlFiatValueFormatting(getRoundedPrecision(transaction?.taxAmount), {
            locale,
            currency,
            fractionDigits: transaction?.taxAmount?.precision,
          })
        : null,

      assetAmount: transaction?.assetAmount
        ? intlAssetValueFormatting(
            Number(getRoundedPrecision(transaction?.assetAmount)),
            {
              locale,
              fractionDigits: transaction?.assetAmount?.precision ?? 8,
            },
          )
        : null,
    };
  }

  return [
    ...(transactionDetailsFields?.status
      ? [
          {
            label: translations.status,
            name: 'transaction-status',
            value: transactionDetailsFields.status,
            variant: isCryptoTransferTransaction
              ? TransactionDetailsFieldVariants.CRYPTO_TRANSACTION_STATUS
              : TransactionDetailsFieldVariants.TRANSACTION_STATUS,
            status: transaction?.status as DetailEntity['status'],
          },
        ]
      : []),
    ...(transactionDetailsFields.type
      ? [
          {
            label: translations.type,
            name: 'transaction-type',
            value: transactionDetailsFields.type,
          },
        ]
      : []),
    ...(transactionDetailsFields?.network
      ? [
          {
            label: translations.network,
            name: 'transaction-network',
            value: transactionDetailsFields.network,
          },
        ]
      : []),
    ...(transactionDetailsFields?.to
      ? [
          {
            label: translations.to,
            name: 'transaction-to',
            value: transactionDetailsFields.to,
          },
        ]
      : []),
    ...(transactionDetailsFields?.walletAddress
      ? [
          {
            label: translations.walletAddress,
            name: 'transaction-walletAddress',
            variant: TransactionDetailsFieldVariants.WALLET_ADDRESS,
            value: transactionDetailsFields.walletAddress,
          },
        ]
      : []),

    ...(transactionDetailsFields?.hash
      ? [
          {
            label: translations.hash,
            name: 'transaction-hash',
            variant: TransactionDetailsFieldVariants.WALLET_ADDRESS,
            value: transactionDetailsFields.hash,
          },
        ]
      : []),
    ...(transactionDetailsFields?.fee
      ? [
          {
            label: translations.fee,
            name: 'transaction-fee',
            value: transactionDetailsFields.fee,
          },
        ]
      : []),
    ...(transactionDetailsFields?.legalClassification
      ? [
          {
            label: translations.assetClassification,
            name: 'transaction-legal-classification',
            value: transactionDetailsFields.legalClassification,
          },
        ]
      : []),
    ...(transactionDetailsFields?.date
      ? [
          {
            label: translations.date,
            name: 'transaction-date-hour',
            variant: TransactionDetailsFieldVariants.TRANSACTION_DATE_HOUR,
            value: transactionDetailsFields.date,
          },
        ]
      : []),
    ...(transactionDetailsFields.assetAmount
      ? [
          {
            label: translations.traded,
            name: 'transaction-traded',
            value: `${transaction?.asset?.symbol} ${transactionDetailsFields.assetAmount}`,
          },
        ]
      : []),
    ...(transactionDetailsFields.price
      ? [
          {
            label: translations.price,
            name: 'transaction-price',
            value: transactionDetailsFields.price,
          },
        ]
      : []),
    ...(transactionDetailsFields.spread
      ? [
          {
            label:
              transaction?.asset?.__typename === 'CryptoAsset' ||
              transaction?.asset?.__typename === 'MetalAsset'
                ? translations.tradeFee
                : translations.spread,
            name: 'transaction-spread',
            value: transactionDetailsFields.spread,
          },
        ]
      : []),
    ...(transactionDetailsFields.taxWithheld
      ? [
          {
            label: translations.taxWithheld,
            name: 'tax-withheld',
            value: transactionDetailsFields.taxWithheld,
          },
        ]
      : []),

    ...(transactionDetailsFields.total
      ? [
          {
            label: translations.total,
            name: 'transaction-total',
            value: `${transaction?.asset?.symbol} ${transactionDetailsFields.total}`,
          },
        ]
      : []),
  ];
};
