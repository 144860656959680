import { useState, useMemo, useCallback } from 'react';
import { useRouter } from 'next/navigation';
import { useComposableModal } from '@bts-web/utils-context';
import { getConvertedSummary } from '../../../utils/getConvertedSummary';
import { getInputValueFromLocalStorage } from '../../../utils/localStorageInputValueUtils';
import { TradeFormProps } from '../../types';
import {
  AccessModalInfoType,
  getAmountInputPlaceHolder,
  useClientUserInfo,
  useHandleGqlErrorsWithSnackbar,
  UserAccessModal,
} from '../../../../../common';
import { useTradeInput } from '../useTradeInput';
import { getYmdForNthDayFromToday } from '../../../utils/dateUtils';
import { checkAppropriatenessAndCreateTradeOffer } from './createTradeHandlers/checkAppropriatenessAndCreateTradeOffer';
import { checkApropriatenessAndCreateSavingsPlan } from './createTradeHandlers/checkAppropriatenessAndCreateSavingsPlan';
import { TradeOfferOperation } from '@bts-web/data-layer/server';
import { AssetType } from '../../../../../asset-details/types';

export interface IUseTradeForm {
  assetDetails: TradeFormProps['assetDetails'];
  currentTradeDetails: TradeFormProps['currentTradeDetails'];
  staticValues: TradeFormProps['staticValues'];
}

export const useTradeForm = ({
  assetDetails,
  currentTradeDetails,
  staticValues,
}: IUseTradeForm) => {
  const router = useRouter();

  const { processErrors } = useHandleGqlErrorsWithSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const [savingsPlanRecurringDate, setSavingsPlanRecurringDate] =
    useState<string>(getYmdForNthDayFromToday(4));

  const [initialInputValue] = useState(
    getInputValueFromLocalStorage({
      assetId: assetDetails.assetId,
    }),
  );

  const { showModal } = useComposableModal();

  const { isReadOnlyUser, approved } = useClientUserInfo();

  const {
    onTradeInputChange,
    onQuickSelectButtonClick,
    displayType,
    onInputBlur,
    inputValue,
    errors,
    setErrors,
    onChangeDisplayType,
  } = useTradeInput({
    rootMaximumTradeAmount: currentTradeDetails.maximumTradeAmount,
    rootMinimumTradeAmount: currentTradeDetails.minimumTradeAmount,
    price: assetDetails.price,
    initialInputValue,
  });

  const onFormSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (isReadOnlyUser) {
        showModal(UserAccessModal, {
          type: AccessModalInfoType.ReadOnly,
        });

        return;
      }

      setIsLoading(true);

      setErrors((state) => ({ ...state, customError: null }));

      const formData = new FormData(e.currentTarget);

      if (
        staticValues.transactionType === 'buy' ||
        staticValues.transactionType === 'sell'
      ) {
        checkAppropriatenessAndCreateTradeOffer(
          formData,
          {
            currentTradeDetails,
            assetDetails,
            transactionType:
              staticValues.transactionType.toUpperCase() as TradeOfferOperation,
          },
          {
            setIsLoading,
            setErrors,
            processErrors,
            router,
          },
        );
      }

      if (staticValues.transactionType === 'savings') {
        checkApropriatenessAndCreateSavingsPlan(
          formData,
          {
            assetDetails,
          },
          {
            setIsLoading,
            setErrors,
            processErrors,
            router,
          },
        );
      }
    },

    [assetDetails, currentTradeDetails],
  );

  const inputPlaceholder = useMemo(() => {
    return getAmountInputPlaceHolder({
      currency: staticValues.currency,
      displayType: displayType,
      locale: staticValues.currentLocale,
      symbol: assetDetails.symbol as string,
    });
  }, [
    assetDetails.symbol,
    displayType,
    staticValues.currency,
    staticValues.currentLocale,
  ]);

  const formHasAnyErrors = errors.tooHigh || errors.tooLow;

  const allButtonsDisabled =
    isReadOnlyUser && approved
      ? false
      : currentTradeDetails.userCannotTrade || assetDetails.assetIsNotTradeable;

  const inputContextData = useMemo(
    () => ({
      currency: staticValues.currency,
      currentLocale: staticValues.currentLocale,
      displayType,
      symbol: assetDetails.symbol as string,
      value: inputValue,
      placeholder: inputPlaceholder,
    }),
    [
      staticValues.currency,
      staticValues.currentLocale,
      displayType,
      assetDetails.symbol,
      inputValue,
      inputPlaceholder,
    ],
  );

  const convertedSummary = useMemo(
    () =>
      getConvertedSummary({
        assetPrice: assetDetails.price,
        assetType: assetDetails.assetType as AssetType,
        tradeType: staticValues.transactionType,
        currency: staticValues.currency,
        displayType,
        locale: staticValues.currentLocale,
        symbol: assetDetails.symbol,
        translations: staticValues.translations,
        value: inputValue,
        maximumTradeAmount: currentTradeDetails.maximumTradeAmount,
      }),
    [
      assetDetails.price,
      assetDetails.assetType,
      assetDetails.symbol,
      staticValues.transactionType,
      staticValues.currency,
      staticValues.currentLocale,
      staticValues.translations,
      displayType,
      inputValue,
      currentTradeDetails.maximumTradeAmount,
    ],
  );

  return {
    formState: {
      isLoading,
      displayType,
      inputValue,
      errors,
      formHasAnyErrors,
      allButtonsDisabled,
      inputPlaceholder,
      inputContextData,
      convertedSummary,
      savingsPlanRecurringDate,
    },
    handlers: {
      onFormSubmit,
      onTradeInputChange,
      onQuickSelectButtonClick,
      onInputBlur,
      onChangeDisplayType,
      setSavingsPlanRecurringDate,
    },
  };
};
