import { FC, PropsWithChildren } from 'react';
import { css } from '@bts-web/utils-style-engine';

const statsSectionStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: 'extra_small_3',
  '& span:first-child': {
    color: 'neutrals.fill_secondary',
    fontSize: 'body.medium',
    lineHeight: 'body.medium',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flex: 1,
  },
  '& span:last-child': {
    color: 'neutrals.text_primary',
    fontSize: 'body.medium_medium',
    fontWeight: 'body.medium_medium',
    lineHeight: 'body.medium_medium',
  },
  minWidth: { base: '43%', lg: '32%' },
  flex: 1,
});

const AssetDetailsStatsSectionEntry: FC<PropsWithChildren> = ({
  children,
  ...rest
}) => {
  return (
    <li
      className={statsSectionStyles}
      aria-label="asset stats section"
      {...rest}
    >
      {children}
    </li>
  );
};

export { AssetDetailsStatsSectionEntry };
