import {
  PortfolioAssetGroupsQuery,
  PortfolioAssetGroupsQueryNode,
  PortfolioAssetGroupsQuery$variables,
} from '@bts-web/data-layer/server';
import { gqlNetworkFetch } from '@bts-web/utils-relay';
import { fetchQueryTags } from '../fetchQueryTags';

export const getPortfolioAssetGroups = async (
  params: PortfolioAssetGroupsQuery$variables,
) => {
  return await gqlNetworkFetch<PortfolioAssetGroupsQuery>(
    PortfolioAssetGroupsQueryNode.params,
    params,
    [fetchQueryTags.portfolio, params.period.toString()],
  );
};
