import {
  getRoundedPrecision,
  intlFiatValueFormatting,
} from '@bts-web/utils-formatting';
import { AreaChartProps } from '@bts-web/design-system/component/chart';
import { stack } from '@bts-web/utils-style-engine';
import { ContentSectionWrapper } from '../../../../../../layouts/ContentSectionWrapper/ContentSectionWrapper';
import { AssetDetailsInfoRow } from '../../../../AssetDetailsInfoRow/AssetDetailsInfoRow';
import { AreaChart } from '../../../../../../common/components/AreaChart/AreaChart';
import { AssetFields } from '../../../../AssetDetailsDataContext/AssetDetailsDataContext.use-client';
import { Locale } from '@bts-web/utils-lokalise';
import { getAppConfig } from '@bts-web/core-app-config';

const chartTimelineLabels = ['2014', '2016', '2018', '2020', '2022'];

export const NetIncome = ({
  assetData,
  locale,
  labels,
}: {
  assetData: AssetFields;
  locale: string | Locale;
  labels: {
    assetDividendYield: string;
    assetDividendPerShare: string;
    assetRevenue: string;
    assetPeRatio: string;
    assetNetIncome: string;
    netIncome: string;
  };
}) => {
  const { currency } = getAppConfig();

  const netIncomeHistoryDataSet = (assetData?.yearlyNetIncomes || []).map(
    (item) => [
      new Date(item?.period || '').getTime(),
      Number(getRoundedPrecision(item?.value) || 0),
    ],
  ) as AreaChartProps['external']['seriesData'];

  const assetNetIncomeValue =
    assetData?.revenue != null &&
    intlFiatValueFormatting(getRoundedPrecision(assetData?.revenue), {
      locale,
      currency,
      fractionDigits: assetData?.revenue?.precision,
    });

  const dividendYieldValue =
    assetData?.dividendYield != null &&
    intlFiatValueFormatting(assetData?.dividendYield as number, {
      locale,
      currency,
    });

  const dividendPerShareValue =
    assetData?.dividendPerShare != null &&
    intlFiatValueFormatting(getRoundedPrecision(assetData?.dividendPerShare), {
      locale,
      currency,
      fractionDigits: assetData?.dividendPerShare.precision,
    });

  const peRatioValue =
    assetData?.peRatio != null &&
    intlFiatValueFormatting(assetData?.peRatio as number, {
      locale,
      currency,
    });

  const firstYearlyNetIncome =
    assetData?.yearlyNetIncomes &&
    assetData?.yearlyNetIncomes?.length > 0 &&
    intlFiatValueFormatting(
      getRoundedPrecision(assetData?.yearlyNetIncomes[0]?.value),
      {
        locale,
        currency,
        fractionDigits: assetData?.yearlyNetIncomes[0]?.value?.precision,
      },
    );

  return (
    <ContentSectionWrapper
      data-testid="net-income-overview"
      aria-label="net income overview"
      sectionTitle={labels.netIncome}
      className={stack({ gap: 'small' })}
    >
      <AreaChart
        currency={currency}
        locale={locale as Locale}
        visual="positive"
        size="small"
        seriesData={netIncomeHistoryDataSet}
        timelineLabels={chartTimelineLabels}
      />

      {dividendYieldValue && (
        <AssetDetailsInfoRow
          label={labels.assetDividendYield}
          value={dividendYieldValue}
        />
      )}
      {dividendPerShareValue && (
        <AssetDetailsInfoRow
          label={labels.assetDividendPerShare}
          value={dividendPerShareValue}
        />
      )}

      {assetNetIncomeValue && (
        <AssetDetailsInfoRow
          label={labels.assetRevenue}
          value={assetNetIncomeValue}
        />
      )}

      {peRatioValue && (
        <AssetDetailsInfoRow label={labels.assetPeRatio} value={peRatioValue} />
      )}

      {firstYearlyNetIncome && (
        <AssetDetailsInfoRow
          label={labels.assetNetIncome}
          value={firstYearlyNetIncome}
        />
      )}
    </ContentSectionWrapper>
  );
};
