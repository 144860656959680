import {
  FormEventHandler,
  MouseEventHandler,
  ReactElement,
  useState,
  useEffect,
} from 'react';

export interface SearchBarProps {
  internal: {
    className: string;
    inputClassName: string;
  };
  external: {
    onFocus?: () => void;
    onBlur?: () => void;
    onClear?: () => void;
    onInput?: (event: React.FormEvent<HTMLInputElement>) => void;
    onChange?: (value: string) => void;
    placeholder?: string;
    maxLength?: number;
    searchIcon: ReactElement;
    dismissIcon: ReactElement;
    value?: string;
    defaultValue?: string;
    disabled?: boolean;
  };
}

export function SearchBar({
  onFocus,
  onBlur,
  onInput,
  onChange,
  onClear,
  className,
  inputClassName,
  placeholder,
  maxLength,
  searchIcon,
  dismissIcon,
  value: externalValue,
  defaultValue,
  disabled,
}: SearchBarProps['internal'] & SearchBarProps['external']) {
  const [internalValue, setInternalValue] = useState(defaultValue || '');

  const isControlled = externalValue !== undefined;

  const value = isControlled ? externalValue : internalValue;

  useEffect(() => {
    if (isControlled) {
      setInternalValue(externalValue);
    }
  }, [isControlled, externalValue]);

  const handleInput: FormEventHandler<HTMLInputElement> = (
    event: React.FormEvent<HTMLInputElement>,
  ) => {
    let newValue = event.currentTarget.value;

    newValue = newValue.replace(/[^a-zA-Z0-9 '()-.&]/g, '').replace(/\*/g, '');

    if (onInput) {
      onInput(event);
    }

    if (!isControlled) {
      setInternalValue(newValue);
    }

    if (onChange) {
      onChange(newValue);
    }
  };

  const handleClear: MouseEventHandler<HTMLDivElement> = () => {
    if (!isControlled) {
      setInternalValue('');
    }

    if (onChange) {
      onChange('');
    }

    if (onClear) {
      onClear();
    }
  };

  return (
    <div className={className} data-testid="search-bar">
      <input
        value={value}
        onInput={handleInput}
        onFocus={onFocus}
        onBlur={onBlur}
        data-state={value && 'filled'}
        type="text"
        title="search"
        id="search-bar"
        name="search-bar"
        className={inputClassName}
        autoComplete="off"
        required={true}
        data-testid="search-bar-input"
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
      />

      <div data-search data-testid="search-bar-icon-search">
        {searchIcon}
      </div>

      <div data-clear data-testid="search-bar-icon-clear" onClick={handleClear}>
        {dismissIcon}
      </div>
    </div>
  );
}
