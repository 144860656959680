import { getAppConfig } from '@bts-web/core-app-config';
import {
  TradeControllerVariants,
  TradeScreenTranslations,
  isBpfsAsset,
} from '../../../utils';
import { AssetType } from '../../../../asset-details/types';
import {
  MutableNonNullableAsset,
  TradeInnerTranslatedValues,
} from '../../types';

export const getTranslationsPerTradeType = ({
  translations,
  variant,
  assetType,
}: {
  assetType: MutableNonNullableAsset['__typename'];
  translations: TradeScreenTranslations;
  variant: TradeControllerVariants;
}) => {
  const isBpf = isBpfsAsset(assetType as AssetType);

  const { currency } = getAppConfig();

  const poweredByText = {
    value: isBpf ? translations.poweredByBpfs : translations.poweredByBitpanda,
    fontSize: isBpf ? 'caption.medium_medium' : 'caption.small',
  };

  const currencyTranslation =
    translations[
      currency.toLocaleLowerCase() as keyof TradeScreenTranslations
    ] || '';

  const translatedInnerValues: TradeInnerTranslatedValues = {
    poweredByText: poweredByText,
    isMax: translations.max,
    verifyInfoText: translations.verifyTradeInfo ?? 'NA',
    tradeButtonText:
      variant === 'buy' ? translations.buyNow : translations.sellNow,
    verifyButtonText: translations.actionButtonVerifyText ?? 'NA',
    errorTooHigh: translations.errorTooHigh ?? 'NA',
    errorTooLow: translations.errorTooLow ?? 'NA',
    grams: translations.grams ?? 'NA',
    inclSpread: translations.inclSpread ?? 'NA',
    selectorLabels: {
      fiat: currencyTranslation,
      asset:
        assetType === AssetType.METAL
          ? translations.grams?.toUpperCase?.() // we need the uppercase variant from translations
          : translations.units,
    },
    confirmButtonText: translations.actionButtonText,
  };

  return { translatedInnerValues };
};
