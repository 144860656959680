import { FC } from 'react';
import { TFunction } from 'i18next';
import { defaultLocale, initServerTranslation } from '@bts-web/utils-lokalise';
import { getTransactionDetailsTranslations } from './utils';
import { TransactionDetailsLayout } from './TransactionDetailsLayout/TransactionDetailsLayout';
import { CryptoTransferDetailsContent } from '../crypto-transfer-details';
import { TransactionDetailsContent } from './TransactionDetailsContent/TransactionDetailsContent';
import {
  isClassicTransaction,
  isCryptoTransferTransaction,
} from '../transactions/types/transactions';

export type TransactionDetailsControllerProps = {
  transactionId: string;
  transactionType: string;
  locale: typeof defaultLocale;
};

const { t } = initServerTranslation();

const TransactionDetailsController: FC<TransactionDetailsControllerProps> = ({
  transactionId,
  locale,
  transactionType,
}) => {
  const translations = getTransactionDetailsTranslations(t as TFunction);

  const isCryptoTransaction = isCryptoTransferTransaction(transactionType);

  const isTradeTransaction = isClassicTransaction(transactionType);

  return (
    <TransactionDetailsLayout navigationBarTitle={translations.topBar}>
      {isCryptoTransaction && (
        <CryptoTransferDetailsContent
          cryptoTransferId={transactionId}
          locale={locale}
        />
      )}

      {isTradeTransaction && (
        <TransactionDetailsContent
          transactionId={transactionId}
          locale={locale}
          transactionType={transactionType}
        />
      )}
    </TransactionDetailsLayout>
  );
};

export { TransactionDetailsController };
