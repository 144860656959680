/**
 * @generated SignedSource<<3fe4f2060e30a73842c67b862a89366e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type InitiatedBy = "AUTOMATED_ORDER" | "BITPANDA_CORPORATE_ACTION" | "BITPANDA_MANUAL_TRADE" | "BITPANDA_SAVINGS" | "OTHER" | "USER";
export type TransactionCategory = "CORPORATE_ACTION" | "CRYPTO_ACTION" | "CRYPTO_TRANSFER" | "NON_USER_INITIATED" | "TRADE";
export type TransactionDirection = "INCOMING" | "OUTGOING";
export type TransactionStatus = "CREATED" | "EXPIRED" | "FAILED" | "FINISHED" | "IN_PROGRESS";
export type TransactionType = "BUY" | "SELL";
export type TransactionsListInput = {
  after?: string | null;
  assetId?: string | null;
  before?: string | null;
  category?: TransactionCategory | null;
  first?: number | null;
  last?: number | null;
  type?: TransactionType | null;
};
export type TransactionsViewQuery$variables = {
  input?: TransactionsListInput | null;
};
export type TransactionsViewQuery$data = {
  readonly transactions: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly node: {
        readonly __typename: string;
        readonly asset: {
          readonly __typename: string;
          readonly id: string;
          readonly logoUrl: string | null;
          readonly name: string | null;
          readonly symbol: string | null;
        } | null;
        readonly assetAmount: PrecisionFloat | null;
        readonly assetPrice?: PrecisionFloat | null;
        readonly category: TransactionCategory;
        readonly direction: TransactionDirection | null;
        readonly fiatAmount: PrecisionFloat | null;
        readonly id: string;
        readonly initiatedBy: InitiatedBy | null;
        readonly offer?: {
          readonly id: string;
        } | null;
        readonly status: TransactionStatus | null;
        readonly time: any | null;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly hasPreviousPage: boolean;
    };
    readonly totalCount: number | null;
  } | null;
};
export type TransactionsViewQuery = {
  response: TransactionsViewQuery$data;
  variables: TransactionsViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "assetPrice",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TransactionOffer",
    "kind": "LinkedField",
    "name": "offer",
    "plural": false,
    "selections": [
      (v2/*: any*/)
    ],
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TransactionsConnection",
    "kind": "LinkedField",
    "name": "transactions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasNextPage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasPreviousPage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endCursor",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TransactionEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "time",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "category",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "initiatedBy",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "direction",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fiatAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "asset",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "logoUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "symbol",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "assetAmount",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "BuyTransaction",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "SellTransaction",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v4/*: any*/),
                "type": "DepositTransaction",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v4/*: any*/),
                "type": "WithdrawalTransaction",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TransactionsViewQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TransactionsViewQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "bd0ad370b3343785b06fd80fb1d85dd9",
    "id": null,
    "metadata": {},
    "name": "TransactionsViewQuery",
    "operationKind": "query",
    "text": "query TransactionsViewQuery(\n  $input: TransactionsListInput\n) {\n  transactions(input: $input) {\n    pageInfo {\n      hasNextPage\n      hasPreviousPage\n      endCursor\n    }\n    totalCount\n    edges {\n      cursor\n      node {\n        __typename\n        id\n        time\n        category\n        initiatedBy\n        direction\n        status\n        fiatAmount\n        asset {\n          id\n          logoUrl\n          symbol\n          name\n          __typename\n        }\n        assetAmount\n        ... on BuyTransaction {\n          assetPrice\n        }\n        ... on SellTransaction {\n          assetPrice\n        }\n        ... on DepositTransaction {\n          offer {\n            id\n          }\n        }\n        ... on WithdrawalTransaction {\n          offer {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "239807513a30f14f6c162a425bf59769";

export default node;
