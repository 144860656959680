'use client';

import { useMemo } from 'react';
import { usePathname } from 'next/navigation';
import { DialogBase, useClientUserInfo } from '../common';
import { useClientTranslation } from '@bts-web/utils-lokalise';
import { TermsAndConditionsContent } from './TermsAndConditionsContent/TermsAndConditionsContent';
import { getTermsAndConditionsTranslations } from './utils/getTermsAndConditionsTranslations';
import { TermsAndConditionsViewQuery } from '@bts-web/data-layer/server';

interface TermsAndConditionsModalProps {
  termsAndConditions: TermsAndConditionsViewQuery['response']['termsAndConditions'];
}

export const TermsAndConditionsModal = ({
  termsAndConditions,
}: TermsAndConditionsModalProps) => {
  const { t } = useClientTranslation();

  const pathname = usePathname();

  const { userType, onboarded } = useClientUserInfo();

  const translations = useMemo(() => getTermsAndConditionsTranslations(t), [t]);

  // This is used to avoid rendering on the origin route
  if (pathname === '/') {
    return null;
  }

  return (
    <DialogBase
      id="terms-and-conditions-modal"
      open
      closeIcon={null}
      size="medium"
      animate="top"
      onMobileFullScreen
      visual="secondary"
      title={translations.termsAndConditions}
      showHeaderBottomDivider
      disableBackdropClose
    >
      <TermsAndConditionsContent
        translations={translations}
        userType={userType}
        onboarded={onboarded}
        termsAndConditions={termsAndConditions}
      />
    </DialogBase>
  );
};
