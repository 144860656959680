import { InputHTMLAttributes } from 'react';

export interface TextInputProps {
  internal: {
    className: string;
    inputClassName?: string;
  };
  external: {
    label?: string;
    helperText?: string;
  } & InputHTMLAttributes<HTMLInputElement>;
}

export const TextInput = ({
  className,
  inputClassName,
  label,
  helperText,
  ...props
}: TextInputProps['internal'] & TextInputProps['external']) => (
  <div className={className} data-testid="text-input-wrapper">
    {label && (
      <label data-element="label" htmlFor="text-input">
        {label}
      </label>
    )}
    <input
      data-element="input"
      {...props}
      type="text"
      title="search"
      id="text-input"
      name="text-input"
      autoComplete="off"
      required={true}
      data-testid="text-input"
      className={inputClassName ?? ''}
    ></input>
    {helperText && <span data-element="helper-text">{helperText}</span>}
  </div>
);
