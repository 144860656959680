import {
  getPrecisionFloatValue,
  getRoundedPrecision,
  intlFiatValueFormatting,
} from '@bts-web/utils-formatting';
import { css } from '@bts-web/utils-style-engine';
import { FC } from 'react';
import {
  NonNullableTransactionFinalizedSummaryData,
  TradeControllerVariants,
} from '../../../utils';
import { initServerTranslation } from '@bts-web/utils-lokalise';
import { getAppConfig } from '@bts-web/core-app-config';

export const PendingContent: FC<{
  currentLocale: string;
  actionVariant: TradeControllerVariants;
  transactionData: NonNullableTransactionFinalizedSummaryData | null;
}> = ({ currentLocale, actionVariant, transactionData }) => {
  const { t } = initServerTranslation();

  const { currency } = getAppConfig();

  const pendingSubtitleTranslationKey =
    actionVariant === 'buy' ? 'pending_buy_subtitle' : 'pending_sell_subtitle';

  return (
    <p
      className={css({
        fontSize: 'body.medium',
        color: 'neutrals.text_secondary',
      })}
      aria-label="pending content"
    >
      {transactionData
        ? t(pendingSubtitleTranslationKey, {
            assetamount: `${getPrecisionFloatValue(
              transactionData.assetAmount,
            )} ${transactionData.asset?.symbol}`,
            fiatamount: intlFiatValueFormatting(
              getRoundedPrecision(transactionData.fiatAmount),
              {
                currency,
                locale: currentLocale,
              },
            ),
          })
        : null}
    </p>
  );
};
