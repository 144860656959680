import { css, flex } from '@bts-web/utils-style-engine';
import { type AssetDetailsViewQuery$data } from '@bts-web/data-layer/server';
import { StockAssetDetailsTranslations } from '../../AssetDetailsStocks';
import { ContentSectionWrapper } from '../../../../../../layouts/ContentSectionWrapper/ContentSectionWrapper';
import { DonutChart } from '../../../../../../common/components/Donut/DonutChart';

const captionSmall = {
  fontSize: 'caption.small',
  fontWeight: 'caption.small',
  lineHeight: 'caption.small',
  letterSpacing: 'caption.small',
};

const adviceTitleStyles = css({
  color: 'neutrals.text_primary',
  fontSize: 'title.medium_medium',
  fontWeight: 'title.medium_medium',
  lineHeight: 'title.medium_medium',
  letterSpacing: 'title.medium_medium',
});

const adviceDescriptionStyles = css({
  color: 'neutrals.text_primary',
  ...captionSmall,
});

const captionStyles = css({
  display: 'block',
  marginTop: 'large',
  color: 'neutrals.text_secondary',
  ...captionSmall,
});

const listStyles = flex({
  direction: 'column',
  gap: 'extra_small',
  ...captionSmall,
});

const listItemStyles = flex({
  alignItems: 'center',
});

const commonBoxItemStyleObj = {
  display: 'block',
  width: '12px',
  height: '12px',
  borderRadius: '4px',
  marginInlineEnd: 'extra_small',
};

interface AnalystRatingsProps {
  recommendationBreakdown: NonNullable<
    NonNullable<
      NonNullable<AssetDetailsViewQuery$data['assetById']>['recommendation']
    >['breakdown']
  >;
  title: string;
  buyInfo: string;
  sellInfo: string;
  holdInfo: string;
  generalInfo: string;
  translations: StockAssetDetailsTranslations;
}

const AnalystRatings = ({
  title,
  buyInfo,
  holdInfo,
  sellInfo,
  generalInfo,
  translations,
  recommendationBreakdown,
}: AnalystRatingsProps) => {
  const analystRatingsAllocationDataSet = recommendationBreakdown.map(
    (item) => ({
      y: (item && item.percentage && item.percentage * 100) || 0,
    }),
  );

  const highestRecomandation = recommendationBreakdown.reduce((max, item) => {
    return item &&
      item.percentage !== null &&
      max &&
      max.percentage !== null &&
      item.percentage > max.percentage
      ? item
      : max;
  }, recommendationBreakdown[0]);

  const adviceLabel = highestRecomandation?.type
    ? translations[
        highestRecomandation?.type as keyof StockAssetDetailsTranslations
      ]
    : '';

  return (
    <ContentSectionWrapper
      data-testid="analyst-ratings"
      aria-label="analyst ratings section"
      sectionTitle={title}
      className={css({
        display: 'flex',
        flexDirection: 'column',
      })}
    >
      <div className={flex({ gap: 'large' })}>
        <DonutChart seriesData={analystRatingsAllocationDataSet} />

        <div
          className={flex({
            direction: 'column',
            justifyContent: 'space-between',
          })}
        >
          <div className={flex({ direction: 'column', gap: 'extra_small_3' })}>
            <h3 className={adviceTitleStyles}>{adviceLabel}</h3>
            <p className={adviceDescriptionStyles}>
              {translations.assetDetailAnalystRatingsDescription}
            </p>
          </div>
          <ul className={listStyles}>
            <li className={listItemStyles}>
              <span
                className={css({
                  ...commonBoxItemStyleObj,
                  backgroundColor: 'positive.fill_primary',
                })}
              />
              {buyInfo}
            </li>
            <li className={listItemStyles}>
              <span
                className={css({
                  ...commonBoxItemStyleObj,
                  backgroundColor: 'brand.fill_secondary',
                })}
              />
              {holdInfo}
            </li>
            <li className={listItemStyles}>
              <span
                className={css({
                  ...commonBoxItemStyleObj,
                  backgroundColor: 'neutrals.fill_secondary',
                })}
              />
              {sellInfo}
            </li>
          </ul>
        </div>
      </div>
      <p className={captionStyles}>{generalInfo}</p>
    </ContentSectionWrapper>
  );
};

export { AnalystRatings };
