'use client';

import { ModalComponent } from '@bts-web/utils-context';
import { Icon } from '@bts-web/design-system/component/icon';
import { css } from '@bts-web/utils-style-engine';
import { DialogBase, Status } from '../../common/components';
import { getCryptoTransferCryptoTransferDetailsLabelsByStatus } from '../utils/getCryptoTransferCryptoTransferDetailsLabelsByStatus';
import { CryptoTransfersTransactionStatus } from '@bts-web/data-layer/server';
import { STATUS_COLORS } from '../../transaction-details/types';
import { ComponentProps } from 'react';

const containerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  mx: 'auto',
  gap: 'extra_large',
  justifyContent: 'center',
  flex: {
    base: 'auto',
    ['lg' as string]: '1',
  },
});

const statusContainerWrap = css({
  margin: 'auto 0',
  gap: 'extra_large',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  pt: {
    base: '0',
    lg: 'small',
  },
  pb: {
    base: '0',
    lg: 'medium',
  },
});

export type CryptoTransferTransactionStatusModalProps = {
  status: CryptoTransfersTransactionStatus;
  description?: string;
};

export const CryptoTransferTransactionStatusModal: ModalComponent<
  CryptoTransferTransactionStatusModalProps
> = ({ onClose, status }) => {
  const { title, header, description } =
    getCryptoTransferCryptoTransferDetailsLabelsByStatus(status);

  const visual =
    status &&
    (STATUS_COLORS[status] as ComponentProps<typeof Status>['visual']);

  return (
    <DialogBase
      id="crypto-transfer-modal"
      open
      onOpenChange={onClose}
      size="medium"
      title={header}
      onMobileFullScreen
      visual="secondary"
      animate="top"
    >
      <div className={containerStyles}>
        <div className={statusContainerWrap}>
          <div
            className={css({
              display: 'flex',
              justifyContent: 'center',
              gap: 'extra_small_2',
            })}
          >
            <div className={css({ display: 'flex', justifyContent: 'center' })}>
              <div
                data-element="swap-icon-container"
                className={css({
                  borderRadius: '29px',
                  display: 'flex',
                  padding: 'extra_small',

                  ...(visual === 'outlineCompleted'
                    ? {
                        background: 'positive.fill_secondary',
                        color: 'positive.text_primary',
                      }
                    : {}),

                  ...(visual === 'outlineOnHold'
                    ? {
                        color: 'warning.text_primary',
                        backgroundColor: 'warning.fill_secondary',
                      }
                    : {}),

                  ...(visual === 'outlineCancelled'
                    ? {
                        color: 'negative.text_primary',
                        backgroundColor: 'negative.fill_secondary',
                      }
                    : {}),

                  ...(visual === 'outlineInfoStatus'
                    ? {
                        color: 'information.text_primary',
                        backgroundColor: 'information.fill_secondary',
                      }
                    : {}),

                  ...(visual === 'outlineNeutralLight'
                    ? {
                        color: 'neutrals.text_primary',
                        backgroundColor: 'neutrals.fill_secondary',
                      }
                    : {}),

                  ...(visual === 'outlineNeutralDark'
                    ? {
                        color: 'neutrals.text_primary',
                        backgroundColor: 'neutrals.fill_secondary',
                      }
                    : {}),
                })}
              >
                <Icon
                  icon="person-swap"
                  theme="regular"
                  size="32"
                  color="inherit"
                />
              </div>
            </div>
          </div>
          <div
            className={css({
              color: 'neutrals.text_primary',
              fontSize: 'headline.large_semi_bold',
              fontWeight: 'headline.large_semi_bold',
              letterSpacing: 'headline.large_semi_bold',
              lineHeight: 'headline.large_semi_bold',
              textAlign: 'center',
            })}
          >
            {title}
          </div>

          <div
            className={css({
              color: 'neutrals.text_primary',
              fontSize: 'body.medium',
              fontWeight: 'body.medium',
              letterSpacing: 'body.medium',
              lineHeight: 'body.medium',
              paddingTop: 'extra_small_2',
            })}
          >
            {description}
          </div>
        </div>
      </div>
    </DialogBase>
  );
};
