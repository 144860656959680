import { css } from '@bts-web/utils-style-engine';
import { FC, ReactNode } from 'react';

interface AlertBoxProps {
  variant:
    | 'success'
    | 'success-full'
    | 'error'
    | 'error-full'
    | 'warning'
    | 'warning-full'
    | 'info'
    | 'info-full';
  children: ReactNode;
  className?: string;
}

const AlertBox: FC<AlertBoxProps> = ({ children, variant, className }) => {
  return (
    <div
      data-testid={`${variant} message box`}
      className={`${css({
        border: '2px solid',
        borderRadius: '8px',
        padding: 'small',
        fontSize: 'label.medium_medium',
        fontWeight: 'label.medium_medium',
        lineHeight: 'label.medium_medium',
        letterSpacing: 'label.medium_medium',
        ...(variant === 'success'
          ? {
              borderColor: 'positive.text_primary',
              color: 'positive.text_primary',
            }
          : {}),
        ...(variant === 'success-full'
          ? {
              border: 'none',
              background: 'positive.fill_tertiary',
              color: 'positive.text_primary',
            }
          : {}),
        ...(variant === 'warning'
          ? {
              borderColor: 'warning.stroke_primary',
              color: 'warning.text_primary',
            }
          : {}),
        ...(variant === 'warning-full'
          ? {
              border: 'none',
              background: 'warning.fill_tertiary',
              color: 'warning.text_primary',
            }
          : {}),
        ...(variant === 'info'
          ? {
              borderColor: 'information.text_primary',
              color: 'information.text_primary',
            }
          : {}),
        ...(variant === 'info-full'
          ? {
              border: 'none',
              background: 'information.fill_tertiary',
              color: 'information.text_primary',
            }
          : {}),
        ...(variant === 'error'
          ? {
              borderColor: 'negative.text_primary',
              color: 'negative.text_primary',
            }
          : {}),
        ...(variant === 'error-full'
          ? {
              border: 'none',
              background: 'negative.fill_tertiary',
              color: 'negative.text_primary',
            }
          : {}),
      })} ${className}`}
    >
      {children}
    </div>
  );
};

export { AlertBox };
