import { FC } from 'react';
import { TFunction } from 'i18next';
import { TransactionFinalizedSummaryDataQuery$data } from '@bts-web/data-layer/server';
import { Locale } from '@bts-web/utils-lokalise';
import { TradeController, TradeControllerVariants } from '../..';
import { getTransactionFinalizedSummary } from '../../../common/gqlActions';
import { AppMainLayoutWrapper } from '../../../layouts/LayoutWithLeftDesktopNav/AppMainLayoutWrapper/AppMainLayoutWrapper';
import { AssetDetailTradeWrapper } from '../../../layouts/AssetDetailTradeWrapper/AssetDetailTradeWrapper';
import { FinishTradeController } from '../FinishTradeController/FinishTradeController.use-server';
import { AssetDetailsControllerWithSuspense } from '../../../asset-details';
import { MutableNonNullableAsset } from '../../TradeParent/types';

export interface FinishTradeWithDesktopWrapperProps {
  searchParams: Record<string, string>;
  currentLocale: Locale;
  translationUtil: TFunction;
  actionVariant: TradeControllerVariants;
}

export const FinishTradeWithDesktopWrapper: FC<
  FinishTradeWithDesktopWrapperProps
> = async ({ searchParams, currentLocale, actionVariant, translationUtil }) => {
  const { offerId } = searchParams;

  const response = await getTransactionFinalizedSummary(offerId).catch((_) => {
    const errorResponseObject: {
      data: {
        transaction: NonNullable<TransactionFinalizedSummaryDataQuery$data>['transaction'];
      };
    } = {
      data: {
        transaction: {
          // fallback for display to uncertain => In progress state because if the user has reached this page it means that the initial offer has been accepted and processed
          status: 'IN_PROGRESS',
          asset: null,
          assetAmount: null,
          fiatAmount: null,
          taxAmount: null,
          totalAmount: null,
        },
      },
    };

    return errorResponseObject;
  });

  const transaction = response?.data?.transaction ?? null;

  const { asset } = transaction ?? {};

  return (
    <>
      <FinishTradeController
        transactionData={transaction}
        currentLocale={currentLocale}
        searchParams={searchParams}
        translationUtil={translationUtil}
        actionVariant={actionVariant}
      />

      <AppMainLayoutWrapper
        title={{
          titleType: 'string',
          value: '',
        }}
        settings={{
          hideMobileHeader: true,
          hideTopBarTradeButton: true,
          hasMobileBottomNav: false,
          noMainElementPaddingBottom: true,
          fullPageBackdropMode: true,
        }}
      >
        {asset && (
          <AssetDetailTradeWrapper
            hideMobile="tradeElement"
            AssetDetailsContent={
              <AssetDetailsControllerWithSuspense
                assetId={asset.id}
                hideTradeCTAFloatingButton
              />
            }
            TradeStateContent={
              <TradeController
                assetByIdData={asset as MutableNonNullableAsset}
                // element is in the background so leave to buy
                variant={'buy'}
                skipCheckAppropriateness
              />
            }
          />
        )}
      </AppMainLayoutWrapper>
    </>
  );
};
