const _supportedLanguages = ['de', 'en'] as const;

const _supportedLocaleRegions = ['DE', 'GB'] as const;

export type Language = (typeof _supportedLanguages)[number];

export type LanguageTitle = 'English' | 'Deutsch';

export type Region = (typeof _supportedLocaleRegions)[number];

export type Locale = `${Language}-${Region}`;

export const languageTitleByCode: Record<Language, LanguageTitle> = {
  de: 'Deutsch',
  en: 'English',
};

export const labelsByLanguage: (translatedValues: {
  germanLabel: string;
  englishLabel: string;
}) => Record<Language, string> = (translatedValues) => ({
  de: translatedValues.germanLabel,
  en: translatedValues.englishLabel,
});
