import {
  DonutChart,
  type DonutChartProps,
} from '@bts-web/design-system/component/chart';
import { css } from '@bts-web/utils-style-engine';

export type ThemedDonutChartProps = DonutChartProps['external'];

const ThemedDonutChart = ({ ...chartProps }: ThemedDonutChartProps) => {
  return (
    <DonutChart
      {...chartProps}
      className={css({
        '& > div': {
          width: '130px',
          height: '130px',
        },
        '& .highcharts-background': { fill: 'transparent' },
        '& .highcharts-plot-background, & .highcharts-plot-border': {
          fill: 'transparent',
        },
        '& .highcharts-pie-series path': {
          stroke: 'white',
          strokeWidth: '3px',
        },
        '& .highcharts-visually-hidden': {
          position: 'absolute !important',
          insetInlineStart: '-999em',
        },
        '& .highcharts-color-0': {
          fill: 'positive.fill_primary',
        },
        '& .highcharts-color-1': {
          fill: 'brand.fill_secondary',
        },
        '& .highcharts-color-2': {
          fill: 'neutrals.fill_secondary',
        },
      })}
    />
  );
};

export { ThemedDonutChart as DonutChart };
