import {
  NavigationBar,
  NavigationBarProps,
} from '@bts-web/design-system/component/navigation-bar';
import { css } from '@bts-web/utils-style-engine';

type ThemedNavigationBarProps = NavigationBarProps['external'] & {
  showBorderBottom?: boolean;
};

const ThemedNavigationBar = ({
  title,
  isTitleCentered = true,
  NavigationLeftSlot,
  NavigationRightSlot,
  showBorderBottom = false,
}: ThemedNavigationBarProps) => (
  <NavigationBar
    className={css({
      display: 'flex',
      minHeight: 48,
      alignItems: 'center',
      backgroundColor: 'screen_background.primary',
      '& li': {
        minWidth: '56px',
      },
      '& button': {
        px: 'medium',
        py: 'extra_small',
      },
      '& [data-element="empty-navigation-slot"]': {
        width: '56px',
      },
      ...(showBorderBottom
        ? {
            width: 'auto',
            borderBottomWidth: '1px',
            borderBottomColor: 'neutrals.divider',
          }
        : {}),
    })}
    titleClassName={css({
      display: 'flex',
      color: 'neutrals.text_primary',
      fontSize: 'body.medium_medium',
      fontWeight: 'body.medium_medium',
      lineHeight: 'body.medium_medium',
      letterSpacing: 'body.medium_medium',
      flexGrow: '1',
      '&[data-visual="centered"]': {
        justifyContent: 'center',
      },
    })}
    title={title}
    isTitleCentered={isTitleCentered}
    NavigationLeftSlot={NavigationLeftSlot}
    NavigationRightSlot={NavigationRightSlot}
  />
);

export { ThemedNavigationBar as NavigationBar };
