'use client';

import {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';

export type BaseDrawerProps = {
  id: string;
  disableOverlayClose?: boolean;
  position?: 'right' | 'bottom';
  closeDrawer?: () => void;
};

export type DrawerComponent<T = object> = (
  props: BaseDrawerProps & T,
) => ReactElement;

export type OpenDrawerFunction = <T extends object = object>(
  DrawerComponent: DrawerComponent<T>,
  additionalProps?: Omit<T, keyof BaseDrawerProps>,
) => void;

interface DrawerState<T extends object = object> {
  Component: DrawerComponent<T> | null;
  props: Omit<T, keyof BaseDrawerProps> | null;
}

interface DrawerContextProps {
  openDrawer: OpenDrawerFunction;
  closeDrawer: () => void;
  drawerStatus: 'open' | 'close' | '';
}

const DrawerContext = createContext<DrawerContextProps | null>(null);

export function useComposableDrawer(): DrawerContextProps {
  const context = useContext(DrawerContext);

  if (!context) {
    throw new Error(
      'useComposableDrawer must be used within a ComposableDrawerContextProvider',
    );
  }

  return context;
}

export const ComposableDrawerContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [drawerState, setDrawerState] = useState<DrawerState<any>>({
    Component: null,
    props: null,
  });

  const [drawerStatus, setDrawerStatus] = useState<'open' | 'close' | ''>('');

  useEffect(() => {
    if (drawerStatus === 'close') {
      const timer = setTimeout(() => {
        setDrawerState({ Component: null, props: null });

        setDrawerStatus('');
      }, 400);

      return () => clearTimeout(timer);
    }
  }, [drawerStatus]);

  const closeDrawer = useCallback(() => setDrawerStatus('close'), []);

  const openDrawer: OpenDrawerFunction = useCallback(
    (DrawerComponent, additionalProps = {} as never) => {
      setDrawerState({ Component: DrawerComponent, props: additionalProps });

      setDrawerStatus('open');
    },
    [],
  );

  const contextValue = useMemo(
    () => ({ openDrawer, closeDrawer, drawerStatus }),
    [openDrawer, closeDrawer, drawerStatus],
  );

  const { Component, props } = drawerState;

  return (
    <DrawerContext.Provider value={contextValue}>
      {children}
      {Component && <Component {...(props as any)} closeDrawer={closeDrawer} />}
    </DrawerContext.Provider>
  );
};
