import { SkeletonElement } from '../../common/components/loading/SkeletonElement/SkeletonElement';
import { stack, flex, css } from '@bts-web/utils-style-engine';
import { CellSkeleton } from '../../common/components/loading/CellSkeleton';
import { HiddenDesktop } from '../../layouts/LayoutWithLeftDesktopNav/Reusable/HiddenDesktop/HiddenDesktop';

export const TransactionsPageSkeleton = () => (
  <div
    className={stack({
      gap: 'large',
      width: '100%',
    })}
    data-testid="transactions-skeleton-container"
  >
    <div className={stack({ paddingX: 'medium', gap: 'large' })}>
      <div className={flex({ gap: 'medium' })}>
        <HiddenDesktop>
          <div className={flex({ gap: 'medium', paddingY: 'small' })}>
            <SkeletonElement width="24px" height="24px" borderRadius="30px" />

            <div className={flex({ gap: 'medium' })}>
              <SkeletonElement width="50px" height="22px" borderRadius="30px" />

              <span className={css({ color: 'neutrals.fill_tertiary' })}>
                /
              </span>

              <SkeletonElement
                width="100px"
                height="22px"
                borderRadius="30px"
              />
            </div>
          </div>
        </HiddenDesktop>
      </div>

      <SkeletonElement height="30px" width="120px" borderRadius="30px" />
    </div>

    <div>
      <CellSkeleton />
      <CellSkeleton />
      <CellSkeleton />
      <CellSkeleton />
    </div>
  </div>
);
