import { PropsWithChildren } from 'react';
import { CheckUserInactivity } from '@bts-web/core-features/auth';
import { getServerUserInfo } from '@bts-web/core-features/common';
import { isDevelopmentEnv } from '@bts-web/utils-functions';
import { ProvidersParent } from './ProvidersParent';

export async function RootLayout({ children }: PropsWithChildren) {
  const userData = await getServerUserInfo();

  return (
    <>
      <ProvidersParent userInfoData={userData}>{children}</ProvidersParent>

      {!isDevelopmentEnv() && <CheckUserInactivity />}
    </>
  );
}
