import {
  MaximumFractionDigits,
  PrecisionFloat,
  getTargetFiatAndAssetAmount,
  intlFiatValueFormatting,
} from '@bts-web/utils-formatting';
import { TradeControllerVariants } from '../../utils';
import { MaximumTradeAmountType } from '../types';
import { AmountQuickSelectItem } from './../../../common/components/AmountQuickSelectButton/AmountQuickSelectButton';
import { getAppConfig, CURRENCY } from '@bts-web/core-app-config';

const createQuickSelectItem = (
  item: string,
  price: PrecisionFloat,
  opts: {
    currency: string;
    currentLocale: string;
  },
) => ({
  label: intlFiatValueFormatting(item, {
    locale: opts.currentLocale,
    currency: opts.currency,
  }),
  fiatAndAssetValues: getTargetFiatAndAssetAmount({
    sourceAmount: { value: item, precision: MaximumFractionDigits.FIAT },
    targetType: 'FIAT',
    price,
  }),
  value: `${item}`,
});

export const QUICK_SELECT_ITEMS_FOR_CURRENCY: Record<
  CURRENCY,
  Array<string>
> = {
  EUR: ['1', '50', '100'],
  AED: ['50', '100'],
};

export const SAVINGS_QUICK_SELECT_ITEMS_FOR_CURRENCY: Record<
  CURRENCY,
  Array<string>
> = {
  EUR: ['10', '50', '100'],
  AED: ['50', '100'],
};

export const getTradeQuickSelectButtons = ({
  transactionType,
  currentLocale,
  price,
  maximumTradeAmount,
  translatedMaxString,
}: {
  price: PrecisionFloat;
  transactionType: TradeControllerVariants;
  currentLocale: string;
  maximumTradeAmount: MaximumTradeAmountType;
  translatedMaxString: string;
}): AmountQuickSelectItem[] => {
  const { currency } = getAppConfig();

  const baseQuickSelectItems =
    transactionType === 'savings'
      ? SAVINGS_QUICK_SELECT_ITEMS_FOR_CURRENCY[currency]
      : QUICK_SELECT_ITEMS_FOR_CURRENCY[currency];

  let quickSelectionItems = baseQuickSelectItems.map((item) =>
    createQuickSelectItem(item, price, { currentLocale, currency }),
  );

  if (transactionType === 'sell' && maximumTradeAmount) {
    quickSelectionItems = [
      ...quickSelectionItems,
      {
        label: translatedMaxString,
        fiatAndAssetValues: {
          FIAT: maximumTradeAmount['FIAT'],
          ASSET: maximumTradeAmount['ASSET'],
        },
        value: 'MAX',
      },
    ];
  } else {
    quickSelectionItems = [
      ...quickSelectionItems,
      createQuickSelectItem('500', price, { currentLocale, currency }),
    ];
  }

  return quickSelectionItems;
};
