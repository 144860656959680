'use client';

import { TFunction } from 'i18next';
import {
  convertToLocaleDateString,
  getRoundedPrecision,
  intlFiatValueFormatting,
  PrecisionFloat,
} from '@bts-web/utils-formatting';
import { Locale, useClientTranslation } from '@bts-web/utils-lokalise';
import { css, stack } from '@bts-web/utils-style-engine';
import { getAppConfig } from '@bts-web/core-app-config';
import { ModalComponent } from '@bts-web/utils-context';
import { SuccessModal } from '../../../../common';
import { getSavingsPlansTranslations } from '../../../../savings-plans/utils/getSavingsPlansTranslations';
import { parseHtmlString } from '@bts-web/utils-functions';

export type SavingsPlanSuccessModalProps = {
  savingsPlanId: string;
  name: string;
  assetName: string;
  assetLogoUrl: string;
  fiatAmount: PrecisionFloat;
  nextRecurrence: string;
  locale: Locale;
};

export const SavingsPlanSuccessModal: ModalComponent<
  SavingsPlanSuccessModalProps
> = ({
  name,
  locale,
  savingsPlanId,
  fiatAmount,
  nextRecurrence,
  assetName,
  onClose,
}) => {
  const { t } = useClientTranslation();

  const translations = getSavingsPlansTranslations(t as TFunction);

  const { currency } = getAppConfig();

  const fiatAmountValue = intlFiatValueFormatting(
    getRoundedPrecision(fiatAmount),
    {
      locale,
      currency,
      fractionDigits: fiatAmount?.precision,
    },
  );

  const description = (
    <div
      className={stack({
        gap: 'extra_small_2',
        fontSize: 'body.medium',
        fontWeight: 'body.medium',
        letterSpacing: 'body.medium',
        lineHeight: 'body.medium',
        color: 'neutrals.text_secondary',
      })}
    >
      <div>
        <span>{translations.savingsPlanSuccessBodyLine1}</span>
        <span
          className={css({
            color: 'neutrals.text_primary',
          })}
        >
          &nbsp;
          {parseHtmlString(
            t('savings_plan_success_body_line_2', {
              amount: fiatAmountValue,
              assetName: assetName,
            }),
          )}
        </span>
        &nbsp;
        <span>{translations.savingsPlanSuccessBodyLine3}</span>
        <span
          className={css({
            color: 'neutrals.text_primary',
          })}
        >
          {convertToLocaleDateString(nextRecurrence, locale)}
        </span>
      </div>

      <div>
        <span>{translations.savingsPlanSuccessBody2Line1}</span>
        <span
          className={css({
            color: 'neutrals.text_primary',
          })}
        >
          {translations.savingsPlanSuccessBody2Line2}
        </span>
        <span>{translations.savingsPlanSuccessBody2Line3}</span>
      </div>
    </div>
  );

  return (
    <SuccessModal
      icon="checkmark-circle"
      title={translations.savingsPlanSuccessTitle}
      subtitle={`${name} ${translations.savingsPlanSuccessSubtitle}`}
      continueLink={{
        label: translations.savingsPlanSuccessCheckItOut,
        url: `/savings-plans/${savingsPlanId}`,
      }}
      poweredByLabel={translations.poweredByBitpanda}
      onClose={onClose}
      description={description}
    />
  );
};
