'use client';

import { ButtonComponentProps } from '../../common/types/components';
import { DrawerComponent, useComposableDrawer } from '@bts-web/utils-context';
import { cloneElement, ReactElement } from 'react';

export const DrawerTriggerButton = ({
  DrawerComponent,
  isDrawerOpeningNotAllowed,
  onClick,
  children,
}: {
  DrawerComponent: DrawerComponent;
  isDrawerOpeningNotAllowed?: boolean;
  onClick?: () => void;
  children: ReactElement<ButtonComponentProps>;
}) => {
  const { openDrawer } = useComposableDrawer();

  const button = children
    ? cloneElement(children, {
        onClick: () => {
          if (onClick) {
            onClick();
          }

          if (isDrawerOpeningNotAllowed) return;

          openDrawer(DrawerComponent);
        },
      })
    : null;

  return button;
};
