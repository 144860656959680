'use client';

import { Language } from '@bts-web/utils-lokalise';
import { useChangeLanguage } from '../../../../../language/index';
import { FC, useEffect, useState } from 'react';
import { LanguageChangeMenu } from '../../../../../language/LanguageChangeMenu/LanguageChangeMenu.use-client';
import { IMenuProps, Menu } from '../../../../../common/components/Menu/Menu';
import { TopBarMenuButton } from '../TopBarMenuButton/TopBarMenuButton';

const dropdownMenuProps: IMenuProps['dropdownMenuProps'] = {
  style: {
    width: '340px',
    maxWidth: `calc(100vw - 40px)`,
    backgroundColor: 'transparent',
    borderRadius: '12px',
  },
  align: 'end',
  sideOffset: 5,
  sticky: 'always',
  updatePositionStrategy: 'always',
};

export type TopBarLanguageTranslations = {
  germanSubtitle: string;
  englishSubtitle: string;
  errorTitle: string;
  errorSubtitle: string;
};

const TopbarLanguageMenuWrapper: FC<{
  translations: TopBarLanguageTranslations;
  defaultLanguage: Language;
}> = ({ translations, defaultLanguage }) => {
  const { onChangeLanguage, isUserUpdateLoading } = useChangeLanguage({
    translations: {
      errorSubtitle: translations.errorSubtitle,
      errorTitle: translations.errorTitle,
    },
  });

  const [currentLanguage, setCurrentLanguage] =
    useState<Language>(defaultLanguage);

  const onLanguageValueChange = (languageValue: string) => {
    setCurrentLanguage(languageValue as Language);
  };

  useEffect(() => {
    if (currentLanguage !== defaultLanguage) {
      onChangeLanguage({
        languageValue: currentLanguage,
        onError: () => {
          setCurrentLanguage(defaultLanguage);
        },
      });
    }
  }, [currentLanguage]);

  return (
    <Menu
      dropdownMenuProps={dropdownMenuProps}
      components={{
        buttonElement: (
          <TopBarMenuButton
            isLoading={isUserUpdateLoading}
            data-testid="language-button"
          >
            {
              {
                de: translations.germanSubtitle,
                en: translations.englishSubtitle,
              }[currentLanguage]
            }
          </TopBarMenuButton>
        ),
        menuContent: (
          <LanguageChangeMenu
            isWithinDropdown={true}
            translations={translations}
            onLanguageValueChange={onLanguageValueChange}
            currentValue={currentLanguage}
          />
        ),
      }}
    />
  );
};

export { TopbarLanguageMenuWrapper };
