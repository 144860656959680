import { ErrorBoundaryWithSuspense } from '../../layouts/ErrorBoundary/ErrorBoundaryWithSuspense';
import { TransactionsPageSkeleton } from '../../transactions/TransactionsPageSkeleton/TransactionsPageSkeleton.server';
import {
  SavingsPlansTransactionsController,
  SavingsPlansTransactionsControllerProps,
} from './SavingsPlansTransactionsController';

export const SavingsPlansTransactionsControllerWithSuspense = async ({
  translations,
}: SavingsPlansTransactionsControllerProps) => {
  return (
    <ErrorBoundaryWithSuspense
      fallbackOrSkeleton={<TransactionsPageSkeleton />}
    >
      <SavingsPlansTransactionsController translations={translations} />
    </ErrorBoundaryWithSuspense>
  );
};
