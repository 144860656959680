import Link from 'next/link';
import { css } from '@bts-web/utils-style-engine';
import { SavingsPlansTransactionsTranslationsKeys } from '../types';
import { HiddenDesktop } from '../../layouts/LayoutWithLeftDesktopNav/Reusable/HiddenDesktop/HiddenDesktop';
import { WrapperForMobileStickySectionHeader } from '../../layouts/LayoutWithLeftDesktopNav/Reusable/WrapperForMobileStickySectionHeader/WrapperForMobileStickySectionHeader';
import { TabBreadcrumbs } from '../../common';
import { getSavingsPlansTransactions } from '../../common/gqlActions/getSavingsPlansTransactions.action';
import { NavigationBackButton } from '../../navigation/index';
import { SavingsPlansTransactionsList } from '../SavingsPlansTransactionsList/SavingsPlansTransactionsList.use-client';
import { SavingsPlansTransactionsContextProvider } from '@bts-web/utils-context';
import { SAVINGS_PLANS_TRANSACTIONS_LIST_PAGE_SIZE } from '../../config/query';
import { ContentSectionWrapper } from '../../layouts';

export interface SavingsPlansTransactionsControllerProps {
  translations: SavingsPlansTransactionsTranslationsKeys;
}

const SavingsPlansTransactionsController = async ({
  translations,
}: SavingsPlansTransactionsControllerProps) => {
  const initialTransactionsData = await getSavingsPlansTransactions({
    first: SAVINGS_PLANS_TRANSACTIONS_LIST_PAGE_SIZE,
    states: ['FINISHED', 'FAILED'],
  });

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: ' column',
        flex: 'auto',
        minHeight: '100vh',
      })}
    >
      <WrapperForMobileStickySectionHeader>
        <HiddenDesktop>
          <div
            className={css({
              backgroundColor: 'screen_background.primary',
            })}
          >
            <TabBreadcrumbs LeftComponent={<NavigationBackButton />}>
              <Link href={'/savings-plans'}>{translations.savingsPlans}</Link>

              <span>{translations.transactions}</span>
            </TabBreadcrumbs>
          </div>
        </HiddenDesktop>
      </WrapperForMobileStickySectionHeader>

      <ContentSectionWrapper sectionTitle={translations.transactions}>
        <SavingsPlansTransactionsContextProvider
          initialTransactionsData={
            initialTransactionsData?.data?.savingsPlanTransactions
          }
        >
          <SavingsPlansTransactionsList translations={translations} />
        </SavingsPlansTransactionsContextProvider>
      </ContentSectionWrapper>
    </div>
  );
};

export { SavingsPlansTransactionsController };
