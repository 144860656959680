import {
  getLocale,
  i18n,
  initServerTranslation,
  Language,
  updateServerLanguage,
} from '@bts-web/utils-lokalise';
import { MutableNonNullableAsset, MutableNonNullableUser } from '../types';
import {
  AssetType,
  getTradeTranslations,
  TradeControllerVariants,
} from '../../utils';
import { applyAppropriatenessCheck } from '../utils/applyAppropriatenessCheck';
import { getTranslationsPerTradeType } from './utils/getTranslationsPerTradeType';
import { getServerUserInfo } from '../../../common/userinfo/InitialUserInfoFetcherAndParser/utils/getServerUserInfo';
import {
  NavigationBackButton,
  NavigationButton,
} from '../../../navigation/index';
import { NavigationBar } from '../../../common';
import { MainTradeDataFlowWrapper } from '../MainTradeDataFlowWrapper/MainTradeDataFlowWrapper';
import { TFunction } from 'i18next';
import { getAppConfig } from '@bts-web/core-app-config';

export interface TradeControllerPropsBase {
  variant: TradeControllerVariants;
  // is used to disable the appropriateness check when this component is used purely for background blurry purposes
  skipCheckAppropriateness?: boolean;
  assetByIdData: MutableNonNullableAsset;
}

const { t } = initServerTranslation();

const TradeController = async ({
  variant,
  skipCheckAppropriateness,
  assetByIdData,
}: TradeControllerPropsBase) => {
  await updateServerLanguage();

  const { __typename, id: assetId } = assetByIdData;

  const currentLocale = getLocale(i18n.resolvedLanguage as Language);

  const translations = getTradeTranslations(t as TFunction);

  const userData = await getServerUserInfo();

  if (['buy', 'savings'].includes(variant) && !skipCheckAppropriateness) {
    await applyAppropriatenessCheck({
      asset: {
        assetId: assetId,
        assetType: __typename as AssetType,
      },
      transactionType: variant.toUpperCase() as 'BUY' | 'SAVINGS',
    });
  }

  const { translatedInnerValues } = getTranslationsPerTradeType({
    assetType: __typename,
    translations,
    variant,
  });

  const variantsTitles: Record<TradeControllerVariants, string> = {
    buy: translations.buy,
    sell: translations.sell,
    savings: translations.savingsPlan,
  };

  const { currency } = getAppConfig();

  return (
    <>
      <NavigationBar
        title={variantsTitles[variant]}
        NavigationLeftSlot={<NavigationBackButton />}
        NavigationRightSlot={
          <NavigationButton
            icon="dismiss"
            to="/assets/portfolio"
            ariaLabel="close trade"
          />
        }
      />

      <MainTradeDataFlowWrapper
        initialData={{
          assetData: assetByIdData,
          userData: userData as MutableNonNullableUser,
        }}
        staticData={{
          currentLocale,
          translations,
          variant,
          currency,
          translatedInnerValues: translatedInnerValues,
        }}
      />
    </>
  );
};

export { TradeController };
