import { Locale } from '@bts-web/utils-lokalise';
import { DocumentationExternalLink } from '../../../../common';
import { ContentSectionWrapper } from '../../../../layouts';
import { AssetFields } from '../../AssetDetailsDataContext/AssetDetailsDataContext.use-client';
import { AnalystRatings } from './subSections/AnalystRatings/AnalystRatings';
import { NetIncome } from './subSections/NetIncome/NetIncome';

export type StockAssetDetailsTranslations = {
  assetDetailBuy: string;
  assetDetailHold: string;
  assetDetailSell: string;
  assetDividendYield: string;
  assetDividendPerShare: string;
  assetRevenue: string;
  assetPeRatio: string;
  assetNetIncome: string;
  netIncome: string;
  assetDetailAnalystRatingsDescription: string;
  assetDetailAnalysRatingsTitle: string;
  assetDetailAnalystRatingsFactset: string;
  buy: string;
  sell: string;
  hold: string;
  legalUrl: string;
};

export const AssetDetailsStocks = ({
  locale,
  assetData,
  translations,
}: {
  locale: Locale;
  assetData: AssetFields;
  translations: StockAssetDetailsTranslations;
}) => {
  const analystRatingsBreakdown = assetData?.recommendation?.breakdown;

  const renderAnalystRatings =
    analystRatingsBreakdown && analystRatingsBreakdown.length > 0;

  return (
    <>
      {renderAnalystRatings && (
        <AnalystRatings
          recommendationBreakdown={analystRatingsBreakdown}
          translations={translations}
          title={translations.assetDetailAnalysRatingsTitle}
          buyInfo={translations.assetDetailBuy}
          holdInfo={translations.assetDetailHold}
          sellInfo={translations.assetDetailSell}
          generalInfo={translations.assetDetailAnalystRatingsFactset}
        />
      )}

      {assetData?.yearlyNetIncomes &&
        assetData?.yearlyNetIncomes.length > 0 && (
          <NetIncome
            assetData={assetData}
            locale={locale as string}
            labels={{
              netIncome: translations.netIncome,
              assetDividendYield: translations.assetDividendYield,
              assetDividendPerShare: translations.assetDividendPerShare,
              assetRevenue: translations.assetRevenue,
              assetPeRatio: translations.assetPeRatio,
              assetNetIncome: translations.assetNetIncome,
            }}
          />
        )}

      {assetData && (
        <ContentSectionWrapper>
          <DocumentationExternalLink
            href={assetData?.legalUrl as string}
            label={translations.legalUrl}
            size="small"
            visual="special"
            icon="document"
          />
        </ContentSectionWrapper>
      )}
    </>
  );
};
