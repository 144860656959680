'use client';

import { FC, ReactNode } from 'react';
import { ButtonBase } from '../../../common/components/ButtonBase/ButtonBase';
import { Drawer } from '../../../layouts/Drawer/Drawer';
import { DrawerTriggerButton } from '../../../layouts/Drawer/DrawerTriggerButton';
import {
  AccessModalInfoType,
  useClientUserInfo,
  UserAccessModal,
} from '../../../common';
import {
  useComposableDrawer,
  useComposableModal,
} from '@bts-web/utils-context';

export const CryptoTransfersDrawer: FC<{
  buttonTransferTopNav: string;
  contentNode: ReactNode;
}> = ({ buttonTransferTopNav, contentNode }) => {
  const CryptoTransferDrawer = () => (
    <Drawer
      disableOverlayClose
      contentNode={contentNode}
      title={buttonTransferTopNav}
    />
  );

  const { showModal } = useComposableModal();

  const { closeDrawer } = useComposableDrawer();

  const { approved } = useClientUserInfo();

  const displayApprovalWarningModal = () => {
    if (!approved) {
      showModal(UserAccessModal, {
        type: AccessModalInfoType.WarningTransfer,
        onModalClose: closeDrawer,
      });
    }
  };

  return (
    <DrawerTriggerButton
      DrawerComponent={CryptoTransferDrawer}
      onClick={displayApprovalWarningModal}
      isDrawerOpeningNotAllowed={!approved}
    >
      <ButtonBase
        visual="secondary"
        size="small"
        data-testid="crypto-transfer-button"
      >
        {buttonTransferTopNav}
      </ButtonBase>
    </DrawerTriggerButton>
  );
};
