import { HiddenDesktop } from '../../layouts/LayoutWithLeftDesktopNav/Reusable/HiddenDesktop/HiddenDesktop';
import { NavigationBar } from '../../common';
import { NavigationBackButton } from '../../navigation';
import { HiddenMobile } from '../../layouts/LayoutWithLeftDesktopNav/Reusable/HiddenMobile/HiddenMobile';
import { css } from '@bts-web/utils-style-engine';
import { ReactNode } from 'react';
import { CloseDrawerButton } from '../CloseDrawerButton';

export interface TransactionDetailsLayoutProps {
  navigationBarTitle: string;
  children: ReactNode;
  backButtonComponent?: ReactNode;
  withClose?: boolean;
}

export const TransactionDetailsLayout = ({
  children,
  navigationBarTitle,
  backButtonComponent = <NavigationBackButton />,
  withClose = false,
}: TransactionDetailsLayoutProps) => {
  return (
    <div
      data-testid="transaction-details-layout"
      className={css({
        background: 'screen_background.primary',
        display: 'flex',
        flexDirection: 'column',
        flex: 'auto',
        gap: 'medium',
        minHeight: '100%',
      })}
    >
      <div
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: 'screen_background.primary',
          position: 'sticky',
          top: 0,
          width: '100%',
          '& nav': {
            width: '100%',
          },
        })}
      >
        <NavigationBar
          title={navigationBarTitle}
          NavigationLeftSlot={
            <HiddenDesktop>{backButtonComponent}</HiddenDesktop>
          }
          {...(withClose
            ? {
                NavigationRightSlot: (
                  <HiddenMobile>
                    <CloseDrawerButton />
                  </HiddenMobile>
                ),
              }
            : {})}
        />
      </div>

      <div
        className={css({
          width: '100%',
          gap: 'extra_small_2',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        })}
      >
        {children}
      </div>
    </div>
  );
};
