'use client';

import { FC, useMemo } from 'react';
import { getAppConfig } from '@bts-web/core-app-config';
import { Locale } from '@bts-web/utils-lokalise';
import { AreaChart, ProgressCircleIndeterminate } from '../../../../common';
import { AssetDetailsTranslations } from '../../../types';
import { generateChartData } from '../../../utils/generateChartData';
import { getChartRangeSelectorsConfig } from '../../../utils/getChartRangeSelectorsConfig';
import { useAssetDetailsDataContext } from '../../AssetDetailsDataContext/AssetDetailsDataContext.use-client';

interface IAssetDetailsAreaChartProps {
  locale: Locale;
  translations: AssetDetailsTranslations;
}

const AssetDetailsAreaChart: FC<IAssetDetailsAreaChartProps> = ({
  locale,
  translations,
}) => {
  const { assetData, loading, changeTimeRange, trend } =
    useAssetDetailsDataContext();

  const { currency } = getAppConfig();

  const { assetSeriesDataMaxPrecision, chartSeriesData } = useMemo(() => {
    const { assetSeriesDataMaxPrecision, chartSeriesData } = generateChartData(
      assetData.history,
    );

    return {
      assetSeriesDataMaxPrecision,
      chartSeriesData,
    };
  }, [assetData.history]);

  return (
    <AreaChart
      digitPrecision={assetSeriesDataMaxPrecision}
      locale={locale}
      currency={currency}
      loadingContentSlot={<ProgressCircleIndeterminate />}
      isLoading={loading}
      visual={trend}
      seriesData={chartSeriesData}
      rangeSelectorsConfig={getChartRangeSelectorsConfig(translations)}
      rangeSelectCallback={changeTimeRange}
      showMinMax
      showTimeframe
    />
  );
};

export { AssetDetailsAreaChart };
