import { ReactNode } from 'react';
import { AccordionContentImage } from './AccordionContentImage';

export type ContentProps = {
  title: string;
  subtitle?: string;
  image: string;
  fallbackImage?: string;
  children: ReactNode;
  onClick?: () => void;
  id?: string;
};

export const AccordionContent = ({
  title,
  subtitle,
  image,
  fallbackImage,
  onClick,
  id,
  children,
}: ContentProps) => {
  return (
    <div
      data-testid="content-container"
      data-element="content-container"
      onClick={onClick}
      {...(id ? { id } : {})}
    >
      <div data-testid="content-wrap" data-element="content-wrap">
        <AccordionContentImage
          image={image}
          title={title}
          fallbackImage={fallbackImage}
          height={35}
          width={35}
        />

        <div data-testid="content-header" data-element="content-header">
          <div data-testid="content-title" data-element="content-title">
            {title}
          </div>

          <div data-testid="content-subtitle" data-element="content-subtitle">
            {subtitle}
          </div>
        </div>
      </div>

      <div data-element="content-slot">{children}</div>
    </div>
  );
};
