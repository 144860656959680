import { AssetType } from '../../../asset-details/types';

export const getConvertedValueExplanation = (
  assetType: AssetType,
  tradeType: 'buy' | 'sell' | 'savings',
  translations: {
    inclSpread: string;
    inclFees: string;
    afterSpread: string;
    afterFees: string;
  },
) => {
  if (tradeType === 'buy' || tradeType === 'savings') {
    if (assetType === AssetType.CRYPTO || assetType === AssetType.METAL) {
      return translations.inclFees;
    }

    return translations.inclSpread;
  }

  if (tradeType === 'sell') {
    if (assetType === AssetType.CRYPTO || assetType === AssetType.METAL) {
      return translations.afterFees;
    }

    return translations.afterSpread;
  }
};
