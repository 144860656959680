import { css } from '@bts-web/utils-style-engine';
import { SkeletonElement } from '../SkeletonElement/SkeletonElement';

export const NavSkeleton = () => (
  <div
    className={css({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 50,
      padding: 'medium',
    })}
  >
    <SkeletonElement width="24px" height="24px" />
    <SkeletonElement width="80px" height="18px" />
    <SkeletonElement width="24px" height="24px" />
  </div>
);
