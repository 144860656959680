import { css } from '@bts-web/utils-style-engine';
import { FC } from 'react';
import { Icon } from '@bts-web/design-system/component/icon';

export const FailContent: FC<{
  failCardText: string;
}> = ({ failCardText }) => {
  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'negative.text_primary',
        backgroundColor: 'negative.fill_focused',
        borderRadius: '16px',
        paddingTop: 'medium',
        paddingInlineStart: 'medium',
        paddingInlineEnd: 'medium',
        paddingBottom: 'large',
        fontSize: 'body.medium',
        gap: 'small',
        maxWidth: 330,
        mx: 'auto',
        textAlign: 'center',
      })}
      aria-label="fail content"
    >
      <Icon
        icon="dismiss-circle"
        theme="regular"
        size="24"
        data-testid="error-card-icon"
      />
      <p>{failCardText}</p>
    </div>
  );
};
