import { ComponentProps } from 'react';
import { Status } from '../common/components';
import {
  CryptoTransfersTransactionsQuery,
  CryptoTransfersTransactionStatus,
  TransactionStatus,
  TransactionViewQuery,
} from '@bts-web/data-layer/server';

export const STATUS_COLORS: Record<
  AllTypeTransactionStatus,
  ComponentProps<typeof Status>['visual']
> = {
  CREATED: 'filledNeutralLight',
  EXPIRED: 'filledNeutralDark',
  FAILED: 'filledNeutralDark',
  FINISHED: 'positive',
  CANCELED: 'outlineCancelled',
  PENDING: 'outlineOnHold',
  PROCESSING: 'outlineOnHold',
  IN_PROGRESS: 'outlineOnHold',
  ON_HOLD: 'outlineOnHold',
  REJECTED: 'outlineCancelled',
} as const;

export type CryptoTransfersTransactionsItemType = NonNullable<
  NonNullable<
    NonNullable<
      CryptoTransfersTransactionsQuery['response']['cryptoTransfersTransactions']
    >['edges']
  >[number]
>['node'];

export type TransactionViewQueryData =
  TransactionViewQuery['response']['transaction'];

export type AllTypeTransactionStatus =
  | TransactionStatus
  | CryptoTransfersTransactionStatus;
