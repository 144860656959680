'use client';

import {
  getLocale,
  i18n,
  Language,
  useClientTranslation,
} from '@bts-web/utils-lokalise';
import { TFunction } from 'i18next';
import { Drawer } from '../../layouts';
import { useCryptoTransferTransactionDetails } from '../hooks/useCryptoTransferTransactionDetails.use-client';
import { getTransactionDetailsTranslations } from '../../transaction-details/utils';
import { CryptoTransferDetails } from '../CryptoTransferDetails/CryptoTransferDetails';
import {
  TransactionDetailsLayout,
  TransactionDetailsSkeleton,
} from '../../transaction-details';

export const transactionDetailsDrawerId = 'transaction_details_drawer';

export const CryptoTransferDetailsDrawer = ({
  cryptoTransferId,
  title,
}: {
  cryptoTransferId: string;
  title?: string | null;
}) => {
  const { cryptoTransferTransaction, isCryptoTransferTransactionFetching } =
    useCryptoTransferTransactionDetails({
      cryptoTransferId,
    });

  const locale = getLocale(i18n.resolvedLanguage as Language);

  const { t } = useClientTranslation();

  const translations = getTransactionDetailsTranslations(t as TFunction);

  return (
    <Drawer
      title={title}
      disableOverlayClose
      contentNode={
        <TransactionDetailsLayout
          navigationBarTitle={translations.topBar}
          withClose
        >
          {isCryptoTransferTransactionFetching ? (
            <TransactionDetailsSkeleton />
          ) : (
            <CryptoTransferDetails
              cryptoTransferTransaction={cryptoTransferTransaction}
              locale={locale}
              translations={translations}
              translationUtil={t as TFunction}
              title={title}
            />
          )}
        </TransactionDetailsLayout>
      }
    />
  );
};
