import { FC } from 'react';
import { TFunction } from 'i18next';
import { defaultLocale, initServerTranslation } from '@bts-web/utils-lokalise';
import {
  TransactionInput,
  TransactionViewQuery,
} from '@bts-web/data-layer/server';
import { getTransactionDetailView } from '../../common/gqlActions/getTransactionDetailView';
import { getTransactionDetailsTranslations } from '../utils';
import { TransactionDetails } from '../TransactionDetails/TransactionDetails';
import { transactionTypeByTypeName } from '../../transactions/types/transactions';

type TransactionDetailsContentProps = {
  transactionId: string;
  transactionType: string;
  locale: typeof defaultLocale;
};

const { t } = initServerTranslation();

const TransactionDetailsContent: FC<TransactionDetailsContentProps> = async ({
  transactionId,
  transactionType,
  locale,
}) => {
  const isSpTransactionInSpTransactionsList =
    transactionType ===
    transactionTypeByTypeName.InternalSavingsPlanTransaction;

  const isSpTransactionInGeneralTransactionsList =
    transactionType === transactionTypeByTypeName.SavingsPlanTransaction;

  const isSavingsPlanTransaction =
    isSpTransactionInGeneralTransactionsList ||
    isSpTransactionInSpTransactionsList;

  const transactionInput: TransactionInput = isSpTransactionInSpTransactionsList
    ? { externalTransactionId: transactionId }
    : {
        tradeId: transactionId,
      };

  const transactionData = await getTransactionDetailView(transactionInput);

  const translations = getTransactionDetailsTranslations(t as TFunction);

  const transaction = transactionData.data.transaction as NonNullable<
    TransactionViewQuery['response']['transaction']
  >;

  return (
    <TransactionDetails
      transaction={transaction}
      translations={translations}
      locale={locale}
      translationUtil={t as TFunction}
      title={transaction?.asset?.name}
      showActionButton={!isSavingsPlanTransaction}
    />
  );
};

export { TransactionDetailsContent };
