'use client';

import { useEffect, useState, useTransition } from 'react';
import { datadogErrorHelper } from '../../common/utils/datadogErrorHelper';
import { DatadogErrorLevels } from '@bts-web/utils-next-infrastructure';
import {
  getTransactionDetailView,
  useHandleGqlErrorsWithSnackbar,
} from '../../common';
import { TransactionViewQuery } from '@bts-web/data-layer/server';

export const useTransactionDetails = ({
  tradeId,
  isFetchedByExternalTransactionId,
}: {
  tradeId?: string;
  isFetchedByExternalTransactionId?: boolean;
}) => {
  const [isTransactionDataFetching, startTransactionDataFetching] =
    useTransition();

  const [transaction, setTransaction] =
    useState<TransactionViewQuery['response']['transaction']>(null);

  const { processErrors } = useHandleGqlErrorsWithSnackbar();

  useEffect(() => {
    startTransactionDataFetching(async () => {
      try {
        const response = await getTransactionDetailView(
          isFetchedByExternalTransactionId
            ? {
                externalTransactionId: tradeId,
              }
            : {
                tradeId,
              },
        );

        if (response?.errors) {
          processErrors(response.errors);
        }

        setTransaction(response?.data?.transaction || null);
      } catch (error) {
        datadogErrorHelper({
          errorMessage: JSON.stringify(error),
          errorSeverity: DatadogErrorLevels.HIGH,
        });
      }
    });
  }, [tradeId, isFetchedByExternalTransactionId]);

  return { isTransactionFetching: isTransactionDataFetching, transaction };
};
