import { initServerTranslation } from '@bts-web/utils-lokalise';
import { TFunction } from 'i18next';
import { getCryptoTransferDetailsTranslations } from './getCryptoTransferCryptoTransferDetailsTranslations';
import { CryptoTransfersTransactionStatus } from '@bts-web/data-layer/server';

export enum CryptoTransactionInfoStatus {
  PENDING = 'PENDING',
  ON_HOLD = 'ON_HOLD',
  PROCESSING = 'PROCESSING',
  CANCELED = 'CANCELED',
  REJECTED = 'REJECTED',
}

type CryptoTransferTransactionTranslationsByStatus = {
  header: string;
  title: string;
  description: string;
};

export const getCryptoTransferCryptoTransferDetailsLabelsByStatus = (
  status: CryptoTransfersTransactionStatus,
): CryptoTransferTransactionTranslationsByStatus => {
  const { t } = initServerTranslation();

  const translations = getCryptoTransferDetailsTranslations(t as TFunction);

  const translationsByStatus: Partial<
    Record<
      CryptoTransfersTransactionStatus,
      CryptoTransferTransactionTranslationsByStatus
    >
  > = {
    PENDING: {
      header: translations.verificationInProgress,
      title: translations.pendingStatusTitle,
      description: translations.pendingStatusDescription,
    },
    ON_HOLD: {
      header: translations.verificationInProgress,
      title: translations.onHoldStatusTitle,
      description: translations.onHoldDescription,
    },
    PROCESSING: {
      header: translations.verificationInProgress,
      title: translations.processingStatusTitle,
      description: translations.processingStatusDescription,
    },
    CANCELED: {
      header: translations.verificationInProgress,
      title: translations.cancelledStatusTitle,
      description: translations.cancelledStatusDescription,
    },
    REJECTED: {
      header: translations.verificationInProgress,
      title: translations.rejectedStatusTitle,
      description: translations.rejectedStatusDescription,
    },
  };

  return translationsByStatus[
    status
  ] as CryptoTransferTransactionTranslationsByStatus;
};
