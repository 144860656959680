'use client';

import { FC, useEffect } from 'react';
import { BuyTradeApplicationState } from '../../../TradeParent/types';
import { TradeScreenTranslations } from '../../../utils';
import { useSearchParams } from 'next/navigation';
import { APPLICATION_STATE_KEY } from '../constants';
import { useAppNotification } from '../../../../notifications/NotificationContextProvider';

const TradeBuyApplicationStateReacter: FC<{
  translations: TradeScreenTranslations;
}> = ({ translations }) => {
  const { setAppNotification, closeAllNotifications } = useAppNotification();

  const searchParams = useSearchParams();

  useEffect(() => {
    const applicationState = searchParams.get(
      APPLICATION_STATE_KEY,
    ) as BuyTradeApplicationState;

    if (applicationState) {
      //TODO: check if below applicationState-based logic is still used, otherwise this component should disappear
      if (applicationState === 'declined') {
        const {
          transactionDeclinedNotificationTitle,
          transactionDeclinedNotificationDescription,
        } = translations as TradeScreenTranslations;

        setAppNotification({
          title: transactionDeclinedNotificationTitle,
          subtitle: transactionDeclinedNotificationDescription,
          withClose: true,
          visual: 'error',
          customDuration: 'infinite',
        });
      }

      if (applicationState === 'failed') {
        const {
          transactionFailedNotificationTitle,
          transactionFailedNotificationDescription,
        } = translations as TradeScreenTranslations;

        setAppNotification({
          title: transactionFailedNotificationTitle,
          subtitle: transactionFailedNotificationDescription,
          withClose: true,
          visual: 'error',
          customDuration: 'infinite',
        });
      }
    }

    return () => {
      closeAllNotifications();
    };
  }, []);

  return null;
};

export { TradeBuyApplicationStateReacter };
