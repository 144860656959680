import { OpenDrawerFunction } from '@bts-web/utils-context';
import { TransactionEntryType } from './TransactionItem';
import {
  isCryptoTransferTransaction,
  transactionTypeByTypeName,
  TransactionTypes,
} from '../types/transactions';
import { CryptoTransferDetailsDrawer } from '../../crypto-transfer-details/CryptoTransferDetailsDrawer/CryptoTransferDetailsDrawer';
import { TransactionDetailsDrawer } from '../../transaction-details/TransactionDetailsDrawer/TransactionDetailsDrawer';

export const openTransactionDetailsDesktopDrawer = (
  openDrawer: OpenDrawerFunction,
  transaction: TransactionEntryType,
) => {
  const transactionType: TransactionTypes =
    transactionTypeByTypeName[
      transaction?.__typename as keyof typeof transactionTypeByTypeName
    ];

  if (isCryptoTransferTransaction(transactionType)) {
    const hasOfferid = (transaction as TransactionEntryType).offer?.id;

    openDrawer(CryptoTransferDetailsDrawer, {
      cryptoTransferId: hasOfferid ?? transaction.id,
      title: transaction?.asset?.name,
    });

    return;
  }

  const isSpTransactionInGeneralTransactionsList =
    transactionType === transactionTypeByTypeName.SavingsPlanTransaction;

  const isSpTransactionInSpTransactionsList =
    transactionType ===
    transactionTypeByTypeName.InternalSavingsPlanTransaction;

  const isSavingsPlanTransaction =
    isSpTransactionInGeneralTransactionsList ||
    isSpTransactionInSpTransactionsList;

  openDrawer(TransactionDetailsDrawer, {
    tradeId: transaction.id,
    title: transaction?.asset?.name,
    isFetchedByExternalTransactionId:
      transactionType ===
      transactionTypeByTypeName.InternalSavingsPlanTransaction,
    showActionButton: !isSavingsPlanTransaction,
  });
};
