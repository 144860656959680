import { FC } from 'react';
import { defaultLocale, initServerTranslation } from '@bts-web/utils-lokalise';
import { TFunction } from 'i18next';
import { getCryptoTransfersTransactions } from '../../common/gqlActions/getCryptoTransfersTransactions';
import { CryptoTransferDetails } from '../CryptoTransferDetails/CryptoTransferDetails';
import { getTransactionDetailsTranslations } from '../../transaction-details/utils';

type CryptoTransferDetailsContentProps = {
  cryptoTransferId: string;
  currency?: string;
  locale: typeof defaultLocale;
};

const { t } = initServerTranslation();

export const CryptoTransferDetailsContent: FC<
  CryptoTransferDetailsContentProps
> = async ({ cryptoTransferId, locale }) => {
  const cryptoTransferData = await getCryptoTransfersTransactions({
    id: cryptoTransferId,
  });

  const translations = getTransactionDetailsTranslations(t as TFunction);

  const cryptoTransfersTransactionNode =
    cryptoTransferData?.data?.cryptoTransfersTransactions?.edges?.[0]?.node ??
    null;

  return (
    <CryptoTransferDetails
      cryptoTransferTransaction={cryptoTransfersTransactionNode}
      locale={locale}
      translations={translations}
      translationUtil={t as TFunction}
    />
  );
};
