import { FC } from 'react';
import { css } from '@bts-web/utils-style-engine';
import { getTransactionDetailsFieldsData } from '../utils/getTransactionDetailsFieldsData';
import {
  TransactionDetailsField,
  TransactionDetailsFieldVariants,
} from '../TransactionDetailsField/TransactionDetailsField';
import { initServerTranslation, Locale } from '@bts-web/utils-lokalise';
import { TFunction } from 'i18next';
import { TransactionDetailsTransactionKeysResult } from '../utils';
import {
  CryptoTransfersTransactionsItemType,
  TransactionViewQueryData,
} from '../types';

const fieldsContainerStyles = css({
  marginBottom: 'extra_small',
  paddingX: 'medium',
  width: '100%',
});

export type TransactionDetailsWrapperProps = {
  transaction: TransactionViewQueryData | CryptoTransfersTransactionsItemType;
  translations: TransactionDetailsTransactionKeysResult & {
    assetSymbol: string;
    fiatSymbol: string;
    transactionType: string;
  };
  locale: Locale;
  currency?: string;
};

export const TransactionDetailsWrapper: FC<TransactionDetailsWrapperProps> = ({
  transaction,
  translations,
  locale,
  currency,
}) => {
  const { t } = initServerTranslation();

  const transactionDetailFields = getTransactionDetailsFieldsData({
    translations,
    transaction,
    locale,
    currency,
    translationUtil: t as TFunction,
  });

  return (
    <ul
      className={fieldsContainerStyles}
      role="listbox"
      data-testid="transaction-details-wrapper"
    >
      {transactionDetailFields.map((field) => {
        if (!field.value) {
          return null;
        }

        if (
          field.variant ===
            TransactionDetailsFieldVariants.TRANSACTION_STATUS ||
          field.variant ===
            TransactionDetailsFieldVariants.CRYPTO_TRANSACTION_STATUS
        ) {
          return (
            <TransactionDetailsField
              key={field.name}
              data-testid={field.name}
              locale={locale}
              label={field.label}
              value={field.value}
              status={field.status}
              variant={field.variant}
            />
          );
        }

        return (
          <TransactionDetailsField
            key={field.name}
            data-testid={field.name}
            label={field.label}
            locale={locale}
            value={field.value}
            variant={field.variant}
          />
        );
      })}
    </ul>
  );
};
