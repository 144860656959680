import { type AssetDetailsTranslations } from '../types';

export const assetDetailsTranslationsKeys: AssetDetailsTranslations = {
  CryptoAsset: 'dashboard_portfolio_crypto',
  MetalAsset: 'dashboard_portfolio_metals',
  StockAsset: 'currency_filter_stock',
  EtfAsset: 'currency_filter_ETF',
  EtcAsset: 'currency_filter_commodities',
  FiatEarnAsset: 'fiat_earn',
  marketValue: 'market_value',
  youOwn: 'you_own',
  dailyHigh: 'daily_high',
  assetFundSize: 'asset_fund_size',
  yearHigh: 'year_high',
  dailyLow: 'daily_low',
  volatility: 'volatility',
  yearLow: 'year_low',
  assetDetailOverview: 'asset_detail_overview',
  viewRelatedTransactions: 'view_related_transactions',
  tradeButton: 'trade_button',
  readLess: 'read_less',
  readMore: 'read_more',
  marketCap: 'market_cap',
  underlyingAsset: 'underlying_asset',
  assetName: 'asset_name',
  assetProvider: 'asset_provider',
  assetTotalExpenseRatio: 'asset_total_expense_ratio',
  assetDistributionPolicy: 'asset_distribution_policy',
  assetReplicationMethod: 'asset_replication_method',
  assetIsin: 'asset_isin',
  legalUrl: 'legal_documents_link',
  perAnnum: 'per_annum',
  last24h: 'last_24h',
  lastWeek: 'last_week',
  lastMonth: 'last_month',
  lastYear: 'last_year',
  maxYears: 'max',
  buy: 'buy',
  sell: 'sell',
  errorAssetNotAvailableTitle: 'info_toast_asset_unavailable_title',
  errorAssetNotAvailableSubtitle: 'info_toast_asset_unavailable_subtitle',
  inOneYear: 'in_one_year',
  inOneMonth: 'in_one_month',
  inOneWeek: 'in_one_week',
  inSixMonths: 'in_six_months',
  inMaxYears: 'max',
  in24Hours: 'in_24_hours',
  averageBuyPrice: 'average_buy_price',
  totalReturn: 'total_return',
  dailyReturn: 'daily_return',
};

export const getAssetDetailsTranslations = (
  t: (translationKey: keyof AssetDetailsTranslations) => string,
): AssetDetailsTranslations => {
  return Object.entries(assetDetailsTranslationsKeys).reduce(
    (acc, [key, value]) => {
      acc[key as keyof AssetDetailsTranslations] = t(
        value as keyof AssetDetailsTranslations,
      );

      return acc;
    },
    {} as AssetDetailsTranslations,
  );
};
