'use client';

import {
  SearchBar,
  SearchBarProps,
} from '@bts-web/design-system/component/search-bar';
import { css } from '@bts-web/utils-style-engine';

const ThemedSearchBar = ({ ...props }: SearchBarProps['external']) => (
  <SearchBar
    className={css({
      position: 'relative',
      backgroundColor: 'neutrals.fill_quinary',
      color: 'neutrals.text_secondary',
      borderEndEndRadius: 'smallBottomRight',
      borderEndStartRadius: 'smallTopRight',
      borderStartStartRadius: 'smallTopLeft',
      borderStartEndRadius: 'smallBottomLeft',
    })}
    inputClassName={css({
      fontSize: 'title.medium',
      lineHeight: 'title.medium',
      letterSpacing: 'title.medium',
      fontWeight: 'title.medium',
      backgroundColor: 'transparent',
      width: '100%',
      height: '100%',
      outline: 'none',
      zIndex: '2',
      paddingTop: 'small',
      paddingBottom: 'small',
      paddingInlineStart: 'extra_large_3',
      paddingInlineEnd: 'medium',
      '& ~ [data-search]': {
        color: 'neutrals.text_secondary',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: '1',
        insetInlineStart: '20px',
        display: 'flex',
        alignItems: 'center',
      },
      '&:focus, &[data-state="filled"]': {
        color: 'neutrals.text_primary',
        '& ~ [data-search]': {
          color: 'neutrals.text_primary',
        },
      },
      '& ~ [data-clear]': {
        color: 'neutrals.fill_secondary',
        position: 'absolute',
        top: 0,
        display: 'none',
        zIndex: '3',
        insetInlineEnd: '0px',
        width: 'extra_large_3',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
      },
      '&:is(:valid)': {
        '& ~ [data-clear]': {
          display: 'flex',
        },
      },
    })}
    {...props}
  />
);

export { ThemedSearchBar as SearchBar };
