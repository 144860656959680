import { DatadogErrorLevels } from '@bts-web/utils-next-infrastructure';
import { PrecisionFloat } from '@bts-web/utils-formatting';
import {
  datadogErrorHelper,
  getAssetByIdAndHystoricalInterval,
} from '../../common';
import { AssetType } from '../types';

type ValueChange = {
  value: string;
  percentage: string;
};

type ExtractedAssetDetails = {
  totalReturn: ValueChange;
  fiatBalance: NonNullable<PrecisionFloat>;
  assetBalance: NonNullable<PrecisionFloat>;
  assetName: string;
  assetType: AssetType;
  assetSymbol: string;
  isAssetOwned: boolean;
  averageBuyPrice: NonNullable<PrecisionFloat>;
  dailyReturn: ValueChange;
};

export type AssetDetailsResponse = NonNullable<
  Awaited<
    ReturnType<typeof getAssetByIdAndHystoricalInterval>
  >['data']['assetById']
>;

export function extractAssetDetailsInfoFromResponse(props: {
  responseData: AssetDetailsResponse | null | undefined;
  assetId: string;
}): ExtractedAssetDetails & {
  responseData: NonNullable<typeof props.responseData>;
} {
  const { assetId, responseData } = props;

  if (!responseData) {
    datadogErrorHelper({
      errorMessage: `Asset data not found for asset ${assetId}`,
      errorSeverity: DatadogErrorLevels.CRITICAL,
    });

    throw new Error('Asset data not found');
  }

  const {
    __typename: assetType,
    portfolio: assetPortfolio,
    symbol,
    name,
  } = responseData;

  // Extract portfolio data
  const rootPortfolioInfo = responseData.portfolio;

  const portfolioEdge = assetPortfolio?.edges?.[0];

  const portfolioNode = portfolioEdge?.node?.assets?.edges?.[0] ?? null;

  // Extract and process portfolio values
  const assetBalance: NonNullable<PrecisionFloat> =
    portfolioNode?.assetBalance ??
      rootPortfolioInfo?.assetBalance ?? { value: '0', precision: 0 };

  const averageBuyPrice: NonNullable<PrecisionFloat> =
    portfolioNode?.averageBuyPrice ?? { value: '0', precision: 0 };

  const fiatBalance: NonNullable<PrecisionFloat> = portfolioNode?.fiatBalance ??
    rootPortfolioInfo?.fiatBalance ?? { value: '0', precision: 0 };

  const totalInvested = Number(
    portfolioNode?.totalInvested?.value ??
      rootPortfolioInfo?.totalInvested?.value ??
      0,
  );

  const isAssetOwned = Number(assetBalance.value) > 0;

  // TODO remove temporary workaround after whitelabel api fix
  /**
   * Below code is a temporary workaround that fixes the totalReturn percentage value bug
   * which is shown as 100% when an asset is not owned
   * See ticket: https://bitpanda.atlassian.net/browse/PAL-2200
   */
  const totalReturn: ValueChange =
    (Number(assetBalance.value) === 0 ||
      (isAssetOwned && totalInvested === 0)) &&
    portfolioNode?.totalReturn
      ? {
          value: '0.00',
          percentage: '0.00',
        }
      : (portfolioNode?.totalReturn as ValueChange);
  /** 👆 End of temporary workaround for totalReturn percentage */

  return {
    totalReturn,
    fiatBalance,
    assetBalance,
    assetName: name ?? 'N/A',
    assetType: assetType as AssetType,
    assetSymbol: symbol ?? 'N/A',
    isAssetOwned,
    averageBuyPrice,
    responseData: responseData,
    dailyReturn: portfolioNode?.dailyReturn ?? { value: '0', percentage: '0' },
  };
}
