import { updateServerLanguage } from '@bts-web/utils-lokalise';
import { type AppProps } from 'next/app';

export const withRuntimeLanguage =
  <P extends AppProps['pageProps']>(Component: React.FC<P>) =>
  async (props: P) => {
    await updateServerLanguage();

    return <Component {...(props && props)} />;
  };
