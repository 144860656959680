import { ErrorBoundaryWithSuspense } from '../../layouts/ErrorBoundary/ErrorBoundaryWithSuspense';
import { SavingsPlansLoadingSkeleton } from '../SavingsPlansLoadingSkeleton/SavingsPlansLoadingSkeleton';
import {
  SavingsPlansController,
  SavingsPlansControllerProps,
} from './SavingsPlansController';

export const SavingsPlansControllerWithSuspense = async ({
  translations,
  assetId,
}: SavingsPlansControllerProps) => {
  return (
    <ErrorBoundaryWithSuspense
      fallbackOrSkeleton={<SavingsPlansLoadingSkeleton />}
    >
      <SavingsPlansController translations={translations} assetId={assetId} />
    </ErrorBoundaryWithSuspense>
  );
};
