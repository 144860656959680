import { type AssetsListGroup } from '@bts-web/data-layer/server';
import { AssetType } from '../types';

/** @todo remove, as the const enum cannot be inlined: https://github.com/microsoft/TypeScript/issues/33703
 * and Babel/SWC requires isolatedModules: true */
const enum ListOfAssetGroups {
  'ListOfAssetGroups' = 'ListOfAssetGroups',
}

const makeListOfAssetGroups = (
  ...groups: AssetsListGroup[]
): ListOfAssetGroups => groups.join(',') as ListOfAssetGroups;

const AssetTypeFilters: Record<AssetType, AssetsListGroup | ListOfAssetGroups> =
  {
    [AssetType.CRYPTO]: makeListOfAssetGroups('COIN', 'TOKEN'),
    [AssetType.METAL]: 'METAL',
    [AssetType.STOCK]: 'STOCK',
    [AssetType.ETC]: 'ETC',
    [AssetType.ETF]: 'ETF',
  };

export { AssetTypeFilters };
