import { css } from '@bts-web/utils-style-engine';
import { Locale } from '@bts-web/utils-lokalise';
import {
  assetUnitsValueToPercentage,
  getRoundedPrecision,
  PrecisionFloat,
  intlFiatValueFormatting,
} from '@bts-web/utils-formatting';
import type { AcceptsDataTestid } from '@bts-web/utils-test/types';
import { getAppConfig } from '@bts-web/core-app-config';
import {
  PriceVariation,
  PriceVariationComponentProps,
} from '../../../../../../common';

type ValueChange = {
  value: string;
  percentage: string;
};

const priceSectionStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 'extra_small_2',
  '& > span': {
    color: 'neutrals.fill_primary',
    fontSize: 'caption.small',
  },
});

interface AssetDetailsPriceProps extends AcceptsDataTestid {
  locale: Locale;
  priceVariation?: ValueChange | null;
  label: string;
  value?: PrecisionFloat | null;
  children?: React.ReactNode;
  hideLabel?: boolean;
  assetBalance?: string;
  bolderTitle?: boolean;
  timestampText?: PriceVariationComponentProps['timestampText'];
  hidePercentage?: boolean;
}

export const AssetDetailsPrice = ({
  label,
  locale,
  value,
  priceVariation,
  hideLabel,
  assetBalance,
  bolderTitle = false,
  timestampText,
  hidePercentage,
}: AssetDetailsPriceProps) => {
  const { currency } = getAppConfig();

  const precisionFloatValue = getRoundedPrecision(value);

  const formattedValue = intlFiatValueFormatting(precisionFloatValue, {
    locale,
    currency,
    fractionDigits: value?.precision,
  });

  let trend = 'neutral' as PriceVariationComponentProps['trend'];

  let visual = 'filledNeutral' as PriceVariationComponentProps['visual'];

  if (priceVariation?.value) {
    const priceChangeValue = Number(priceVariation.value);

    trend =
      priceChangeValue > 0
        ? 'positive'
        : priceChangeValue < 0
          ? 'negative'
          : 'neutral';

    visual =
      priceChangeValue > 0
        ? 'filledPositive'
        : priceChangeValue < 0
          ? 'filledNegative'
          : 'filledNeutral';
  }

  return (
    <div className={priceSectionStyles} aria-label="asset price container">
      {!hideLabel && <span>{label}</span>}

      <div>
        <h6
          className={css({
            color: 'neutrals.text_primary',
            fontSize: 'headline.medium_medium',
            fontWeight: bolderTitle
              ? 'headline.large_semi_bold'
              : 'headline.medium_medium',
            lineHeight: 'headline.medium_medium',
            letterSpacing: 'headline.medium_medium',
          })}
        >
          {formattedValue}
        </h6>
        {assetBalance && (
          <p
            className={css({
              fontSize: 'body.medium',
              mt: 'extra_small_4',
            })}
          >
            {assetBalance}
          </p>
        )}
      </div>
      <PriceVariation
        size="medium"
        trend={trend}
        visual={visual}
        timestampText={timestampText}
        value={
          priceVariation?.value
            ? intlFiatValueFormatting(priceVariation?.value, {
                locale,
                currency,
                fractionDigits: value?.precision,
              })
            : ''
        }
        percentage={
          !hidePercentage
            ? assetUnitsValueToPercentage(priceVariation?.percentage, {
                isAbsolute: true,
                locale,
              })
            : undefined
        }
      />
    </div>
  );
};
