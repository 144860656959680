/**
 * @generated SignedSource<<14aa5211657a038e9bb5f4af0984c14a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CryptoTransfersTransactionRequiredActions = "BENEFICIARY_DETAILS";
export type CryptoTransfersTransactionStatus = "CANCELED" | "FINISHED" | "ON_HOLD" | "PENDING" | "PROCESSING" | "REJECTED";
export type CryptoTransfersTransactionType = "DEPOSIT" | "WITHDRAWAL";
export type LegalClassification = "ARTS" | "EMTS" | "NONE" | "OTHER";
export type TaxDeclarationStatus = "DECLARED" | "NOT_ELIGIBLE" | "UNDECLARED" | "UNKNOWN";
export type CryptoTransfersTransactionsListInput = {
  assetId?: string | null;
  coinNetworkId?: string | null;
  cursor?: string | null;
  dateEnd?: any | null;
  dateStart?: any | null;
  id?: string | null;
  size?: number | null;
  status?: ReadonlyArray<CryptoTransfersTransactionStatus> | null;
  type?: CryptoTransfersTransactionType | null;
};
export type CryptoTransfersTransactionsQuery$variables = {
  input: CryptoTransfersTransactionsListInput;
};
export type CryptoTransfersTransactionsQuery$data = {
  readonly cryptoTransfersTransactions: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: string;
        readonly asset: {
          readonly __typename: string;
          readonly id: string;
          readonly legalClassification: LegalClassification | null;
          readonly logoUrl: string | null;
          readonly minimumSellAmount: PrecisionFloat | null;
          readonly name: string | null;
          readonly symbol: string | null;
        };
        readonly assetAmount: PrecisionFloat;
        readonly assetFee: PrecisionFloat;
        readonly createdAt: any | null;
        readonly creditedAt: any | null;
        readonly externalAddress: {
          readonly address: string;
          readonly createdAt: any;
          readonly destinationTag: string | null;
          readonly destinationTagType: string | null;
          readonly id: string;
          readonly network: {
            readonly id: string;
            readonly name: string | null;
          };
        } | null;
        readonly fiat: {
          readonly id: string;
          readonly name: string | null;
          readonly symbol: string | null;
        };
        readonly fiatAmount: PrecisionFloat;
        readonly fiatFee: PrecisionFloat;
        readonly hash: string | null;
        readonly id: string;
        readonly internalAddress: {
          readonly address: string;
          readonly createdAt: any;
          readonly destinationTag: string | null;
          readonly destinationTagType: string | null;
          readonly id: string;
          readonly network: {
            readonly id: string;
            readonly name: string | null;
          };
        } | null;
        readonly network: {
          readonly id: string;
          readonly name: string | null;
        };
        readonly requiredActions: ReadonlyArray<CryptoTransfersTransactionRequiredActions | null> | null;
        readonly status: CryptoTransfersTransactionStatus;
        readonly taxDeclarationStatus: TaxDeclarationStatus | null;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
    };
  } | null;
};
export type CryptoTransfersTransactionsQuery = {
  response: CryptoTransfersTransactionsQuery$data;
  variables: CryptoTransfersTransactionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Network",
  "kind": "LinkedField",
  "name": "network",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "address",
    "storageKey": null
  },
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "destinationTag",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "destinationTagType",
    "storageKey": null
  },
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CryptoTransfersTransactionsConnection",
    "kind": "LinkedField",
    "name": "cryptoTransfersTransactions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endCursor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasNextPage",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CryptoTransfersTransactionsEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CryptoAddress",
                "kind": "LinkedField",
                "name": "internalAddress",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CryptoAddress",
                "kind": "LinkedField",
                "name": "externalAddress",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "asset",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "logoUrl",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "legalClassification",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minimumSellAmount",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "assetAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "assetFee",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Fiat",
                "kind": "LinkedField",
                "name": "fiat",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fiatAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fiatFee",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hash",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "requiredActions",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "taxDeclarationStatus",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creditedAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CryptoTransfersTransactionsQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CryptoTransfersTransactionsQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "b7d15f8e0e6a7ff3109104e3a348f6fd",
    "id": null,
    "metadata": {},
    "name": "CryptoTransfersTransactionsQuery",
    "operationKind": "query",
    "text": "query CryptoTransfersTransactionsQuery(\n  $input: CryptoTransfersTransactionsListInput!\n) {\n  cryptoTransfersTransactions(input: $input) {\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      node {\n        __typename\n        id\n        internalAddress {\n          id\n          address\n          network {\n            id\n            name\n          }\n          destinationTag\n          destinationTagType\n          createdAt\n        }\n        externalAddress {\n          id\n          address\n          network {\n            id\n            name\n          }\n          destinationTag\n          destinationTagType\n          createdAt\n        }\n        asset {\n          id\n          logoUrl\n          name\n          symbol\n          legalClassification\n          minimumSellAmount\n          __typename\n        }\n        assetAmount\n        assetFee\n        fiat {\n          id\n          name\n          symbol\n        }\n        fiatAmount\n        fiatFee\n        network {\n          id\n          name\n        }\n        hash\n        status\n        requiredActions\n        taxDeclarationStatus\n        createdAt\n        creditedAt\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "81e8276dcd2b4e175137f18adee41432";

export default node;
