import { ReactNode } from 'react';
import { UserAccessModalTranslations } from './getUserAccessModalTranslations';

type ModalDataConfig = {
  icon: {
    name: string;
    type: string;
  };
  title: string;
  message: string;
  action: {
    component?: ReactNode;
    label?: string;
    to?: string;
  };
};

export enum AccessModalInfoType {
  ReadOnly = 'readOnly',
  InfoApprovalPending = 'infoApprovalPending',
  WarningTrade = 'warningTrade',
  WarningTransfer = 'warningTransfer',
}

export const getAccessModalInfo = (
  translations: UserAccessModalTranslations,
  type: AccessModalInfoType,
): ModalDataConfig => {
  const config: { [key: string]: ModalDataConfig } = {
    readOnly: {
      icon: {
        name: 'eye',
        type: 'info',
      },
      title: translations.userRoleModalReadOnlyTitle,
      message: translations.userRoleModalReadOnlySubtitle,
      action: {
        label: translations.goToPortfolio,
        to: '/assets/portfolio',
      },
    },
    infoApprovalPending: {
      icon: {
        name: 'warning',
        type: 'warning',
      },
      title: translations.userStageInfoPendingApproval,
      message: translations.userStageModalPendingApprovalSubtitle,
      action: {
        label: translations.goToPortfolio,
        to: '/assets/portfolio',
      },
    },
    warningTrade: {
      icon: {
        name: 'warning',
        type: 'warning',
      },
      title: translations.errorTitleTradingUnavailable,
      message: translations.errorMessageTradingUnavailable,
      action: {
        label: translations.goToPortfolio,
        to: '/assets/portfolio',
      },
    },
    warningTransfer: {
      icon: {
        name: 'warning',
        type: 'warning',
      },
      title: translations.errorTitleTransfersUnavailable,
      message: translations.errorMessagePendingApprovalTransfers,
      action: {
        label: translations.goToPortfolio,
        to: '/assets/portfolio',
      },
    },
  };

  return config[type] || {};
};
