import { getPrecisionFloatValue } from '@bts-web/utils-formatting';
import { AreaChartProps } from '@bts-web/design-system/component/chart';
import { type AssetDetailsViewQuery } from '@bts-web/data-layer/server';

const generateChartData = (
  assetHistory: NonNullable<
    AssetDetailsViewQuery['response']['assetById']
  >['history'],
): {
  chartSeriesData: AreaChartProps['external']['seriesData'];
  assetSeriesDataMaxPrecision: number;
} => {
  let assetSeriesDataMaxPrecision = 0;

  const chartSeriesData: AreaChartProps['external']['seriesData'] = (
    assetHistory || []
  ).map((item) => {
    assetSeriesDataMaxPrecision = Math.max(
      Number(item?.close?.precision),
      assetSeriesDataMaxPrecision,
    );

    return [
      new Date(item?.time || '').getTime(),
      Number(getPrecisionFloatValue(item?.close) || 0),
    ];
  });

  return {
    chartSeriesData,
    assetSeriesDataMaxPrecision,
  };
};

export { generateChartData };
