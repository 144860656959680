import {
  getPrecisionFloatValue,
  getRoundedPrecision,
  intlAssetValueFormatting,
  intlFiatValueFormatting,
} from '@bts-web/utils-formatting';
import { css } from '@bts-web/utils-style-engine';
import { FC } from 'react';
import { NonNullableTransactionFinalizedSummaryData } from '../../../utils';
import { getAppConfig } from '@bts-web/core-app-config';
import { initServerTranslation } from '@bts-web/utils-lokalise';

const successBuySubtitlesStyles = css({
  fontSize: 'body.medium',
  color: 'neutrals.text_secondary',
});

const successBuyTitleHighlight = css({
  color: 'neutrals.text_primary',
});

export const SuccessBuyContent: FC<{
  currentLocale: string;
  transactionData: NonNullableTransactionFinalizedSummaryData;
  translatedValues: {
    firstLine: string;
    screenSubtitle: string;
    screenSubtitleTwo: string;
  };
}> = ({ currentLocale, transactionData, translatedValues }) => {
  const { t } = initServerTranslation();

  const { additionalBuyConfirmationText, currency } = getAppConfig();

  const lbbwAdditionalLine = additionalBuyConfirmationText
    ? t(additionalBuyConfirmationText as any)
    : null;

  const { firstLine, screenSubtitle, screenSubtitleTwo } = translatedValues;

  let amount = '';

  let fiatAmount = '';

  if (transactionData) {
    amount = intlAssetValueFormatting(
      getPrecisionFloatValue(transactionData.assetAmount),
      {
        locale: currentLocale,
        fractionDigits: transactionData?.assetAmount?.precision,
      },
    );

    fiatAmount = intlFiatValueFormatting(
      getRoundedPrecision(transactionData.fiatAmount),
      {
        locale: currentLocale,
        currency,
      },
    );
  }

  return (
    <p
      className={successBuySubtitlesStyles}
      data-testid="tradeSuccessBuyMessage"
      aria-label="trade success buy message"
    >
      {firstLine}
      <span className={successBuyTitleHighlight}>
        {t('success_buy_subtitle_line_2', {
          amount,
          assetname: transactionData?.asset?.name,
          fiatamount: fiatAmount,
          interpolation: {
            escapeValue: false,
          },
        })}
      </span>
      <br />
      {screenSubtitle}{' '}
      <span className={successBuyTitleHighlight}>{screenSubtitleTwo}</span>
      {lbbwAdditionalLine ? (
        <>
          <br />
          <br />
          <span>{lbbwAdditionalLine}</span>
        </>
      ) : null}
    </p>
  );
};
