import { stack, hstack, css, flex } from '@bts-web/utils-style-engine';
import { Divider, NavSkeleton, SkeletonElement } from '../common';
import { HiddenMobile } from '../layouts/LayoutWithLeftDesktopNav/Reusable/HiddenMobile/HiddenMobile';
import { HiddenDesktop } from '../layouts';

// const MOCK_CHART_DATA: RangeSelectorConfigItem[] = [
//   {
//     timeAmountId: TimeAmounts.OneDay,
//     text: '1D',
//   },
//   {
//     timeAmountId: TimeAmounts.SevenDays,
//     text: '7D',
//   },
//   {
//     timeAmountId: TimeAmounts.OneMonth,
//     text: '30D',
//   },
//   {
//     timeAmountId: TimeAmounts.SixMonths,
//     text: '6M',
//   },
//   {
//     timeAmountId: TimeAmounts.OneYear,
//     text: '1Y',
//   },
//   {
//     timeAmountId: TimeAmounts.MaxYears,
//     text: 'Max',
//   },
// ];

const AssetOverviewSkeleton = () => (
  <div
    className={stack({
      gap: 'small',
      paddingX: 'medium',
      paddingY: 'large',
      width: '100%',
    })}
    data-testid="portfolio-overview-skeleton-container"
  >
    <SkeletonElement
      width="193px"
      height="32px"
      borderRadius="16px"
      testId="portfolio-overview-skeleton-title"
    />
    <div className={hstack({ justifyContent: 'space-between' })}>
      <div
        className={stack({ gap: 'small' })}
        data-testid="portfolio-overview-skeleton-left"
      >
        <SkeletonElement width="62px" height="14px" borderRadius="30px" />
        <SkeletonElement width="104px" height="14px" borderRadius="30px" />
      </div>
      <div
        className={stack({ gap: 'small' })}
        data-testid="portfolio-overview-skeleton-right"
      >
        <div className={hstack()}>
          <SkeletonElement width="62px" height="14px" borderRadius="30px" />
          <SkeletonElement width="52px" height="14px" borderRadius="30px" />
        </div>
        <div className={hstack()}>
          <SkeletonElement width="62px" height="14px" borderRadius="30px" />
          <SkeletonElement width="52px" height="14px" borderRadius="30px" />
        </div>
      </div>
    </div>
  </div>
);

const Overview = () => (
  <div
    className={stack({
      gap: 'small',
      paddingX: 'medium',
      paddingY: 'large',
      width: '100%',
    })}
    data-testid="portfolio-overview-skeleton-container"
  >
    <SkeletonElement
      width="193px"
      height="32px"
      borderRadius="16px"
      testId="portfolio-overview-skeleton-title"
    />
    <div className={hstack({ justifyContent: 'space-between' })}>
      <div
        className={stack({ gap: 'small' })}
        data-testid="portfolio-overview-skeleton-left"
      >
        <SkeletonElement width="62px" height="14px" borderRadius="30px" />
        <SkeletonElement width="104px" height="14px" borderRadius="30px" />
      </div>
      <div
        className={stack({ gap: 'small' })}
        data-testid="portfolio-overview-skeleton-right"
      >
        <div className={hstack()}>
          <SkeletonElement width="62px" height="14px" borderRadius="30px" />
          <SkeletonElement width="52px" height="14px" borderRadius="30px" />
          <SkeletonElement width="52px" height="14px" borderRadius="30px" />
        </div>
        <div className={hstack()}>
          <SkeletonElement width="62px" height="14px" borderRadius="30px" />
          <SkeletonElement width="52px" height="14px" borderRadius="30px" />
          <SkeletonElement width="52px" height="14px" borderRadius="30px" />
        </div>
      </div>
    </div>
  </div>
);

const Breadcrumbs = () => (
  <div
    className={flex({
      gap: 'medium',
      paddingX: 'medium',
      paddingY: 'medium',
    })}
  >
    <SkeletonElement width="24px" height="24px" borderRadius="30px" />

    <div className={flex({ gap: 'medium' })}>
      <SkeletonElement width="50px" height="22px" borderRadius="30px" />
      <span className={css({ color: 'neutrals.fill_tertiary' })}>/</span>
      <SkeletonElement width="100px" height="22px" borderRadius="30px" />
    </div>
  </div>
);

const Asset = () => (
  <div
    className={css({
      paddingX: 'medium',
      display: 'flex',
      flexDirection: 'row',
      gap: 'extra_small_2',
    })}
  >
    <SkeletonElement width="40px" height="40px" borderRadius="50%" />

    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: 'extra_small_2',
      })}
    >
      <SkeletonElement width="150px" height="25px" />

      <SkeletonElement width="30px" height="18px" />
    </div>
  </div>
);

export const AssetDetailsHeaderSkeleton = () => (
  <div
    className={css({
      display: 'flex',
      height: '100%',
    })}
  >
    <HiddenMobile
      className={css({
        width: '100%',
      })}
    >
      <div
        className={css({
          width: '100%',
          display: 'flex',
          pt: 'small',
          paddingInlineStart: 'medium',
          gap: 'extra_small_2',
        })}
      >
        <SkeletonElement width="48" height="48" borderRadius="50%" />

        <div
          className={css({
            display: 'flex',
            flexDirection: 'column',
            gap: 'extra_small_3',
          })}
        >
          <SkeletonElement width="90px" height="30px" />
          <SkeletonElement width="30px" height="18px" />
        </div>
      </div>
      <SkeletonElement
        width="100%"
        height="500px"
        backgroundColor="white"
        className={css({
          borderColor: 'white',
          borderBottomColor: 'neutrals.fill_focused',
          borderWidth: '1px',
        })}
      />
      <AssetOverviewSkeleton />
    </HiddenMobile>

    <HiddenDesktop>
      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          pb: 'medium',
          width: '100%',
          maxWidth: {
            lg: '420px',
          },
        })}
      >
        <NavSkeleton />

        <Divider />

        <Breadcrumbs />

        <Asset />

        {/* <AreaChart
          locale="en-GB"
          rangeSelectorsConfig={MOCK_CHART_DATA}
          seriesData={[]}
        /> */}

        <Overview />
      </div>
    </HiddenDesktop>
  </div>
);
