'use client';

import { FC, ReactNode, useEffect, useState } from 'react';
import { Icon } from '@bts-web/design-system/component/icon';
import {
  Link,
  linkStyles,
  LinkSizeVariants,
  LinkVisualVariants,
} from '../Link/Link';

type BaseDocumentationExternalLinkProps = {
  href: string;
  download?: string;
  size?: LinkSizeVariants;
  visual?: LinkVisualVariants;
  // this is to be used when the target site has 'Refused to display 'URL' in a frame because it set 'X-Frame-Options' to 'sameorigin'.' so we open the link in a new tab
  useRawOpenUrl?: boolean;
  icon?: string;
  className?: string;
};

type DocumentationExternalLinkProps =
  | (BaseDocumentationExternalLinkProps & {
      label: string;
      children?: never;
    })
  | (BaseDocumentationExternalLinkProps & {
      label?: never;
      children: ReactNode;
    });

/**
 * DocumentationExternalLink component renders a link that can be used to navigate to an external document.
 * It supports different types of links such as download links, raw open URLs, and navigation links.
 * The component also handles Safari-specific behavior for PDF links.
 *
 * @param {DocumentationExternalLinkProps} props - The properties for the DocumentationExternalLink component.
 * @param {string} props.label - The label for the link.
 * @param {string} props.href - The URL to navigate to.
 * @param {boolean} [props.download] - If true, the link will be a download link.
 * @param {React.ReactNode} props.children - The children elements to be rendered inside the link.
 * @param {boolean} [props.useRawOpenUrl] - If true, the link will open in a new tab.
 * @param {string} [props.size='small'] - The size of the link.
 * @param {string} [props.visual='primary'] - The visual style of the link.
 * @param {string} [props.icon] - The icon to be displayed alongside the link.
 * @param {string} [props.className] - Additional class names for the link.
 * @param {object} [props.otherProps] - Additional properties to be passed to the link element.
 *
 * @returns {JSX.Element} The rendered DocumentationExternalLink component.
 */
const DocumentationExternalLink: FC<DocumentationExternalLinkProps> = ({
  label,
  href,
  download,
  children,
  useRawOpenUrl,
  size = 'small',
  visual = 'primary',
  icon,
  className,
  ...otherProps
}) => {
  const [isSafari, setIsSafari] = useState(false);

  const route = `/document?documentUrl=${href}&documentTitle=${label ? label : typeof children === 'string' ? children : ''}`;

  const downloadLinkProps = { href, download };

  const navigationLinkProps = {
    href: route,
    passHref: true,
  };

  const externalLinkProps = { href, target: '_blank' };

  const props = (() => {
    if (download) return downloadLinkProps;

    if (useRawOpenUrl || (isSafari && href.endsWith('.pdf'))) {
      return externalLinkProps;
    }

    return navigationLinkProps;
  })();

  const isNative = useRawOpenUrl || download;

  const Element = isNative ? 'a' : Link;

  useEffect(() => {
    const ua = navigator.userAgent.toLowerCase();

    setIsSafari(
      ua.includes('safari') && !ua.includes('chrome') && !ua.includes('crios'),
    );
  }, []);

  return (
    <Element
      {...props}
      aria-label="external-link"
      className={`${linkStyles({ visual, size })} ${className}`}
      data-testid="documentation-external-link"
      role="link"
      tabIndex={0}
      {...(icon &&
        !isNative && { RightComponent: <Icon theme="regular" icon={icon} /> })}
      {...otherProps}
    >
      {label || children}
    </Element>
  );
};

export { DocumentationExternalLink };
