'use client';

import { ReactElement } from 'react';
import { css } from '@bts-web/utils-style-engine';

const buttonTitleStyles = css({
  color: 'neutrals.text_primary',
  fontSize: 'label.medium_medium',
  fontWeight: 'label.medium_medium',
  letterSpacing: 'label.medium_medium',
  lineHeight: 'label.medium_medium',
  textAlign: 'start',
});

const iconContainerStyles = css({
  display: 'flex',
  backgroundColor: 'transparent',
  width: '32px',
  height: '32px',
  borderRadius: '100px',
  justifyContent: 'center',
  alignItems: 'center',
});

const buttonStyles = css({
  display: 'flex',
  gap: 'small',
  alignItems: 'center',
  cursor: 'pointer',
  padding: 'medium',
  borderBottomWidth: '1px',
  borderBottomColor: 'neutrals.divider',
  '&:active': {
    background: 'rgba(1,1,1,0.1)',
  },
});

const buttonContentStyles = css({
  display: 'flex',
  gap: '2px',
  flexDirection: 'column',
  alignItems: 'start',
  flex: 1,
});

const buttonSubTitleStyles = css({
  color: 'neutrals.text_secondary',
  fontSize: 'caption.small',
  fontWeight: 'caption.small',
  lineHeight: 'caption.small',
  letterSpacing: 'caption.small',
  textAlign: 'start',
});

interface TradeOptionButtonProps {
  title: string;
  subTitle?: string;
  icon: ReactElement;
  tag?: ReactElement;
  onClick: () => void;
}

const TradeOptionButton = ({
  title,
  subTitle,
  icon,
  tag,
  onClick,
  ...rest
}: TradeOptionButtonProps) => (
  <button
    type="button"
    className={buttonStyles}
    onClick={onClick}
    aria-label={title}
    {...rest}
  >
    <div className={iconContainerStyles}>{icon}</div>

    <div className={buttonContentStyles}>
      <span className={buttonTitleStyles}>{title}</span>

      {subTitle && <span className={buttonSubTitleStyles}>{subTitle}</span>}
    </div>

    {tag ? tag : null}
  </button>
);

export { TradeOptionButton };
