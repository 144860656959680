import { css } from '@bts-web/utils-style-engine';
import { HiddenMobile } from '../LayoutWithLeftDesktopNav/Reusable/HiddenMobile/HiddenMobile';
import { FC } from 'react';
import { i18n, initServerTranslation, Language } from '@bts-web/utils-lokalise';
import { TopbarLanguageMenuWrapper } from '../LayoutWithLeftDesktopNav/DesktopTopHeaderBar/subcomponents/TopbarLanguageMenuWrapper/TopbarLanguageMenuWrapper';
import { TFunction } from 'i18next';
import { getMainNavigationTranslations } from '../LayoutWithLeftDesktopNav/utils/getMainNavigationTranslations';
import { getAppConfig } from '@bts-web/core-app-config';

const containerStyles = css({
  display: 'flex',
  height: '100vh',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: 'small',
  overflow: 'auto',
  lg: {
    backgroundColor: 'screen_background.primary',
    gap: 'large',
    paddingY: 'large',
    alignItems: 'center',
  },
});

const contentWrapperStyles = css({
  display: 'flex',
  height: '100vh',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: 'screen_background.primary',
  lg: {
    border: '1px solid #eaeaea',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    justifyContent: 'space-between',
    gap: 'small',
    height: 'auto',
    width: '520px',
  },
});

interface ResponsiveLayoutWithLogoProps extends React.PropsWithChildren {
  ariaLabel?: string;
  showLanguageSwitcher?: boolean;
}

const { t } = initServerTranslation();

const ResponsiveLayoutWithLogoHeader: FC<{
  showLanguageSwitcher?: boolean;
}> = ({ showLanguageSwitcher }) => {
  const defaultSelectedLanguage = i18n.resolvedLanguage as Language;

  const { appLogo } = getAppConfig();

  const LogoElement = appLogo;

  const translations = getMainNavigationTranslations(t as TFunction);

  if (showLanguageSwitcher) {
    return (
      <HiddenMobile
        className={css({
          width: '520px',
        })}
      >
        <div
          className={css({
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          })}
        >
          <div
            className={css({
              width: '102px',
            })}
          />
          <LogoElement size={25} />

          <TopbarLanguageMenuWrapper
            translations={translations}
            defaultLanguage={defaultSelectedLanguage}
          />
        </div>
      </HiddenMobile>
    );
  }

  return (
    <HiddenMobile>
      <LogoElement size={25} />
    </HiddenMobile>
  );
};

const ResponsiveLayoutWithLogo = ({
  children,
  ariaLabel,
  showLanguageSwitcher,
}: ResponsiveLayoutWithLogoProps) => {
  return (
    <div aria-label={ariaLabel} className={containerStyles}>
      <ResponsiveLayoutWithLogoHeader
        showLanguageSwitcher={showLanguageSwitcher}
      />
      <div className={contentWrapperStyles}>{children}</div>
    </div>
  );
};

export { ResponsiveLayoutWithLogo };
