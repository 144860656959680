'use client';

import { useEffect, useState } from 'react';
import Image, { ImageProps, StaticImageData } from 'next/image';
import { defaultAssetPngImg, defaultAssetInlineEncoded } from '../../assets';
import { getImageSrc } from './utils';

export type ImageSource =
  | string
  | StaticImageData
  | { default: StaticImageData }
  | { src: string; blurDataURL?: string };

type AssetImageProps = Omit<ImageProps, 'src'> & {
  testId?: string;
  placeholder?: string;
  src: ImageSource | undefined;
  height: number;
  width: number;
};

const AssetImage = ({
  alt,
  width,
  height,
  src,
  priority,
  testId = 'asset-image',
  placeholder = 'blur',
  ...props
}: AssetImageProps) => {
  const [imageSrc, setImageSrc] = useState<string>(() =>
    src
      ? getImageSrc({ height: Number(height), width: Number(width), src })
      : getImageSrc({
          height: Number(height),
          width: Number(width),
          src: defaultAssetPngImg,
        }),
  );

  useEffect(() => {
    setImageSrc(
      src
        ? getImageSrc({ height: Number(height), width: Number(width), src })
        : getImageSrc({
            height: Number(height),
            width: Number(width),
            src: defaultAssetPngImg,
          }),
    );
  }, [src, height, width]);

  const handleImageError = () => {
    const defaultSrc = getImageSrc({
      height: Number(height),
      width: Number(width),
      src: defaultAssetPngImg,
    });

    if (imageSrc !== defaultSrc) {
      setImageSrc(defaultSrc);
    }
  };

  return (
    <Image
      width={width}
      height={height}
      src={imageSrc}
      alt={alt}
      priority={priority}
      title={alt}
      placeholder={placeholder}
      blurDataURL={defaultAssetInlineEncoded}
      style={{ width, height }}
      onError={handleImageError}
      loading="eager"
      unoptimized={true}
      data-testid={testId}
      {...props}
    />
  );
};

export { AssetImage };
