'use client';

import { useState, PropsWithChildren } from 'react';
import type {
  AccordionComponentProps,
  AreaChartComponentProps,
  NavigationBackButtonComponentProps,
  SelectComponentProps,
  SortButtonComponentProps,
  TransactionCellComponentProps,
  AssetImageComponentProps,
  NavigationBarComponentProps,
  ProgressBarComponentProps,
  ListCellsSelectionItemComponentProps,
  ListCellsSelectionRootComponentProps,
  SkeletonElementProps,
  ToastComponentProps,
} from '../../../types/components';
import { IconProps } from '@bts-web/design-system/component/icon';

enum SortOrderOption {
  desc = 'DESC',
  asc = 'ASC',
}

export const DonutChartComponentMock = () => (
  <div data-testid="donut-chart-mock"></div>
);

export const AreaChartComponentMock = ({
  isDisabled,
  isLoading,
  loadingContentSlot,
  rangeSelectCallback,
  rangeSelectorsConfig,
}: AreaChartComponentProps) => (
  <div data-testid="area-chart-mock">
    {rangeSelectorsConfig?.map((rangeConfig) => (
      <button
        key={rangeConfig.timeAmountId}
        data-testid="tabBar-mini-button"
        disabled={isDisabled}
        onClick={(_e) => rangeSelectCallback?.(rangeConfig.timeAmountId)}
      >
        {rangeConfig.text}
      </button>
    ))}
    {isLoading && loadingContentSlot}
  </div>
);

export const NavigationButtonMock = () => <button>Button navigation</button>;

export const AssetImageComponentMock = ({
  testId,

  alt,
  src,
}: AssetImageComponentProps) => (
  <img data-testid={testId} src={src as string} alt={alt} />
);

export const NavigationBackButtonComponentMock = ({
  handleVirtualNavigation,
}: NavigationBackButtonComponentProps) => (
  <button
    onClick={handleVirtualNavigation}
    data-testid="navigation-back-button-mock"
  ></button>
);

export const TransactionCellComponentMock = ({
  key,
  title,
  transactionType,
  date,
  moneyAmountText,
  coinAmountText,
}: TransactionCellComponentProps) => (
  <div data-testid="transaction-cell" key={key}>
    <span>{title}</span>
    <span>{transactionType}</span>
    <span>{date}</span>
    <span>{moneyAmountText}</span>
    <span>{coinAmountText}</span>
  </div>
);

export const AccordionComponentMock = ({ items }: AccordionComponentProps) => {
  return (
    <div>
      {items.map((item) => (
        <div key={item.value}>
          <div data-testid="trigger" onClick={item.header.onClick}>
            {item.header.title}
          </div>
          <div>{item.header.subtitle}</div>
          <div>
            {item.contents.map((contentItem) => (
              <div key={contentItem.title} onClick={contentItem.onClick}>
                <span>{contentItem.title}</span>
                <span>{contentItem.subtitle}</span>
                {contentItem.ContentIndicatorSlot}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export const SelectComponentMock = ({
  options,
  value,
  onChange,
  defaultValue,
  ...otherProps
}: SelectComponentProps) => {
  const [stateValue, setStateValue] = useState(defaultValue);

  const newValue = value || stateValue;

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const findItem = options.find(
      (option: { value: string }) => option.value === event.target.value,
    );

    setStateValue(event.target.value);

    onChange(event.target.value, findItem);
  };

  return (
    <select
      {...otherProps}
      onChange={handleChange}
      value={newValue}
      data-testid="select"
    >
      {options.map((option: { value: string; label: string }) => (
        <option key={option.label} value={option.value} data-testid="option">
          {option.label}
        </option>
      ))}
    </select>
  );
};

export const SortButtonComponentMock = ({
  onChange,
}: SortButtonComponentProps) => {
  const [sortValue, setSortValue] = useState('DESC');

  const handleClick = () => {
    const newSortValue =
      sortValue === SortOrderOption.desc
        ? SortOrderOption.asc
        : SortOrderOption.desc;

    setSortValue(newSortValue);

    onChange(newSortValue);
  };

  return (
    <button data-state={sortValue} onClick={handleClick}>
      Sort button
    </button>
  );
};

export const NavigationBarComponentMock = ({
  NavigationLeftSlot,
  NavigationRightSlot,
  title,
  isTitleCentered,
  children,
}: NavigationBarComponentProps) => (
  <>
    {NavigationLeftSlot}
    {title && (
      <div
        data-testid="navigation-bar-title"
        data-visual={isTitleCentered ? 'centered' : ''}
      >
        {title}
      </div>
    )}
    {children}
    {NavigationRightSlot}
  </>
);

export const ProgressBarComponentMock = ({
  progress,
}: ProgressBarComponentProps) => (
  <div data-testid="progress-bar" data-value={progress} />
);

export const ListCellsSelectionItemComponentMock = ({
  itemRef,
  className,
  activeItemId,
  itemId,
  icon,
  label,
  subLabel,
  children,
  value,
  isDisabled,
  ...rest
}: ListCellsSelectionItemComponentProps) => {
  return (
    <div
      ref={itemRef as unknown as React.Ref<HTMLDivElement>}
      data-testid="list-cells-selection-item-container"
      className={className}
    >
      <label htmlFor={itemId}>
        <span>
          {icon} {label}
        </span>
        <span>{subLabel}</span>
      </label>
      <div className="item-slot">
        <button
          data-testid="list-cells-selection-item"
          role="radio"
          aria-checked={value === activeItemId ? 'true' : 'false'}
          data-state={value === activeItemId ? 'checked' : 'unchecked'}
          value={value}
          id={itemId}
          disabled={isDisabled}
          {...rest}
        >
          {children}
        </button>
      </div>
    </div>
  );
};

export const ListCellsSelectionRootComponentMock = ({
  children,
  onValueChange,

  ...rest
}: ListCellsSelectionRootComponentProps) => (
  <div
    role="radiogroup"
    data-testid="list-cells-selection-root"
    onClick={(event) => {
      const value = (event.target as HTMLInputElement).value;

      onValueChange?.(value);
    }}
    {...rest}
  >
    {children}
  </div>
);

export const ToastProviderMock = ({ children }: PropsWithChildren) => (
  <div>{children}</div>
);

export const ToastMock = ({
  onOpenChange,
  open,
  subtitle,
  title,
  action,
  withClose = false,
  duration = 5000,
}: ToastComponentProps) => (
  <div>
    {open && (
      <>
        <div>{title}</div>
        <div>{action?.component}</div>
        <div>duration:{duration}</div>
        <div>{subtitle}</div>
        <div>{`withClose:${withClose}`}</div>
        <button onClick={() => onOpenChange(!open)}>Toggle</button>
      </>
    )}
  </div>
);

export const SkeletonElementComponentMock = ({
  testId,
}: SkeletonElementProps) => <div data-testid={testId} />;

export const IconComponentMock = ({ icon }: IconProps) => (
  <span data-testid={`icon_${icon}`}>{icon}</span>
);
