/**
 * @generated SignedSource<<ce41b0945506b01364ec466e0c7bb20a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CorporateActionType = "DELISTING" | "DIVIDEND" | "MERGER_CASH" | "MERGER_CASH_STOCK" | "MERGER_STOCK" | "REVERSE_STOCK_SPLIT" | "RIGHTS_ISSUE" | "SPIN_OFF" | "STOCK_SPLIT" | "TENDER";
export type CryptoTransfersTransactionRequiredActions = "BENEFICIARY_DETAILS";
export type CryptoTransfersTransactionStatus = "CANCELED" | "FINISHED" | "ON_HOLD" | "PENDING" | "PROCESSING" | "REJECTED";
export type CryptoTransfersTransactionType = "DEPOSIT" | "WITHDRAWAL";
export type NonUserInitiatedTransactionDirection = "INCOMING" | "OUTGOING";
export type NonUserInitiatedTransactionType = "GIVEAWAY" | "METAL_STORAGE_FEE" | "OTC_TRADE" | "STAKING_REWARD" | "TAX_REFUND";
export type NotificationType = "CORPORATE_ACTION" | "CRYPTO_TRANSFER" | "NEW_LEGAL_DOCUMENTS" | "NON_USER_INITIATED_TRANSACTION" | "REPORT";
export type ReportType = "ACCOUNT_STATEMENT" | "CRYPTO_TAX";
export type NotificationsInput = {
  after?: string | null;
  first?: number | null;
  type?: NotificationType | null;
};
export type NotificationsViewQuery$variables = {
  input?: NotificationsInput | null;
};
export type NotificationsViewQuery$data = {
  readonly notificationsV2: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly node: {
        readonly __typename: string;
        readonly content?: {
          readonly asset: {
            readonly id: string;
            readonly name: string | null;
            readonly symbol: string | null;
          };
          readonly assetAmount: PrecisionFloat;
          readonly corporateActionType?: CorporateActionType;
          readonly cryptoTransferType?: CryptoTransfersTransactionType;
          readonly direction?: NonUserInitiatedTransactionDirection;
          readonly fiat: {
            readonly id: string;
            readonly name: string | null;
            readonly symbol: string | null;
          };
          readonly fiatAmount: PrecisionFloat;
          readonly id: string;
          readonly nuitType?: NonUserInitiatedTransactionType;
          readonly reportType: ReportType;
          readonly requiredAction?: CryptoTransfersTransactionRequiredActions | null;
          readonly status?: CryptoTransfersTransactionStatus;
        };
        readonly createdAt: any;
        readonly id: string;
        readonly readAt: any | null;
        readonly translationKey: string;
      } | null;
    } | null> | null;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
      readonly startCursor: string | null;
    } | null;
    readonly totalCount: number | null;
    readonly unreadCount: number | null;
  } | null;
};
export type NotificationsViewQuery = {
  response: NotificationsViewQuery$data;
  variables: NotificationsViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unreadCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "translationKey",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v11 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportNotificationContent",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v7/*: any*/),
        {
          "alias": "reportType",
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ReportNotification",
  "abstractKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v14 = [
  (v7/*: any*/),
  (v12/*: any*/),
  (v13/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "asset",
  "plural": false,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assetAmount",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "Fiat",
  "kind": "LinkedField",
  "name": "fiat",
  "plural": false,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fiatAmount",
  "storageKey": null
},
v19 = {
  "alias": "cryptoTransferType",
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiredAction",
  "storageKey": null
},
v22 = {
  "alias": "corporateActionType",
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v23 = {
  "alias": "nuitType",
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "direction",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "asset",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v7/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationsViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NotificationConnection",
        "kind": "LinkedField",
        "name": "notificationsV2",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CryptoTransferNotificationContent",
                        "kind": "LinkedField",
                        "name": "content",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "CryptoTransferNotification",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CorporateActionNotificationContent",
                        "kind": "LinkedField",
                        "name": "content",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v22/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "CorporateActionNotification",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NonUserInitiatedTransactionNotificationContent",
                        "kind": "LinkedField",
                        "name": "content",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "NonUserInitiatedTransactionNotification",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationsViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NotificationConnection",
        "kind": "LinkedField",
        "name": "notificationsV2",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CryptoTransferNotificationContent",
                        "kind": "LinkedField",
                        "name": "content",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v25/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "CryptoTransferNotification",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CorporateActionNotificationContent",
                        "kind": "LinkedField",
                        "name": "content",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v25/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v22/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "CorporateActionNotification",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "NonUserInitiatedTransactionNotificationContent",
                        "kind": "LinkedField",
                        "name": "content",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v25/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v23/*: any*/),
                          (v24/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "NonUserInitiatedTransactionNotification",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e4fd8d9dd942aa9b480545e43a4239b4",
    "id": null,
    "metadata": {},
    "name": "NotificationsViewQuery",
    "operationKind": "query",
    "text": "query NotificationsViewQuery(\n  $input: NotificationsInput\n) {\n  notificationsV2(input: $input) {\n    totalCount\n    unreadCount\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        __typename\n        id\n        translationKey\n        readAt\n        createdAt\n        ... on ReportNotification {\n          content {\n            id\n            reportType: type\n          }\n        }\n        ... on CryptoTransferNotification {\n          content {\n            id\n            asset {\n              __typename\n              id\n              name\n              symbol\n            }\n            assetAmount\n            fiat {\n              id\n              name\n              symbol\n            }\n            fiatAmount\n            cryptoTransferType: type\n            status\n            requiredAction\n          }\n        }\n        ... on CorporateActionNotification {\n          content {\n            id\n            asset {\n              __typename\n              id\n              name\n              symbol\n            }\n            assetAmount\n            fiat {\n              id\n              name\n              symbol\n            }\n            fiatAmount\n            corporateActionType: type\n          }\n        }\n        ... on NonUserInitiatedTransactionNotification {\n          content {\n            id\n            asset {\n              __typename\n              id\n              name\n              symbol\n            }\n            assetAmount\n            fiat {\n              id\n              name\n              symbol\n            }\n            fiatAmount\n            nuitType: type\n            direction\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "04a9c5c0f663c2d47c25e83136d42d56";

export default node;
