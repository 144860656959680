import { css } from '@bts-web/utils-style-engine';
import { getLocale, i18n, Language } from '@bts-web/utils-lokalise';
import { TermsAndConditionsViewQuery } from '@bts-web/data-layer/server';
import { IAgreeButton } from '../IAgreeButton/IAgreeButton';
import { getAppConfig, links } from '@bts-web/core-app-config';
import { IAgreeExtraTermsButton } from '../IAgreeExtraTermsButton/IAgreeExtraTermsButton.use-client';
import { TermsTranslations } from '../types';
import {
  TermsAndPrivacy,
  termsContentTextStyles,
} from '../TermsAndPrivacy/TermsAndPrivacy.use-client';
import { AppUserFullInfo } from '../../common/userinfo/types';
import { DocumentationExternalLink } from '../../common/components';

interface TermsAndConditionsContentProps {
  translations: TermsTranslations;
  onboarded: AppUserFullInfo['onboarded'];
  userType: AppUserFullInfo['userType'];
  termsAndConditions: TermsAndConditionsViewQuery['response']['termsAndConditions'];
  firstAmlQuestionId?: string;
  onClose?: () => void;
  className?: string;
}

const TermsAndConditionsContent = ({
  onboarded,
  className,
  userType,
  termsAndConditions,
  translations,
  firstAmlQuestionId,
  onClose,
}: TermsAndConditionsContentProps) => {
  const { riskDisclosureLinks } = getAppConfig();

  const locale = getLocale(i18n.resolvedLanguage as Language);

  const riskLinkDisclosureByTranslation =
    riskDisclosureLinks[i18n.resolvedLanguage as Language];

  const groupPrivacyLinkByTranslation =
    locale === 'en-GB' ? links.groupPrivacyLinkEN : links.groupPrivacyLinkDE;

  const hasTerms = Boolean(
    termsAndConditions &&
      termsAndConditions?.edges &&
      termsAndConditions?.edges?.length,
  );

  const AgreeButton =
    // had to split into two separate buttons due to the WL behavior of not being able to call updateUser before onboardUser
    onboarded === true ? (
      <IAgreeExtraTermsButton
        userType={userType}
        termsAndConditions={termsAndConditions}
        onClose={onClose}
        disabled={!hasTerms}
      >
        <span>{translations.termsAcceptButton}</span>
      </IAgreeExtraTermsButton>
    ) : (
      <IAgreeButton
        userType={userType}
        firstAmlQuestionId={firstAmlQuestionId}
        disabled={!hasTerms}
      >
        <span>{translations.iAgree}</span>
      </IAgreeButton>
    );

  const LinksSpacing = () => <>&nbsp;&amp;&nbsp;</>;

  return (
    <div
      className={`${css({
        gap: 'medium',
        display: 'flex',
        flexDirection: 'column',
        flex: 'auto',
        overflowY: 'auto',
        height: '100%',
      })} ${className}`}
      data-testid="terms-and-conditions-content"
    >
      <TermsAndPrivacy
        onboarded={onboarded}
        translations={translations}
        termsAndConditions={termsAndConditions}
      />
      <div
        className={css({
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        })}
      >
        <div
          data-testid="risk-terms"
          className={`${termsContentTextStyles}  ${css({ mt: 'auto', display: 'flex', gap: 'extra_large', flexDirection: 'column' })}`}
        >
          <p className={termsContentTextStyles}>
            <span>{translations.riskTermDisclosure}</span>
            &nbsp;
            <DocumentationExternalLink
              size="small"
              data-testid="risk-terms-link"
              visual="primaryUnderline"
              href={riskLinkDisclosureByTranslation}
              label={translations.term_risk_link}
            />
            <LinksSpacing />
            <DocumentationExternalLink
              size="small"
              data-testid="group-privacy-link"
              visual="primaryUnderline"
              href={groupPrivacyLinkByTranslation}
              label={translations.onboardingTermsGroupPrivacy}
            />
          </p>

          {AgreeButton}
        </div>
      </div>
    </div>
  );
};

export { TermsAndConditionsContent };
