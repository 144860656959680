import { assetUnitsValueToPercentage } from '@bts-web/utils-formatting';
import { StockAssetDetailsTranslations } from '../types';
import { TFunction } from 'i18next';
import { AssetDetailsViewQuery$data } from '@bts-web/data-layer/server';

interface IgetStockAssetTranslationsProps {
  t: TFunction;
  assetData: NonNullable<AssetDetailsViewQuery$data['assetById']>;
  locale: string;
}

export const getStockAssetTranslations = ({
  assetData,
  locale,
  t,
}: IgetStockAssetTranslationsProps): StockAssetDetailsTranslations => {
  const breakDown = assetData?.recommendation?.breakdown;

  const breakdownToObject = breakDown?.length
    ? Object.fromEntries(
        breakDown?.map((obj) => [
          obj?.type,
          obj?.percentage && obj?.percentage * 100,
        ]),
      )
    : {};

  return {
    netIncome: t('asset_title_net_income'),
    assetDetailAnalysRatingsTitle: t('asset_detail_analyst_ratings_title'),
    buy: t('buy'),
    sell: t('sell'),
    hold: t('hold'),
    assetDetailAnalystRatingsDescription: t(
      'asset_detail_analyst_ratings_description',
      {
        number: assetData.recommendation?.totalCount,
      },
    ),
    assetDetailAnalystRatingsFactset: t('asset_detail_analyst_ratings_factset'),
    assetDetailBuy: t('asset_detail_buy', {
      percent: assetUnitsValueToPercentage(breakdownToObject['buy'], {
        locale: locale,
      }),
    }),
    assetDetailHold: t('asset_detail_hold', {
      percent: assetUnitsValueToPercentage(breakdownToObject['hold'], {
        locale: locale,
      }),
    }),
    assetDetailSell: t('asset_detail_sell', {
      percent: assetUnitsValueToPercentage(breakdownToObject['sell'], {
        locale: locale,
      }),
    }),
    assetDividendYield: t('asset_dividend_yeld', {
      year: assetData?.dividendYieldYear,
    }),
    assetDividendPerShare: t('asset_dividend_per_share', {
      year: assetData?.dividendPerShareYear,
    }),
    assetRevenue: t('asset_revenue', { year: assetData?.revenueYear }),
    assetPeRatio: t('asset_pe_ratio', {
      year: assetData?.peRatioYear,
    }),
    assetNetIncome: t('asset_net_income', {
      year:
        (assetData?.yearlyNetIncomes?.length &&
          assetData?.yearlyNetIncomes[0]?.period) ||
        '',
    }),
    legalUrl: t('legal_documents_link'),
  };
};
