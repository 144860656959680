import { FormEvent, ReactNode } from 'react';
import Link from 'next/link';
import { css } from '@bts-web/utils-style-engine';

export type LinkVisualVariants =
  | 'primary'
  | 'primaryUnderline'
  | 'secondary'
  | 'secondaryUnderline'
  | 'secondaryOnBrand'
  | 'secondaryOnBrandUnderline'
  | 'special'
  | 'specialUnderline'
  | 'caption'
  | 'captionUnderline';

export type LinkSizeVariants = 'extra_small' | 'small' | 'medium';

export type LinkProps = {
  visual?: LinkVisualVariants;
  size?: LinkSizeVariants;
  children: ReactNode;
  href: string;
  disabled?: boolean;
  RightComponent?: React.ReactElement;
  onClick?: (e: FormEvent<HTMLAnchorElement>) => void;
};

export const linkStyles = ({
  visual,
  size,
}: {
  visual: LinkVisualVariants;
  size: LinkSizeVariants;
}) =>
  css({
    display: 'inline-flex',
    alignItems: 'center',
    flexShrink: 0,
    cursor: 'pointer',
    gap: 'extra_small_2',
    textUnderlineOffset: '4px',
    '&[data-state="disabled"]': {
      cursor: 'default',
    },
    width: 'fit-content',
    ...(visual === 'primary'
      ? {
          color: 'neutrals.text_primary',
          '&[data-state="active"]:hover': {
            color: 'neutrals.fill_pressed',
          },
          '&[data-state="disabled"]': {
            color: 'neutrals.text_disabled',
          },
        }
      : {}),
    ...(visual === 'primaryUnderline'
      ? {
          color: 'brand.fill_primary',
          textDecoration: 'underline',
          '&[data-state="active"]:hover': {
            color: 'brand.text_primary',
          },
          '&[data-state="disabled"]': {
            color: 'brand.text_disabled',
          },
        }
      : {}),
    ...(visual === 'secondary'
      ? {
          color: 'brand.text_primary',
          '&[data-state="active"]:hover': {
            color: 'neutrals.fill_pressed',
          },
          '&[data-state="disabled"]': {
            color: 'brand.text_disabled',
            opacity: '0.3',
          },
        }
      : {}),
    ...(visual === 'secondaryUnderline'
      ? {
          color: 'brand.text_primary',
          textDecoration: 'underline',
          '&[data-state="active"]:hover': {
            color: 'neutrals.fill_pressed',
          },
          '&[data-state="disabled"]': {
            color: 'brand.text_disabled',
            opacity: '0.3',
          },
        }
      : {}),
    ...(visual === 'secondaryOnBrand'
      ? {
          color: 'brand.fill_primary',
          '&[data-state="active"]:hover': {
            color: 'brand.fill_pressed',
          },
          '&[data-state="disabled"]': {
            color: 'brand.text_disabled',
            opacity: '0.3',
          },
        }
      : {}),
    ...(visual === 'secondaryOnBrandUnderline'
      ? {
          color: 'neutrals.text_primary_inverted',
          textDecoration: 'underline',
          '&[data-state="active"]:hover': {
            color: 'brand.fill_pressed',
          },
          '&[data-state="disabled"]': {
            color: 'brand.text_disabled',
            opacity: '0.3',
          },
        }
      : {}),
    ...(visual === 'special'
      ? {
          color: 'neutrals.text_primary',
          backgroundColor: 'neutrals.fill_quaternary',
          borderRadius: '30px',
          padding: 'extra_small_2',
          '&[data-state="disabled"]': {
            backgroundColor: 'neutrals.fill_quinary',
            opacity: '0.5',
          },
        }
      : {}),
    ...(visual === 'specialUnderline'
      ? {
          color: 'neutrals.text_primary',
          backgroundColor: 'neutrals.fill_quaternary',
          borderRadius: '30px',
          padding: 'extra_small_2',
          textDecoration: 'underline',
          '&[data-state="disabled"]': {
            backgroundColor: 'neutrals.fill_quinary',
            opacity: '0.5',
          },
        }
      : {}),
    ...(visual === 'caption'
      ? {
          color: 'neutrals.text_secondary',
          '&[data-state="active"]:hover': {
            color: 'neutrals.fill_pressed',
          },
          '&[data-state="disabled"]': {
            color: 'neutrals.text_secondary',
            opacity: '0.3',
          },
        }
      : {}),
    ...(visual === 'captionUnderline'
      ? {
          color: 'neutrals.text_secondary',
          textDecoration: 'underline',
          '&[data-state="active"]:hover': {
            color: 'neutrals.fill_pressed',
          },
          '&[data-state="disabled"]': {
            color: 'neutrals.text_secondary',
            opacity: '0.3',
          },
        }
      : {}),
    ...(size === 'extra_small'
      ? {
          fontSize: 'caption.small',
          fontWeight: 'caption.small',
          lineHeight: 'body.medium_medium',
        }
      : {}),
    ...(size === 'small'
      ? {
          fontSize: 'body.medium_medium',
          fontWeight: 'body.medium_medium',
          lineHeight: 'body.medium_medium',
        }
      : {}),
    ...(size === 'medium'
      ? {
          fontSize: 'label.medium_medium',
          fontWeight: 'label.medium_medium',
          lineHeight: 'label.medium_medium',
        }
      : {}),
  });

export const ThemedLink = ({
  visual = 'primary',
  size = 'medium',
  href,
  disabled = false,
  children,
  RightComponent,
  onClick,
  ...linkProps
}: LinkProps) => {
  if (disabled) {
    return (
      <span
        data-testid="link"
        className={linkStyles({
          visual,
          size,
        })}
        data-state="disabled"
      >
        {children}
        {RightComponent}
      </span>
    );
  }

  return (
    <Link
      {...(onClick && { onClick: onClick })}
      data-testid="link"
      className={linkStyles({ visual, size })}
      href={href}
      data-state="active"
      {...linkProps}
    >
      {children}

      {RightComponent}
    </Link>
  );
};

export { ThemedLink as Link };
