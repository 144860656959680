import { itemsListHeaderStyles } from '../../common/styles/itemsListHeaderStyles';
import { css } from '@bts-web/utils-style-engine';

const titleStyles = css({
  fontSize: 'headline.small',
  fontWeight: 'headline.small',
  lineHeight: 'headline.small',
  letterSpacing: 'headline.small',
  padding: 'medium',
  paddingTop: 0,
  paddingInlineStart: 0,
  '@media (min-width: 1024px)': {
    fontSize: 'headline.small_medium',
    fontWeight: 'headline.small_medium',
    lineHeight: 'headline.small_medium',
    letterSpacing: 'headline.small_medium',
    paddingBottom: 'large',
  },
});

interface TransactionsPageHeaderProps {
  translations: {
    transactions: string;
    transactionDescription: string;
    amount: string;
  };
}

export const TransactionsPageHeader = ({
  translations,
}: TransactionsPageHeaderProps) => {
  return (
    <>
      <h1 className={titleStyles} aria-label="transactions page header">
        {translations.transactions}
      </h1>

      <div data-testid="columns-headers" className={itemsListHeaderStyles}>
        <span>{translations.transactionDescription}</span>
        <span>{translations.amount}</span>
      </div>
    </>
  );
};
