import { TradeOptionsDrawerContent } from './TradeOptionsDrawerContent';
import { Drawer } from '../../../layouts';

const TradeOptionsDrawer = ({
  savingsPlansCount,
}: {
  savingsPlansCount: number;
}) => {
  return (
    <Drawer
      position="bottom"
      contentNode={
        <TradeOptionsDrawerContent savingsPlansCount={savingsPlansCount} />
      }
    />
  );
};

export { TradeOptionsDrawer };
