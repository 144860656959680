'use client';

import { Icon } from '@bts-web/design-system/component/icon';
import { css } from '@bts-web/utils-style-engine';
import { i18n, type Language } from '@bts-web/utils-lokalise';
import { useChangeLanguage } from '../../../language';
import { Drawer } from '../../Drawer/Drawer';
import { DrawerTriggerButton } from '../../Drawer/DrawerTriggerButton';
import { DrawerContent } from '../../../common/components/DrawerContent/DrawerContent';
import { LanguageChangeMenu } from '../../../language/LanguageChangeMenu/LanguageChangeMenu.use-client';
import { useEffect, useState } from 'react';
import { useComposableDrawer } from '@bts-web/utils-context';
import { getAppConfig } from '@bts-web/core-app-config';

export type MobileLanguageDrawerTranslations = {
  errorTitle: string;
  errorSubtitle: string;
  germanSubtitle: string;
  englishSubtitle: string;
  title: string;
};

interface MobileLanguageDrawerProps {
  translations: MobileLanguageDrawerTranslations;
  contrastVariant?: boolean;
}

const languageDrawerId = 'mobile-language-drawer';

const MobileLanguageDrawer = ({
  translations,
  contrastVariant,
}: MobileLanguageDrawerProps) => {
  const { closeDrawer } = useComposableDrawer();

  const { landingPageLangIconColorScheme } = getAppConfig();

  const { onChangeLanguage, isUserUpdateLoading } = useChangeLanguage({
    translations: {
      errorSubtitle: translations.errorSubtitle,
      errorTitle: translations.errorTitle,
    },
  });

  const defaultSelectedLanguage = i18n.resolvedLanguage as Language;

  const [currentLanguage, setCurrentLanguage] = useState<Language>(
    defaultSelectedLanguage,
  );

  const onLanguageValueChange = (languageValue: string) => {
    setCurrentLanguage(languageValue as Language);

    closeDrawer();
  };

  useEffect(() => {
    if (currentLanguage !== defaultSelectedLanguage) {
      onChangeLanguage({
        languageValue: currentLanguage,
        onError: () => {
          setCurrentLanguage(defaultSelectedLanguage);
        },
      });
    }
  }, [currentLanguage]);

  const LanguageDrawer = () => (
    <Drawer
      data-testid={languageDrawerId}
      position="bottom"
      title={translations.errorTitle}
      contentNode={
        <div className={css({ minHeight: 300 })}>
          <DrawerContent
            title={translations.title}
            ariaLabel="language options"
          >
            <LanguageChangeMenu
              translations={translations}
              onLanguageValueChange={onLanguageValueChange}
              currentValue={currentLanguage}
            />
          </DrawerContent>
        </div>
      }
    />
  );

  return (
    <DrawerTriggerButton DrawerComponent={LanguageDrawer}>
      <button
        type="button"
        data-testid="mobile-language-button"
        className={css({
          cursor: 'pointer',
          color: contrastVariant ? 'brand.on_fill_primary' : 'inherit',
        })}
        disabled={isUserUpdateLoading}
      >
        {isUserUpdateLoading ? (
          <Icon
            aria-label="language change loading icon"
            data-element="loading-icon"
            theme="filled"
            size="20"
            icon="spinner-ios"
            className={
              landingPageLangIconColorScheme === 'dark'
                ? css({ color: 'neutrals.text_primary' })
                : css({ color: 'neutrals.text_secondary' })
            }
          />
        ) : (
          <Icon
            icon="globe"
            theme="regular"
            className={
              landingPageLangIconColorScheme === 'dark'
                ? css({ color: 'neutrals.text_primary' })
                : css({ color: 'neutrals.text_secondary' })
            }
          />
        )}
      </button>
    </DrawerTriggerButton>
  );
};

export { MobileLanguageDrawer };
