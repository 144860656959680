'use client';

import { FC, memo } from 'react';
import { useSearchParams } from 'next/navigation';
import { css } from '@bts-web/utils-style-engine';
import { intlAssetValueFormatting } from '@bts-web/utils-formatting';
import { AmountInput, AmountInputWrapper, Divider } from '../../../common';
import { useTradeForm } from './hooks/useTradeForm/useTradeForm';
import { DisplaySwitchWrapper } from '../../../common/components/DisplaySwitchWrapper';
import { BelowInputInfoTable } from '../../../common/components/BelowInputInfoTable/BelowInputInfoTable';
import { MainTradeFormErrorBox } from '../MainTradeFormErrorBox/MainTradeFormErrorBox';
import { QuickSelectButtons } from './subcomponents/QuickSelectButtons/QuickSelectButtons';
import { VERIFY_LINK_KEY } from '../constants';
import { TradeFormProps } from './types';
import { VerificationLinkForm } from './subcomponents/VerificationLinkForm';
import { HiddenInputs } from './subcomponents/HiddenInputs';
import { SubmitSection } from './subcomponents/SubmitSection';
import { SetUpSavingsPlanBox } from './subcomponents/SetUpSavingsPlanBox/SetUpSavingsPlanBox';

export const MainTradeForm: FC<TradeFormProps> = ({
  assetDetails,
  currentTradeDetails,
  locale,
  staticValues,
  isDataLoading,
}) => {
  const { formState, handlers } = useTradeForm({
    assetDetails,
    currentTradeDetails,
    staticValues,
  });

  const searchParams = useSearchParams();

  const verifyLink = searchParams.get(VERIFY_LINK_KEY);

  if (verifyLink) {
    return (
      <VerificationLinkForm
        formState={formState}
        staticValues={staticValues}
        currentTradeDetails={currentTradeDetails}
        verifyLink={verifyLink}
      />
    );
  }

  const submitDisabled =
    formState.inputValue?.length === 0 ||
    formState.formHasAnyErrors ||
    formState.allButtonsDisabled ||
    Number(formState.inputValue.replace(',', '.')) === 0;

  return (
    <form
      onSubmit={handlers.onFormSubmit}
      className={css({ flex: 1, display: 'flex', flexDir: 'column' })}
      data-testid="trade-form"
    >
      <HiddenInputs formState={formState} />

      <AmountInputWrapper
        inputContextData={formState.inputContextData}
        switcherElement={
          staticValues.transactionType === 'sell' ? (
            <DisplaySwitchWrapper
              parentValue={formState.displayType}
              items={[
                {
                  label: staticValues.translatedValues.selectorLabels.fiat,
                  value: 'FIAT',
                },
                {
                  label: staticValues.translatedValues.selectorLabels.asset,
                  value: 'ASSET',
                },
              ]}
              onChange={handlers.onChangeDisplayType}
            />
          ) : null
        }
      >
        {({ inputRef, modifiedBlur }) => {
          return (
            <AmountInput
              ref={inputRef}
              placeholder={formState.inputPlaceholder}
              value={formState.inputValue}
              disabled={formState.allButtonsDisabled}
              onBlur={modifiedBlur}
              onChange={(e) => handlers.onTradeInputChange(e.target.value)}
            />
          );
        }}
      </AmountInputWrapper>

      <div
        className={css({
          display: 'flex',
          flexDirection: 'column',
          gap: 'extra_small_2',
          pt: 'small',
          pb: 'medium',
        })}
      >
        <BelowInputInfoTable
          items={[
            {
              title: staticValues.translations['units'],
              value: formState.convertedSummary,
            },

            {
              title: staticValues.translations['minimum'],
              value: intlAssetValueFormatting(
                currentTradeDetails.minimumTradeAmount.FIAT?.value ?? 0,
                {
                  currency: staticValues.currency,
                  locale: staticValues.currentLocale,
                },
              ),
            },
          ]}
        />

        {formState.errors && (
          <MainTradeFormErrorBox
            errors={formState.errors}
            translatedValues={staticValues.translatedValues}
          />
        )}
      </div>

      <Divider />

      <QuickSelectButtons
        items={currentTradeDetails.quickSelectTradeItems}
        onQuickSelectButtonClick={handlers.onQuickSelectButtonClick}
        disabled={formState.allButtonsDisabled}
        displayType={formState.displayType}
        inputValue={formState.inputValue}
      />

      {staticValues.transactionType === 'savings' && (
        <div className={css({ paddingTop: 'medium' })}>
          <SetUpSavingsPlanBox
            translations={{
              setUpSavingsPlanBoxTitle:
                staticValues.translations.setUpSavingsPlanBoxTitle,
              setUpSavingsPlanBoxDescription:
                staticValues.translations.setUpSavingsPlanBoxDescription,
              frequency: staticValues.translations.frequency,
              startsAt: staticValues.translations.startsAt,
              monthly: staticValues.translations.MONTHLY,
              ok: staticValues.translations.ok,
              cancel: staticValues.translations.cancel,
            }}
            locale={locale}
            startsAtValue={formState.savingsPlanRecurringDate}
            onChangeDate={handlers.setSavingsPlanRecurringDate}
          />
        </div>
      )}

      <SubmitSection
        loading={(formState.isLoading || isDataLoading) && !submitDisabled}
        disabled={submitDisabled}
        staticValues={staticValues}
      />
    </form>
  );
};

export default memo(MainTradeForm);
