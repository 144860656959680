import { Icon, IconThemes } from '@bts-web/design-system/component/icon';
import { css } from '@bts-web/utils-style-engine';
import Link from 'next/link';
import { FC, ReactNode, useMemo } from 'react';

type BaseTopBarMenuItemProps = {
  icon?: string;
  iconTheme?: IconThemes;
  label: string;
  disabled?: boolean;
  endSlot?: ReactNode;
};

type ButtonTopBarMenuItemProps = BaseTopBarMenuItemProps & {
  type: 'button';
  action: () => void;
  onItemClick?: never;
};

type LinkTopBarMenuItemProps = BaseTopBarMenuItemProps & {
  type: 'link' | 'externalLink' | 'downloadLink';
  action: string;
  onItemClick?: () => void;
};

export type TopBarMenuItemProps =
  | ButtonTopBarMenuItemProps
  | LinkTopBarMenuItemProps;

export const TopBarMenuItem: FC<TopBarMenuItemProps> = ({
  action,
  icon,
  iconTheme = 'filled',
  label,
  type,
  disabled,
  endSlot,
  onItemClick,
}) => {
  const disabledLinkFunction = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.preventDefault();
  };

  const typeIsLink =
    type === 'link' || type === 'downloadLink' || type === 'externalLink';

  const { Element, elementProps } = useMemo(() => {
    let Element: typeof Link | 'a' | 'button' = Link;

    const elementProps: Record<
      string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      boolean | string | ((props: any) => void)
    > = {};

    if (disabled) {
      elementProps.disabled = true;

      elementProps.onClick = disabledLinkFunction;
    }

    if (type === 'button') {
      Element = 'button';

      elementProps.type = 'button';

      elementProps.onClick = action;
    }

    if (type === 'link') {
      elementProps.href = action;

      Element = Link;
    }

    if (type === 'externalLink') {
      elementProps.href = action;

      elementProps.target = '_blank';

      elementProps.rel = 'noopener noreferrer';

      Element = 'a';
    }

    if (type === 'downloadLink') {
      elementProps.href = action;

      elementProps.download = action;

      Element = 'a';
    }

    return { elementProps, Element };
  }, [action, disabled, type]);

  if (onItemClick && !disabled && typeIsLink) {
    elementProps.onClick = onItemClick;
  }

  return (
    <Element
      data-testid={label}
      aria-label={`${disabled ? 'disabled ' : ''}${type === 'button' ? 'button' : 'link'} for ${label}`}
      className={`${disabled ? 'disabled' : ''} ${css({
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: 'small',
        padding: 'small',
        color: 'neutrals.fill_primary',
        textDecoration: 'none',
        cursor: 'pointer',
        backgroundColor: 'screen_background.primary',
        textAlign: 'start',
        '&:hover': {
          '&:not(.disabled)': {
            backgroundColor: 'neutrals.fill_quaternary',
          },
        },
        '&.disabled': {
          userSelect: 'none',
          cursor: 'not-allowed',
          color: 'neutrals.text_disabled',
        },
      })}`}
      data-disabled={disabled}
      {...elementProps}
      href={!disabled ? (elementProps.href as string) : '#'}
    >
      <div
        className={css({ display: 'flex', alignItems: 'center', gap: 'small' })}
      >
        {icon && <Icon icon={icon} size="24" theme={iconTheme} />}
        {label}
      </div>
      {endSlot && (
        <div className={css({ marginInlineStart: 'auto' })}>{endSlot}</div>
      )}
    </Element>
  );
};
