'use client';

import { getLocale, i18n, Language } from '@bts-web/utils-lokalise';
import { css } from '@bts-web/utils-style-engine';
import { AmountFor } from '@bts-web/data-layer/server';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import {
  amountInputWrapperStyles,
  getInputClassNameByAmountLength,
  getAssetDisplayValueFromSource,
} from '../../../common';

export type AmountInputWrapperProps = {
  switcherElement?: ReactNode;
  inputContextData: {
    displayType: AmountFor;
    value: string;
    currency: string;
    symbol: string;
    placeholder?: string;
  };
  onInputBlur?: (value: string) => void;
  children?: (props: {
    inputRef: React.RefObject<HTMLInputElement | null>;
    modifiedBlur: (value: string) => void;
  }) => ReactNode;
};

export const AmountInputWrapper: FC<AmountInputWrapperProps> = ({
  switcherElement,
  children,
  onInputBlur,
  inputContextData,
}) => {
  const locale = getLocale(i18n.resolvedLanguage as Language);

  const { value, placeholder } = inputContextData;

  const sizeClassName = getInputClassNameByAmountLength(value);

  const [fillerDisplayValue, setFillerDisplayValue] = useState<string>('');

  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const modifiedBlur = (value: string) => {
    setIsFocused(false);

    if (onInputBlur) onInputBlur(value);
  };

  const onInputRegionFocus = () => {
    setIsFocused(true);

    setTimeout(() => {
      inputRef.current?.focus();
    }, 50);
  };

  useEffect(() => {
    const { currency, displayType, value, symbol } = inputContextData;

    setFillerDisplayValue(
      getAssetDisplayValueFromSource({
        currency: currency,
        locale,
        sourceAmount: value,
        sourceType: displayType,
        customSymbol: symbol,
      }),
    );
  }, [inputContextData]);

  const showPlaceHolder = value.length === 0;

  useEffect(() => {
    setTimeout(() => {
      if (children) {
        onInputRegionFocus();
      }
    }, 300);
  }, []);

  return (
    <div
      data-testid="amount-input-wrapper"
      className={css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
      })}
    >
      <div
        className={css({
          flex: 1,
          minWidth: 0,
          minHeight: 46,
        })}
      >
        {!isFocused && (
          <div className={amountInputWrapperStyles}>
            <div
              role="button"
              aria-label="change amount"
              data-testid="trade-summary-input-button"
              onClick={children ? onInputRegionFocus : undefined}
              className={`cursorPointer ${sizeClassName} ${showPlaceHolder ? 'placeHolderFiller' : ''}`}
            >
              {showPlaceHolder ? placeholder : fillerDisplayValue}
            </div>
          </div>
        )}

        {isFocused && (
          <div>
            {/* the input */}
            {children && inputRef !== null
              ? children({ inputRef, modifiedBlur })
              : null}
          </div>
        )}
      </div>

      {switcherElement && (
        <div
          className={css({
            flex: 'none',
            paddingInlineStart: 'extra_small_2',
          })}
        >
          {switcherElement}
        </div>
      )}
    </div>
  );
};
