import { css } from '@bts-web/utils-style-engine';
import { ReactNode } from 'react';

export type TagVisualVariants =
  | 'outlineNeutralSecondary'
  | 'outlineNeutralPrimary'
  | 'outlinePositive'
  | 'outlineWarning'
  | 'outlineNegative'
  | 'filledInfoStatus'
  | 'filledNeutralDark';

export type TagSizeVariants = 'medium';

export type TagProps = {
  visual?: TagVisualVariants;
  size?: TagSizeVariants;
  children: ReactNode;
};

export const Tag = ({ visual, size, children }: TagProps) => (
  <span
    data-testid="tag"
    className={css({
      borderRadius: '32px',
      display: 'inline-block',
      ...(visual === 'outlinePositive'
        ? {
            borderWidth: '1px',
            borderColor: 'positive.stroke_primary',
            color: 'positive.text_primary',
          }
        : {}),
      ...(visual === 'outlineWarning'
        ? {
            borderWidth: '1px',
            borderColor: 'warning.stroke_primary',
            color: 'warning.text_primary',
          }
        : {}),
      ...(visual === 'outlineNegative'
        ? {
            borderWidth: '1px',
            borderColor: 'negative.fill_primary',
            color: 'negative.text_primary',
          }
        : {}),
      ...(visual === 'outlineNeutralPrimary'
        ? {
            borderWidth: '1px',
            borderColor: 'neutrals.fill_primary',
            color: 'neutrals.text_primary',
          }
        : {}),
      ...(visual === 'outlineNeutralSecondary'
        ? {
            borderWidth: '1px',
            borderColor: 'neutrals.fill_primary',
            color: 'neutrals.text_primary_inverted',
          }
        : {}),

      ...(visual === 'filledNeutralDark'
        ? {
            background: 'neutrals.fill_disabled',
            color: 'neutrals.text_disabled',
          }
        : {}),

      ...(visual === 'filledInfoStatus'
        ? {
            borderWidth: '1px',
            borderColor: 'information.fill_primary',
            background: 'information.fill_primary',
            color: 'information.text_primary_inverted',
          }
        : {}),

      ...(size === 'medium'
        ? {
            paddingTop: 'extra_small_3',
            paddingInlineEnd: 'extra_small_2',
            paddingBottom: 'extra_small_3',
            paddingInlineStart: 'extra_small_2',
            fontSize: 'caption.medium_medium',
            fontWeight: 'caption.medium_medium',
            lineHeight: 'caption.medium_medium',
            letterSpacing: 'caption.medium_medium',
          }
        : {}),
    })}
  >
    {children}
  </span>
);
